import { LoginResponse } from '../models/login'
import { ErrorRawResponse, ErrorResponse } from '../models/error'
import { ResetActionTypes, ResetStoreAction } from './reset'

// Action Types

const SAVE_API_HOST = 'SAVE_API_HOST' as const
const POST_LOGIN = 'POST_LOGIN' as const
const POST_LOGIN_REQUEST = 'POST_LOGIN_REQUEST' as const
const POST_LOGIN_SUCCESS = 'POST_LOGIN_SUCCESS' as const
const POST_LOGIN_REGISTRATION_REQUIRED_SUCCESS =
  'POST_LOGIN_REGISTRATION_REQUIRED_SUCCESS' as const
const POST_LOGIN_FAILURE = 'POST_LOGIN_FAILURE' as const
const LINK_LINE_ACCOUNT = 'LINK_LINE_ACCOUNT' as const
const LINK_LINE_ACCOUNT_REQUEST = 'LINK_LINE_ACCOUNT_REQUEST' as const
const LINK_LINE_ACCOUNT_SUCCESS = 'LINK_LINE_ACCOUNT_SUCCESS' as const
const LINK_LINE_ACCOUNT_FAILURE = 'LINK_LINE_ACCOUNT_FAILURE' as const
const UNLINK_LINE_ACCOUNT = 'UNLINK_LINE_ACCOUNT' as const
const UNLINK_LINE_ACCOUNT_REQUEST = 'UNLINK_LINE_ACCOUNT_REQUEST' as const
const UNLINK_LINE_ACCOUNT_SUCCESS = 'UNLINK_LINE_ACCOUNT_SUCCESS' as const
const UNLINK_LINE_ACCOUNT_FAILURE = 'UNLINK_LINE_ACCOUNT_FAILURE' as const

export const LoginActionTypes = {
  POST_LOGIN,
  // POST_LOGIN_REQUEST,
  // POST_LOGIN_SUCCESS,
  // POST_LOGIN_FAILURE,
  LINK_LINE_ACCOUNT,
  // LINK_LINE_ACCOUNT_REQUEST,
  // LINK_LINE_ACCOUNT_SUCCESS,
  // LINK_LINE_ACCOUNT_FAILURE,
  UNLINK_LINE_ACCOUNT,
  // UNLINK_LINE_ACCOUNT_REQUEST,
  // UNLINK_LINE_ACCOUNT_SUCCESS,
  // UNLINK_LINE_ACCOUNT_FAILURE,
}

// Action Creators

function saveApiHost(apiHost: string) {
  return {
    type: SAVE_API_HOST,
    payload: { apiHost },
  }
}

function postLogin(linetoken: string) {
  return {
    type: POST_LOGIN,
    payload: { linetoken },
  }
}

function postLoginRequest() {
  return {
    type: POST_LOGIN_REQUEST,
  }
}

function postLoginRegistrationRequiredSuccess(
  _errorStyleResponse: ErrorRawResponse
) {
  return {
    type: POST_LOGIN_REGISTRATION_REQUIRED_SUCCESS,
  }
}

function postLoginSuccess(login: LoginResponse) {
  return {
    type: POST_LOGIN_SUCCESS,
    payload: { login },
  }
}

function postLoginFailure(error: ErrorResponse) {
  return {
    type: POST_LOGIN_FAILURE,
    payload: { error },
  }
}

function linkLineAccount(linetoken: string) {
  return {
    type: LINK_LINE_ACCOUNT,
    payload: { linetoken },
  }
}

function linkLineAccountRequest() {
  return {
    type: LINK_LINE_ACCOUNT_REQUEST,
  }
}

function linkLineAccountSuccess() {
  return {
    type: LINK_LINE_ACCOUNT_SUCCESS,
  }
}

function linkLineAccountFailure(error: any) {
  return {
    type: LINK_LINE_ACCOUNT_FAILURE,
    payload: {
      error,
    },
  }
}

function unlinkLineAccount() {
  return {
    type: UNLINK_LINE_ACCOUNT,
  }
}

function unlinkLineAccountRequest() {
  return {
    type: UNLINK_LINE_ACCOUNT_REQUEST,
  }
}

function unlinkLineAccountSuccess() {
  return {
    type: UNLINK_LINE_ACCOUNT_SUCCESS,
  }
}

function unlinkLineAccountFailure(error: any) {
  return {
    type: UNLINK_LINE_ACCOUNT_FAILURE,
    payload: {
      error,
    },
  }
}

export const LoginActionCreators = {
  saveApiHost,
  postLogin,
  postLoginRequest,
  postLoginRegistrationRequiredSuccess,
  postLoginSuccess,
  postLoginFailure,
  linkLineAccount,
  linkLineAccountRequest,
  linkLineAccountSuccess,
  linkLineAccountFailure,
  unlinkLineAccount,
  unlinkLineAccountRequest,
  unlinkLineAccountSuccess,
  unlinkLineAccountFailure,
}

// Actions

type SaveApiHostAction = ReturnType<typeof saveApiHost>
export type PostLoginAction = ReturnType<typeof postLogin>
type PostLoginRequestAction = ReturnType<typeof postLoginRequest>
type PostLoginRegistrationRequiredSuccessAction = ReturnType<
  typeof postLoginRegistrationRequiredSuccess
>
type PostLoginSuccessAction = ReturnType<typeof postLoginSuccess>
type PostLoginFailureAction = ReturnType<typeof postLoginFailure>
export type LinkLineAccountAction = ReturnType<typeof linkLineAccount>
type LinkLineAccountRequestAction = ReturnType<typeof linkLineAccountRequest>
type LinkLineAccountSuccessAction = ReturnType<typeof linkLineAccountSuccess>
type LinkLineAccountFailureAction = ReturnType<typeof linkLineAccountFailure>
export type UnlinkLineAccountAction = ReturnType<typeof unlinkLineAccount>
type UnlinkLineAccountRequestAction = ReturnType<
  typeof unlinkLineAccountRequest
>
type UnlinkLineAccountSuccessAction = ReturnType<
  typeof unlinkLineAccountSuccess
>
type UnlinkLineAccountFailureAction = ReturnType<
  typeof unlinkLineAccountFailure
>

export type LoginAction =
  | SaveApiHostAction
  | PostLoginAction
  | PostLoginRequestAction
  | PostLoginRegistrationRequiredSuccessAction
  | PostLoginSuccessAction
  | PostLoginFailureAction
  | LinkLineAccountAction
  | LinkLineAccountRequestAction
  | LinkLineAccountSuccessAction
  | LinkLineAccountFailureAction
  | UnlinkLineAccountAction
  | UnlinkLineAccountRequestAction
  | UnlinkLineAccountSuccessAction
  | UnlinkLineAccountFailureAction
  | ResetStoreAction

// State

interface LoginState {
  apiHost: string | undefined
  loginRequired: boolean
  registrationRequired: boolean
  token: string | undefined
  expireIn: number | undefined
  expiresAt: Date | undefined
  loginFailed: boolean
  error: ErrorResponse | undefined
}

const initialState: LoginState = {
  apiHost: undefined,
  loginRequired: true,
  registrationRequired: false,
  token: undefined,
  expireIn: undefined,
  expiresAt: undefined,
  loginFailed: false,
  error: undefined,
}

// Selector

export function apiHostSelector(rootState: { login: LoginState }): string {
  return rootState?.login?.apiHost!
}

export function tokenSelector(rootState: { login: LoginState }): string {
  return rootState?.login?.token!
}

export function loginStatusSelector(rootState: { login: LoginState }) {
  return {
    token: rootState?.login?.token!,
    loginRequired: rootState?.login?.loginRequired,
    registrationRequired: rootState?.login?.registrationRequired,
    expiresAt: rootState?.login?.expiresAt,
    loginFailed: rootState?.login?.loginFailed,
    error: rootState?.login?.error,
  }
}

// Reducer

export function loginReducer(
  state = initialState,
  action: LoginAction
): LoginState {
  switch (action.type) {
    case SAVE_API_HOST:
      return {
        ...state,
        apiHost: action.payload.apiHost,
      }
    case POST_LOGIN_REQUEST:
      return {
        ...state,
        loginRequired: false,
        loginFailed: false,
        error: undefined,
      }
    case POST_LOGIN_REGISTRATION_REQUIRED_SUCCESS:
      return {
        ...state,
        registrationRequired: true,
      }
    case POST_LOGIN_SUCCESS:
      const login: LoginResponse = action.payload.login
      return {
        ...state,
        loginRequired: false,
        registrationRequired: login.registrationRequired,
        token: login.token,
        expireIn: login.expireIn,
        expiresAt: login.expiresAt,
        loginFailed: false,
      }
    case POST_LOGIN_FAILURE:
      return {
        ...state,
        token: undefined,
        expireIn: undefined,
        expiresAt: undefined,
        loginFailed: true, // Probably LINE accessToken is expired
        error: action.payload.error,
      }
    case LINK_LINE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loginRequired: true,
        registrationRequired: false,
      }
    case LINK_LINE_ACCOUNT_FAILURE:
      return {
        ...state,
        loginRequired: true,
        registrationRequired: true,
      }
    case UNLINK_LINE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loginRequired: true,
        registrationRequired: true,
      }
    case UNLINK_LINE_ACCOUNT_FAILURE:
      // FIXME: need to check flow
      return {
        ...state,
        loginRequired: true,
        registrationRequired: true,
      }
    case ResetActionTypes.RESET_STORE:
      return { ...initialState, apiHost: state.apiHost }
    default:
      return state
  }
}
