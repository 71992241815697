import React from 'react'

export function ArrowNextIcon() {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>ico_arrow_next</title>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline
          stroke="#FFFFFF"
          strokeWidth="2.7"
          points="9.0225 5.2363 15.7865 12.0003 9.0225 18.7633"
        ></polyline>
      </g>
    </svg>
  )
}
