import React, { PropsWithChildren } from 'react'

interface LinkIconProps {
  color?: string
}

export function LinkIcon({ color }: PropsWithChildren<LinkIconProps>) {
  const fill = color || '#777777'
  const stroke = fill

  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>ico_arrow_circlenext</title>
      <g
        id="ico_arrow_circlenext"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M12,0 C5.373,0 0,5.373 0,12 C0,18.627 5.373,24 12,24 C18.627,24 24,18.627 24,12 C24,5.373 18.627,0 12,0 M12,3 C16.962,3 21,7.038 21,12 C21,16.962 16.962,21 12,21 C7.038,21 3,16.962 3,12 C3,7.038 7.038,3 12,3"
          id="Fill-1"
          fill={fill}
        ></path>
        <polyline
          id="Stroke-3"
          stroke={stroke}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="12.729 17.103 17.103 12 12.729 6.897"
        ></polyline>
        <line
          x1="7"
          y1="12"
          x2="16"
          y2="12"
          id="Stroke-5"
          stroke={stroke}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></line>
      </g>
    </svg>
  )
}
