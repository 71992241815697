import React from 'react'

export function RadioOffIcon() {
  return (
    <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
      <circle
        stroke="#D8D8D8"
        strokeWidth="3"
        fill="#FFF"
        cx="16"
        cy="16"
        r="14"
        fillRule="evenodd"
      />
    </svg>
  )
}
