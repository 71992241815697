import React, { useState, useCallback, useEffect } from 'react'

import { BackNavButtonPanel } from './BackNavPanel'
import { MembershipAgreementPanel } from './MembershipAgreementPanel'
import { FullScreen } from '../molecules/FullScreen'
import { useScroll } from '../../hooks/scroll'
import { Padding } from '../atoms/Padding'

export function useMembershipAgreement() {
  const [membershipAgreementVisibility, setMembershipAgreementVisibility] =
    useState<boolean>(false)
  const { saveCurrentScrollY, resetScroll, restoreScroll } = useScroll()
  const handleMembershipAgreementOpen = useCallback(() => {
    saveCurrentScrollY()
    setMembershipAgreementVisibility(true)
  }, [setMembershipAgreementVisibility, saveCurrentScrollY])
  const haneldMembershipAgreementClose = useCallback(() => {
    setMembershipAgreementVisibility(false)
  }, [setMembershipAgreementVisibility])

  useEffect(() => {
    if (membershipAgreementVisibility) {
      resetScroll()
    } else {
      restoreScroll()
    }
  }, [membershipAgreementVisibility, resetScroll, restoreScroll])

  return {
    membershipAgreementVisibility,
    handleMembershipAgreementOpen,
    haneldMembershipAgreementClose,
  }
}

export interface FullScreenMembershipAgreementProps {
  backLabel: string
  onClose: () => void
}

export function FullScreenMembershipAgreement({
  backLabel,
  onClose,
}: FullScreenMembershipAgreementProps) {
  return (
    <FullScreen>
      <BackNavButtonPanel label={backLabel} onClick={onClose} />
      <Padding bottom="40px">
        <MembershipAgreementPanel />
      </Padding>
    </FullScreen>
  )
}
