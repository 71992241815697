import { History } from 'history'
import { applyMiddleware, combineReducers, createStore, compose } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'

import { loginReducer } from './modules/login'
import { memberReducer } from './modules/member'
import { pointReducer } from './modules/point'
import { tradeReducer } from './modules/trade'
import { announcementReducer } from './modules/announcement'
import { couponReducer } from './modules/coupon'
import { reservationReducer } from './modules/reservation'
import rootSaga from './sagas'

export interface RootState {
  router: ReturnType<typeof connectRouter>
  login: ReturnType<typeof loginReducer>
  member: ReturnType<typeof memberReducer>
  point: ReturnType<typeof pointReducer>
  trade: ReturnType<typeof tradeReducer>
  announcement: ReturnType<typeof announcementReducer>
  coupon: ReturnType<typeof couponReducer>
  reservation: ReturnType<typeof reservationReducer>
}

const isDevelopment = process.env.NODE_ENV === 'development'

const composeEnhancers =
  (isDevelopment && (window as any)?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

export function createFinalStore(history: History) {
  const reducers = combineReducers({
    router: connectRouter(history),
    login: loginReducer,
    member: memberReducer,
    point: pointReducer,
    trade: tradeReducer,
    announcement: announcementReducer,
    coupon: couponReducer,
    reservation: reservationReducer,
  })
  const sagaMiddleware = createSagaMiddleware()
  const middlewares = [routerMiddleware(history), sagaMiddleware]
  const enhancers = composeEnhancers(applyMiddleware(...middlewares))

  const store = createStore(reducers, enhancers)

  sagaMiddleware.run(rootSaga, { history })

  return store
}
