import React, { CSSProperties, MouseEvent, PropsWithChildren } from 'react'

const styles: { [name: string]: CSSProperties } = {
  panel: {
    borderRadius: '8px 8px 8px 8px',
    backgroundColor: '#FFFFFF',
    padding: '16px',
  },
}

interface RoundedPanelProps {
  style?: CSSProperties
  onClick?: ((event: MouseEvent<HTMLDivElement>) => void) | undefined
}

export function RoundedPanel({
  style,
  onClick,
  children,
}: PropsWithChildren<RoundedPanelProps>) {
  return (
    <div style={{ ...styles.panel, ...style }} onClick={onClick}>
      {children}
    </div>
  )
}
