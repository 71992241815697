export interface ErrorRawResponse {
  status: number
  message: string
}

export interface ErrorResponse {
  status: number
  message: string
  httpStatus?: number
}

export function fromErrorRawResponse(
  payload: ErrorRawResponse,
  httpStatus?: number
): ErrorResponse {
  if (payload.status === undefined && payload.message === undefined) {
    return {
      status: -1,
      message: JSON.stringify(payload),
      httpStatus: httpStatus,
    }
  }

  return {
    status: payload.status,
    message: payload.message,
    httpStatus: httpStatus,
  }
}

export function fromAuthCodeErrorRawResponse(
  payload: ErrorRawResponse,
  httpStatus?: number
): ErrorResponse {
  if (payload.status === undefined && payload.message === undefined) {
    return {
      status: -1,
      message: JSON.stringify(payload),
      httpStatus: httpStatus,
    }
  }

  const message = (status: number) => {
    switch (status) {
      case 201:
        return '正しいメールアドレスを入力してください'
      case 402:
        return '入力いただいたメールアドレスは既に登録されています'
      case 100:
        return 'エラーが発生しました。ご迷惑をおかけしています。\n左上の「戻る」ボタンを押していただき再度お試しください。'
      case 200:
        return 'エラーが発生しました。ご迷惑をおかけしています。\nお客様窓口までご連絡いただきエラーコードをお知らせください。'
      case 209:
        return 'エラーが発生しました。ご迷惑をおかけしています。\n左上の「戻る」ボタンを押していただき再度お試しください。'
      default:
        return 'エラーが発生しました。ご迷惑をおかけしています。\n左上の「戻る」ボタンを押していただき再度お試しください。'
    }
  }

  return {
    status: payload.status,
    message: message(payload.status),
    httpStatus: httpStatus,
  }
}

export function fromVerifyAuthCodeErrorRawResponse(
  payload: ErrorRawResponse,
  httpStatus?: number
): ErrorResponse {
  if (payload.status === undefined && payload.message === undefined) {
    return {
      status: -1,
      message: JSON.stringify(payload),
      httpStatus: httpStatus,
    }
  }

  const message = (status: number) => {
    switch (status) {
      case 201:
        return '入力された認証コードで認証ができませんでした'
      case 100:
        return 'エラーが発生しました。ご迷惑をおかけしています。\n左上の「戻る」ボタンを押していただき再度お試しください。'
      case 200:
        return 'エラーが発生しました。ご迷惑をおかけしています。\nお客様窓口までご連絡いただきエラーコードをお知らせください。'
      case 206:
        return '入力された認証コードで認証ができませんでした'
      case 207:
        return '認証コードの有効期限が切れました。\n最初からやりなおしてください。'
      case 209:
        return 'エラーが発生しました。ご迷惑をおかけしています。\n左上の「戻る」ボタンを押していただき再度お試しください。'
      case 210:
        return 'エラーが発生しました。最初からやりなおしてください。\nお客様窓口までご連絡いただきエラーコードをお知らせください。'
      case 402:
        return 'エラーが発生しました。ご迷惑をおかけしています。\n左上の「戻る」ボタンを押していただき再度お試しください。'
      default:
        return 'エラーが発生しました。ご迷惑をおかけしています。\n左上の「戻る」ボタンを押していただき再度お試しください。'
    }
  }

  return {
    status: payload.status,
    message: message(payload.status),
    httpStatus: httpStatus,
  }
}
