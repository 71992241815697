import React, { CSSProperties } from 'react'

import { Panel } from '../atoms/Panel'
import { BackIcon } from '../atoms/icons/BackIcon'
import { NoDecorationLink } from '../atoms/NoDecorationLink'

interface BackNavProps {
  label: string
}

interface BackNavButtonPanelProps {
  label: string
  onClick: () => void
}

interface BackNavLinkPanelProps {
  label: string
  to: string
}

const styles: { [name: string]: CSSProperties } = {
  fixedTop: {
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    width: '100%',
    backgroundColor: '#f2f2f2',
    zIndex: 10,
  },
  paddingSpace: {
    height: '64px',
    width: '100%',
  },
  panel: {
    height: '64px',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: '#d8d8d8',
  },
  gridStyle: {
    height: '64px',
    display: 'grid',
    gridTemplateColumns: '45px auto',
  },
  icon: {
    alignSelf: 'center',
    justifySelf: 'left',
  },
  label: {
    fontSize: '16px',
    alignSelf: 'center',
    justifySelf: 'left',
    // textDecoration: 'none',
  },
  link: {
    textDecoration: 'none',
  },
  button: {
    margin: 0,
    border: 0,
    padding: 0,
    width: '100%',
    // backgroundColor: 'red',
  },
}

function BackNav({ label }: BackNavProps) {
  return (
    <Panel style={styles.panel} gridStyle={styles.gridStyle}>
      <div style={styles.icon}>
        <BackIcon />
      </div>
      <div style={styles.label}>{label}</div>
    </Panel>
  )
}

export function BackNavButtonPanel({
  label,
  onClick,
}: BackNavButtonPanelProps) {
  return (
    <>
      <div style={styles.fixedTop}>
        <button onClick={onClick} style={styles.button}>
          <BackNav label={label} />
        </button>
      </div>
      <div style={styles.paddingSpace} />
    </>
  )
}

export function BackNavLinkPanel({ label, to }: BackNavLinkPanelProps) {
  return (
    <>
      <div style={styles.fixedTop}>
        <NoDecorationLink to={to || '/'} style={styles.link}>
          <BackNav label={label} />
        </NoDecorationLink>
      </div>
      <div style={styles.paddingSpace} />
    </>
  )
}
