import React, { CSSProperties, useCallback } from 'react'

import { IconButton } from './IconButton'
import { BellIcon } from '../atoms/icons/BellIcon'

interface BellButtonProps {
  badgeCount?: number
}

function Label() {
  const style: CSSProperties = {
    fontSize: '12px',
    fontWeight: 'bold',
    textAlign: 'center',
  }
  return <div style={style}>お知らせ</div>
}

export function BellButton({ badgeCount }: BellButtonProps) {
  const Icon = useCallback(() => {
    return <BellIcon badgeCount={badgeCount} />
  }, [badgeCount])
  return <IconButton icon={Icon} label={Label} />
}
