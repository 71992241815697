import React, { CSSProperties } from 'react'

export function EmptyListPanel({ label }: { label: string }) {
  const style: CSSProperties = {
    padding: '11px 16px 0 16px',
    fontSize: '12px',
    lineHeight: '16px',
  }

  return <div style={style}>{label}</div>
}
