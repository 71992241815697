import React, { CSSProperties } from 'react'

import { TotalPoint } from '../../models/point'
import { numberWithCommas } from '../../utils/numberWithCommas'
import { PointCardHistorySummary } from '../molecules/PointCardHistorySummary'

interface PointHistorySummaryPanelProps {
  totalPoint: TotalPoint
}

const styles: { [name: string]: CSSProperties } = {
  container: {
    borderBottom: '1px solid #D8D8D8',
  },
  remark: {
    fontSize: '12px',
    fontWeight: 'normal',
    padding: '8px 18px',
  },
  summary: {
    height: '77px',
    backgroundColor: '#F0F0F0',
    padding: '8px 16px 4px 16px',
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
  },
  box: {
    borderRadius: '8px',
    paddingTop: '8px',
    paddingLeft: '12px',
    paddingRight: '12px',
    paddingBottom: '4px',
    display: 'grid',
    // gridTemplateRows: 'auto auto',
    alignContent: 'center',
  },
  availablePointBox: {
    backgroundColor: '#FFFFFF',
  },
  label: {
    fontSize: '10px',
    fontWeight: 'bold',
    lineHeight: '15px',
    color: '#5B5B5B',
  },
  value: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    textAlign: 'right',
  },
  grandTotalValue: {
    color: '#F06400',
  },
}

export function PointHistorySummaryPanel({
  totalPoint,
}: PointHistorySummaryPanelProps) {
  return (
    <div style={styles.container}>
      <div style={styles.remark}>
        ポイントの有効期限は最終御利用日から1年間有効です。
      </div>
      <div style={styles.summary}>
        <div style={{ ...styles.box, ...styles.availablePointBox }}>
          <span style={styles.label}>利用可能ポイント</span>
          <span style={{ ...styles.value, ...styles.grandTotalValue }}>
            {numberWithCommas(totalPoint.grandTotalPoints)}
          </span>
        </div>
        <div style={styles.box}>
          <span style={styles.label}>通常ポイント</span>
          <span style={styles.value}>
            {numberWithCommas(totalPoint.totalPoinstWithoutTimeLimit)}
          </span>
        </div>
        <div style={styles.box}>
          <span style={styles.label}>期間限定ポイント</span>
          <span style={styles.value}>
            {numberWithCommas(totalPoint.totalPoinstWithTimeLimit)}
          </span>
        </div>
      </div>
      <PointCardHistorySummary
        headerText="期間限定ポイント（内訳）"
        expirationPointInfos={totalPoint.expirationPoints}
      />
    </div>
  )
}
