import { CouponMeta, Coupon, CouponsSearchOptions } from '../models/coupon'
import { ResetActionTypes, ResetStoreAction } from './reset'

// Action Types

const FETCH_COUPONS = 'FETCH_COUPONS' as const
const FETCH_COUPONS_REQUEST = 'FETCH_COUPONS_REQUEST' as const
const FETCH_COUPONS_SUCCESS = 'FETCH_COUPONS_SUCCESS' as const
const FETCH_COUPONS_FAILURE = 'FETCH_COUPONS_FAILURE' as const

export const CouponActionTypes = {
  FETCH_COUPONS,
  // FETCH_COUPONS_REQUEST,
  // FETCH_COUPONS_SUCCESS,
  // FETCH_COUPONS_FAILURE,
}

// Action Creators

export function fetchCouponsAction(options: CouponsSearchOptions) {
  return {
    type: FETCH_COUPONS,
    payload: { options },
  }
}

function fetchCouponsRequestAction(options: CouponsSearchOptions) {
  return {
    type: FETCH_COUPONS_REQUEST,
    payload: { options },
  }
}

function fetchCouponsSuccessAction(meta: CouponMeta, coupons: Coupon[]) {
  return {
    type: FETCH_COUPONS_SUCCESS,
    payload: { meta, coupons },
  }
}

function fetchCouponsFailureAction(error: any) {
  return {
    type: FETCH_COUPONS_FAILURE,
    payload: { error },
  }
}

export const CouponActionCreators = {
  fetchCouponsAction,
  fetchCouponsRequestAction,
  fetchCouponsSuccessAction,
  fetchCouponsFailureAction,
}

// Actions

export type FetchCouponsAction = ReturnType<typeof fetchCouponsAction>

type CouponAction =
  | FetchCouponsAction
  | ReturnType<typeof fetchCouponsSuccessAction>
  | ReturnType<typeof fetchCouponsSuccessAction>
  | ReturnType<typeof fetchCouponsFailureAction>
  | ResetStoreAction

// State

interface CouponState {
  meta: CouponMeta | undefined
  coupons: Coupon[] | undefined
}

const initialState: CouponState = {
  meta: undefined,
  coupons: undefined,
}

// Selector

export function couponMetaSelector(rootState: { coupon: CouponState }) {
  return rootState.coupon.meta
}

export function couponsSelector(rootState: { coupon: CouponState }) {
  return rootState.coupon.coupons
}

// Reducer

export function couponReducer(
  state = initialState,
  action: CouponAction
): CouponState {
  switch (action.type) {
    case FETCH_COUPONS_SUCCESS:
      return {
        ...state,
        meta: action.payload.meta,
        coupons: action.payload.coupons,
      }
    case ResetActionTypes.RESET_STORE:
      return initialState
    default:
      return state
  }
}
