import React from 'react'

import { InputProps, Input } from '../atoms/Input'

export function InputEmail({
  name,
  value,
  readOnly,
  variant,
  onChange,
  ...rest
}: InputProps) {
  return (
    <Input
      type="email"
      name={name}
      value={value}
      readOnly={readOnly}
      variant={variant}
      onChange={onChange}
      {...rest}
    />
  )
}
