import React, { CSSProperties } from 'react'

import { IconButton } from './IconButton'
import { LineOfficialAccountIcon } from '../atoms/icons/LineOfficialAccountIcon'

function Label() {
  const style: CSSProperties = {
    fontSize: '12px',
    fontWeight: 'bold',
    textAlign: 'center',
  }
  return (
    <>
      <div style={style}>LINE公式</div>
      <div style={style}>アカウント</div>
    </>
  )
}

export function LineOfficialAccountButton() {
  return <IconButton icon={LineOfficialAccountIcon} label={Label} />
}
