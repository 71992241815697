import React, { PropsWithChildren } from 'react'

interface BackIconProps {}

export function BackIcon({ children }: PropsWithChildren<BackIconProps>) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>ico_return</title>
      <g
        id="ico_return"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M1.35,21.8498 L14.115,21.8498 C18.67,21.8498 22.365,18.1568 22.365,13.5998 C22.365,9.0428 18.67,5.3498 14.115,5.3498 L1.35,5.3498"
          id="Stroke-1"
          stroke="#5B5B5B"
          strokeWidth="2.7"
        ></path>
        <polyline
          id="Stroke-3"
          stroke="#5B5B5B"
          strokeWidth="2.7"
          points="4.35 2.3498 1.35 5.3498 4.35 8.3498"
        ></polyline>
      </g>
    </svg>
  )
}
