import React, { CSSProperties } from 'react'

import { MemberRank } from '../../models/member'
import { ExpirationPointInfo } from '../../models/point'
import { formatDate } from '../../utils/date'

interface PointCardHistorySummaryProps {
  headerText: string
  memberRank?: MemberRank
  isEmployee?: boolean
  expirationPointInfos?: ExpirationPointInfo[]
}

const backgroundColorPalette: { [name: string]: string } = {
  guest: '#00DB54', // '#0cc81d',
  general: '#ff9500', // '#f06400',
  vip: '#ea3f0c', // '#cf1414',
  employee: '#009cff', // '#017dff',
}

const styles: { [name: string]: CSSProperties } = {
  container: {
    paddingTop: '4px',
    paddingLeft: '12px',
    paddingRight: '10px',
    paddingBottom: '8px',
    // border: '1px solid black',
  },
  list: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridAutoRows: '18px',
    paddingTop: '8px',
    paddingBottom: '8px',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
  },
  header: {
    fontSize: '10px',
    lineHeight: '15px',
    fontWeight: 'bold',
    paddingLeft: '8px',
    color: '#282828',
  },
  listItem: {
    fontSize: '10px',
    lineHeight: '15px',
    fontWeight: 'bold',
    paddingLeft: '8px',
    color: '#5b5b5b',
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    alignContent: 'center',
  },
  listItemEven: {
    backgroundColor: '#f2f2f2',
  },
  listItemOdd: {
    backgroundColor: '#ffffff',
  },
  listItemExpiration: {
    justifySelf: 'start',
    paddingLeft: '16px',
  },
  listItemPoint: {
    justifySelf: 'end',
    paddingRight: '16px',
  },
}

export function PointCardHistorySummary({
  headerText,
  memberRank,
  isEmployee,
  expirationPointInfos,
}: PointCardHistorySummaryProps) {
  const containerStyle: CSSProperties =
    isEmployee !== undefined && memberRank
      ? {
          ...styles.container,
          backgroundColor:
            backgroundColorPalette[isEmployee ? 'employee' : memberRank],
        }
      : styles.container

  return (
    <div style={containerStyle}>
      <div style={styles.list}>
        <div style={styles.header}>{headerText}</div>
        {expirationPointInfos && expirationPointInfos.length === 0 ? (
          <div style={{ ...styles.listItem, ...styles.listItemEven }}>
            <div style={styles.listItemExpiration}>− − − − まで</div>
            <div style={styles.listItemPoint}>内 0 ポイント</div>
          </div>
        ) : (
          expirationPointInfos &&
          expirationPointInfos.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  ...styles.listItem,
                  ...(index % 2 === 0
                    ? styles.listItemEven
                    : styles.listItemOdd),
                }}
              >
                <div style={styles.listItemExpiration}>
                  {formatDate(item.expirationDateString)} まで
                </div>
                <div style={styles.listItemPoint}>
                  内 {item.expirationPoints} ポイント
                </div>
              </div>
            )
          })
        )}
      </div>
    </div>
  )
}
