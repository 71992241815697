import React, { CSSProperties } from 'react'

import { Panel } from '../atoms/Panel'

interface EmailVerificationHelpPanelProps {
  apiErrorMessage?: string
  apiErrorStatus?: number
}

const styles: { [name: string]: CSSProperties } = {
  wrapper1: {
    height: '64px',
    backgroundColor: '#ff9501',
    paddingTop: '8px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  title: {
    color: '#fff',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  wrapper2: {
    // height: '64px',
    // backgroundColor: '#fff',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  message: {
    fontSize: '14px',
    lineHeight: '21px',
    textAlign: 'left',
    whiteSpace: 'pre-wrap',
  },
  message2: {
    fontSize: '10px',
    lineHeight: '21px',
    textAlign: 'left',
  },
}

export function EmailVerificationHelpPanel({
  apiErrorMessage,
  apiErrorStatus,
}: EmailVerificationHelpPanelProps) {
  return (
    <>
      <Panel fullWidth style={styles.wrapper1}>
        <div style={styles.title}>入力したメールアドレスへ届いた</div>
        <div style={styles.title}>【認証コード】を入力してください</div>
      </Panel>
      {apiErrorMessage && (
        <Panel style={styles.wrapper2}>
          <div style={styles.message}>{apiErrorMessage}</div>
          {apiErrorStatus && (
            <div style={styles.message2}>
              {`（エラーコード：${apiErrorStatus}）`}
            </div>
          )}
        </Panel>
      )}
    </>
  )
}
