import React, { CSSProperties } from 'react'
import {
  TradeHistoryRecordDetail,
  TradeHistoryRecordDetailOfCoupon,
  TradeHistoryRecordDetailOfPoint,
  TradeHistoryRecordDetailOfShiharai,
  TradeHistoryRecordDetailOfDiscount,
  TradeHistoryRecordDetailOfShohin,
} from '../../models/trade'
import { numberWithCommas } from '../../utils/numberWithCommas'
import { discountablePrice } from '../../utils/receipt'

const detailStyles: { [name: string]: CSSProperties } = {
  container: {
    paddingBottom: '8px',
    fontSize: '12px',
    lineHeight: '18px',
    display: 'grid',
    gridTemplateRows: 'auto',
    color: '#282828',
  },
  productName: {
    paddingRight: '48px',
  },
  priceDetails: {
    paddingLeft: '8px',
  },
  priceValue: {
    display: 'grid',
    justifyContent: 'end',
  },
}

const pointDetailStyles: { [name: string]: CSSProperties } = {
  container: {
    paddingBottom: '8px',
    display: 'grid',
    gridTemplateColumns: 'auto 77px 64px',
  },
  label: {
    justifySelf: 'start',
    textAlign: 'start',
  },
  value: {
    justifySelf: 'end',
    textAlign: 'end',
  },
  padding: {
    justifySelf: 'start',
    textAlign: 'start',
  },
}

const shiharaiDetailStyles: { [name: string]: CSSProperties } = {
  container: {
    paddingBottom: '8px',
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'space-between',
  },
  label: {
    justifySelf: 'start',
    textAlign: 'start',
  },
  value: {
    justifySelf: 'end',
    textAlign: 'end',
  },
}

// 明細種別: 商品は0
function ReceiptTradeDetailOfShohin({
  tradeDetail,
  isCancel,
}: {
  tradeDetail: TradeHistoryRecordDetailOfShohin
  isCancel?: boolean
}) {
  return (
    <div style={detailStyles.container}>
      <div style={detailStyles.productName}>
        {tradeDetail.productCode} {tradeDetail.productName}
        {tradeDetail.taxTypeText}
      </div>
      {tradeDetail.quantity > 1 && (
        <div style={detailStyles.priceDetails}>
          ({tradeDetail.quantity}個 X 単{tradeDetail.unitPrice})
        </div>
      )}
      <div style={detailStyles.priceValue}>
        {discountablePrice(tradeDetail.detailTotalFee, isCancel)}円
      </div>
    </div>
  )
}

// 明細種別 クーポンは1
function ReceiptTradeDetailOfCoupon({
  tradeDetail,
  isCancel,
  isCouponValueSameline,
}: {
  tradeDetail: TradeHistoryRecordDetailOfCoupon
  isCancel?: boolean
  /**
   * trueの場合、割引額をクーポン名と同じ行に表示する
   */
  isCouponValueSameline?: boolean
}) {
  const { couponName, couponNo, couponDiscountAmount } = tradeDetail
  const couponDetailStyle: {
    [name: string]: CSSProperties
  } = isCouponValueSameline
    ? {
        // 会計にかかるクーポン表示の際は改行しないためにデザインを指定
        container: {
          ...detailStyles.container,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
      }
    : detailStyles
  return (
    <div style={couponDetailStyle.container}>
      <div style={detailStyles.productName}>
        <div>{couponName}</div>
        <div>No. {couponNo}</div>
      </div>
      <div style={detailStyles.priceValue}>
        {discountablePrice(couponDiscountAmount, !isCancel) + '円'}
      </div>
    </div>
  )
}

// 明細種別: ポイントは2
function ReceiptTradeDetailOfPoint({
  tradeDetail,
}: {
  tradeDetail: TradeHistoryRecordDetailOfPoint
}) {
  return (
    <div style={pointDetailStyles.container} key={tradeDetail.pointName}>
      <div style={pointDetailStyles.label}>　({tradeDetail.pointName}</div>
      <div style={pointDetailStyles.value}>
        {numberWithCommas(tradeDetail.point)}
      </div>
      <div style={pointDetailStyles.padding}>)</div>
    </div>
  )
}

// 明細種別: 支払いは3
function ReceiptTradeDetailOfShiharai({
  tradeDetail,
  isCancel,
}: {
  tradeDetail: TradeHistoryRecordDetailOfShiharai
  isCancel?: boolean
}) {
  return (
    <div style={shiharaiDetailStyles.container}>
      <div style={shiharaiDetailStyles.label}>
        {tradeDetail.creditCompanyName}
      </div>
      <div style={shiharaiDetailStyles.value}>
        {discountablePrice(tradeDetail.otherPayment, isCancel)}円
      </div>
    </div>
  )
}

// 明細種別: 割引は4
function ReceiptTradeDetailOfDiscount({
  tradeDetail,
  isCancel,
}: {
  tradeDetail: TradeHistoryRecordDetailOfDiscount
  isCancel?: boolean
}) {
  const discountName = () => {
    if (tradeDetail.discountName.length !== 0) return tradeDetail.discountName
    // 割引額のマイナスは値増し、反対は値引き
    return tradeDetail.discountAmount < 0 ? '値増し' : '値引き'
  }
  return (
    <div style={detailStyles.container}>
      <div style={detailStyles.productName}>
        {tradeDetail.relatedProductNumber} {discountName()}
      </div>
      <div style={detailStyles.priceValue}>
        {discountablePrice(tradeDetail.discountAmount, !isCancel)}円
      </div>
    </div>
  )
}

export function ReceiptTradeDetail({
  tradeDetail,
  isCancel,
  isCouponValueSameline,
}: {
  tradeDetail: TradeHistoryRecordDetail
  /**
   * trueの場合、detailType: 0,1,3,4 において特定の値がマイナス値で表示される
   */
  isCancel?: boolean
  /**
   * trueの場合、detailType:1のクーポン詳細のクーポン名称と金額が同列に表示される
   */
  isCouponValueSameline?: boolean
}) {
  switch (tradeDetail.detailType) {
    case 0:
      return (
        <ReceiptTradeDetailOfShohin
          tradeDetail={tradeDetail}
          isCancel={isCancel}
        />
      )
    case 1:
      return (
        <ReceiptTradeDetailOfCoupon
          tradeDetail={tradeDetail}
          isCancel={isCancel}
          isCouponValueSameline={isCouponValueSameline}
        />
      )
    case 2:
      return <ReceiptTradeDetailOfPoint tradeDetail={tradeDetail} />
    case 3:
      return (
        <ReceiptTradeDetailOfShiharai
          tradeDetail={tradeDetail}
          isCancel={isCancel}
        />
      )
    case 4:
      return (
        <ReceiptTradeDetailOfDiscount
          tradeDetail={tradeDetail}
          isCancel={isCancel}
        />
      )
    default:
      return null
  }
}
