import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { useLiff } from './liff'
import { LoginActionCreators } from '../modules/login'

export function useLinkLineAccount() {
  const { accessToken } = useLiff()
  const dispatch = useDispatch()
  const linkLineAccount = useCallback(() => {
    if (accessToken) {
      return dispatch(LoginActionCreators.linkLineAccount(accessToken))
    }
  }, [dispatch, accessToken])

  return { linkLineAccount }
}

export function useUnlinkLineAccount() {
  const dispatch = useDispatch()
  const unlinkLineAccount = useCallback(() => {
    return dispatch(LoginActionCreators.unlinkLineAccount())
  }, [dispatch])

  return { unlinkLineAccount }
}
