import React, { CSSProperties } from 'react'

import { IconButton } from './IconButton'
import { MenuIcon } from '../atoms/icons/MenuIcon'

function Label() {
  const style: CSSProperties = {
    fontSize: '12px',
    fontWeight: 'bold',
    textAlign: 'center',
  }
  return (
    <>
      <div style={style}>その他</div>
      <div style={style}>メニュー</div>
    </>
  )
}

export function OtherItemsButton() {
  return <IconButton icon={MenuIcon} label={Label} />
}
