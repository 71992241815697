import React from 'react'

import { PointHistoryRecord } from '../../models/point'
import { EmptyListPanel } from '../molecules/EmptyListPanel'
import { PointHistoryListItem } from '../molecules/PointHistoryListItem'

interface PointHistoryListProps {
  records: PointHistoryRecord[]
}

export function PointHistoryList({ records }: PointHistoryListProps) {
  if (records.length === 0) {
    return <EmptyListPanel label="現在ポイントご利用明細はありません。" />
  }

  return (
    <>
      {records.map((record, i) => {
        return <PointHistoryListItem key={i} record={record} />
      })}
    </>
  )
}
