import React, { useCallback, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

import { BackNavLinkPanel, BackNavButtonPanel } from '../organisms/BackNavPanel'
import { EmailHelpPanel } from '../organisms/EmailHelpPanel'
import { EmailForm } from '../organisms/EmailForm'
import { EmailVerificationHelpPanel } from '../organisms/EmailVerificationHelpPanel'
import { EmailVerificationForm } from '../organisms/EmailVerificationForm'
import { SignUpHelpPanel } from '../organisms/SignUpHelpPanel'
import { SignUpForm } from '../organisms/SignUpForm'
import { SupportPanel } from '../organisms/SupportPanel'
import { ConfirmMigrateHelpPanel } from '../organisms/ConfirmMigrateHelpPanel'
import { CompleteMigrateHelpPanel } from '../organisms/CompleteMigrateHelpPanel'
import { MigrateForm } from '../organisms/MigrateForm'
import { LoadingPanel } from '../molecules/LoadingPanel'
import { ActionLink } from '../molecules/ActionLink'
import { Padding } from '../atoms/Padding'
import { YoshioKunFaceIcon } from '../atoms/YoshioKunFaceIcon'
import { DefaultTemplate } from '../templates/DefaultTemplate'
import { CreateMemberParams } from '../../models/member'
import { ErrorResponse } from '../../models/error'
import { useMember, useAuthcode, useCreateMember } from '../../hooks/member'
import { SignUpFormValues } from '../../validations/member'

interface EmailParams {
  email: string
}

interface EmailVerificationParams {
  verificationCode: string
}

interface SignUpFormPagePresenterProps {
  cardNo: string
  defaultValues?: SignUpFormValues
  onSubmit: (params: CreateMemberParams) => void
}

interface SignUpEmailPagePresenterProps {
  defaultEmail: string | undefined
  apiError?: ErrorResponse
  onSubmit: (params: EmailParams) => void
}

interface ConfirmMigratePagePresenterProps {
  apiError?: ErrorResponse
  onSubmit: () => void
  onBackClick: () => void
}

interface SignUpEmailVerificationPagePresenterProps {
  apiError?: ErrorResponse
  onSubmit: (params: EmailVerificationParams) => void
  onResendClick: () => void
  onResetClick: () => void
  onBackClick: () => void
}

interface CompleteMigratePagePresenterProps {
  onSubmit: () => void
}

export function SignUpLoadingPagePresenter() {
  const Main = (
    <>
      <BackNavLinkPanel label="戻る" to="/pointcard" />
      <SignUpHelpPanel />
      <LoadingPanel />
    </>
  )

  return <DefaultTemplate title="ふるいちアプリ" main={Main} autoScrollToTop />
}

export function SignUpEmailPagePresenter({
  defaultEmail,
  apiError,
  onSubmit,
}: SignUpEmailPagePresenterProps) {
  const defaultValues = defaultEmail
    ? {
        email: defaultEmail,
      }
    : undefined

  const hasApiExpectValidationError = (error?: ErrorResponse) =>
    error && error.status !== 201 && error.status !== 402
  const supportRequirement = (error?: ErrorResponse) =>
    error && error.status === 200

  const Main = (
    <>
      <BackNavLinkPanel label="戻る" to="/pointcard" />
      <EmailHelpPanel
        apiErrorMessage={
          hasApiExpectValidationError(apiError) ? apiError?.message : undefined
        }
        apiErrorStatus={
          hasApiExpectValidationError(apiError) ? apiError?.status : undefined
        }
      />
      {supportRequirement(apiError) && <SupportPanel />}
      <Padding bottom="40px">
        {/* APIエラー発生時にバリデーションエラー(201/402) 以外は、フォームを隠す */}
        {hasApiExpectValidationError(apiError) ? (
          <></>
        ) : (
          <EmailForm
            defaultValues={defaultValues}
            hasApiError={!!apiError}
            errorMessage={apiError?.message}
            onSubmit={onSubmit}
          />
        )}
      </Padding>
    </>
  )

  return (
    <DefaultTemplate title="メールアドレス送信" main={Main} autoScrollToTop />
  )
}

export function ConfirmMigratePagePresenter({
  onSubmit,
  apiError,
  onBackClick,
}: ConfirmMigratePagePresenterProps) {
  const supportRequirement = (error?: ErrorResponse) =>
    error && error.status === 200

  const Main = (
    <>
      <BackNavButtonPanel label="戻る" onClick={onBackClick} />
      <ConfirmMigrateHelpPanel
        apiErrorMessage={apiError?.message}
        apiErrorStatus={apiError?.status}
      />
      {supportRequirement(apiError) && <SupportPanel />}
      {!apiError && (
        <Padding bottom="40px" left="16px" right="16px">
          <MigrateForm onSubmit={onSubmit} />
        </Padding>
      )}
    </>
  )

  return (
    <DefaultTemplate
      title="オンラインアカウント連携"
      main={Main}
      autoScrollToTop
    />
  )
}

export function SignUpEmailVerificationPagePresenter({
  apiError,
  onSubmit,
  onResendClick,
  onResetClick,
  onBackClick,
}: SignUpEmailVerificationPagePresenterProps) {
  const hasApiValidationError = (error?: ErrorResponse) =>
    error && (error.status === 201 || error.status === 206)
  const hasApiExpectValidationError = (error?: ErrorResponse) =>
    error && error.status !== 201 && error.status !== 206
  const hasApiSystemError = (error?: ErrorResponse) =>
    error &&
    error.status !== 201 &&
    error.status !== 206 &&
    error.status !== 207
  const supportRequirement = (error?: ErrorResponse) =>
    error && (error.status === 200 || error.status === 210)

  const Main = (
    <>
      <BackNavButtonPanel label="戻る" onClick={onBackClick} />
      <EmailVerificationHelpPanel
        apiErrorMessage={
          hasApiExpectValidationError(apiError) ? apiError?.message : undefined
        }
        apiErrorStatus={
          hasApiSystemError(apiError) ? apiError?.status : undefined
        }
      />
      {supportRequirement(apiError) && <SupportPanel />}
      <Padding bottom="40px">
        {/* APIエラー発生時にバリデーションエラー(201/206) 以外は、フォームを隠す */}
        {hasApiExpectValidationError(apiError) ? (
          <Padding left="16px" right="16px">
            {/* 認証コード有効期限切れ(207)の場合は、認証コード再発行 */}
            {apiError?.status === 207 ? (
              <ActionLink
                label="認証コードを再送信する"
                onClick={onResendClick}
              />
            ) : (
              <></>
            )}
          </Padding>
        ) : (
          <EmailVerificationForm
            hasApiError={!!apiError}
            errorMessage={apiError?.message}
            onSubmit={onSubmit}
            onResendClick={onResendClick}
            onResetClick={
              hasApiValidationError(apiError) ? undefined : onResetClick
            }
          />
        )}
      </Padding>
    </>
  )

  return <DefaultTemplate title="認証コード入力" main={Main} autoScrollToTop />
}

export function CompleteMigratePagePresenter({
  onSubmit,
}: CompleteMigratePagePresenterProps) {
  const Main = (
    <>
      <CompleteMigrateHelpPanel />
      <div>
        <Padding top="16px" bottom="16px">
          <YoshioKunFaceIcon />
        </Padding>
        <ActionLink label="ホーム画面へ戻る" onClick={onSubmit} />
      </div>
    </>
  )

  return <DefaultTemplate title="お知らせ" main={Main} autoScrollToTop />
}

export function SignUpFormPagePresenter({
  cardNo,
  defaultValues,
  onSubmit,
}: SignUpFormPagePresenterProps) {
  const defaultConfirming = false
  const Main = (
    <>
      <BackNavLinkPanel label="戻る" to="/pointcard" />
      <SignUpHelpPanel />
      <Padding bottom="40px">
        <SignUpForm
          cardNo={cardNo}
          defaultValues={defaultValues}
          defaultConfirming={defaultConfirming}
          onSubmit={onSubmit}
        />
      </Padding>
    </>
  )

  return <DefaultTemplate title="新規会員登録" main={Main} autoScrollToTop />
}

export function useSignUpPage() {
  const { member, fetchMember } = useMember()
  const {
    authEmailSent,
    authEmailStatus,
    migrateRequired,
    authEmailError,
    authcodeVerified,
    authcodeError,
    sendAuthcode,
    clearSendAuthcodeError,
    verifyAuthcode,
  } = useAuthcode()
  const { createMember } = useCreateMember()
  const [sentEmail, setSentEmail] = useState<string | undefined>(undefined)
  const [processing, setProcessing] = useState(false)
  const handleEmailSubmit = useCallback(
    (params: EmailParams) => {
      setSentEmail(params.email)
      sendAuthcode(params.email)
    },
    [sendAuthcode]
  )
  const handleMigrateSubmit = useCallback(() => {
    // 統合時のリクエストのため、sentEmailがundefinedのユースケースは存在しない
    sendAuthcode(sentEmail!)
  }, [sendAuthcode, sentEmail])
  const handleVerificationCodeSubmit = useCallback(
    (params: EmailVerificationParams) => {
      verifyAuthcode(params.verificationCode)
    },
    [verifyAuthcode]
  )
  const handleResendClick = useCallback(() => {
    // 認証コードの再発行リクエストのため、sentEmailがundefinedのユースケースは存在しない
    sendAuthcode(sentEmail!)
  }, [sendAuthcode, sentEmail])
  const handleResetClick = useCallback(() => {
    setSentEmail(undefined)
    clearSendAuthcodeError()
  }, [clearSendAuthcodeError])
  const handleBackHomeClick = useCallback(() => {
    clearSendAuthcodeError()
  }, [clearSendAuthcodeError])
  const handleBackClick = useCallback(() => {
    setSentEmail(undefined)
    clearSendAuthcodeError()
  }, [clearSendAuthcodeError])

  const handleSubmit = useCallback(
    (params: CreateMemberParams) => {
      if (processing) return
      setProcessing(true)
      setTimeout(() => {
        setProcessing(false)
      }, 3000)
      createMember(params)
    },
    [createMember, processing]
  )
  const loading = member === undefined
  const cardNo = member?.cardNo
  const email = member?.email
  const emailRegistered = !!email
  const memberRegistered = emailRegistered && member?.birthday
  const emailSent = authEmailSent
  const emailStatus = authEmailStatus
  const migrate = migrateRequired

  useEffect(() => {
    return () => {
      clearSendAuthcodeError()
    }
  }, [clearSendAuthcodeError])

  useEffect(() => {
    return () => {
      clearSendAuthcodeError()
    }
  }, [clearSendAuthcodeError])

  useEffect(() => {
    !member && fetchMember()
  }, [member, fetchMember])

  return {
    loading,
    cardNo,
    sentEmail,
    // authEmailSent,
    authEmailError,
    authcodeVerified,
    authcodeError,
    emailRegistered,
    memberRegistered,
    emailSent,
    emailStatus,
    migrate,
    handleEmailSubmit,
    handleVerificationCodeSubmit,
    handleResendClick,
    handleResetClick,
    handleSubmit,
    handleMigrateSubmit,
    handleBackHomeClick,
    handleBackClick,
  }
}

export function SignUpPage() {
  const {
    loading,
    cardNo,
    sentEmail,
    // authEmailSent,
    authEmailError,
    // authcodeVerified,
    authcodeError,
    emailRegistered,
    memberRegistered,
    emailSent,
    emailStatus,
    migrate,
    handleEmailSubmit,
    handleVerificationCodeSubmit,
    handleResendClick,
    handleResetClick,
    handleSubmit,
    handleMigrateSubmit,
    handleBackHomeClick,
    handleBackClick,
  } = useSignUpPage()

  if (loading) {
    return <SignUpLoadingPagePresenter />
  }

  if (memberRegistered && migrate) {
    return <CompleteMigratePagePresenter onSubmit={handleBackHomeClick} />
  }

  if (memberRegistered) {
    return <Redirect to="/" />
  }

  if (!emailRegistered) {
    if (!emailSent) {
      return (
        <SignUpEmailPagePresenter
          defaultEmail={sentEmail}
          apiError={authEmailError}
          onSubmit={handleEmailSubmit}
        />
      )
    } else if (emailSent && migrate && emailStatus === 1) {
      // ECサイトとのアカウント統合が必要な場合
      return (
        <ConfirmMigratePagePresenter
          onSubmit={handleMigrateSubmit}
          apiError={authEmailError}
          onBackClick={handleBackClick}
        />
      )
    } else {
      return (
        <SignUpEmailVerificationPagePresenter
          apiError={authcodeError}
          onSubmit={handleVerificationCodeSubmit}
          onResendClick={handleResendClick}
          onResetClick={handleResetClick}
          onBackClick={handleBackClick}
        />
      )
    }
  } else {
    return <SignUpFormPagePresenter cardNo={cardNo!} onSubmit={handleSubmit} />
  }
}
