import { useState, useCallback } from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'

import { TradeHistoryRecord, TradeHistorySearchOptions } from '../models/trade'
import {
  TradeActionCreators,
  tradeHistoryMetaSelector,
  tradeHistoryRecordsSelector,
} from '../modules/trade'

export function useTradeHistory() {
  const dispatch = useDispatch()
  const fetchTradeHistory = useCallback(
    (searchOptions: TradeHistorySearchOptions) => {
      return dispatch(
        TradeActionCreators.fetchTradeHistoryAction(searchOptions)
      )
    },
    [dispatch]
  )
  const meta = useSelector(tradeHistoryMetaSelector, shallowEqual)
  const records = useSelector(tradeHistoryRecordsSelector, shallowEqual)
  const [selectedRecord, setSelectedRecord] = useState<
    TradeHistoryRecord | undefined
  >(undefined)

  return {
    meta,
    records,
    selectedRecord,
    fetchTradeHistory,
    setSelectedRecord,
  }
}
