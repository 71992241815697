import React, { CSSProperties } from 'react'

import { IconButton } from './IconButton'
import { PurchaseHistoryIcon } from '../atoms/icons/PurchaseHistoryIcon'

function Label() {
  const style: CSSProperties = {
    // fontSize: '12px',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    textAlign: 'center',
  }
  return <div style={style}>購買履歴</div>
}

export function PurchaseHistoryButton() {
  return <IconButton icon={PurchaseHistoryIcon} label={Label} />
}
