import { fork } from 'redux-saga/effects'
import { History } from 'history'

import { loginSagas } from './loginSagas'
import { memberSagas } from './memberSagas'
import { pointSagas } from './pointSagas'
import { tradeSagas } from './tradeSagas'
import { announcementSagas } from './announcementSagas'
import { couponSagas } from './couponSagas'
import { reservationSagas } from './reservationSagas'

function* rootSaga(context: { history: History }) {
  yield fork(loginSagas, context)
  yield fork(memberSagas)
  yield fork(pointSagas)
  yield fork(tradeSagas)
  yield fork(announcementSagas)
  yield fork(couponSagas)
  yield fork(reservationSagas)
}

export default rootSaga
