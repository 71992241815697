import React, { CSSProperties } from 'react'

import { FullWidthListItem } from '../molecules/FullWidthList'
import { Coupon } from '../../models/coupon'

const styles: { [name: string]: CSSProperties } = {
  container: {
    borderTop: '1px solid #D8D8D8',
    paddingBottom: '16px',
    backgroundColor: '#FFFFFF',
  },
  listItem: {
    border: 0,
    paddingBottom: '8px',
  },
  noticeTitle: {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '18px',
    whiteSpace: 'pre-wrap',
    paddingTop: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
    display: 'grid',
    gridTemplateColumns: 'auto',
    justifyContent: 'start',
  },
  noticeDescription: {
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 'normal',
    whiteSpace: 'pre-wrap',
    paddingTop: '8px',
    paddingLeft: '16px',
    paddingRight: '16px',
    display: 'grid',
    gridTemplateColumns: 'auto',
    justifyContent: 'start',
  },
}

interface CouponBarcodeDescriptionListItemProps {
  coupon: Coupon
}

export function CouponBarcodeDescriptionListItem({
  coupon,
}: CouponBarcodeDescriptionListItemProps) {
  return (
    <FullWidthListItem style={styles.listItem}>
      <div style={styles.noticeTitle}>{coupon.noticeTitle}</div>
      <div style={styles.noticeDescription}>{coupon.noticeDescription}</div>
    </FullWidthListItem>
  )
}

interface CouponBarcodeDescriptionListProps {
  selectedCoupons: { [couponCode: string]: Coupon }
}

export function CouponBarcodeDescriptionList({
  selectedCoupons,
}: CouponBarcodeDescriptionListProps) {
  return (
    <div style={styles.container}>
      {Object.keys(selectedCoupons).map((couponCode, i) => {
        const coupon = selectedCoupons[couponCode]
        return <CouponBarcodeDescriptionListItem key={i} coupon={coupon} />
      })}
    </div>
  )
}
