import React from 'react'

export function MenuIcon() {
  return (
    <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.018"
      >
        <path stroke="#5B5B5B" d="M5.333 6.667h21.329" />
        <path stroke="#FF9501" d="M5.333 16h21.329" />
        <path stroke="#5B5B5B" d="M5.333 25.333h14.238" />
      </g>
    </svg>
  )
}
