import React, { CSSProperties, PropsWithChildren } from 'react'

interface ToolTipProps {
  text?: string | string[]
  iconPositionX: number
}

const styles: { [name: string]: CSSProperties } = {
  tooltip: {
    position: 'relative',
  },
  position: {
    position: 'absolute',
    left: '50%',
    top: '0',
    transform: 'translateX(-9px)',
  },
  triangle: {
    position: 'relative',
    width: '18px',
    aspectRatio: '1 / 0.86',
    clipPath: 'polygon(50% 0,100% 100%,0 100%)',
    background: '#FF9500',
    margin: '-2px 2px',
  },
  content: {
    position: 'relative',
    width: '186px',
    background: '#FF9500',
    borderRadius: '8px',
    padding: '8px',
  },
  message: {
    display: 'flex',
    flexDirection: 'column',
    color: '#fff',
    fontSize: '12px',
    fontWeight: 'lighter',
  },
}

export function ToolTip({
  text,
  iconPositionX,
}: PropsWithChildren<ToolTipProps>) {
  const contentStyle = (right: number): CSSProperties => ({
    ...styles.content,
    right: `${right}%`,
  })

  return (
    <div style={styles.tooltip}>
      <div style={styles.position}>
        <div style={styles.triangle} />
        <div style={contentStyle((iconPositionX / window.innerWidth) * 100)}>
          <div style={styles.message}>
            {Array.isArray(text)
              ? text.map((t, i) => {
                  return <span key={i}>{t}</span>
                })
              : text}
          </div>
        </div>
      </div>
    </div>
  )
}
