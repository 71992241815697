import React, { useCallback, useState } from 'react'

import { Title } from '../atoms/Title'
import { AppAgreementsForm } from '../organisms/AppAgreementsForm'
import { DefaultTemplate } from '../templates/DefaultTemplate'
import { DescriptionPanel } from '../organisms/DescriptionPanel'
import { useLinkLineAccount } from '../../hooks/login'
import { Padding } from '../atoms/Padding'

interface AppAgreementsPagePresenterProps {
  onSubmit: () => void
}

export function AppAgreementsPagePresenter({
  onSubmit,
}: AppAgreementsPagePresenterProps) {
  const Main = (
    <>
      <Title>
        <div>ふるいちポイント会員サービス</div>
        <div>ご利用時の確認事項</div>
      </Title>
      <DescriptionPanel text="会員規約と個人情報保護方針をお読みいただき、同意いただける方は、同意するにチェックを入れて「カードを発行する」ボタンを押してください。" />
      <Padding bottom="40px">
        <AppAgreementsForm onSubmit={onSubmit} />
      </Padding>
    </>
  )

  return <DefaultTemplate title="確認" main={Main} autoScrollToTop />
}

export function AppAgreementsPage() {
  const { linkLineAccount } = useLinkLineAccount()
  const [processing, setProcessing] = useState(false)
  const handleSubmit = useCallback(() => {
    if (processing) return
    setProcessing(true)
    setTimeout(() => {
      setProcessing(false)
    }, 3000)

    return linkLineAccount()
  }, [linkLineAccount, processing])

  return <AppAgreementsPagePresenter onSubmit={handleSubmit} />
}
