import React, { CSSProperties, useState, useMemo, useEffect } from 'react'

import { formatSeconds, remainingTimeInSeconds } from '../../utils/datetime'
import { Barcode } from '../atoms/Barcode'
import { Spinner } from '../atoms/icons/Spinner'

export function AccountBarcodeLabel() {
  const style: CSSProperties = {
    fontSize: '12px',
    color: '#282828',
    // fontWeight: 'bold',
    lineHeight: '18px',
    paddingTop: '12px',
    paddingLeft: '16px',
    paddingRight: '16px',
    display: 'grid',
    gridTemplateColumns: 'auto',
    justifyContent: 'start',
    backgroundColor: '#FFFFFF',
  }

  return <div style={style}>カード番号</div>
}

interface AccountCodeBarcodeProps {
  onetimeCode: string | undefined
}

function AccountCodeBarcode({ onetimeCode }: AccountCodeBarcodeProps) {
  const style: CSSProperties = {
    color: '#000000',
    display: 'grid',
    justifyItems: 'center',
    alignItems: 'end',
  }
  return (
    <div style={style}>
      {onetimeCode ? <Barcode value={onetimeCode} /> : <Spinner />}
    </div>
  )
}

interface AccountCodeProps {
  cardNo: string
}

function AccountCode({ cardNo }: AccountCodeProps) {
  // const code = '1234 5678 9012 3456'
  const style: CSSProperties = {
    fontSize: '14px',
    fontWeight: 'bold',
    // fontFamily: 'Helvetica Bold',
    color: '#282828',
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'end',
  }
  return (
    <div style={style}>
      <span>{cardNo}</span>
    </div>
  )
}

interface BarcodeExpirationProps {
  onetimeCodeExpiresAt: string
  onOnetimeCodeExpire: () => void
}

function BarcodeExpiration({
  onetimeCodeExpiresAt,
  onOnetimeCodeExpire,
}: BarcodeExpirationProps) {
  const [seconds, setSeconds] = useState<number | null>(0)
  const styles: { [name: string]: CSSProperties } = useMemo(
    () => ({
      container: {
        fontSize: '12px',
        color: '#282828',
        lineHeight: '15px',
        display: 'grid',
        justifyItems: 'center',
        alignItems: 'end',
      },
    }),
    []
  )
  const remainingTime = formatSeconds(seconds)

  useEffect(() => {
    // ワンタイムコードが更新されたとき、残り時間を再計算
    const calculatedSeconds = remainingTimeInSeconds(
      onetimeCodeExpiresAt,
      new Date()
    )
    setSeconds(calculatedSeconds)
  }, [onetimeCodeExpiresAt])
  useEffect(() => {
    if (seconds === 0) return
    // ワンタイムコードの残り時間をカウントダウン
    const dispose = setTimeout(() => {
      if (seconds === null) return
      setSeconds(seconds - 1)
      if (seconds === 1) {
        onOnetimeCodeExpire()
      }
    }, 1000)
    return () => clearTimeout(dispose)
  }, [onOnetimeCodeExpire, seconds, setSeconds])

  return (
    <div style={styles.container}>
      <div>
        <span>{remainingTime}</span>{' '}
        <span onClick={onOnetimeCodeExpire}>表示更新</span>
      </div>
    </div>
  )
}

interface AccountBarcodeProps {
  onetimeCode: string | undefined
  onetimeCodeExpiresAt: string | undefined
  cardNo: string
  paddingTop: string
  paddingBottom: string
  onOnetimeCodeExpire: () => void
}

export function AccountBarcode({
  onetimeCode,
  onetimeCodeExpiresAt,
  cardNo,
  paddingTop,
  paddingBottom,
  onOnetimeCodeExpire,
}: AccountBarcodeProps) {
  const style: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '116px 30px 18px',
    backgroundColor: '#FFFFFF',
    paddingTop,
    paddingBottom,
  }

  return (
    <div style={style}>
      <AccountCodeBarcode onetimeCode={onetimeCode} />
      <AccountCode cardNo={cardNo} />
      {onetimeCodeExpiresAt && (
        <BarcodeExpiration
          onetimeCodeExpiresAt={onetimeCodeExpiresAt}
          onOnetimeCodeExpire={onOnetimeCodeExpire}
        />
      )}
    </div>
  )
}
