import React from 'react'

import { Panel } from '../atoms/Panel'
import { SignUpButton } from '../molecules/SignUpButton'

interface SignUpButtonPanelProps {
  to: string
}

export function SignUpButtonPanel({ to }: SignUpButtonPanelProps) {
  return (
    <Panel paddingTop={16}>
      <SignUpButton to={to} />
    </Panel>
  )
}
