import React, {
  createContext,
  useState,
  useEffect,
  PropsWithChildren,
  useContext,
} from 'react'
// import liff from '@line/liff'
// ↑を使うと Android の LINE アプリ内ブラウザーで liff.init() が高確率で返ってこない

import { LoadingPage } from '../components/pages/LoadingPage'

// @ts-ignore
const liff = window.liff

// 1. LIFF の初期化
// 2. LINE にログイン
// 3. CRM にログイン
// 4. もし CRM 未登録なら:
//   1. 同意画面を出す
//   2. 同意後に CRM に登録
// 5. 会員の情報を取得
// 6. 会員のランクによって表示を変える

interface LiffContextValue {
  liffId: string | undefined
  botBasicId: string
}

const LiffContext = createContext<LiffContextValue>({
  liffId: undefined,
  botBasicId: '',
})

function useLiffContext(
  initialLiffId: string,
  initialBotBasicId: string
): LiffContextValue {
  const [liffId] = useState<string>(initialLiffId)
  const [botBasicId] = useState<string>(initialBotBasicId)

  return {
    liffId,
    botBasicId,
  }
}

function useLineLogin() {
  const { liffId } = useContext(LiffContext)
  const [liffInitializing, setLiffInitializing] = useState<boolean>(true)
  const [lineLoggedIn, setLineLoggedIn] = useState<boolean>(false)

  // LIFF の初期化
  useEffect(() => {
    async function initialize() {
      try {
        await liff.init({ liffId: liffId! })
        setLiffInitializing(false)
      } catch (error) {
        console.debug('initialization error:', error)
      }
    }
    initialize()
  }, [liffId])
  // LINE にログイン
  useEffect(() => {
    if (liffInitializing) return
    if (liff.isLoggedIn()) {
      // const decodedIDToken = liff.getDecodedIDToken()
      // const accessToken = liff.getAccessToken()
      setLineLoggedIn(true)
      // const debugPrintProfile = async () => {
      //   const profile = await liff.getProfile()
      //   console.debug('profile:', profile)
      // }
      // debugPrintProfile()
    } else {
      // LINE アプリ内ブラウザー以外の場合に、LINE のログインページに飛ばす
      liff.login()
    }
  }, [liffInitializing])

  return { lineLoggedIn }
}

export function useLiff() {
  const { liffId, botBasicId } = useContext(LiffContext)
  const accessToken = liff.getAccessToken() ?? ''
  const liffVersion = liff.getVersion()

  return {
    liff,
    liffId,
    botBasicId,
    accessToken,
    liffVersion,
  }
}

function LineLogin({ children }: PropsWithChildren<{}>) {
  const { lineLoggedIn } = useLineLogin()

  return (
    <>{lineLoggedIn ? children : <LoadingPage status="initializing-liff" />}</>
  )
}

interface LiffProviderProps {
  liffId: string
  botBasicId: string
}

export function LiffProvider({
  liffId,
  botBasicId,
  children,
}: PropsWithChildren<LiffProviderProps>) {
  const liffContextValue = useLiffContext(liffId, botBasicId)

  return (
    <LiffContext.Provider value={liffContextValue}>
      <LineLogin>{children}</LineLogin>
    </LiffContext.Provider>
  )
}
