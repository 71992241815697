import React, { CSSProperties } from 'react'

interface SubtitleProps {
  text: string
}

const styles: { [name: string]: CSSProperties } = {
  wrapper: {
    paddingTop: '6px',
  },
  grid: {
    height: '50px',
    display: 'grid',
    justifyContent: 'start',
    alignContent: 'center',
  },
  text: {
    color: '#5b5b5b',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'bold',
    paddingLeft: '16px',
  },
}

export function Subtitle({ text }: SubtitleProps) {
  return (
    <div style={styles.wrapper}>
      <div style={styles.grid}>
        <div style={styles.text}>{text}</div>
      </div>
    </div>
  )
}
