import React, { useCallback, useMemo, useState, useEffect } from 'react'

import { BackNavLinkPanel, BackNavButtonPanel } from '../organisms/BackNavPanel'
import { DefaultTemplate } from '../templates/DefaultTemplate'
import { Subtitle } from '../molecules/Subtitle'
import {
  AccountBarcode,
  AccountBarcodeLabel,
} from '../molecules/AccountBarcode'
import { CouponBarcodeList } from '../organisms/CouponBarcodeList'
import { CouponBarcodeDescriptionList } from '../organisms/CouponBarcodeDescriptionList'
import { LoadingPanel } from '../molecules/LoadingPanel'
import { Coupon } from '../../models/coupon'
import { useMember, useOnetimeCode } from '../../hooks/member'
import { useCoupons, useSelectedCoupons } from '../../hooks/coupon'
import { useScroll } from '../../hooks/scroll'
import { getDateStr } from '../../utils/date'
import { SelectableCouponList } from '../organisms/SelectableCouponList'

export function CouponsLoadingPagePresenter() {
  const Main = (
    <>
      <BackNavLinkPanel label="戻る" to="/" />
      <Subtitle text="クーポン" />
      <LoadingPanel />
    </>
  )

  return <DefaultTemplate title="クーポン" main={Main} autoScrollToTop />
}

interface CouponsListPagePresenterProps {
  // coupon
  coupons: Coupon[]
  selectedCoupons: { [couponCode: string]: Coupon }
  dateStr: string
  // account
  onCouponToggleSelect: (coupon: Coupon) => void
  onShowCouponBarcodes: () => void
}

export function CouponsListPagePresenter({
  coupons,
  selectedCoupons,
  dateStr,
  onCouponToggleSelect,
  onShowCouponBarcodes,
}: CouponsListPagePresenterProps) {
  const Main = useMemo(() => {
    return (
      <>
        <BackNavLinkPanel label="戻る" to="/" />
        <Subtitle text="クーポン" />
        <SelectableCouponList
          coupons={coupons || []}
          selectedCoupons={selectedCoupons}
          dateStr={dateStr}
          onToggleSelect={onCouponToggleSelect}
          onShowCouponBarcodes={onShowCouponBarcodes}
        />
      </>
    )
  }, [
    coupons,
    selectedCoupons,
    dateStr,
    onCouponToggleSelect,
    onShowCouponBarcodes,
  ])

  return <DefaultTemplate title="クーポン" main={Main} autoScrollToTop />
}

interface CouponsBarcordsPagePresenterProps {
  // coupon
  selectedCoupons: { [couponCode: string]: Coupon }
  // account
  onetimeCode: string | undefined
  onetimeCodeExpiresAt: string | undefined
  cardNo: string
  onBackToList: () => void
  // account
  onOnetimeCodeExpire: () => void
}

export function CouponsBarcordsPagePresenter({
  selectedCoupons,
  onetimeCode,
  onetimeCodeExpiresAt,
  cardNo,
  onBackToList,
  onOnetimeCodeExpire,
}: CouponsBarcordsPagePresenterProps) {
  const Main = useMemo(() => {
    return (
      <>
        <BackNavButtonPanel label="戻る" onClick={onBackToList} />
        <AccountBarcodeLabel />
        <AccountBarcode
          onetimeCode={onetimeCode}
          onetimeCodeExpiresAt={onetimeCodeExpiresAt}
          cardNo={cardNo}
          paddingTop="8px"
          paddingBottom="18px"
          onOnetimeCodeExpire={onOnetimeCodeExpire}
        />
        <CouponBarcodeList selectedCoupons={selectedCoupons} />
        <CouponBarcodeDescriptionList selectedCoupons={selectedCoupons} />
      </>
    )
  }, [
    selectedCoupons,
    onetimeCode,
    onetimeCodeExpiresAt,
    cardNo,
    onBackToList,
    onOnetimeCodeExpire,
  ])

  return <DefaultTemplate title="クーポン" main={Main} autoScrollToTop />
}

function useCouponsPage() {
  const { member, fetchMember } = useMember()
  const { onetimeCode, onetimeCodeExpiresAt, fetchOnetimeCode } =
    useOnetimeCode()
  const { coupons, fetchCoupons } = useCoupons()
  const { selectedCoupons, toggleSelectedCoupon } = useSelectedCoupons()
  const [showCouponBarcodes, setShowCouponBarcodes] = useState<boolean>(false)
  const dateStr = getDateStr()
  const { scrollY, saveCurrentScrollY, resetScroll, restoreScroll } =
    useScroll()
  console.debug('scrollY: ', scrollY)
  const handleShowCouponBarcodes = useCallback(() => {
    saveCurrentScrollY()
    setShowCouponBarcodes(true)
  }, [saveCurrentScrollY])
  const handleBackToList = useCallback(() => {
    setShowCouponBarcodes(false)
  }, [])
  const handleOnetimeCodeExpire = useCallback(() => {
    fetchOnetimeCode()
  }, [fetchOnetimeCode])

  useEffect(() => {
    fetchCoupons()
    fetchMember()
    fetchOnetimeCode()
  }, [fetchCoupons, fetchMember, fetchOnetimeCode])

  useEffect(() => {
    if (showCouponBarcodes) {
      resetScroll()
    } else {
      restoreScroll()
    }
  }, [showCouponBarcodes, resetScroll, restoreScroll])

  return {
    coupons,
    member,
    onetimeCode,
    onetimeCodeExpiresAt,
    selectedCoupons,
    showCouponBarcodes,
    dateStr,
    handleCouponToggleSelect: toggleSelectedCoupon,
    handleShowCouponBarcodes,
    handleBackToList,
    handleOnetimeCodeExpire,
  }
}

export function CouponsPage() {
  const {
    coupons,
    member,
    onetimeCode,
    onetimeCodeExpiresAt,
    selectedCoupons,
    showCouponBarcodes,
    dateStr,
    handleCouponToggleSelect,
    handleShowCouponBarcodes,
    handleBackToList,
    handleOnetimeCodeExpire,
  } = useCouponsPage()

  if (!member || !coupons) {
    return <CouponsLoadingPagePresenter />
  }

  if (!showCouponBarcodes) {
    return (
      <CouponsListPagePresenter
        coupons={coupons}
        selectedCoupons={selectedCoupons}
        dateStr={dateStr}
        onCouponToggleSelect={handleCouponToggleSelect}
        onShowCouponBarcodes={handleShowCouponBarcodes}
      />
    )
  }

  return (
    <CouponsBarcordsPagePresenter
      selectedCoupons={selectedCoupons}
      onetimeCode={onetimeCode}
      onetimeCodeExpiresAt={onetimeCodeExpiresAt}
      cardNo={member.cardNo}
      onBackToList={handleBackToList}
      onOnetimeCodeExpire={handleOnetimeCodeExpire}
    />
  )
}
