import { fork, call, put, select, takeEvery } from 'redux-saga/effects'

import {
  ReservationActionTypes,
  ReservationActionCreators,
  FetchReservationsAction,
} from '../modules/reservation'
import { fetchReservations } from '../utils/api'
import { tokenSelector, apiHostSelector } from '../modules/login'

export function* fetchReservationsSaga(
  action: FetchReservationsAction
): Generator<any, void, any> {
  try {
    const options = action.payload.options
    const apiHost = yield select(apiHostSelector)
    const token = yield select(tokenSelector)
    yield put(ReservationActionCreators.fetchReservationsRequestAction(options))
    const { meta, reservations } = yield call(
      fetchReservations,
      apiHost,
      token,
      options
    )
    yield put(
      ReservationActionCreators.fetchReservationsSuccessAction(
        meta,
        reservations
      )
    )
  } catch (error) {
    yield put(ReservationActionCreators.fetchReservationsFailureAction(error))
  }
}

function* handleFetchReservationsSaga() {
  yield takeEvery(
    ReservationActionTypes.FETCH_RESERVATIONS,
    fetchReservationsSaga
  )
}

export function* reservationSagas() {
  yield fork(handleFetchReservationsSaga)
}
