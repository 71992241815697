import React from 'react'

export function PurchaseHistoryIcon() {
  return (
    <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M17 21v-5c0-.55-.45-1-1-1H6c-.55 0-1 .45-1 1v5"
          stroke="#FF9501"
          strokeWidth="1.897"
        />
        <path
          d="M27 5a4 4 0 11-8 0 4 4 0 018 0zM19.645 13.024A6 6 0 0129 18v1"
          stroke="#5B5B5B"
          strokeWidth="2"
        />
        <path
          d="M15 10c0 1.1-.9 2-2 2H7c-1.1 0-2-.9-2-2V9c0-1.1.9-2 2-2h6c1.1 0 2 .9 2 2v1z"
          stroke="#FF9501"
          strokeWidth="2"
        />
        <path
          d="M31 31v-7c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v7"
          stroke="#5B5B5B"
          strokeWidth="1.905"
        />
      </g>
    </svg>
  )
}
