import React from 'react'

import { Panel } from '../atoms/Panel'
import {
  InfoListItemWithLabel,
  InfoListItemHeader,
  InfoListItemHeaderWithAction,
  InfoListDivider,
} from '../molecules/InfoList'
import { Member, toGenderLabel } from '../../models/member'
import { formatDate } from '../../utils/date'

interface ProfilePanelProps {
  member: Member | undefined
  editPath: string
}

export function ProfilePanel({ member, editPath }: ProfilePanelProps) {
  return (
    <Panel>
      {member && !member.isGuest ? (
        <InfoListItemHeaderWithAction
          label="プロフィール"
          actions={[{ label: '変更する', path: editPath }]}
          rounded
        />
      ) : (
        <InfoListItemHeader label="プロフィール" rounded />
      )}
      <InfoListItemWithLabel
        label="氏名"
        value={
          member?.lastName
            ? `${member?.lastName} ${member?.firstName}`
            : '- - - -'
        }
      />
      <InfoListDivider />
      <InfoListItemWithLabel
        label="カナ"
        value={
          member?.lastNameKana
            ? `${member?.lastNameKana} ${member?.firstNameKana}`
            : '- - - -'
        }
      />
      <InfoListDivider />
      <InfoListItemWithLabel
        label={
          <>
            メール
            <br />
            アドレス
          </>
        }
        value={member?.email || ''}
      />
      <InfoListDivider />
      <InfoListItemWithLabel
        label="性別"
        value={
          member && member.gender !== undefined
            ? toGenderLabel(member?.gender)
            : ''
        }
      />
      <InfoListDivider />
      <InfoListItemWithLabel
        label="生年月日"
        value={member?.birthday ? formatDate(member.birthday) : ''}
      />
      <InfoListDivider />
      <InfoListItemWithLabel
        label="郵便番号"
        value={member?.postalCode || ''}
      />
      <InfoListDivider />
      <InfoListItemWithLabel
        label="都道府県"
        value={member?.prefecture || ''}
      />
      <InfoListDivider />
      <InfoListItemWithLabel label="市区町村" value={member?.city || ''} />
      <InfoListDivider />
      <InfoListItemWithLabel label="町名番地" value={member?.street || ''} />
      <InfoListDivider />
      <InfoListItemWithLabel
        label="ビルマンション"
        value={member?.apartment || ''}
      />
      <InfoListDivider />
      <InfoListItemWithLabel
        label="電話番号"
        value={member?.phone || ''}
        rounded
      />
    </Panel>
  )
}
