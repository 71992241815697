import React from 'react'

export function BarcodeIcon() {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>ico_code</title>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <line
          x1="5"
          y1="9"
          x2="5"
          y2="15"
          stroke="#FFFFFF"
          strokeWidth="2"
        ></line>
        <line
          x1="8.5"
          y1="9"
          x2="8.5"
          y2="15"
          stroke="#FFFFFF"
          strokeWidth="2"
        ></line>
        <line
          x1="19"
          y1="9"
          x2="19"
          y2="15"
          stroke="#FFFFFF"
          strokeWidth="2"
        ></line>
        <line
          x1="15.5"
          y1="9"
          x2="15.5"
          y2="15"
          stroke="#FFFFFF"
          strokeWidth="2"
        ></line>
        <line
          x1="12"
          y1="9"
          x2="12"
          y2="15"
          stroke="#FFFFFF"
          strokeWidth="2"
        ></line>
        <polyline
          stroke="#FFFFFF"
          strokeWidth="2.4"
          points="5 3 1.5 3 1.5 6.5"
        ></polyline>
        <polyline
          stroke="#FFFFFF"
          strokeWidth="2.4"
          points="19 3 22.5 3 22.5 6.5"
        ></polyline>
        <polyline
          stroke="#FFFFFF"
          strokeWidth="2.4"
          points="5 21 1.5 21 1.5 17.5"
        ></polyline>
        <polyline
          stroke="#FFFFFF"
          strokeWidth="2.4"
          points="19 21 22.5 21 22.5 17.5"
        ></polyline>
      </g>
    </svg>
  )
}
