import React, { CSSProperties } from 'react'

import { LinkIcon } from '../atoms/icons/LinkIcon'

interface ActionLinkProps {
  label: string
  onClick: () => void
}

const styles: { [name: string]: CSSProperties } = {
  container: {
    height: '34px',
    display: 'grid',
    gridGap: '8px',
    gridTemplateColumns: 'auto auto',
    placeItems: 'center',
  },
  message: {
    fontSize: '14px',
    lineHeight: '21px',
    color: '#5B5B5B',
    justifySelf: 'right',
  },
  icon: {
    color: '#0cc81d',
    fontSize: '14px',
    justifySelf: 'left',
  },
}

export function ActionLink({ label, onClick }: ActionLinkProps) {
  return (
    <div style={styles.container}>
      <div style={styles.message} onClick={onClick}>
        {label}
      </div>
      <div style={styles.icon} onClick={onClick}>
        <LinkIcon />
      </div>
    </div>
  )
}
