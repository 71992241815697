import {
  Member,
  UpdateMemberParams,
  CreateMemberParams,
  OnetimeCodeInfo,
  MemberRankInfo,
  MemberRankInfoResponse,
} from '../models/member'
import { ResetActionTypes, ResetStoreAction } from './reset'

import { ErrorResponse } from './../models/error'

// Action Types

const FETCH_MEMBER = 'FETCH_MEMBER' as const
const FETCH_MEMBER_REQUEST = 'FETCH_MEMBER_REQUEST' as const
const FETCH_MEMBER_SUCCESS = 'FETCH_MEMBER_SUCCESS' as const
const FETCH_MEMBER_FAILURE = 'FETCH_MEMBER_FAILURE' as const
const CREATE_MEMBER = 'CREATE_MEMBER' as const
const CREATE_MEMBER_REQUEST = 'CREATE_MEMBER_REQUEST' as const
const CREATE_MEMBER_SUCCESS = 'CREATE_MEMBER_SUCCESS' as const
const CREATE_MEMBER_FAILURE = 'CREATE_MEMBER_FAILURE' as const
const UPDATE_MEMBER = 'UPDATE_MEMBER' as const
const UPDATE_MEMBER_REQUEST = 'UPDATE_MEMBER_REQUEST' as const
const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS' as const
const UPDATE_MEMBER_FAILURE = 'UPDATE_MEMBER_FAILURE' as const
const SEND_AUTHCODE = 'SEND_AUTHCODE' as const
const SEND_AUTHCODE_REQUEST = 'SEND_AUTHCODE_REQUEST' as const
const SEND_AUTHCODE_SUCCESS = 'SEND_AUTHCODE_SUCCESS' as const
const SEND_AUTHCODE_FAILURE = 'SEND_AUTHCODE_FAILURE' as const
const SEND_AUTHCODE_CLEAR_ERROR = 'SEND_AUTHCODE_CLEAR_ERROR' as const
const VERIFY_AUTHCODE = 'VERIFY_AUTHCODE' as const
const VERIFY_AUTHCODE_REQUEST = 'VERIFY_AUTHCODE_REQUEST' as const
const VERIFY_AUTHCODE_SUCCESS = 'VERIFY_AUTHCODE_SUCCESS' as const
const VERIFY_AUTHCODE_FAILURE = 'VERIFY_AUTHCODE_FAILURE' as const
const FETCH_ONETIME_CODE = 'FETCH_ONETIME_CODE' as const
const FETCH_ONETIME_CODE_REQUEST = 'FETCH_ONETIME_CODE_REQUEST' as const
const FETCH_ONETIME_CODE_SUCCESS = 'FETCH_ONETIME_CODE_SUCCESS' as const
const FETCH_ONETIME_CODE_FAILURE = 'FETCH_ONETIME_CODE_FAILURE' as const
const FETCH_MEMBER_RANK_INFO = 'FETCH_MEMBER_RANK_INFO' as const
const FETCH_MEMBER_RANK_INFO_REQUEST = 'FETCH_MEMBER_RANK_INFO_REQUEST' as const
const FETCH_MEMBER_RANK_INFO_SUCCESS = 'FETCH_MEMBER_RANK_INFO_SUCCESS' as const
const FETCH_MEMBER_RANK_INFO_FAILURE = 'FETCH_MEMBER_RANK_INFO_FAILURE' as const

export const MemberActionTypes = {
  FETCH_MEMBER,
  // FETCH_MEMBER_REQUEST,
  // FETCH_MEMBER_SUCCESS,
  // FETCH_MEMBER_FAILURE,
  CREATE_MEMBER,
  // CREATE_MEMBER_REQUEST,
  // CREATE_MEMBER_SUCCESS,
  // CREATE_MEMBER_FAILURE,
  UPDATE_MEMBER,
  // UPDATE_MEMBER_REQUEST,
  // UPDATE_MEMBER_SUCCESS,
  // UPDATE_MEMBER_FAILURE,
  SEND_AUTHCODE,
  // SEND_AUTHCODE_REQUEST,
  // SEND_AUTHCODE_SUCCESS,
  // SEND_AUTHCODE_FAILURE,
  // SEND_AUTHCODE_CLEAR_ERROR,
  VERIFY_AUTHCODE,
  // VERIFY_AUTHCODE_REQUEST,
  VERIFY_AUTHCODE_SUCCESS,
  // VERIFY_AUTHCODE_FAILURE,
  FETCH_ONETIME_CODE,
  // FETCH_ONETIME_CODE_REQUEST,
  // FETCH_ONETIME_CODE_SUCCESS,
  // FETCH_ONETIME_CODE_FAILURE,
  FETCH_MEMBER_RANK_INFO,
  // FETCH_MEMBER_RANK_INFO_REQUEST,
  // FETCH_MEMBER_RANK_INFO_SUCCESS,
  // FETCH_MEMBER_RANK_INFO_FAILURE,
}

// Action Creators

export function fetchMemberAction() {
  return {
    type: FETCH_MEMBER,
  }
}

function fetchMemberRequestAction() {
  return {
    type: FETCH_MEMBER_REQUEST,
  }
}

function fetchMemberSuccessAction(member: Member) {
  return {
    type: FETCH_MEMBER_SUCCESS,
    payload: { member },
  }
}

function fetchMemberFailureAction(error: any) {
  return {
    type: FETCH_MEMBER_FAILURE,
    payload: { error },
  }
}

export function createMemberAction(params: CreateMemberParams) {
  return {
    type: CREATE_MEMBER,
    payload: { params },
  }
}

function createMemberRequestAction() {
  return {
    type: CREATE_MEMBER_REQUEST,
  }
}

function createMemberSuccessAction(member: Member) {
  return {
    type: CREATE_MEMBER_SUCCESS,
    payload: { member },
  }
}

function createMemberFailureAction(error: any) {
  return {
    type: CREATE_MEMBER_FAILURE,
    payload: { error },
  }
}

function updateMemberAction(params: UpdateMemberParams) {
  return {
    type: UPDATE_MEMBER,
    payload: { params },
  }
}

function updateMemberRequestAction() {
  return {
    type: UPDATE_MEMBER_REQUEST,
  }
}

function updateMemberSuccessAction(member: Member) {
  return {
    type: UPDATE_MEMBER_SUCCESS,
    payload: { member },
  }
}

function updateMemberFailureAction(error: any) {
  return {
    type: UPDATE_MEMBER_FAILURE,
    payload: { error },
  }
}

function sendAuthcodeAction(email: string) {
  return {
    type: SEND_AUTHCODE,
    payload: { email },
  }
}

function sendAuthcodeRequestAction(email: string) {
  return {
    type: SEND_AUTHCODE_REQUEST,
    payload: { email },
  }
}

function sendAuthcodeSuccessAction(
  email: string,
  status: number,
  migrateRequired: boolean
) {
  return {
    type: SEND_AUTHCODE_SUCCESS,
    payload: { email, status, migrateRequired },
  }
}

function sendAuthcodeFailureAction(error: any) {
  return {
    type: SEND_AUTHCODE_FAILURE,
    payload: { error },
  }
}

export function sendAuthcodeClearErrorAction() {
  return {
    type: SEND_AUTHCODE_CLEAR_ERROR,
  }
}

function verifyAuthcodeAction(authcode: string) {
  return {
    type: VERIFY_AUTHCODE,
    payload: { authcode },
  }
}

function verifyAuthcodeRequestAction(authcode: string) {
  return {
    type: VERIFY_AUTHCODE_REQUEST,
    payload: { authcode },
  }
}

function verifyAuthcodeSuccessAction(authcode: string) {
  return {
    type: VERIFY_AUTHCODE_SUCCESS,
    payload: { authcode },
  }
}

function verifyAuthcodeFailureAction(error: any) {
  return {
    type: VERIFY_AUTHCODE_FAILURE,
    payload: { error },
  }
}

export function fetchOnetimeCodeAction() {
  return {
    type: FETCH_ONETIME_CODE,
  }
}

function fetchOnetimeCodeRequestAction() {
  return {
    type: FETCH_ONETIME_CODE_REQUEST,
  }
}

function fetchOnetimeCodeSuccessAction(onetimeCodeInfo: OnetimeCodeInfo) {
  return {
    type: FETCH_ONETIME_CODE_SUCCESS,
    payload: {
      onetimeCode: onetimeCodeInfo.onetimeCode,
      expiresAt: onetimeCodeInfo.expiresAt,
    },
  }
}

function fetchOnetimeCodeFailureAction(error: any) {
  return {
    type: FETCH_ONETIME_CODE_FAILURE,
    payload: { error },
  }
}

export function fetchMemberRankInfoAction() {
  return {
    type: FETCH_MEMBER_RANK_INFO,
  }
}

function fetchMemberRankInfoRequestAction() {
  return {
    type: FETCH_MEMBER_RANK_INFO_REQUEST,
  }
}

function fetchMemberRankInfoSuccessAction(
  memberRankInfo: MemberRankInfoResponse
) {
  return {
    type: FETCH_MEMBER_RANK_INFO_SUCCESS,
    payload: {
      status: memberRankInfo.status,
      memberRankInfo: memberRankInfo.memberRank,
    },
  }
}

function fetchMemberRankInfoFailureAction(error: any) {
  return {
    type: FETCH_MEMBER_RANK_INFO_FAILURE,
    payload: { error },
  }
}

export const MemberActionCreators = {
  fetchMemberAction,
  fetchMemberRequestAction,
  fetchMemberSuccessAction,
  fetchMemberFailureAction,
  createMemberAction,
  createMemberRequestAction,
  createMemberSuccessAction,
  createMemberFailureAction,
  updateMemberAction,
  updateMemberRequestAction,
  updateMemberSuccessAction,
  updateMemberFailureAction,
  sendAuthcodeAction,
  sendAuthcodeRequestAction,
  sendAuthcodeSuccessAction,
  sendAuthcodeFailureAction,
  sendAuthcodeClearErrorAction,
  verifyAuthcodeAction,
  verifyAuthcodeRequestAction,
  verifyAuthcodeSuccessAction,
  verifyAuthcodeFailureAction,
  fetchOnetimeCodeAction,
  fetchOnetimeCodeRequestAction,
  fetchOnetimeCodeSuccessAction,
  fetchOnetimeCodeFailureAction,
  fetchMemberRankInfoAction,
  fetchMemberRankInfoRequestAction,
  fetchMemberRankInfoSuccessAction,
  fetchMemberRankInfoFailureAction,
}

// Actions

export type FetchMemberAction = ReturnType<typeof fetchMemberAction>
export type CreateMemberAction = ReturnType<typeof createMemberAction>
export type UpdateMemberAction = ReturnType<typeof updateMemberAction>
export type SendAuthcodeAction = ReturnType<typeof sendAuthcodeAction>
export type VerifyAuthcodeAction = ReturnType<typeof verifyAuthcodeAction>
export type FetchOnetimeCodeAction = ReturnType<typeof fetchOnetimeCodeAction>
export type FetchMemberRankInfoAction = ReturnType<
  typeof fetchMemberRankInfoAction
>

type MemberAction =
  | FetchMemberAction
  | ReturnType<typeof fetchMemberRequestAction>
  | ReturnType<typeof fetchMemberSuccessAction>
  | ReturnType<typeof fetchMemberFailureAction>
  | CreateMemberAction
  | ReturnType<typeof createMemberRequestAction>
  | ReturnType<typeof createMemberSuccessAction>
  | ReturnType<typeof createMemberFailureAction>
  | UpdateMemberAction
  | ReturnType<typeof updateMemberRequestAction>
  | ReturnType<typeof updateMemberSuccessAction>
  | ReturnType<typeof updateMemberFailureAction>
  | SendAuthcodeAction
  | ReturnType<typeof sendAuthcodeRequestAction>
  | ReturnType<typeof sendAuthcodeSuccessAction>
  | ReturnType<typeof sendAuthcodeFailureAction>
  | ReturnType<typeof sendAuthcodeClearErrorAction>
  | VerifyAuthcodeAction
  | ReturnType<typeof verifyAuthcodeRequestAction>
  | ReturnType<typeof verifyAuthcodeSuccessAction>
  | ReturnType<typeof verifyAuthcodeFailureAction>
  | FetchOnetimeCodeAction
  | ReturnType<typeof fetchOnetimeCodeRequestAction>
  | ReturnType<typeof fetchOnetimeCodeSuccessAction>
  | ReturnType<typeof fetchOnetimeCodeFailureAction>
  | FetchMemberRankInfoAction
  | ReturnType<typeof fetchMemberRankInfoRequestAction>
  | ReturnType<typeof fetchMemberRankInfoSuccessAction>
  | ReturnType<typeof fetchMemberRankInfoFailureAction>
  | ResetStoreAction

// State

interface MemberStatus {
  updating: boolean
  updated: boolean
  updateError: any
}

interface MemberState {
  member: Member | undefined
  memberStatus: MemberStatus
  authEmail: string | undefined
  authEmailSent: boolean
  authEmailError: ErrorResponse | undefined
  authEmailStatus: number | undefined
  migrateRequired: boolean | undefined
  authcode: string | undefined
  authcodeVerified: boolean
  authcodeError: ErrorResponse | undefined
  onetimeCode: string | undefined
  onetimeCodeExpiresAt: string | undefined
  memberRankInfo: MemberRankInfo | undefined
}

const memberStatusInitial: MemberStatus = {
  updating: false,
  updated: false,
  updateError: undefined,
}

const memberStatusUpdating: MemberStatus = {
  updating: true,
  updated: false,
  updateError: undefined,
}

const memberStatusUpdated: MemberStatus = {
  updating: false,
  updated: true,
  updateError: undefined,
}

const initialState: MemberState = {
  member: undefined,
  memberStatus: memberStatusInitial,
  authEmail: undefined,
  authEmailSent: false,
  authEmailStatus: undefined,
  migrateRequired: undefined,
  authEmailError: undefined,
  authcode: undefined,
  authcodeVerified: false,
  authcodeError: undefined,
  onetimeCode: undefined,
  onetimeCodeExpiresAt: undefined,
  memberRankInfo: undefined,
}

// Selector

export function memberSelector(rootState: { member: MemberState }) {
  return rootState.member.member
}

export function memberStatusSelector(rootState: { member: MemberState }) {
  return rootState.member.memberStatus
}

export function authEmailSentSelector(rootState: { member: MemberState }) {
  return rootState.member.authEmailSent
}

export function authEmailStatusSelector(rootState: { member: MemberState }) {
  return rootState.member.authEmailStatus
}

export function migrateRequiredSelector(rootState: { member: MemberState }) {
  return rootState.member.migrateRequired
}

export function authEmailErrorSelector(rootState: { member: MemberState }) {
  return rootState.member.authEmailError
}

export function authcodeVerifiedSelector(rootState: { member: MemberState }) {
  return rootState.member.authcodeVerified
}

export function authcodeErrorSelector(rootState: { member: MemberState }) {
  return rootState.member.authcodeError
}

export function onetimeCodeSelector(rootState: { member: MemberState }) {
  return rootState.member.onetimeCode
}

export function onetimeCodeExpiresAtSelector(rootState: {
  member: MemberState
}) {
  return rootState.member.onetimeCodeExpiresAt
}

export function memberRankInfoSelector(rootState: { member: MemberState }) {
  return rootState.member.memberRankInfo
}

// Reducer

export function memberReducer(
  state = initialState,
  action: MemberAction
): MemberState {
  switch (action.type) {
    case FETCH_MEMBER_SUCCESS:
      return {
        ...state,
        member: action.payload.member,
        memberStatus: memberStatusInitial,
      }
    case CREATE_MEMBER_SUCCESS:
      return {
        ...state,
        member: action.payload.member,
      }
    case CREATE_MEMBER_FAILURE:
      return {
        ...state,
        // member: action.payload.member,
      }
    case UPDATE_MEMBER_REQUEST:
      return {
        ...state,
        memberStatus: memberStatusUpdating,
      }
    case UPDATE_MEMBER_SUCCESS:
      return {
        ...state,
        member: action.payload.member,
        memberStatus: memberStatusUpdated,
      }
    case SEND_AUTHCODE_REQUEST:
      return {
        ...state,
        authEmailSent: state.migrateRequired ? true : false,
        authEmailError: undefined,
        authcodeError: undefined,
      }
    case SEND_AUTHCODE_SUCCESS:
      return {
        ...state,
        authEmailSent: true,
        authEmail: action.payload.email,
        authEmailStatus: action.payload.status,
        migrateRequired: action.payload.migrateRequired,
      }
    case SEND_AUTHCODE_FAILURE:
      return {
        ...state,
        authEmailSent: state.migrateRequired ? true : false,
        authEmailError: action.payload.error,
      }
    case SEND_AUTHCODE_CLEAR_ERROR:
      return {
        ...state,
        authEmailSent: false,
        authEmail: undefined,
        authEmailStatus: undefined,
        migrateRequired: undefined,
        authEmailError: undefined,
        authcodeError: undefined,
      }
    case VERIFY_AUTHCODE_REQUEST:
      return {
        ...state,
        authcodeVerified: false,
        authcodeError: undefined,
      }
    case VERIFY_AUTHCODE_SUCCESS:
      return {
        ...state,
        authcodeVerified: true,
        authcode: action.payload.authcode,
      }
    case VERIFY_AUTHCODE_FAILURE:
      return {
        ...state,
        authcodeError: action.payload.error,
      }
    case FETCH_ONETIME_CODE_SUCCESS:
      return {
        ...state,
        onetimeCode: action.payload.onetimeCode,
        onetimeCodeExpiresAt: action.payload.expiresAt,
      }
    case FETCH_MEMBER_RANK_INFO_SUCCESS:
      return {
        ...state,
        memberRankInfo: action.payload.memberRankInfo,
      }
    case ResetActionTypes.RESET_STORE:
      return initialState
    default:
      return state
  }
}
