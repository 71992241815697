import React, { useCallback, useEffect } from 'react'

import { BackNavLinkPanel } from '../organisms/BackNavPanel'
import { ProfileForm } from '../organisms/ProfileForm'
import { DefaultTemplate } from '../templates/DefaultTemplate'
import { Subtitle } from '../molecules/Subtitle'
import { LoadingPanel } from '../molecules/LoadingPanel'
import { Padding } from '../atoms/Padding'
import { Redirect, useHistory } from 'react-router-dom'
import { UpdateMemberParams } from '../../models/member'
import { ProfileFormValues } from '../../validations/member'
import { useMember, useUpdateMember } from '../../hooks/member'

interface CardInfoProfileEditPagePresenterProps {
  defaultValues: ProfileFormValues | undefined
  onSubmit: (params: UpdateMemberParams) => void
}

export function CardInfoProfileEditPagePresenter({
  defaultValues,
  onSubmit,
}: CardInfoProfileEditPagePresenterProps) {
  const Main = (
    <>
      <BackNavLinkPanel label="戻る" to="/cardinfo" />
      <Subtitle text="プロフィール編集" />
      {defaultValues ? (
        <Padding bottom="40px">
          <ProfileForm defaultValues={defaultValues} onSubmit={onSubmit} />
        </Padding>
      ) : (
        <LoadingPanel />
      )}
    </>
  )

  return (
    <DefaultTemplate title="プロフィール編集" main={Main} autoScrollToTop />
  )
}

function useCardInfoProfileEditPage() {
  const history = useHistory()
  const { member, memberStatus, fetchMember } = useMember()
  const { updateMember } = useUpdateMember()
  const handleSubmit = useCallback(
    (params: UpdateMemberParams) => {
      updateMember(params)
    },
    [updateMember]
  )
  const defaultValues: ProfileFormValues | undefined = member && {
    lastName: member.lastName,
    firstName: member.firstName,
    lastNameKana: member.lastNameKana,
    firstNameKana: member.firstNameKana,
    gender: member.gender,
    birthday: member.birthday,
    phone: member.phone,
    // email: member.email,
    postalCode: member.postalCode,
    prefecture: member.prefecture,
    city: member.city,
    street: member.street,
    apartment: member.apartment,
  }

  useEffect(() => {
    if (!member) {
      fetchMember()
    }
  }, [member, fetchMember])
  useEffect(() => {
    if (memberStatus.updated) {
      history.push('/')
    }
  }, [history, member, memberStatus])

  return { isGuest: member?.isGuest, defaultValues, handleSubmit }
}

export function CardInfoProfileEditPage() {
  const { isGuest, defaultValues, handleSubmit } = useCardInfoProfileEditPage()

  if (isGuest) {
    return <Redirect to="/cardinfo" />
  }

  return (
    <CardInfoProfileEditPagePresenter
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
    />
  )
}
