import React, { FocusEvent, useCallback } from 'react'

import { InputProps, Input } from '../atoms/Input'
import { formatPostalCode } from '../../utils/postalCode'

type InputPostalcodeProps = InputProps & {
  // formik
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => any
}

export function InputPostalcode({
  name,
  value,
  readOnly,
  onChange,
  onBlur,
  setFieldValue,
  ...rest
}: InputPostalcodeProps) {
  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (value) {
        const correctedValue = formatPostalCode(value)
        if (correctedValue !== value) {
          setFieldValue && setFieldValue(name, correctedValue)
        }
      }
      onBlur && onBlur(event)
    },
    [name, value, onBlur, setFieldValue]
  )

  return (
    <Input
      type="tel"
      name={name}
      value={value}
      readOnly={readOnly}
      pattern="[0-9]+"
      onChange={onChange}
      onBlur={handleBlur}
      {...rest}
    />
  )
}
