import { useCallback, useState } from 'react'
import { getScrollY, scrollToTop, scrollToY } from '../utils/scroll'

export function useScroll() {
  const [scrollY, setScrollY] = useState<number>(0)
  const saveCurrentScrollY = useCallback(() => {
    setScrollY(getScrollY())
  }, [])
  const resetScroll = useCallback(() => {
    scrollToTop()
  }, [])
  const restoreScroll = useCallback(() => {
    scrollToY(scrollY)
  }, [scrollY])

  return { scrollY, saveCurrentScrollY, resetScroll, restoreScroll }
}

// useEffect(() => {
//   if (selectedRecord) {
//     resetScroll()
//   } else {
//     restoreScroll()
//   }
// }, [selectedRecord, resetScroll, restoreScroll])
// useEffect(() => {
//   resetScroll()
// }, [meta, resetScroll])
