import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { AppAgreementsPage } from './components/pages/AppAgreementsPage'

export function PublicRoutes() {
  return (
    <Switch>
      <Route>
        <AppAgreementsPage />
      </Route>
    </Switch>
  )
}
