import React, { CSSProperties } from 'react'

import {
  RoundedListItemHeader,
  RoundedListItemFooter,
} from '../molecules/RoundedList'
import { Panel } from '../atoms/Panel'
import { LinkIcon } from '../atoms/icons/LinkIcon'
import { TradeHistoryRecord } from '../../models/trade'
import { EmptyListPanel } from '../molecules/EmptyListPanel'
import { formatDate } from '../../utils/date'
import { calculateTotalFee, discountablePrice } from '../../utils/receipt'

const styles: { [name: string]: CSSProperties } = {
  label: {
    fontWeight: 'bold',
    color: '#5B5B5B',
    fontSize: '10px',
    lineHeight: '15px',
  },
  value: {
    fontWeight: 'bold',
    color: '#5B5B5B',
    paddingLeft: '4px',
    fontSize: '10px',
    lineHeight: '12px',
  },
  saleDate: {
    paddingTop: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'start',
  },
  storeName: {
    paddingTop: '8px',
    paddingLeft: '16px',
    paddingRight: '16px',
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'start',
  },
  transactionType: {
    paddingTop: '13px',
    paddingLeft: '16px',
    paddingRight: '16px',
    display: 'grid',
    gridTemplateColumns: 'auto',
    justifyContent: 'start',
  },
  transactionTypeLabel: {
    color: '#5B5B5B',
    fontSize: '14px',
    lineHeight: '21px',
  },
  guaranteeContainer: {
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingTop: '13px',
    paddingBottom: '13px',
  },
  guaranteeNumber: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'start',
  },
  notLastGuaranteeNumber: {
    paddingBottom: '8px',
  },
  grandTotal: {
    cursor: 'pointer',
    paddingTop: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: '16px',
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  grandTotalLabel: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 'bold',
  },
  grandTotalValue: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 'bold',
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'end',
    gridGap: '8px',
  },
}

interface TradeHistoryListItemProps {
  record: TradeHistoryRecord
  onClick: () => void
}

export function TradeHistoryListItem({
  record,
  onClick,
}: TradeHistoryListItemProps) {
  const { totalFee, isCancel } = calculateTotalFee(record)
  const formattedGrandTotal = discountablePrice(totalFee, isCancel)

  return (
    <Panel style={{ marginTop: '8px' }}>
      <RoundedListItemHeader>
        <div style={styles.saleDate}>
          <div style={styles.label}>利用日:</div>
          <div style={styles.value}>{formatDate(record.salesDate)}</div>
        </div>
        <div style={styles.storeName}>
          <div style={styles.label}>利用店舗:</div>
          <div style={styles.value}>{record.storeName}</div>
        </div>
        <div style={styles.transactionType}>
          <div style={styles.transactionTypeLabel}>
            {record.transactionTypeName}
          </div>
        </div>
        <div style={styles.guaranteeContainer}>
          {record.guaranteeDetails.map((guranteeDetail, index) => (
            <div
              key={guranteeDetail.securityGuaranteeId}
              style={
                record.guaranteeDetails.length > index + 1
                  ? {
                      ...styles.guaranteeNumber,
                      ...styles.notLastGuaranteeNumber,
                    }
                  : styles.guaranteeNumber
              }
            >
              <div style={styles.label}>安心保証番号:</div>
              <div style={styles.value}>
                {guranteeDetail.securityGuaranteeId}
              </div>
            </div>
          ))}
        </div>
      </RoundedListItemHeader>
      <RoundedListItemFooter>
        <div style={styles.grandTotal} onClick={onClick}>
          <div style={styles.grandTotalLabel}>金額</div>
          <div style={styles.grandTotalValue}>
            <div>{formattedGrandTotal} 円</div>
            <LinkIcon />
          </div>
        </div>
      </RoundedListItemFooter>
    </Panel>
  )
}

interface TradeHistoryListProps {
  records: TradeHistoryRecord[]
  onDetailClick: (record: TradeHistoryRecord) => void
}

export function TradeHistoryList({
  records,
  onDetailClick,
}: TradeHistoryListProps) {
  if (records.length <= 0) {
    return <EmptyListPanel label="現在購買履歴はありません。" />
  }

  return (
    <>
      {records.map((record, i) => {
        return (
          <TradeHistoryListItem
            key={i}
            record={record}
            onClick={() => onDetailClick(record)}
          />
        )
      })}
    </>
  )
}
