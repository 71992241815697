import { FormikErrors } from 'formik'
import { UpdateMemberParams, CreateMemberParams } from '../models/member'

const MAX_NAME_LENGTH = 100
export const MIN_PASSWORD_LENGTH = 8
export const MAX_PASSWORD_LENGTH = 100
const MAX_CITY_LENGTH = 30
const MAX_STREET_LENGTH = 30
const MAX_APARTMENT_LENGTH = 30

export type ProfileFormValues = UpdateMemberParams

export function uniqErrors(errors: { [name: string]: string | undefined }): {
  [name: string]: string | undefined
} {
  return Object.entries(errors).reduce(
    (uniqErrors, [fieldName, errorMessage]) => {
      if (!errorMessage) {
        return uniqErrors
      }
      return { ...uniqErrors, [fieldName]: errorMessage }
    },
    {}
  )
}

export function getNameError(value: string | undefined, label: string) {
  try {
    if (!value) {
      return `${label}を入力してください`
    } else if (value.length > MAX_NAME_LENGTH) {
      return `${label}は${MAX_NAME_LENGTH}文字以内で入力してください`
    }
    return undefined
  } catch (e: any) {
    console.debug('getNameError: value, error:', value, e)
    return `${label}に識別できないエラーがあります`
  }
}

export function getGenderError(value: string | undefined, label: string) {
  // gender: 'other' | 'male' | 'female' // 性別(0:不明 1:男性 2:女性)
  const genders = ['other', 'male', 'female']
  try {
    if (!value || !genders.includes(value)) {
      return `${label}を選択してください`
    }
    return undefined
  } catch (e: any) {
    console.debug('getGenderError: value, error:', value, e)
    return `${label}に識別できないエラーがあります`
  }
}

export function getDateError(value: string | undefined, label: string) {
  try {
    if (!value || !value.match(/^[12][0-9]{3}-[01][0-9]-[0-3][0-9]$/)) {
      return `${label}を入力してください`
    }
    return undefined
  } catch (e: any) {
    console.debug('getDateError: value, error:', value, e)
    return `${label}に識別できないエラーがあります`
  }
}

export function getPhoneError(value: string | undefined, label: string) {
  try {
    if (!value) {
      return `${label}を入力してください`
    } else if (!value.match(/^0[0-9]{9,10}$/)) {
      return `${label}を10桁または11桁の数字で入力してください`
    }
    return undefined
  } catch (e: any) {
    console.debug('getPhoneError: value, error:', value, e)
    return `${label}に識別できないエラーがあります`
  }
}

export function getPostalcodeError(value: string | undefined, label: string) {
  try {
    if (!value) {
      return `${label}を入力してください`
    } else if (!value || !value.match(/^[0-9]{7}$/)) {
      return `${label}を7桁の数字で入力してください`
    }
    return undefined
  } catch (e: any) {
    console.debug('getPostalcodeError: value, error:', value, e)
    return `${label}に識別できないエラーがあります`
  }
}

export function getPrefectureError(value: string | undefined, label: string) {
  try {
    if (!value) {
      return `${label}を選択してください`
    }
    return undefined
  } catch (e: any) {
    console.debug('getPrefecture: value, error:', value, e)
    return `${label}に識別できないエラーがあります`
  }
}

export function getCityError(value: string | undefined, label: string) {
  try {
    if (!value) {
      return `${label}を入力してください`
    } else if (value.length > MAX_CITY_LENGTH) {
      return `${label}は${MAX_CITY_LENGTH}文字以内で入力してください`
    }
    return undefined
  } catch (e: any) {
    console.debug('getCity: value, error:', value, e)
    return `${label}に識別できないエラーがあります`
  }
}

export function getStreetError(value: string | undefined, label: string) {
  try {
    if (!value) {
      return `${label}を入力してください`
    } else if (value.length > MAX_STREET_LENGTH) {
      return `${label}は${MAX_STREET_LENGTH}文字以内で入力してください`
    }
    return undefined
  } catch (e: any) {
    console.debug('getStreet: value, error:', value, e)
    return `${label}に識別できないエラーがあります`
  }
}

export function getApartmentError(value: string | undefined, label: string) {
  try {
    if (!value) {
      return undefined
    } else if (value.length > MAX_APARTMENT_LENGTH) {
      return `${label}は${MAX_APARTMENT_LENGTH}文字以内で入力してください`
    }
    return undefined
  } catch (e: any) {
    console.debug('getApartment: value, error:', value, e)
    return `${label}に識別できないエラーがあります`
  }
}

export function getPasswordError(value: string | undefined, label: string) {
  try {
    // !,#,$,%,&,_,-,+,|
    if (!value) {
      return `${label}を入力してください`
    } else if (
      !value.match(/^(?=.*[A-Za-z])(?=.*[0-9])[-!#$%&_+|0-9A-Za-z]+$/)
    ) {
      return `${label}は英数字を含む英数字記号(!#$%&_-+|)${MIN_PASSWORD_LENGTH}桁〜${MAX_PASSWORD_LENGTH}桁で入力してください`
    } else if (value.length < MIN_PASSWORD_LENGTH) {
      return `${label}は英数字を含む英数字記号(!#$%&_-+|)${MIN_PASSWORD_LENGTH}桁以上で入力してください`
    } else if (value.length > MAX_PASSWORD_LENGTH) {
      return `${label}は英数字を含む英数字記号(!#$%&_-+|)${MAX_PASSWORD_LENGTH}桁以内で入力してください`
    }
    return undefined
  } catch (e: any) {
    console.debug('getPasswordError: value, error:', value, e)
    return `${label}に識別できないエラーがあります`
  }
}

export function getPrivacyPolicyAgreedError(
  value: boolean | undefined,
  label: string
) {
  try {
    if (!value) {
      return `${label}に同意してください`
    }
    return undefined
  } catch (e: any) {
    console.debug('getPrivacyPolicyAgreedError: value, error:', value, e)
    return `${label}に識別できないエラーがあります`
  }
}

type ProfileFormErrors = FormikErrors<ProfileFormValues>

export function validateProfileForm(
  values: ProfileFormValues
): ProfileFormErrors {
  const errors: ProfileFormErrors = {}

  errors.lastName = getNameError(values.lastName, '氏名(姓)')
  errors.firstName = getNameError(values.firstName, '氏名(名)')
  errors.lastNameKana = getNameError(values.lastNameKana, '姓カナ')
  errors.firstNameKana = getNameError(values.firstNameKana, '名カナ')
  errors.gender = getGenderError(values.gender, '性別')
  errors.birthday = getDateError(values.birthday, '生年月日')
  errors.phone = getPhoneError(values.phone, '電話番号')
  // if (!values.email) errors.email = 'メールアドレスを入力してください'
  errors.postalCode = getPostalcodeError(values.postalCode, '郵便番号')
  errors.prefecture = getPrefectureError(values.prefecture, '都道府県')
  errors.city = getCityError(values.city, '市区町村')
  errors.street = getStreetError(values.street, '町名番地')
  errors.apartment = getApartmentError(values.apartment, 'ビルマンション')

  return uniqErrors(errors)
}

export type SignUpFormValues = CreateMemberParams & {
  privacyPolicyAgreed: boolean
}

type SignUpFormErrors = FormikErrors<SignUpFormValues>

export function validateSignUpForm(values: SignUpFormValues): SignUpFormErrors {
  const errors: SignUpFormErrors = {
    ...validateProfileForm(values),
  }

  errors.password = getPasswordError(values.password, 'パスワード')
  errors.privacyPolicyAgreed = getPrivacyPolicyAgreedError(
    values.privacyPolicyAgreed,
    '個人情報保護方針'
  )

  return uniqErrors(errors)
}
