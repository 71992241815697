// import fetch from 'isomorphic-fetch'
import 'isomorphic-fetch' // Use this style to use fetch-mock

import {
  fromAnnouncementsRawResponse,
  AnnouncementsSearchOptions,
  parseAnnouncementsSearchOptions,
} from '../models/announcement'
import {
  fromCouponsRawResponse,
  CouponsSearchOptions,
  parseCouponsSearchOptions,
} from '../models/coupon'
import {
  fromReservationsRawResponse,
  ReservationsSearchOptions,
  parseReservationsSearchOptions,
} from '../models/reservation'
import { fromLoginRawResponse } from '../models/login'
import {
  fromUpdateMemberParams,
  UpdateMemberParams,
  CreateMemberParams,
  fromCreateMemberParams,
  fromMemberRawResponse,
  fromOnetimeCodeRawResponse,
  fromMemberRankRawResponse,
} from '../models/member'
import {
  PointHistorySearchOptions,
  parsePointHistorySearchOptions,
  fromTotalPointRawResponse,
  fromPointHistoryRawResponse,
} from '../models/point'
import {
  TradeHistorySearchOptions,
  parseTradeHistorySearchOptions,
  fromTradeHistoryRawResponse,
} from '../models/trade'
import {
  fromErrorRawResponse,
  fromAuthCodeErrorRawResponse,
  fromVerifyAuthCodeErrorRawResponse,
} from '../models/error'

// fetch option
// {
//   method: "POST", // *GET, POST, PUT, DELETE, etc.
//   mode: "cors", // no-cors, cors, *same-origin
//   cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
//   credentials: "same-origin", // include, same-origin, *omit
//   headers: {
//       "Content-Type": "application/json; charset=utf-8",
//       // "Content-Type": "application/x-www-form-urlencoded",
//   },
//   redirect: "follow", // manual, *follow, error
//   referrer: "no-referrer", // no-referrer, *client
//   body: JSON.stringify(data), // 本文のデータ型は "Content-Type" ヘッダーと一致する必要があります
// }

function buildHeaders(token?: string) {
  return {
    'Content-Type': 'application/json; charset=utf-8',
    ...(token && { Authorization: `Bearer ${token}` }),
  }
}

// Session and account

export async function login(apiHost: string, linetoken: string) {
  const url = `${apiHost}/api/line/login`
  const response = await fetch(url, {
    method: 'POST',
    headers: buildHeaders(),
    body: JSON.stringify({ linetoken }),
  })
  const json = await response.json()

  if (json.status === 0) {
    return fromLoginRawResponse(json)
  } else if (json.status === 100) {
    return fromErrorRawResponse(json)
  }

  throw fromErrorRawResponse(json)
}

export async function linkLineAccount(apiHost: string, linetoken: string) {
  const url = `${apiHost}/api/line/register`
  const response = await fetch(url, {
    method: 'POST',
    headers: buildHeaders(),
    body: JSON.stringify({ linetoken }),
  })
  const json = await response.json()
  if (json.status === 0) {
    return { status: json.status }
  }

  throw fromErrorRawResponse(json, response.status)
}

export async function unlinkLineAccount(apiHost: string, token: string) {
  const url = `${apiHost}/api/line/unlink`
  const response = await fetch(url, {
    method: 'DELETE',
    headers: buildHeaders(token),
  })
  const json = await response.json()
  if (json.status === 0) {
    return { status: json.status }
  }

  throw fromErrorRawResponse(json)
}

export async function sendAuthcode(
  apiHost: string,
  token: string,
  email: string,
  migrate: number
) {
  const url = `${apiHost}/api/line/authcode`
  const response = await fetch(url, {
    method: 'POST',
    headers: buildHeaders(token),
    body: JSON.stringify({ mail_address: email, migrate }),
  })
  const json = await response.json()
  if (json.status === 0 || json.status === 1) {
    return { status: json.status }
  }

  throw fromAuthCodeErrorRawResponse(json)
}

export async function verifyAuthcode(
  apiHost: string,
  token: string,
  authcode: string,
  migrate: number
) {
  const url = `${apiHost}/api/line/authcodecheck`
  const response = await fetch(url, {
    method: 'POST',
    headers: buildHeaders(token),
    body: JSON.stringify({ authcode, migrate }),
  })
  const json = await response.json()
  if (json.status === 0) {
    return { status: json.status }
  }

  throw fromVerifyAuthCodeErrorRawResponse(json)
}

// Announcement

export async function fetchAnnouncements(
  apiHost: string,
  token: string,
  options: AnnouncementsSearchOptions
) {
  const searchParams = new URLSearchParams(
    parseAnnouncementsSearchOptions(options)
  )
  const query = searchParams.toString()
  const url = `${apiHost}/api/line/announcement?${query}`
  const response = await fetch(url, {
    method: 'GET',
    headers: buildHeaders(token),
  })
  const json = await response.json()
  if (json.status === 0) {
    return fromAnnouncementsRawResponse(json)
  }

  throw fromErrorRawResponse(json)
}

export async function updateAnnouncementAsRead(
  apiHost: string,
  token: string,
  mAnnouncementId: number
) {
  const url = `${apiHost}/api/line/read`
  const response = await fetch(url, {
    method: 'PUT',
    headers: buildHeaders(token),
    body: JSON.stringify({ m_announcement_id: mAnnouncementId }),
  })
  const json = await response.json()
  if (json.status === 0) {
    return true
  }

  throw fromErrorRawResponse(json)
}

// Coupon

export async function fetchCoupons(
  apiHost: string,
  token: string,
  options: CouponsSearchOptions
) {
  const searchParams = new URLSearchParams(parseCouponsSearchOptions(options))
  const query = searchParams.toString()
  const url = `${apiHost}/api/line/coupon?${query}`
  const response = await fetch(url, {
    method: 'GET',
    headers: buildHeaders(token),
  })
  const json = await response.json()
  if (json.status === 0) {
    return fromCouponsRawResponse(json)
  }

  throw fromErrorRawResponse(json)
}

// Reservation

export async function fetchReservations(
  apiHost: string,
  token: string,
  options: ReservationsSearchOptions
) {
  const searchParams = new URLSearchParams(
    parseReservationsSearchOptions(options)
  )
  const query = searchParams.toString()
  const url = `${apiHost}/api/line/reservehistory?${query}`
  const response = await fetch(url, {
    method: 'GET',
    headers: buildHeaders(token),
  })
  const json = await response.json()

  if (json.status === 0) {
    return fromReservationsRawResponse(json)
  }

  throw fromErrorRawResponse(json)
}

// Member

export async function fetchMember(apiHost: string, token: string) {
  const url = `${apiHost}/api/line/me`
  const response = await fetch(url, {
    method: 'GET',
    headers: buildHeaders(token),
  })
  const json = await response.json()
  if (json.status === 0) {
    return fromMemberRawResponse(json)
  }

  throw fromErrorRawResponse(json)
}

export async function createMember(
  apiHost: string,
  token: string,
  params: CreateMemberParams
) {
  const url = `${apiHost}/api/line/mainregister`
  const response = await fetch(url, {
    method: 'POST', // 'POST' なぜか PUT
    headers: buildHeaders(token),
    body: JSON.stringify(fromCreateMemberParams(params)),
  })
  const json = await response.json()
  if (json.status === 0) {
    return fromMemberRawResponse(json)
  }

  throw fromErrorRawResponse(json)
}

export async function updateMember(
  apiHost: string,
  token: string,
  params: UpdateMemberParams
) {
  const url = `${apiHost}/api/line/modification`
  const response = await fetch(url, {
    method: 'PUT',
    headers: buildHeaders(token),
    body: JSON.stringify(fromUpdateMemberParams(params)),
  })
  const json = await response.json()
  if (json.status === 0) {
    return fromMemberRawResponse(json)
  }

  throw fromErrorRawResponse(json)
}

export async function fetchMemberRankInfo(apiHost: string, token: string) {
  const url = `${apiHost}/api/line/memberrank`
  const response = await fetch(url, {
    method: 'GET',
    headers: buildHeaders(token),
  })
  const json = await response.json()
  if (json.status === 0) {
    return fromMemberRankRawResponse(json)
  }

  throw fromErrorRawResponse(json)
}

export async function fetchOnetimeCode(apiHost: string, token: string) {
  const url = `${apiHost}/api/line/onetimecode`
  const response = await fetch(url, {
    method: 'GET',
    headers: buildHeaders(token),
  })
  const json = await response.json()
  if (json.status === 0) {
    return fromOnetimeCodeRawResponse(json)
  }

  throw fromErrorRawResponse(json)
}

// Point

export async function fetchCurrentPoints(apiHost: string, token: string) {
  const url = `${apiHost}/api/line/totalpoint`
  const response = await fetch(url, {
    method: 'GET',
    headers: buildHeaders(token),
  })
  const json = await response.json()
  if (json.status === 0) {
    return fromTotalPointRawResponse(json)
  }

  throw fromErrorRawResponse(json)
}

// options:
//   count integer ページ当たりの表示件数           未指定の場合全件取得
//   page  integer ページ番号                     未指定の場合1
//   sort  integer ソート(0:日付で降順 1:日付で昇順) 未指定の場合降順
//   from  date    取得期間開始日(YYYY-MM-DD) 未指定の場合絞らない
//   to    date    取得期間終了日(YYYY-MM-DD) 未指定の場合絞らない
export async function fetchPointHistory(
  apiHost: string,
  token: string,
  options: PointHistorySearchOptions
) {
  const searchParams = new URLSearchParams(
    parsePointHistorySearchOptions(options)
  )
  const query = searchParams.toString()
  const url = `${apiHost}/api/line/pointhistory?${query}`
  const response = await fetch(url, {
    method: 'GET',
    headers: buildHeaders(token),
  })
  const json = await response.json()
  if (json.status === 0) {
    return fromPointHistoryRawResponse(json)
  }

  throw fromErrorRawResponse(json)
}

// Trade

// options:
//   count integer ページ当たりの表示件数           未指定の場合全件取得
//   page  integer ページ番号                     未指定の場合1
//   sort  integer ソート(0:日付で降順 1:日付で昇順) 未指定の場合降順
//   from  date    取得期間開始日(YYYY-MM-DD) 未指定の場合絞らない
//   to    date    取得期間終了日(YYYY-MM-DD) 未指定の場合絞らない
export async function fetchTradeHistory(
  apiHost: string,
  token: string,
  options: TradeHistorySearchOptions
) {
  const searchParams = new URLSearchParams(
    parseTradeHistorySearchOptions(options)
  )
  const query = searchParams.toString()
  const url = `${apiHost}/api/line/tradehistory?${query}`
  const response = await fetch(url, {
    method: 'GET',
    headers: buildHeaders(token),
  })
  const json = await response.json()
  if (json.status === 0) {
    return fromTradeHistoryRawResponse(json)
  }

  throw fromErrorRawResponse(json)
}
