import React, { PropsWithChildren, CSSProperties, ReactElement } from 'react'

import { Panel } from '../atoms/Panel'

const styles: { [name: string]: CSSProperties } = {
  panel: {
    paddingTop: '16px',
  },
  text: {
    fontSize: '14px',
    lineHeight: '21px',
    textAlign: 'left',
    whiteSpace: 'pre-wrap',
  },
}

interface DescriptionPanelProps {
  text?: string | string[] | ReactElement
  style?: CSSProperties
}

export function DescriptionPanel({
  text,
  style,
  children,
}: PropsWithChildren<DescriptionPanelProps>) {
  return (
    <Panel style={styles.panel}>
      <div style={{ ...styles.text, ...style }}>{text || children}</div>
    </Panel>
  )
}
