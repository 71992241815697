import React, { CSSProperties } from 'react'

import { Panel } from '../atoms/Panel'

interface ConfirmMigratePanelProps {
  apiErrorMessage?: string
  apiErrorStatus?: number
}

const styles: { [name: string]: CSSProperties } = {
  wrapper1: {
    height: '64px',
    backgroundColor: '#ff9501',
    paddingTop: '8px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  title: {
    color: '#fff',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  wrapper2: {
    // height: '64px',
    // backgroundColor: '#fff',
    borderBottom: '1px #D8D8D8 solid',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  noBorderWrapper2: {
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  message: {
    fontSize: '14px',
    lineHeight: '21px',
    textAlign: 'left',
    whiteSpace: 'pre-wrap',
  },
  message2: {
    fontSize: '10px',
    lineHeight: '21px',
    textAlign: 'left',
  },
}

export function ConfirmMigrateHelpPanel({
  apiErrorMessage,
  apiErrorStatus,
}: ConfirmMigratePanelProps) {
  return (
    <>
      <Panel fullWidth style={styles.wrapper1}>
        <div style={styles.title}>ふるいちオンラインアカウント</div>
        <div style={styles.title}>ふるいちアプリ（LINEアカウント）との連携</div>
      </Panel>
      <Panel
        style={apiErrorMessage ? styles.noBorderWrapper2 : styles.wrapper2}
      >
        {apiErrorMessage ? (
          <div style={styles.message}>{apiErrorMessage}</div>
        ) : (
          <>
            <div style={styles.message}>
              入力いただいたメールアドレスは、ふるいちオンラインアカウントでの登録がお済みです。
            </div>
            <br />
            <div style={styles.message}>
              ふるいちオンラインアカウントとLINEアカウントを連携することによりふるいちアプリとふるいちオンラインを共通でご利用いただけます。
            </div>
            <br />
            <div style={styles.message}>
              下記の説明を確認し、すべての項目にチェックをいれて同意いただけますと、連携が可能となります。
            </div>
          </>
        )}
        {apiErrorStatus && (
          <div style={styles.message2}>
            {`（エラーコード：${apiErrorStatus}）`}
          </div>
        )}
      </Panel>
    </>
  )
}
