import {
  TradeHistoryRecord,
  TradeHistorySearchOptions,
  TradeHistoryMeta,
} from '../models/trade'
import { ResetActionTypes, ResetStoreAction } from './reset'

// Action Types

const FETCH_TRADE_HISTORY = 'FETCH_TRADE_HISTORY' as const
const FETCH_TRADE_HISTORY_REQUEST = 'FETCH_TRADE_HISTORY_REQUEST' as const
const FETCH_TRADE_HISTORY_SUCCESS = 'FETCH_TRADE_HISTORY_SUCCESS' as const
const FETCH_TRADE_HISTORY_FAILURE = 'FETCH_TRADE_HISTORY_FAILURE' as const

export const TradeActionTypes = {
  FETCH_TRADE_HISTORY,
  // FETCH_TRADE_HISTORY_REQUEST,
  // FETCH_TRADE_HISTORY_SUCCESS,
  // FETCH_TRADE_HISTORY_FAILURE,
}

// Action Creators

export function fetchTradeHistoryAction(options: TradeHistorySearchOptions) {
  return {
    type: FETCH_TRADE_HISTORY,
    payload: { options },
  }
}

function fetchTradeHistoryRequestAction(options: TradeHistorySearchOptions) {
  return {
    type: FETCH_TRADE_HISTORY_REQUEST,
    payload: { options },
  }
}

function fetchTradeHistorySuccessAction(
  meta: TradeHistoryMeta,
  records: TradeHistoryRecord[]
) {
  return {
    type: FETCH_TRADE_HISTORY_SUCCESS,
    payload: { meta, records },
  }
}

function fetchTradeHistoryFailureAction(error: any) {
  return {
    type: FETCH_TRADE_HISTORY_FAILURE,
    payload: { error },
  }
}

export const TradeActionCreators = {
  fetchTradeHistoryAction,
  fetchTradeHistoryRequestAction,
  fetchTradeHistorySuccessAction,
  fetchTradeHistoryFailureAction,
}

// Actions

export type FetchTradeHistoryAction = ReturnType<typeof fetchTradeHistoryAction>

type TradeAction =
  | FetchTradeHistoryAction
  | ReturnType<typeof fetchTradeHistorySuccessAction>
  | ReturnType<typeof fetchTradeHistorySuccessAction>
  | ReturnType<typeof fetchTradeHistoryFailureAction>
  | ResetStoreAction

// State

interface TradeState {
  meta: TradeHistoryMeta | undefined
  records: TradeHistoryRecord[] | undefined
}

const initialState: TradeState = {
  meta: undefined,
  records: undefined,
}

// Selector

export function tradeHistoryMetaSelector(rootState: { trade: TradeState }) {
  return rootState.trade.meta
}

export function tradeHistoryRecordsSelector(rootState: { trade: TradeState }) {
  return rootState.trade.records
}

// Reducer

export function tradeReducer(
  state = initialState,
  action: TradeAction
): TradeState {
  switch (action.type) {
    case FETCH_TRADE_HISTORY_SUCCESS:
      return {
        ...state,
        meta: action.payload.meta,
        records: action.payload.records,
      }
    case ResetActionTypes.RESET_STORE:
      return initialState
    default:
      return state
  }
}
