import React, { CSSProperties } from 'react'

import { IconButton } from './IconButton'
import { HomeShortcutIcon } from '../atoms/icons/HomeShortcutIcon'

function Label() {
  const style: CSSProperties = {
    fontSize: '12px',
    fontWeight: 'bold',
    textAlign: 'center',
  }
  return (
    <>
      <div style={style}>ショート</div>
      <div style={style}>カット</div>
    </>
  )
}

export function AddHomeShortcutButton() {
  return <IconButton icon={HomeShortcutIcon} label={Label} />
}
