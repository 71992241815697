// Action Types

const RESET_STORE = 'RESET_STORE' as const

export const ResetActionTypes = {
  RESET_STORE,
}

// Action Creators

function resetStoreAction() {
  return { type: RESET_STORE }
}

export const ResetActionCreators = {
  resetStoreAction,
}

// Actions

export type ResetStoreAction = ReturnType<typeof resetStoreAction>

// type ResetAction = ResetStoreAction

// State

// Selector

// Reducer
