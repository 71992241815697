import React, { CSSProperties } from 'react'

import { Barcode } from '../atoms/Barcode'
import { FullWidthListItem } from '../molecules/FullWidthList'
import { Reservation } from '../../models/reservation'

const styles: { [name: string]: CSSProperties } = {
  container: {
    borderTop: '1px solid #D8D8D8',
    paddingBottom: '16px',
    backgroundColor: '#FFFFFF',
  },
  listItem: {
    border: 0,
  },
  couponName: {
    color: '#282828',
    fontSize: '12px',
    lineHeight: '18px',
    paddingTop: '12px',
    paddingLeft: '16px',
    paddingRight: '16px',
    display: 'grid',
    gridTemplateColumns: 'auto',
    justifyContent: 'start',
  },
  barcode: {
    paddingTop: '16px',
    paddingBottom: '8px',
    color: '#000000',
    display: 'grid',
    justifyItems: 'center',
    alignItems: 'end',
  },
  code: {
    fontSize: '14px',
    fontWeight: 'bold',
    // fontFamily: 'Helvetica Bold',
    color: '#282828',
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'end',
  },
}

interface ReservationBarcodePanelProps {
  reservation: Reservation
}

export function ReservationBarcodePanel({
  reservation,
}: ReservationBarcodePanelProps) {
  return (
    <div style={styles.container}>
      <FullWidthListItem style={styles.listItem}>
        <div style={styles.couponName}>{reservation.productName}</div>
        <div style={styles.barcode}>
          <Barcode value={reservation.reserveNo} />
        </div>
        <div style={styles.code}>
          <span>{reservation.reserveNo}</span>
        </div>
      </FullWidthListItem>
    </div>
  )
}
