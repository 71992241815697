import React, {
  CSSProperties,
  ChangeEvent,
  useCallback,
  useState,
  PropsWithChildren,
} from 'react'

import { CheckedIcon } from '../atoms/icons/CheckedIcon'
import { UncheckedIcon } from '../atoms/icons/UncheckedIcon'

const styles: { [name: string]: CSSProperties } = {
  container: {
    display: 'grid',
    boxSizing: 'border-box',
    width: '100%',
    minHeight: '51px',
    alignItems: 'center',
  },
  label: {
    display: 'grid',
    gridTemplateColumns: '24px auto',
    alignItems: 'start',
  },
  input: {
    position: 'absolute',
    left: '-9999px',
    visibility: 'hidden',
    zIndex: -9999,
  },
  labelText: {
    display: 'block',
    paddingLeft: '8px',
    alignSelf: 'center',
  },
}

interface CheckboxProps {
  name: string
  value: string | undefined
  checked?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

export function Checkbox({
  name,
  value,
  checked: defaultChecked,
  onChange,
  children,
}: PropsWithChildren<CheckboxProps>) {
  const [checked, setChecked] = useState<boolean>(!!defaultChecked)
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked)
      onChange && onChange(event)
    },
    [onChange]
  )

  return (
    <div style={styles.container}>
      <label style={styles.label}>
        <input
          type="checkbox"
          name={name}
          style={styles.input}
          value={value}
          checked={checked}
          onChange={handleChange}
        />
        {checked ? <CheckedIcon /> : <UncheckedIcon />}
        <div style={styles.labelText}>{children}</div>
      </label>
    </div>
  )
}
