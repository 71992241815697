import React, { useCallback, useMemo, useEffect } from 'react'

import { BackNavLinkPanel, BackNavButtonPanel } from '../organisms/BackNavPanel'
import { DefaultTemplate } from '../templates/DefaultTemplate'
import { Subtitle } from '../molecules/Subtitle'
import { SubtitleWithPager } from '../molecules/SubtitleWithPager'
import { PagerBar } from '../molecules/PagerBar'
import { TradeHistoryList } from '../organisms/TradeHistoryList'
import { TradeHistoryDetail } from '../organisms/TradeHistoryDetail'
import { AboutTradeHistoryPage } from '../organisms/AboutTradeHistoryPage'
import {
  TradeHistoryMeta,
  TradeHistoryRecord,
  TradeHistorySearchOptions,
} from '../../models/trade'
import { LoadingPanel } from '../molecules/LoadingPanel'
import { useTradeHistory } from '../../hooks/trade'
import { useScroll } from '../../hooks/scroll'

export function TradeHistoryLoadingPagePresenter() {
  const Main = (
    <>
      <BackNavLinkPanel label="戻る" to="/" />
      <AboutTradeHistoryPage />
      <Subtitle text="購買履歴" />
      <LoadingPanel />
    </>
  )

  return <DefaultTemplate title="購買履歴" main={Main} autoScrollToTop />
}

interface TradeHistoryPagePresenterProps {
  tradeHistoryMeta: TradeHistoryMeta
  records: TradeHistoryRecord[]
  onDetailClick: (record: TradeHistoryRecord) => void
  onPageClick: (page: number) => void
}

export function TradeHistoryPagePresenter({
  tradeHistoryMeta,
  records,
  onDetailClick,
  onPageClick,
}: TradeHistoryPagePresenterProps) {
  const Main = useMemo(() => {
    return (
      <>
        <BackNavLinkPanel label="戻る" to="/" />
        <AboutTradeHistoryPage />
        <SubtitleWithPager
          text="購買履歴"
          totalNum={tradeHistoryMeta.dataCount}
          perPage={tradeHistoryMeta.perPage}
          currentPage={tradeHistoryMeta.pageNo}
        />
        <TradeHistoryList
          records={records || []}
          onDetailClick={onDetailClick}
        />
        {records.length > 0 && (
          <PagerBar
            totalNum={tradeHistoryMeta.dataCount}
            perPage={tradeHistoryMeta.perPage}
            currentPage={tradeHistoryMeta.pageNo}
            onPageClick={onPageClick}
          />
        )}
      </>
    )
  }, [tradeHistoryMeta, records, onDetailClick, onPageClick])

  return <DefaultTemplate title="購買履歴" main={Main} autoScrollToTop />
}

interface TradeHistoryDetailPagePresenterProps {
  selectedRecord: TradeHistoryRecord
  onBackToList: () => void
}

export function TradeHistoryDetailPagePresenter({
  selectedRecord,
  onBackToList,
}: TradeHistoryDetailPagePresenterProps) {
  const Main = useMemo(() => {
    return (
      <>
        <BackNavButtonPanel label="戻る" onClick={onBackToList} />
        <TradeHistoryDetail record={selectedRecord} />
      </>
    )
  }, [selectedRecord, onBackToList])

  return <DefaultTemplate title="購買詳細情報" main={Main} autoScrollToTop />
}

function useTradeHistoryPage() {
  const {
    meta,
    records,
    selectedRecord,
    fetchTradeHistory,
    setSelectedRecord,
  } = useTradeHistory()
  const perPage = 10
  const { saveCurrentScrollY, resetScroll, restoreScroll } = useScroll()
  const handleDetailClick = useCallback(
    (record: TradeHistoryRecord) => {
      saveCurrentScrollY()
      setSelectedRecord(record)
    },
    [setSelectedRecord, saveCurrentScrollY]
  )
  const handlePageClick = useCallback(
    (page: number) => {
      const searchOptions: TradeHistorySearchOptions = {
        count: perPage,
        page,
      }
      fetchTradeHistory(searchOptions)
    },
    [fetchTradeHistory]
  )
  const handleBackToLIst = useCallback(() => {
    setSelectedRecord(undefined)
  }, [setSelectedRecord])

  useEffect(() => {
    const searchOptions: TradeHistorySearchOptions = {
      count: perPage,
      page: 1,
    }
    fetchTradeHistory(searchOptions)
  }, [fetchTradeHistory])
  useEffect(() => {
    if (selectedRecord) {
      resetScroll()
    } else {
      restoreScroll()
    }
  }, [selectedRecord, resetScroll, restoreScroll])
  useEffect(() => {
    resetScroll()
  }, [meta, resetScroll])

  return {
    tradeHistoryMeta: meta,
    records,
    selectedRecord,
    handleBackToLIst,
    handlePageClick,
    handleDetailClick,
  }
}

export function TradeHistoryPage() {
  const {
    tradeHistoryMeta,
    records,
    selectedRecord,
    handleDetailClick,
    handlePageClick,
    handleBackToLIst,
  } = useTradeHistoryPage()

  if (!(records && tradeHistoryMeta)) {
    return <TradeHistoryLoadingPagePresenter />
  }

  if (!selectedRecord) {
    return (
      <TradeHistoryPagePresenter
        tradeHistoryMeta={tradeHistoryMeta}
        records={records}
        onDetailClick={handleDetailClick}
        onPageClick={handlePageClick}
      />
    )
  }

  return (
    <TradeHistoryDetailPagePresenter
      selectedRecord={selectedRecord}
      onBackToList={handleBackToLIst}
    />
  )
}
