// gtag.js (Google Analytics 4)

import { useEffect } from 'react'
import { History } from 'history'

// See: https://note.com/dd_techblog/n/n29535fd4f557
function loadGoogleAnalytics(gaMeasurementId: string) {
  const scriptTag = document.createElement('script')
  scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${gaMeasurementId}`
  scriptTag.async = true
  document.body.appendChild(scriptTag)
}

function gtag(
  key: string,
  trackingId: string,
  config?: {
    page_path?: string
    cookie_domain?: string
    cookie_flags?: string
  }
) {
  // @ts-ignore
  window.dataLayer = window.dataLayer || []
  // @ts-ignore
  // console.debug('gtag():', arguments)
  // @ts-ignore
  window.dataLayer.push(arguments)
}

// https://medium.com/javascript-in-plain-english/google-analytics-with-react-router-and-hooks-16d403ddc528
function initializeGoogleAnalytics(
  gaMeasurementId: string,
  initialPagePath: string
) {
  // @ts-ignore
  window.gtag = window.gtag || gtag
  // @ts-ignore
  gtag('js', new Date())
  gtag('config', gaMeasurementId, {
    cookie_domain: window.location.hostname,
    cookie_flags: 'SameSite=None; Secure',
  })
  gtag('config', gaMeasurementId, {
    page_path: initialPagePath,
  })
}

export function useGoogleAnalytics(
  history: History<any>,
  gaMeasurementId: string
) {
  useEffect(() => {
    const initialPagePath = `${history.location.pathname}${history.location.search}`
    loadGoogleAnalytics(gaMeasurementId)
    initializeGoogleAnalytics(gaMeasurementId, initialPagePath)
  }, [history, gaMeasurementId])

  useEffect(() => {
    // console.debug(`gtag: page_path: (1) ${history.location.pathname}`)
    gtag('config', gaMeasurementId, {
      page_path: `${history.location.pathname}${history.location.search}`,
    })

    const unlisten = history.listen((location) => {
      // @ts-ignore
      if (!window.gtag) return
      if (!gaMeasurementId) {
        console.log(
          'Tracking not enabled, as `gaMeasurementId` was not given and there is no `GA_MEASUREMENT_ID`.'
        )
        return
      }

      // console.debug(`gtag: page_path: (n) ${history.location.pathname}`)
      gtag('config', gaMeasurementId, {
        page_path: history.location.pathname,
      })
    })

    return unlisten
  }, [history, gaMeasurementId])
}

export function useGtag() {
  return { gtag }
}
