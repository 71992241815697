import { useCallback } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'

import {
  MemberActionCreators,
  memberSelector,
  memberStatusSelector,
  onetimeCodeSelector,
  onetimeCodeExpiresAtSelector,
  memberRankInfoSelector,
  authEmailErrorSelector,
  authcodeErrorSelector,
  authEmailSentSelector,
  authEmailStatusSelector,
  migrateRequiredSelector,
  authcodeVerifiedSelector,
} from '../modules/member'
import { CreateMemberParams, UpdateMemberParams } from '../models/member'

export function useMember() {
  const dispatch = useDispatch()
  const fetchMember = useCallback(() => {
    return dispatch(MemberActionCreators.fetchMemberAction())
  }, [dispatch])
  const member = useSelector(memberSelector, shallowEqual)
  const memberStatus = useSelector(memberStatusSelector, shallowEqual)

  return {
    member,
    memberStatus,
    fetchMember,
  }
}

export function useCreateMember() {
  const dispatch = useDispatch()
  const createMember = useCallback(
    (params: CreateMemberParams) => {
      return dispatch(MemberActionCreators.createMemberAction(params))
    },
    [dispatch]
  )

  return {
    createMember,
  }
}

export function useUpdateMember() {
  const dispatch = useDispatch()
  const updateMember = useCallback(
    (params: UpdateMemberParams) => {
      return dispatch(MemberActionCreators.updateMemberAction(params))
    },
    [dispatch]
  )

  return {
    updateMember,
  }
}

export function useAuthcode() {
  const dispatch = useDispatch()
  const authEmailSent = useSelector(authEmailSentSelector, shallowEqual)
  const authEmailStatus = useSelector(authEmailStatusSelector, shallowEqual)
  const migrateRequired = useSelector(migrateRequiredSelector, shallowEqual)
  const authEmailError = useSelector(authEmailErrorSelector, shallowEqual)
  const authcodeVerified = useSelector(authcodeVerifiedSelector, shallowEqual)
  const authcodeError = useSelector(authcodeErrorSelector, shallowEqual)
  const sendAuthcode = useCallback(
    (email: string) => {
      return dispatch(MemberActionCreators.sendAuthcodeAction(email))
    },
    [dispatch]
  )
  const clearSendAuthcodeError = useCallback(() => {
    return dispatch(MemberActionCreators.sendAuthcodeClearErrorAction())
  }, [dispatch])
  const verifyAuthcode = useCallback(
    (authcode: string) => {
      return dispatch(MemberActionCreators.verifyAuthcodeAction(authcode))
    },
    [dispatch]
  )

  return {
    authEmailSent,
    authEmailStatus,
    migrateRequired,
    authEmailError,
    authcodeVerified,
    authcodeError,
    sendAuthcode,
    clearSendAuthcodeError,
    verifyAuthcode,
  }
}

export function useOnetimeCode() {
  const dispatch = useDispatch()
  const fetchOnetimeCode = useCallback(() => {
    return dispatch(MemberActionCreators.fetchOnetimeCodeAction())
  }, [dispatch])
  const onetimeCode = useSelector(onetimeCodeSelector, shallowEqual)
  const onetimeCodeExpiresAt = useSelector(
    onetimeCodeExpiresAtSelector,
    shallowEqual
  )

  return {
    onetimeCode,
    onetimeCodeExpiresAt,
    fetchOnetimeCode,
  }
}

export function useMemberRankInfo() {
  const dispatch = useDispatch()
  const fetchMemberRankInfo = useCallback(() => {
    return dispatch(MemberActionCreators.fetchMemberRankInfoAction())
  }, [dispatch])
  const memberRankInfo = useSelector(memberRankInfoSelector, shallowEqual)

  return {
    memberRankInfo,
    fetchMemberRankInfo,
  }
}
