import React from 'react'

export function PointCardIcon() {
  return (
    <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path fill="#FF9501" d="M1 15h30v-3H1z" />
        <path
          d="M1 8c0-1.1.9-2 2-2h26c1.1 0 2 .9 2 2v16c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V8z"
          stroke="#5B5B5B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
