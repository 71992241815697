import React, { CSSProperties } from 'react'

import { Panel } from '../atoms/Panel'

const title1 = 'ふるいち会員規約'
const title2 = 'ふるいちポイント利用規約'

const styles: { [name: string]: CSSProperties } = {
  container: {
    paddingTop: '18px',
    backgroundColor: '#fff',
  },
  title: {
    margin: 0,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#282828',
    textAlign: 'center',
  },
  text: {
    padding: 18,
    fontSize: '14px',
    whiteSpace: 'pre-wrap',
  },
}

export function MembershipAgreementPanel() {
  return (
    <Panel paddingTop={18}>
      <div style={styles.container}>
        <h3 style={styles.title}>{title1}</h3>
        <div style={styles.text}>
          当規約は、株式会社テイツー（以下「当社」といいます）が提供するポイントサービスを中心とする会員向けサービスについて、会員の資格及びサービスの内容その他の条件について定めたものです。当規約は、当社の会員及び会員となろうとする全てのお客様に適用されます。お客様は当社の会員に申し込み又は会員サービスの提供を受けた時点で当規約に同意いただいたものとみなされます。
          {'\n'}
          {'\n'}
          第１条　用語の定義{'\n'}
          本規約で使用する用語の定義は、以下に定めるとおりとします。{'\n'}
          (1)
          「会員」とは、当社の会員向けサービスの提供を受ける全てのお客様をいいます。
          {'\n'}
          (2)
          「カード会員」とは、会員のうち当社店舗の店頭等でポイントカードの発行を受けた方をいいます。
          {'\n'}
          (3)
          「仮会員」とは、会員のうち、当社所定の会員登録を行っていない方をいいます。
          {'\n'}
          (4)
          「登録会員」とは、お客様のうち当社所定の会員登録を行い、IDの発行を受けた方をいいます。
          {'\n'}
          (5)
          「会員サービス」とは、お客様へのポイントの付与を目的としたふるいちポイントサービスを中心とする、会員に向けた各種サービスをいいます。
          {'\n'}
          (6)
          「ポイント」とは、ふるいちポイントサービスにおいて会員に付与される、当社店舗等での支払い等に利用することができるポイントをいいます。
          {'\n'}
          (7)
          「ポイントカード」とは、当社が会員サービスのため発行する、会員であることを表彰したカードをいいます。
          {'\n'}
          (8)
          「当社アプリ」とは、LINE株式会社が提供するコミュニケーションアプリ「LINE」のLINEミニアプリプラットフォームにて当社が提供する会員サービス
          ふるいちアプリをいいます。{'\n'}
          (9)
          「当社サイト」とは、当社が会員サービスの案内を行うためのウェブサイトをいいます。当社サイトにはマイページが含まれます。
          {'\n'}
          (10)「マイページ」とは、会員において、保有するポイントの取得・使用の履歴や残高等の確認等を目的とした、当社サイト内に設置されたウェブページをいいます。
          {'\n'}
          (11)「テイツーグループ」とは、当社及び当社の子会社及び関連会社で構成される企業集団をいいます。テイツーグループには以下の企業が含まれます。
          {'\n'}
          　　　株式会社テイツー{'\n'}
          　　　株式会社山徳{'\n'}
          (12)「当社店舗」とは、当社が経営する「古本市場」「トレカパーク」「ふるいち」などの店舗をいいます。
          {'\n'}
          {'\n'}
          第２条　会員の区分{'\n'}
          当社の会員は次のとおり区分されます。{'\n'}
          (1)
          「ふるいち会員」とは、当社の全ての会員をいいます。ふるいち会員にはカード会員、仮会員であるふるいちゲスト会員、及び、登録会員であるふるいち一般会員、ふるいちVIP会員が含まれます。
          {'\n'}
          (2)
          「ふるいちカード会員」とは、会員のうち、当社店舗の店頭等でポイントカードの発行を受けた方をいいます。ふるいちカード会員はカード会員にあたります
          {'\n'}
          (3)
          「ふるいちゲスト会員」とは、当社アプリの利用者のうち、当社所定の会員登録を行っていない方をいいます。ふるいちゲスト会員は仮会員にあたります。
          {'\n'}
          (4)
          「ふるいち一般会員」とは、登録会員のうち、ふるいちＶＩＰ会員以外の会員をいいます。
          {'\n'}
          (5)
          「ふるいちＶＩＰ会員」とは、登録会員のうち、ＶＩＰ会員の要件を満たした会員をいいます。ふるいちＶＩＰ会員に対しては特典が与えられる場合があります。
          {'\n'}
          {'\n'}
          第３条　会員申込み{'\n'}
          １　当社の会員になろうとするお客様は、次のいずれかの方法で当社の会員になることを申し込んで頂きます。
          {'\n'}
          (1) 当社店舗でのポイントカードの発行を申し込んでいただく方法{'\n'}
          (2)
          アプリストア等からお客様のスマートフォン等の端末にLINE株式会社が提供するコミュニケーションアプリ「LINE」をインストールいただき、LINEミニアプリ
          ふるいちアプリを起動していただく方法{'\n'}
          　　※LINEミニアプリ「ふるいちアプリ」をご利用にあたり、コミュニケーションアプリ「LINE」の利用が必要であり、LINE株式会社が定めるLINE利用規約の内容に同意の上、「LINE」をお客様ご自身であらかじめ設定、登録、アップデート等を行う必要があります。
          {'\n'}
          (3) 当社サイト上から、会員登録していただく方法{'\n'}
          ２　お客様は、当社からポイントカードの発行を受けるか、当社アプリの使用を開始した時点で会員としての資格が与えられたものとします。仮会員は次項に定める方法で登録して頂くことで、登録会員になることができます。
          {'\n'}
          ３　仮会員のお客様は、当社アプリ上又は当社サイト上において、当社が定める方法により会員登録を行っていただくことで登録会員になることができます。お客様は、当社からIDおよびパスワード（以下「ＩＤ等」といいます）の発行を受けた時点で、登録会員としての資格が与えられたものとします。
          {'\n'}
          {'\n'}
          第４条　会費等{'\n'}
          会員の会費はいずれも無料です。ただし、当社は会員サービスに付随するサービスとして任意の有償サービスを設定することがあり、会員が有償サービスの提供を受けることを選択した場合には、別途利用料金が発生することがあります。
          {'\n'}
          {'\n'}
          第５条　ポイントカード、アプリ等の毀損{'\n'}
          １　会員に発行されるポイントカード、会員が利用を開始した当社アプリ及び登録会員に発行されるID等は、いずれも本人のみが利用できるものとし、第三者に譲渡又は貸与することはできません。
          {'\n'}
          ２　会員はポイントカード、当社アプリ及びID等を会員自身の責任において管理するものとします。ポイントカード、当社アプリ又は登録会員のID等を用いて当社に対して行われた意思表示は、たとえ会員以外の第三者によるものであったとしても、会員本人の意思表示とみなします。当社は、これらが第三者により使用されたことにより会員又は第三者に生じた損害等について、一切の責任を負うものではありません。
          {'\n'}
          ３　カード会員、仮会員がポイントカードを紛失、滅失、毀損により使用できなくなった場合、又は利用中の当社アプリを毀損又は消失等した場合には、会員としての資格を当然に失うものとします。この場合、ポイントの再交付はできません。
          {'\n'}
          ４　登録会員について前項の事態が生じた場合には、ID等と紐付いて管理されているポイントは失効しません。ただし、登録会員がID等を紛失した場合にはその限りではありません。
          {'\n'}
          {'\n'}
          第６条　ID及びパスワードの管理{'\n'}
          １　登録会員は、パスワードを任意の時期に変更することができます。なお、パスワードは、他人に知られることがないよう定期的に変更する等、会員本人が責任をもって管理してください。
          {'\n'}
          ２　登録会員は、ID等が盗まれたり、第三者に使用されていることが判明した場合には、直ちに当社にその旨を連絡するとともに、当社からの指示がある場合にはこれに従うものとします。
          {'\n'}
          {'\n'}
          第７条　登録内容の変更{'\n'}
          　登録会員は、当社に登録した個人情報について登録内容の変更が生じた場合、すみやかに当社アプリまたは当社サイトにおいて修正を行うようにお願いします。登録会員が登録内容の修正を行わなかったことにより登録会員に生じた不利益について、当社は一切の責任を負うものではありません。
          {'\n'}
          {'\n'}
          第８条　会員サービスの内容{'\n'}
          １　会員は、会員サービスとして、当社店舗等において商品の購入または売却を行った際に、当社店舗における支払い等に利用することができるポイントを取得するサービスである
          {'\n'}
          ふるいちポイントサービスを受けることができます。ふるいちポイントサービスの詳細な条件等については、別途定める「ふるいちポイント利用規約」において定めます。
          {'\n'}
          ２　登録会員は、会員サービスとしてマイページを利用することができます。カード会員、仮会員はマイページを利用できません。
          {'\n'}
          ３　会員は、前2項のほか、会員サービスに付随する有償又は無償のサービスを受けることができます。付随サービスの内容等は、当社アプリ、当社サイトその他の方法によりその都度告知されます。なお、当社は付随サービスを常に提供することや、その内容を保証するものではありません。
          {'\n'}
          {'\n'}
          第９条　個人情報の保護と利用{'\n'}
          １　当社は、登録会員の登録及び会員サービスの実施にあたり取得した会員の個人情報を、当社の株式会社テイツー個人情報保護方針（以下「当社個人情報保護方針」といいます）に従い取り扱うものとします。併せて、会員は、会員サービスに関連した個人情報の取り扱いについて、本規約において定めるところに同意するものとします。
          {'\n'}
          ２　当社は、登録会員の登録及び会員サービスの実施にあたり取得した会員の個人情報を当社個人情報保護方針に掲げる目的のほか、次の各号に掲げる利用目的において利用します。
          {'\n'}
          (1) ポイントの付与及び利用{'\n'}
          (2)
          会員サービス及び当社アプリ利便性の向上を目的とした利用状況の調査及び分析
          {'\n'}
          (3) マーケティングに関する調査及び分析{'\n'}
          (4)
          当社及びテイツーグループの商品、サービス、キャンペーンまたはイベントに関する情報の提供その他テイツーグループの広告宣伝の実施
          {'\n'}
          (5) 会員からの問い合わせ及び要望等に対する対応{'\n'}
          (6) 不正利用の調査{'\n'}
          (7) その他上記各号に準ずるか、これらに密接に関連する目的{'\n'}
          ３　当社は、登録情報を、前項の利用目的の達成を目的として、テイツーグループ内において共同して利用することがあります。共同利用の詳細は以下のとおりです。
          {'\n'}
          (1) 共同して利用される個人データの項目{'\n'}
          登録情報に含まれるお客様ご本人の全ての個人データ{'\n'}
          (2) 共同して利用する者の範囲{'\n'}
          株式会社山徳{'\n'}
          (3) 共同して利用する者の利用目的{'\n'}
          　　本条第2項のとおり{'\n'}
          (4) 当該個人データの管理について責任を有する者の氏名または名称{'\n'}
          　　株式会社テイツー{'\n'}
          {'\n'}
          第１０条　退会{'\n'}
          １　会員が会員サービスの提供の継続を希望しない場合、当社サイト上から退会手続きを行うことができます。なお、カード会員は、ポイントカードを返却いただくこと、仮会員はアプリ上でLINEアカウントの連携を解除していただくことで退会することができます。
          {'\n'}
          ２　会員としての資格は一身専属と致します。お客様がお亡くなりになった場合には当然に退会したものとみなされ、会員としての資格は相続の対象となりません。
          {'\n'}
          ３　会員が退会し又はその他の理由で会員資格を失った場合、会員が保有していたポイントは全て失効します。この場合、ポイントの再交付はできません。
          {'\n'}
          ４　当社は、会員が退会した後も適切な案内および業務遂行が可能となるよう、会員の個人情報および利用情報等を合理的な期間、保有することがあります。
          {'\n'}
          {'\n'}
          第１１条　会員資格の重複の禁止{'\n'}
          お客様は、カード会員、仮会員、登録会員を問わず、会員資格を重複して取得することはできません。お客様が会員資格を重複して取得していたことが分かった場合、当社は会員資格の一部又は全部を取り消すことがあります。
          {'\n'}
          {'\n'}
          第１２条　会員資格の失効・取消・保有ポイントの利用停止{'\n'}
          １　当社は、会員が次のいずれかに該当する場合、会員資格を取り消すことができます。
          {'\n'}
          (1) ５年間にわたり会員サービスの利用がない場合{'\n'}
          (2) 不正にポイントを取得し又は利用した場合{'\n'}
          (3) 登録会員の登録内容に虚偽が含まれることが判明した場合{'\n'}
          (4) 本規約に定める会員の義務のいずれかに違反した場合{'\n'}
          (5) 過去において会員資格が取り消されたことがあったことが分かった場合
          {'\n'}
          (6)
          その他、当社およびテイツーグループとの信頼関係を著しく損なう行為を行った場合
          {'\n'}
          ２　当社は、前項に基づき会員資格を取り消された会員について、その後の会員登録を受け付けないことができます。
          {'\n'}
          ３　会員が会員資格を喪失した場合には、ポイントのほか、会員サービスに関する一切の権利を失うものとします。また資格の喪失にともなって当社に対して何らの請求権も有しないものとします。
          {'\n'}
          {'\n'}
          第１３条　反社会的勢力の排除{'\n'}
          会員は会員申込み時に暴力団等の反社会的勢力に該当しないことを確約するとともに、会員資格を有したのち、該当することになった場合は会員資格を取消す場合があります。
          {'\n'}
          {'\n'}
          第１４条　不正に対する措置{'\n'}
          会員が本規約に違反し、ポイントを不正に取得若しくは利用し、又はその他の不正な行為により当社又はテイツーグループに損害を与えた場合、当社は当該会員に対し、損害賠償の請求を行う場合があります。
          {'\n'}
          {'\n'}
          第１５条　会員サービスの提供中断・停止{'\n'}
          １　当社は、次の各号のいずれかに該当する場合には、事前の通知又はお客様の承諾なく、会員サービスの全部又は一部の提供を中断又は停止することがあります。
          {'\n'}
          (1)
          会員サービスに使用しているシステムその他当社が管理するシステム（以下「当社システム」といいます）の保守、点検、修理、仕様等を変更するとき
          {'\n'}
          (2) 当社システムを稼働するための設備の保守、点検、修理等をするとき
          {'\n'}
          (3) 不測の障害等が発生したとき{'\n'}
          (4)
          天災地変、戦争・テロ・動乱・暴動等の不可抗力により会員サービスの提供ができなくなったとき
          {'\n'}
          (5) その他、当社が会員サービスの中断・停止を必要と判断したとき{'\n'}
          ２　前項の定めに基づき会員サービスの全部又は一部の提供を中断又は停止したことにより、会員及びその他第三者に何らかの損害が生じたとしても、これによって生じたいかなる損害についても、当社及びテイツーグループは一切の責任を負いません。
          {'\n'}
          {'\n'}
          第１６条　会員サービスの廃止{'\n'}
          １　当社は会員サービスの全部又は一部の提供を終了することができるものとします。この場合、当社が別途定める場合を除き、会員サービス終了時において、会員の保有ポイントは失効します。
          {'\n'}
          ２　当社は、会員サービスを終了する場合は、その3か月以前に当社アプリ及び当社サイトにおいて告知又は掲載するとともに、登録会員に対しては登録されている電子メールアドレスに対してメールにて通知します。
          {'\n'}
          ３　当社は、本規約に基づき会員サービスの全部又は一部を廃止したことにより会員及びその他第三者が被った損害につき、一切の責任を負わないものとします。
          {'\n'}
          {'\n'}
          第１７条　会員の遵守事項{'\n'}
          会員は、会員サービスの利用にあたり、次のいずれかに該当する行為を行ってはなりません。
          {'\n'}
          (1) 法令又は公序良俗に反する行為、又はそのおそれのある行為{'\n'}
          (2) 犯罪行為若しくは犯罪に結びつく行為またはそれらのおそれのある行為
          {'\n'}
          (3)
          当社及び他人の著作権、商標権、特許権等の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利、ノウハウ及び利益等を侵害する行為、又はそのおそれのある行為
          {'\n'}
          (4)
          会員サービスの運営又は当社若しくはテイツーグループの業務を妨害する行為
          {'\n'}
          (5)
          当社、テイツーグループ若しくは他人の信用を毀損する行為、又は他人に対するいやがらせ、若しくは誹謗中傷する行為
          {'\n'}
          (6)
          会員サービスが予定している利用目的と異なる目的、又は不正の目的をもって利用する行為
          {'\n'}
          (7) 反社会的勢力に対する利益の供与その他の協力行為{'\n'}
          (8) 他の会員その他の第三者に不利益、損害又は不快感を与える行為{'\n'}
          {'\n'}
          第１８条　権利の帰属{'\n'}
          会員サービス及び会員サービスを通じて利用者に提供される情報等に係る著作権、特許権等の知的財産権、ノウハウ等の一切の権利は第三者に帰属するものを除き当社に帰属します。会員サービスの提供は、会員に対し何らの権利の移転を伴うものではなく、また、会員に対し、会員サービスの利用に必要な範囲を超えてこれらの情報等の使用又は利用を認めるものではありません。
          {'\n'}
          {'\n'}
          第１９条　本規約の変更{'\n'}
          当社は、会員に通知することなく本規約を変更・追加・削除することができるものとします。本規約の変更は、当社アプリ又は当社サイトにおいて告知又は掲載することにより効力が生じるものとし、規約の変更後、会員が会員サービスを利用した場合、会員は本規約の変更を承諾したものとみなします。
          {'\n'}
          {'\n'}
          第２０条　権利の譲渡禁止{'\n'}
          会員は会員の地位及び会員としての権利について、本規約に定めるものを除き、第三者に譲渡および貸与してはなりません。
          {'\n'}
          {'\n'}
          第２１条　当社からの通知{'\n'}
          １　当社は当社サイトでの掲出や電子メールによる送信、その他当社が適切と判断する方法により、会員に対し、随時必要な事項を通知します。
          {'\n'}
          ２　前項の通知は、当社が当該通知を当社サイトに掲出または電子メールにより送信した時点より効力を発します。
          {'\n'}
          {'\n'}
          第２２条　免責事項{'\n'}
          当社は当社の責めに帰すべき事由を除き、会員サービス提供に起因して発生した会員の損害に対し、一切の責任を負わないものとします。
          {'\n'}
          {'\n'}
          第２３条　分離可能性{'\n'}
          本規約の一部が法令又は裁判所により違法、無効又は不能であるとされた場合においても、本規約のその他の規定は有効に存続するものとします。
          {'\n'}
          {'\n'}
          第２４条　準拠法{'\n'}
          本規約の効力および規程の解釈ならびに履行等については、日本法を適用します。
          {'\n'}
          {'\n'}
          第２５条　専属管轄{'\n'}
          本規約にかかわり、お客様と当社の間で紛争が生じた場合は東京地方裁判所を第１審の専属的合意管轄裁判所とします。
          {'\n'}
        </div>
        <h3 style={styles.title}>{title2}</h3>
        <div style={styles.text}>
          当規約は、株式会社テイツー(以下、「当社」といいます。)が、ふるいち会員規約（以下「会員規約」といいます）に定める会員に対して、会員サービスであるポイントサービスを提供するにあたり、その諸条件を定めるものです。本サービスに関し本規約に規定のない事項については、会員規約が適用されます。当規約は当社の会員及び会員となろうとする全てのお客様に適用されるものとし、お客様は当社の会員に申し込み又は会員サービスの提供を受けた時点で当規約に同意いただいたものとみなされます。
          {'\n'}
          {'\n'}
          第１条　ポイントの付与{'\n'}
          １　当社は、会員に対し商品等を販売したとき、又は会員から商品等を買い取ったときにポイントを付与します。
          {'\n'}
          ２　ポイント付与の対象となる取引（以下「対象取引」といいます）、ポイントの付与率、その他ポイント付与の条件は、当社が決定し、当社アプリ内又は当社サイト上において会員に告知します。ポイント付与の条件は、物品等の販売又は買取の時期、物品等の内容、会員による取引の頻度又は金額、その他の事由によって異なることがあります。
          {'\n'}
          ３　ポイントは、対象取引が行われてから、当社が定める一定の期間を経た後に付与されます。この期間内に、当社が対象取引につき取消、返品などがあったことを確認した場合、対象取引にポイントは付与されず、また対象取引に価格の変更があった場合は、変更後の購入額に応じて付与されます。その他ポイントの付与に関する最終的な判断は、当社が行うものとし、会員はこれに従うものとします。
          {'\n'}
          ４　当社は会員に対し、通常のポイントとは別に、使用可能な期間や対象商品を限定したポイント（以下「特別ポイント」といいます）を付与する場合があります。
          {'\n'}
          {'\n'}
          第２条　ポイントの有効期間{'\n'}
          １　ポイント（特別ポイントを除きます）の有効期間は、会員が最後に対象取引を行った日から１年間とします。特別ポイントの有効期間は特別ポイントを付与する都度当社が定める期間とします。
          {'\n'}
          ２　有効期間を満了したポイントは自動的に失効します。当社は、失効したポイントに対しては何らの補償も行いません。
          {'\n'}
          {'\n'}
          第３条　マイページ{'\n'}
          １　当社は登録会員に対し、会員によるポイントの獲得及び使用の履歴、ポイントの現残高等（以下「履歴情報」といいます）を、登録会員向けのマイページにて告知します。
          {'\n'}
          ２　当社はカード会員、仮会員の履歴情報を保管する義務を負わず、またカード会員、仮会員に対して履歴情報を開示しません。
          {'\n'}
          {'\n'}
          第４条　ポイントの合算および複数登録の禁止{'\n'}
          １　会員は、保有するポイントを他の会員又は第三者に譲渡、貸付又は担保に供したり、会員間でポイントを共有したりすることはできません。
          {'\n'}
          ２　何らかの事情により一人の会員が複数の会員資格を有するに至った場合であっても、それぞれの会員資格において保有するポイントを合算することはできません。なお、お客様が１人で複数の会員資格を保有することは、会員規約により禁止されています。
          {'\n'}
          {'\n'}
          第５条　ポイントの取消・消滅{'\n'}
          １　当社がポイントを付与した後に、対象取引について返品又はキャンセルがあった場合、当社は、対象取引により付与されたポイントを取り消すことができます。
          {'\n'}
          ２　前項に加え、当社は会員が次の各号のいずれかに該当すると判断した場合、当社は会員に事前に通知することなく、会員が保有するポイントの一部または全部を取り消すことができます。この場合、当社は会員に対して何らの責任を負うものではありません。
          {'\n'}
          (1) 違法または不正な行為があった場合{'\n'}
          (2) 会員規約、本規約、その他当社が定める規約等に違反した場合{'\n'}
          (3) 会員資格を停止または喪失した場合{'\n'}
          (4)
          その他、当社が合理的な理由に基づきポイントを取り消すことが適当と判断した場合
          {'\n'}
          {'\n'}
          第６条　ポイントの利用{'\n'}
          １　会員は、当社が定める方法により、保有するポイントを、１ポイント１円の換算率で、当社店舗における支払いの全部または一部の支払いに利用することができます。{' '}
          {'\n'}
          ２　当社は、ポイントの利用対象となる商品を制限したり、ポイントの利用に条件を付けたりすることがあります。
          {'\n'}
          ３　会員がポイントによる決済を取り消した場合、原則として当該決済に利用されたポイントが返還され、現金による返還は行われません。{' '}
          {'\n'}
          {'\n'}
          第７条　ポイント利用後のポイントの取り消し{'\n'}
          会員がポイントを支払いに使用した後に、会員の過失に基づく事由によりポイントの付与が取り消された場合、当社は会員に対し、ポイントの付与が取消されたことによる代金支払いの不足額を現金または当社の指定する方法にて支払うよう請求する場合があります。
          {'\n'}
          {'\n'}
          第８条　換金の不可{'\n'}
          会員はポイントを第三者に販売することはできず、当社に対してポイントの買取を求めることはできません。会員は、その他の方法によっても、ポイントを換金することはできません。
          {'\n'}
          {'\n'}
          第９条　第三者による使用{'\n'}
          当社は、取引の相手方が真正なポイントカードを提示した場合、当社アプリの画面を表示した場合、又は当社アプリを操作して代金支払いのためにポイントを使用した場合、会員によりポイントが使用されたものとみなします。これらの場合、たとえ第三者が不正に会員のポイントを使用したものであっても、当社は使用されたポイントを返還せず、会員に生じた損害についても一切責任を負いません。
          {'\n'}
          {'\n'}
          第１０条　税金及び費用{'\n'}
          会員は、ポイントの取得又は利用等にともない生じた税金その他の付帯費用については、会員自身でこれらを負担するものとします。
          {'\n'}
          {'\n'}
          第１１条　サービス内容の変更{'\n'}
          当社は、会員に事前に通知することなく、ポイントに関するサービスの内容または条件の変更(ポイントの廃止、ポイント付与の停止、対象取引の変更、ポイント付与率または利用率の変更を含みますが、これらに限られません)を行うことがあり、
          会員はこれをあらかじめ承諾するものとします。当社は、これらの変更により会員に不利益または損害が生じた場合でも、一切責任を負わないものとします
          {'\n'}
          {'\n'}
          第１２条　分離可能性{'\n'}
          本規約の一部が法令又は裁判所により違法、無効又は不能であるとされた場合においても、本規約のその他の規定は有効に存続するものとします。
          {'\n'}
          {'\n'}
          第１３条　準拠法{'\n'}
          本規約の効力および規程の解釈ならびに履行等について、日本法を適用します。
          {'\n'}
          {'\n'}
          第１４条　専属管轄{'\n'}
          本規約にかかわり、会員と当社の間で紛争が生じた場合は東京地方裁判所を第１審の専属的合意管轄裁判所とします。
          {'\n'}
        </div>
      </div>
    </Panel>
  )
}
