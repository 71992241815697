import React from 'react'

import { Announcement } from '../../models/announcement'
import { EmptyListPanel } from '../molecules/EmptyListPanel'
import { AnnouncementListItem } from '../molecules/AnnouncementListItem'

interface AnnouncementListPanelProps {
  announcements: Announcement[]
  onDetailClick: (announcement: Announcement) => void
}

export function AnnouncementListPanel({
  announcements,
  onDetailClick,
}: AnnouncementListPanelProps) {
  if (announcements.length === 0) {
    return <EmptyListPanel label="現在お知らせはありません。" />
  }

  return (
    <>
      {announcements.map((announcement, i) => {
        return (
          <AnnouncementListItem
            key={i}
            announcement={announcement}
            onClick={() => onDetailClick(announcement)}
          />
        )
      })}
    </>
  )
}
