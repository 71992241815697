import React, { CSSProperties, PropsWithChildren } from 'react'
// import { Link } from 'react-router-dom'

export function FullWidthListDivider() {
  const styles: { [name: string]: CSSProperties } = {
    container: {
      backgroundColor: '#fff',
    },
    line: {
      backgroundColor: '#d8d8d8',
      height: '1px',
      margin: 0,
    },
  }

  return (
    <div style={styles.container}>
      <div style={styles.line} />
    </div>
  )
}

interface FullWidthListItemProps {
  // value: string
  // rounded?: boolean
  style?: CSSProperties
  onClick?: () => void
}

export function FullWidthListItem({
  style,
  onClick,
  children,
}: PropsWithChildren<FullWidthListItemProps>) {
  const defaultStyle: CSSProperties = {
    backgroundColor: '#fff',
    minHeight: '44px',
    borderBottom: '1px solid #d8d8d8',
  }

  return (
    <>
      <div onClick={onClick} style={{ ...defaultStyle, ...style }}>
        {children}
      </div>
    </>
  )
}
