import React, { CSSProperties } from 'react'

import { Panel } from '../atoms/Panel'

const styles: { [name: string]: CSSProperties } = {
  wrapper: {
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  message: {
    fontSize: '14px',
    lineHeight: '21px',
    textAlign: 'left',
  },
}

export function CompleteMigrateHelpPanel() {
  return (
    <>
      <Panel style={styles.wrapper}>
        <div style={styles.message}>
          連携が完了いたしました。ご登録ありがとうございます。
        </div>
      </Panel>
    </>
  )
}
