import React, { CSSProperties, useCallback, useEffect } from 'react'

import { useFormik } from 'formik'

import { Panel } from '../atoms/Panel'
import { Field } from '../atoms/Field'
import { Button } from '../atoms/Button'
import { InputEmail } from '../molecules/InputEmail'
import { ErrorMessage } from '../atoms/ErrorMessage'

interface EmailValues {
  email: string
}

export type EmailFormValues = EmailValues

interface EmailFormProps {
  defaultValues?: EmailValues | undefined
  hasApiError: boolean
  errorMessage?: string
  onSubmit: (params: EmailValues) => void
}

const styles: { [name: string]: CSSProperties } = {
  wrapper: {
    marginTop: '16px',
  },
  fieldLabel: {
    marginTop: '14.5px',
  },
}

const defaultInitialValues: EmailFormValues = {
  email: '',
}

function useEmailForm(
  defaultValues: EmailValues | undefined,
  onSubmit: (values: EmailValues) => void
) {
  const handleValidate = useCallback((values: EmailValues) => {
    const errors: any = {}

    const emailRegExp = /^.+@.+\..+$/
    if (!values.email) {
      errors.email = 'メールアドレスを入力してください'
    } else if (!emailRegExp.test(values.email)) {
      errors.emailInProgress = ''
    }

    return errors
  }, [])
  const handleSubmit = useCallback(
    (values: EmailFormValues) => {
      const params: EmailValues = {
        email: values.email,
      }
      onSubmit(params)
    },
    [onSubmit]
  )
  const formik = useFormik<EmailFormValues>({
    initialValues: { ...defaultInitialValues, ...defaultValues },
    onSubmit: handleSubmit,
    // validateOnBlur: true,
    validateOnChange: true,
    validate: handleValidate,
  })

  return { formik }
}

export function EmailForm({
  defaultValues,
  hasApiError,
  errorMessage,
  onSubmit,
}: EmailFormProps) {
  const { formik } = useEmailForm(defaultValues, onSubmit)
  const hasErrors = Object.keys(formik.errors).length > 0

  useEffect(() => {
    if (hasApiError && formik.isSubmitting) {
      formik.setSubmitting(false)
    }
  }, [hasApiError, formik])

  return (
    <Panel style={styles.wrapper}>
      <form onSubmit={formik.handleSubmit}>
        <Field
          label="メールアドレス"
          style={styles.fieldLabel}
          error={formik.errors.email}
        >
          <InputEmail
            name="email"
            value={formik.values.email}
            required
            onChange={formik.handleChange}
          />
        </Field>
        {hasApiError && (
          <ErrorMessage
            message={
              errorMessage
                ? errorMessage
                : '正しいメールアドレスを入力してください'
            }
          />
        )}
        <Button
          type="submit"
          variant="black"
          disabled={formik.isSubmitting || hasErrors || !formik.values.email}
          style={{ marginTop: '22.5px' }}
        >
          送信する
        </Button>
      </form>
    </Panel>
  )
}
