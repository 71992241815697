import { useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { PointHistorySearchOptions } from '../models/point'

import {
  PointActionCreators,
  pointSelector,
  pointRecordsSelector,
  pointMetaSelector,
} from '../modules/point'

export function useTotalPoints() {
  const dispatch = useDispatch()
  const fetchTotalPoints = useCallback(() => {
    return dispatch(PointActionCreators.fetchPointAction())
  }, [dispatch])
  const point = useSelector(pointSelector, shallowEqual)

  return { point, fetchTotalPoints }
}

export function usePointHistory() {
  const dispatch = useDispatch()
  const { point, fetchTotalPoints } = useTotalPoints()
  const fetchPointHistory = useCallback(
    (searchOptions: PointHistorySearchOptions) => {
      return dispatch(
        PointActionCreators.fetchPointHistoryAction(searchOptions)
      )
    },
    [dispatch]
  )
  const meta = useSelector(pointMetaSelector, shallowEqual)
  const records = useSelector(pointRecordsSelector, shallowEqual)

  return { meta, records, point, fetchPointHistory, fetchTotalPoints }
}
