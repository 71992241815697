import React, { PropsWithChildren } from 'react'

import { CheckIconProps } from './CheckedIcon'

export function UncheckedIcon({ children }: PropsWithChildren<CheckIconProps>) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>ico_check_off</title>
      <g
        id="ico_check_off"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M12,0 C5.373,0 0,5.373 0,12 C0,18.627 5.373,24 12,24 C18.627,24 24,18.627 24,12 C24,5.373 18.627,0 12,0 M12,3 C16.962,3 21,7.037 21,12 C21,16.963 16.962,21 12,21 C7.038,21 3,16.963 3,12 C3,7.037 7.038,3 12,3"
          id="Fill-1"
          fill="#D8D7D7"
        ></path>
        <polyline
          id="Stroke-3"
          stroke="#D8D7D7"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="6.7041 11.5879 10.3631 15.2469 16.5731 9.0379"
        ></polyline>
      </g>
    </svg>
  )
}
