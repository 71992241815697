import React, { CSSProperties, useCallback, useEffect } from 'react'

import { useFormik } from 'formik'

import { Panel } from '../atoms/Panel'
import { Field } from '../atoms/Field'
import { Button } from '../atoms/Button'
// import { InputDigits } from '../molecules/InputDigits'
import { InputText } from '../molecules/InputText'
import { ActionLink } from '../molecules/ActionLink'
import { Padding } from '../atoms/Padding'
import { ErrorMessage } from '../atoms/ErrorMessage'

interface EmailVerificationValues {
  verificationCode: string
}

export type EmailVerificationFormValues = EmailVerificationValues

interface EmailVerificationFormProps {
  defaultValues?: EmailVerificationValues
  hasApiError: boolean
  errorMessage?: string
  onSubmit: (params: EmailVerificationValues) => void
  onResendClick: () => void
  onResetClick?: () => void
}

const styles: { [name: string]: CSSProperties } = {
  wrapper: {
    marginTop: '16px',
  },
  fieldLabel: {
    marginTop: '14.5px',
  },
}

const defaultInitialValues: EmailVerificationFormValues = {
  verificationCode: '',
}

function useEmailVerificationForm(
  defaultValues: EmailVerificationValues | undefined,
  onSubmit: (values: EmailVerificationValues) => void
) {
  const handleValidate = useCallback(
    async (values: EmailVerificationValues) => {
      const errors: any = {}

      if (!values.verificationCode) {
        errors.verificationCode = '認証コードを入力してください'
      } else if (values.verificationCode.length !== 6) {
        errors.verificationCodeInProgress = ''
      }

      return errors
    },
    []
  )
  const handleSubmit = useCallback(
    (values: EmailVerificationFormValues) => {
      const params: EmailVerificationValues = {
        verificationCode: values.verificationCode,
      }
      onSubmit(params)
    },
    [onSubmit]
  )
  const formik = useFormik<EmailVerificationFormValues>({
    initialValues: { ...defaultInitialValues, ...defaultValues },
    onSubmit: handleSubmit,
    // validateOnBlur: true,
    validateOnChange: true,
    validate: handleValidate,
  })

  return { formik }
}

export function EmailVerificationForm({
  defaultValues,
  hasApiError,
  onSubmit,
  errorMessage,
  onResendClick,
  onResetClick,
}: EmailVerificationFormProps) {
  const { formik } = useEmailVerificationForm(defaultValues, onSubmit)
  const hasErrors = Object.keys(formik.errors).length > 0

  useEffect(() => {
    if (hasApiError && formik.isSubmitting) {
      formik.setSubmitting(false)
    }
  }, [hasApiError, formik])

  return (
    <Panel style={styles.wrapper}>
      <form onSubmit={formik.handleSubmit}>
        <Field
          label="認証コード"
          style={styles.fieldLabel}
          error={formik.errors.verificationCode}
        >
          {/* <InputDigits */}
          <InputText
            name="verificationCode"
            value={formik.values.verificationCode}
            required
            onChange={formik.handleChange}
            // setFieldValue={formik.setFieldValue}
          />
        </Field>
        {hasApiError && (
          <ErrorMessage
            message={
              errorMessage
                ? errorMessage
                : '入力された認証コードで認証ができませんでした'
            }
          />
        )}
        <Button
          type="submit"
          variant="black"
          disabled={
            formik.isSubmitting || hasErrors || !formik.values.verificationCode
          }
          style={{ marginTop: '22.5px' }}
        >
          入力する
        </Button>
      </form>
      <Padding top="24px">
        <ActionLink label="認証コードを再送信する" onClick={onResendClick} />
      </Padding>
      {onResetClick && (
        <Padding top="16px">
          <ActionLink
            label="違うメールアドレスへ送り直す"
            onClick={onResetClick}
          />
        </Padding>
      )}
    </Panel>
  )
}
