import { fork, call, put, select, takeEvery } from 'redux-saga/effects'

import {
  AnnouncementActionTypes,
  AnnouncementActionCreators,
  FetchAnnouncementsAction,
  UpdateAnnouncementAsReadAction,
} from '../modules/announcement'
import { fetchAnnouncements, updateAnnouncementAsRead } from '../utils/api'
import { tokenSelector, apiHostSelector } from '../modules/login'

export function* fetchAnnouncementsSaga(
  action: FetchAnnouncementsAction
): Generator<any, void, any> {
  try {
    const options = action.payload.options
    const apiHost = yield select(apiHostSelector)
    const token = yield select(tokenSelector)
    yield put(
      AnnouncementActionCreators.fetchAnnouncementsRequestAction(options)
    )
    const data = yield call(fetchAnnouncements, apiHost, token, options)
    yield put(
      AnnouncementActionCreators.fetchAnnouncementsSuccessAction(
        data.meta,
        data.announcements
      )
    )
  } catch (error) {
    yield put(AnnouncementActionCreators.fetchAnnouncementsFailureAction(error))
  }
}

export function* updateAnnouncementAsReadSaga(
  action: UpdateAnnouncementAsReadAction
): Generator<any, void, any> {
  try {
    const mAnnouncementId = action.payload.mAnnouncementId
    const apiHost = yield select(apiHostSelector)
    const token = yield select(tokenSelector)
    yield put(
      AnnouncementActionCreators.updateAnnouncementAsReadRequestAction(
        mAnnouncementId
      )
    )
    yield call(updateAnnouncementAsRead, apiHost, token, mAnnouncementId)
    yield put(
      AnnouncementActionCreators.updateAnnouncementAsReadSuccessAction(
        mAnnouncementId
      )
    )
  } catch (error) {
    yield put(
      AnnouncementActionCreators.updateAnnouncementAsReadFailureAction(error)
    )
  }
}

function* handleFetchAnnouncementsSaga() {
  yield takeEvery(
    AnnouncementActionTypes.FETCH_ANNOUNCEMENTS,
    fetchAnnouncementsSaga
  )
}

function* handleUpdateAnnouncementAsReadSaga() {
  yield takeEvery(
    AnnouncementActionTypes.UPDATE_ANNOUNCEMENT_AS_READ,
    updateAnnouncementAsReadSaga
  )
}

export function* announcementSagas() {
  yield fork(handleFetchAnnouncementsSaga)
  yield fork(handleUpdateAnnouncementAsReadSaga)
}
