import React, { FocusEvent, useCallback } from 'react'

import { InputProps, Input } from '../atoms/Input'

type InputTelProps = InputProps & {
  // formik
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => any
}

function toNumbers(text: string) {
  return text
    .replace(/[０-９]/g, (char) => {
      return String.fromCharCode(char.charCodeAt(0) - 0xfee0)
    })
    .replace(/[^0-9]/g, (char) => {
      return ''
    })
}

export function InputTel({
  name,
  value,
  readOnly,
  onChange,
  onBlur,
  setFieldValue,
  ...rest
}: InputTelProps) {
  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (value) {
        const correctedValue = toNumbers(value)
        if (correctedValue !== value) {
          setFieldValue && setFieldValue(name, correctedValue)
        }
      }
      onBlur && onBlur(event)
    },
    [name, value, onBlur, setFieldValue]
  )

  return (
    <Input
      type="tel"
      name={name}
      value={value}
      readOnly={readOnly}
      onChange={onChange}
      onBlur={handleBlur}
      {...rest}
    />
  )
}
