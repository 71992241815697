import React, { useCallback, useEffect } from 'react'

import { PointCardCardPanel } from '../organisms/PointCardCardPanel'
import { DefaultTemplate } from '../templates/DefaultTemplate'
import { SignUpButtonPanel } from '../organisms/SignUpButtonPanel'
import { IconButtonsPanel } from '../organisms/IconButtonsPanel'
import { PointCardHelpLinkPanel } from '../organisms/PointCardHelpLinkPanel'
import { MemberRank, MemberRankInfo } from '../../models/member'
import { ExpirationPointInfo } from '../../models/point'

import { LoadingPanel } from '../molecules/LoadingPanel'
import { useLiff } from '../../hooks/liff'
import {
  useMember,
  useMemberRankInfo,
  useOnetimeCode,
} from '../../hooks/member'
import { useTotalPoints } from '../../hooks/point'
import { useAnnouncements } from '../../hooks/announcement'
import { useCoupons } from '../../hooks/coupon'
import { useGtag } from '../../hooks/googleAnalytics'
import { isBeforeVersion } from '../../utils/checkVersion'

interface PointCardPagePresenterProps {
  isGuest: boolean
  isVip: boolean
  isEmployee: boolean
  points: number | undefined
  expirationPointInfos?: ExpirationPointInfo[]
  onetimeCode: string | undefined
  onetimeCodeExpiresAt: string | undefined
  cardNo: string
  botBasicId: string
  memberRankInfo: MemberRankInfo | undefined
  numAnnouncements: number | undefined
  numCoupons: number | undefined
  isShortcutAvailable: boolean
  onOnetimeCodeExpire: () => void
  onHomeShortcutClick: () => void
  onLinkClick: () => void
}

export function PointCardLoadingPagePresenter() {
  const Main = <LoadingPanel />

  return <DefaultTemplate title="ふるいちアプリ" main={Main} autoScrollToTop />
}

export function PointCardPagePresenter({
  isGuest,
  isVip,
  isEmployee,
  points,
  expirationPointInfos,
  onetimeCode,
  onetimeCodeExpiresAt,
  cardNo,
  botBasicId,
  memberRankInfo,
  numAnnouncements,
  numCoupons,
  isShortcutAvailable,
  onOnetimeCodeExpire,
  onHomeShortcutClick,
  onLinkClick,
}: PointCardPagePresenterProps) {
  const memberRank: MemberRank = isGuest ? 'guest' : isVip ? 'vip' : 'general'

  const Main = (
    <>
      <PointCardCardPanel
        memberRank={memberRank}
        points={points}
        isEmployee={isEmployee}
        expirationPointInfos={expirationPointInfos}
        onetimeCode={onetimeCode}
        onetimeCodeExpiresAt={onetimeCodeExpiresAt}
        cardNo={cardNo}
        memberRankInfo={memberRankInfo}
        onOnetimeCodeExpire={onOnetimeCodeExpire}
      />
      <PointCardHelpLinkPanel />
      {isGuest && <SignUpButtonPanel to="/account/sign-up" />}
      <IconButtonsPanel
        botBasicId={botBasicId}
        numAnnouncements={numAnnouncements}
        numCoupons={numCoupons}
        isShortcutAvailable={isShortcutAvailable}
        onHomeShortcutClick={onHomeShortcutClick}
        onLinkClick={onLinkClick}
      />
    </>
  )

  return <DefaultTemplate title="ふるいちアプリ" main={Main} autoScrollToTop />
}

const homeShortcutMinimalVersion = '13.20.0'

function usePointCardPage() {
  const { gtag } = useGtag()
  const { liff } = useLiff()
  const { member, fetchMember } = useMember()
  const { onetimeCode, onetimeCodeExpiresAt, fetchOnetimeCode } =
    useOnetimeCode()
  const { point, fetchTotalPoints } = useTotalPoints()
  const { memberRankInfo, fetchMemberRankInfo } = useMemberRankInfo()
  const { announcementMeta, fetchAnnouncements } = useAnnouncements()
  const { couponMeta, fetchCoupons } = useCoupons()
  const numAnnouncements = announcementMeta?.unreadCount
  const numCoupons = couponMeta?.canUseCount
  const isShortcutAvailable = !isBeforeVersion(
    liff.getLineVersion(),
    homeShortcutMinimalVersion
  )

  const onOnetimeCodeExpire = useCallback(() => {
    fetchOnetimeCode()
  }, [fetchOnetimeCode])

  const onHomeShortcutClick = useCallback(async () => {
    gtag('event', 'createShortcut')
    await liff.createShortcutOnHomeScreen({
      url: `https://miniapp.line.me/${process.env.REACT_APP_LIFF_ID}`,
    })
  }, [gtag, liff])

  const onLinkClick = useCallback(() => {
    liff.openWindow({
      url: 'https://line.me/update',
    })
  }, [liff])

  useEffect(() => {
    fetchMember()
    fetchTotalPoints()
    fetchOnetimeCode()
    fetchMemberRankInfo()
    fetchAnnouncements()
    fetchCoupons()
  }, [
    fetchMember,
    fetchTotalPoints,
    fetchOnetimeCode,
    fetchMemberRankInfo,
    fetchAnnouncements,
    fetchCoupons,
  ])

  return {
    member,
    point,
    onetimeCode,
    onetimeCodeExpiresAt,
    memberRankInfo,
    numAnnouncements,
    numCoupons,
    isShortcutAvailable,
    onOnetimeCodeExpire,
    onHomeShortcutClick,
    onLinkClick,
  }
}

export function PointCardPage() {
  const { botBasicId } = useLiff()
  const {
    member,
    point,
    onetimeCode,
    onetimeCodeExpiresAt,
    memberRankInfo,
    numAnnouncements,
    numCoupons,
    isShortcutAvailable,
    onOnetimeCodeExpire,
    onHomeShortcutClick,
    onLinkClick,
  } = usePointCardPage()

  if (!member) {
    return <PointCardLoadingPagePresenter />
  }

  const { isGuest, isVip, isEmployee } = member
  const points = point?.grandTotalPoints
  const expirationPointInfos = point?.expirationPoints
    ? point.expirationPoints.slice(0, 3)
    : []

  return (
    <PointCardPagePresenter
      isGuest={isGuest}
      isVip={isVip}
      isEmployee={isEmployee}
      points={points}
      expirationPointInfos={expirationPointInfos}
      onetimeCode={onetimeCode}
      onetimeCodeExpiresAt={onetimeCodeExpiresAt}
      cardNo={member.cardNo}
      botBasicId={botBasicId}
      memberRankInfo={memberRankInfo}
      numAnnouncements={numAnnouncements}
      numCoupons={numCoupons}
      isShortcutAvailable={isShortcutAvailable}
      onOnetimeCodeExpire={onOnetimeCodeExpire}
      onHomeShortcutClick={onHomeShortcutClick}
      onLinkClick={onLinkClick}
    />
  )
}
