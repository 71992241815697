import React, { useEffect } from 'react'

import { Padding } from '../atoms/Padding'
import { BackNavLinkPanel } from '../organisms/BackNavPanel'
import { DefaultTemplate } from '../templates/DefaultTemplate'
import { CardInfoCardNumberPanel } from '../organisms/CardInfoCardNumberPanel'
import { ProfilePanel } from '../organisms/ProfilePanel'
import { LineAccountUnlinkPanel } from '../organisms/LineAccountUnlinkPanel'
import { LoadingPanel } from '../molecules/LoadingPanel'
import { Member } from '../../models/member'
import { useMember } from '../../hooks/member'

interface CardInfoPagePresenterProps {
  member: Member | undefined
}

export function CardInfoPagePresenter({ member }: CardInfoPagePresenterProps) {
  const Main = (
    <>
      <Padding top="24px">
        <BackNavLinkPanel label="戻る" to="/" />
      </Padding>
      {member ? (
        <>
          <Padding top="17px">
            <CardInfoCardNumberPanel cardNo={member?.cardNo} />
          </Padding>
          <Padding top="16px">
            <ProfilePanel member={member} editPath="/cardinfo/profile/edit" />
          </Padding>
          <Padding top="16px" bottom="80px">
            <LineAccountUnlinkPanel />
          </Padding>
        </>
      ) : (
        <LoadingPanel />
      )}
    </>
  )

  return <DefaultTemplate title="カード情報" main={Main} autoScrollToTop />
}

export function CardInfoPage() {
  const { member, fetchMember } = useMember()

  useEffect(() => {
    fetchMember()
  }, [fetchMember])

  return <CardInfoPagePresenter member={member} />
}
