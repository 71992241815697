import {
  TotalPoint,
  PointHistoryRecord,
  PointHistorySearchOptions,
  PointHistoryMeta,
} from '../models/point'
import { ResetActionTypes, ResetStoreAction } from './reset'

// Action Types

const FETCH_POINT = 'FETCH_POINT' as const
const FETCH_POINT_REQUEST = 'FETCH_POINT_REQUEST' as const
const FETCH_POINT_SUCCESS = 'FETCH_POINT_SUCCESS' as const
const FETCH_POINT_FAILURE = 'FETCH_POINT_FAILURE' as const
const FETCH_POINT_HISTORY = 'FETCH_POINT_HISTORY' as const
const FETCH_POINT_HISTORY_REQUEST = 'FETCH_POINT_HISTORY_REQUEST' as const
const FETCH_POINT_HISTORY_SUCCESS = 'FETCH_POINT_HISTORY_SUCCESS' as const
const FETCH_POINT_HISTORY_FAILURE = 'FETCH_POINT_HISTORY_FAILURE' as const

export const PointActionTypes = {
  FETCH_POINT,
  // FETCH_POINT_REQUEST,
  // FETCH_POINT_SUCCESS,
  // FETCH_POINT_FAILURE,
  FETCH_POINT_HISTORY,
  // FETCH_POINT_HISTORY_REQUEST,
  // FETCH_POINT_HISTORY_SUCCESS,
  // FETCH_POINT_HISTORY_FAILURE,
}

// Action Creators

export function fetchPointAction() {
  return {
    type: FETCH_POINT,
  }
}

function fetchPointRequestAction() {
  return {
    type: FETCH_POINT_REQUEST,
  }
}

function fetchPointSuccessAction(point: TotalPoint) {
  return {
    type: FETCH_POINT_SUCCESS,
    payload: { point },
  }
}

function fetchPointFailureAction(error: any) {
  return {
    type: FETCH_POINT_FAILURE,
    payload: { error },
  }
}
export function fetchPointHistoryAction(options: PointHistorySearchOptions) {
  return {
    type: FETCH_POINT_HISTORY,
    payload: { options },
  }
}

function fetchPointHistoryRequestAction(options: PointHistorySearchOptions) {
  return {
    type: FETCH_POINT_HISTORY_REQUEST,
    payload: { options },
  }
}

function fetchPointHistorySuccessAction(
  meta: PointHistoryMeta,
  records: PointHistoryRecord[]
) {
  return {
    type: FETCH_POINT_HISTORY_SUCCESS,
    payload: { meta, records },
  }
}

function fetchPointHistoryFailureAction(error: any) {
  return {
    type: FETCH_POINT_HISTORY_FAILURE,
    payload: { error },
  }
}

export const PointActionCreators = {
  fetchPointAction,
  fetchPointRequestAction,
  fetchPointSuccessAction,
  fetchPointFailureAction,
  fetchPointHistoryAction,
  fetchPointHistoryRequestAction,
  fetchPointHistorySuccessAction,
  fetchPointHistoryFailureAction,
}

// Actions

export type FetchPointAction = ReturnType<typeof fetchPointAction>
export type FetchPointHistoryAction = ReturnType<typeof fetchPointHistoryAction>

type PointAction =
  | FetchPointAction
  | ReturnType<typeof fetchPointSuccessAction>
  | ReturnType<typeof fetchPointSuccessAction>
  | ReturnType<typeof fetchPointFailureAction>
  | FetchPointHistoryAction
  | ReturnType<typeof fetchPointHistorySuccessAction>
  | ReturnType<typeof fetchPointHistorySuccessAction>
  | ReturnType<typeof fetchPointHistoryFailureAction>
  | ResetStoreAction

// State

interface PointState {
  point: TotalPoint | undefined
  meta: PointHistoryMeta | undefined
  records: PointHistoryRecord[] | undefined
}

const initialState: PointState = {
  point: undefined,
  meta: undefined,
  records: undefined,
}

// Selector

export function pointSelector(rootState: { point: PointState }) {
  return rootState.point.point
}

export function pointMetaSelector(rootState: { point: PointState }) {
  return rootState.point.meta
}

export function pointRecordsSelector(rootState: { point: PointState }) {
  return rootState.point.records
}

// Reducer

export function pointReducer(
  state = initialState,
  action: PointAction
): PointState {
  switch (action.type) {
    case FETCH_POINT_SUCCESS:
      return {
        ...state,
        point: action.payload.point,
      }
    case FETCH_POINT_HISTORY_SUCCESS:
      return {
        ...state,
        meta: action.payload.meta,
        records: action.payload.records,
      }
    case ResetActionTypes.RESET_STORE:
      return initialState
    default:
      return state
  }
}
