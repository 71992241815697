import React from 'react'
import {
  TradeHistoryRecordDetail,
  TradeHistoryRecordDetailOfPoint,
  TradeHistoryRecordDetailOfShiharai,
  filterTradeDetailByType,
} from '../../models/trade'
import { reduceCouponDiscountDetails } from '../../utils/receipt'
import { ReceiptTradeDetail } from './ReceiptTradeDetail'

/**
 * 明細種別: 0(商品明細)と明細種別: 4(割引明細)を表示し、
 * その後、商品に対して適用されたクーポン詳細を表示する
 */
export function ShohinAndDiscountDetails({
  tradeDetails,
  isCancel,
}: {
  tradeDetails: TradeHistoryRecordDetail[]
  isCancel: boolean
}) {
  // detailType: 0 が商品、detailType:4 商品個別の値引き、値増し情報
  const shohinAndDiscountDetails = filterTradeDetailByType([0, 4], tradeDetails)

  const couponDetails = filterTradeDetailByType(1, tradeDetails)
  // クーポンNoが 97 or 99 で始まるものは商品に対して適応されるものなので小計前に表示する
  const couponDiscountDetails = reduceCouponDiscountDetails(couponDetails, [
    '97',
    '99',
  ])

  return (
    <>
      {[...shohinAndDiscountDetails, ...couponDiscountDetails].map(
        (tradeDetail, i) => (
          <ReceiptTradeDetail
            key={i}
            tradeDetail={tradeDetail}
            isCancel={isCancel}
          />
        )
      )}
    </>
  )
}

/**
 * 明細種別:2(ポイント明細)一覧を
 * ポイント名(pointName)毎にまとめて表示する
 */
export function PointGainDetails({
  tradeDetails,
}: {
  tradeDetails: TradeHistoryRecordDetail[]
}) {
  const pointDetails = filterTradeDetailByType(2, tradeDetails)
  // pointName毎に値をまとめる。以下は一例
  // [{ point: 100, pointName: "通常ポイント" }, { point: 10, pointName: "通常ポイント" }]
  // => [{ point:110 :pointName: "通常ポイント" }]
  const reducedPointDetails = pointDetails.reduce((acum, cur) => {
    const foundindex = acum.findIndex(
      (cur2) => cur2.detailType === cur.detailType
    )
    if (foundindex > -1) {
      acum[foundindex] = {
        ...acum[foundindex],
        point: acum[foundindex].point + cur.point,
      }
    } else {
      acum.push(cur)
    }
    return acum
  }, [] as TradeHistoryRecordDetailOfPoint[])

  return (
    <>
      {reducedPointDetails.map((detail) => (
        <ReceiptTradeDetail key={detail.pointName} tradeDetail={detail} />
      ))}
    </>
  )
}

/**
 * 明細種別:3(支払い詳細)一覧の
 * transactionTypeが0006(クレジットカードによる支払い)のものを
 * 名前(creditCompanyName)毎にまとめて表示する
 */
export function CreditCardShiharaiDetails({
  tradeDetails,
  isCancel,
}: {
  tradeDetails: TradeHistoryRecordDetail[]
  isCancel?: boolean
}) {
  const creditCardShiharaiDetails = filterTradeDetailByType(
    3,
    tradeDetails
  ).filter((d) => d.paymentTransactionType === '0006')
  // creditCompanyName毎に値をまとめる。以下は一例
  // [{ otherPayment: 100, creditCompanyName: "JCB" }, { otherPayment: 10, creditCompanyName: "JCB" }]
  // => [{ otherPayment: 110, creditCompanyName: "JCB" }]
  const reducedCreditCardShiharaiDetails = creditCardShiharaiDetails.reduce(
    (acum, cur) => {
      const foundIndex = acum.findIndex(
        (cur2) => cur2.creditCompanyName === cur.creditCompanyName
      )
      if (foundIndex > -1) {
        acum[foundIndex] = {
          ...acum[foundIndex],
          otherPayment: acum[foundIndex].otherPayment + cur.otherPayment,
        }
      } else {
        acum.push(cur)
      }
      return acum
    },
    [] as TradeHistoryRecordDetailOfShiharai[]
  )

  return (
    <>
      {reducedCreditCardShiharaiDetails.map((detail) => (
        <ReceiptTradeDetail
          key={detail.creditCompanyName}
          tradeDetail={detail}
          isCancel={isCancel}
        />
      ))}
    </>
  )
}

/**
 * 合計金額に適応されるクーポンの一覧を表示する
 */
export function GrandTotalCouponDetails({
  tradeDetails,
  isCancel,
}: {
  tradeDetails: TradeHistoryRecordDetail[]
  isCancel?: boolean
}) {
  const couponDetails = filterTradeDetailByType(1, tradeDetails)
  // クーポンNoが 98 で始まるものは合計金額に対して適応されるものなので合計金額前に表示する
  const couponDiscountDetails = reduceCouponDiscountDetails(couponDetails, [
    '98',
  ])

  return (
    <>
      {couponDiscountDetails.map((detail) => (
        <ReceiptTradeDetail
          key={detail.couponNo}
          tradeDetail={detail}
          isCancel={isCancel}
          isCouponValueSameline
        />
      ))}
    </>
  )
}
