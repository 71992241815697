import { fork, call, put, select, takeEvery } from 'redux-saga/effects'

import {
  CouponActionTypes,
  CouponActionCreators,
  FetchCouponsAction,
} from '../modules/coupon'
import { fetchCoupons } from '../utils/api'
import { tokenSelector, apiHostSelector } from '../modules/login'

export function* fetchCouponsSaga(
  action: FetchCouponsAction
): Generator<any, void, any> {
  try {
    const options = action.payload.options
    const apiHost = yield select(apiHostSelector)
    const token = yield select(tokenSelector)
    yield put(CouponActionCreators.fetchCouponsRequestAction(options))
    const { meta, coupons } = yield call(fetchCoupons, apiHost, token, options)
    yield put(CouponActionCreators.fetchCouponsSuccessAction(meta, coupons))
  } catch (error) {
    yield put(CouponActionCreators.fetchCouponsFailureAction(error))
  }
}

function* handleFetchCouponsSaga() {
  yield takeEvery(CouponActionTypes.FETCH_COUPONS, fetchCouponsSaga)
}

export function* couponSagas() {
  yield fork(handleFetchCouponsSaga)
}
