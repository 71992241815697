import React from 'react'

import { DescriptionPanel } from '../organisms/DescriptionPanel'

export function UnlinkLineAccountDescriptionForGuest() {
  const description =
    'ふるいちアプリとの連携を解除します。本登録をしている場合、一度連携を解除すると同一のふるいちポイントカード会員では再度ログインできなくなるためご注意ください。'

  return <DescriptionPanel text={description} />
}

export function UnlinkLineAccountDescriptionForNonGuest() {
  const description =
    '下記の説明を確認し、すべての項目にチェックをいれて同意いただけますと、解除が可能となります。'

  return (
    <>
      <DescriptionPanel text={description} />
    </>
  )
}
