import React, {
  CSSProperties,
  ReactElement,
  useLayoutEffect,
  useRef,
} from 'react'
import { Helmet } from 'react-helmet-async'

import { scrollToTop } from '../../utils/scroll'

interface DefaultTemplateProps {
  title: string
  main: ReactElement
  backgroundColor?: string
  autoScrollToTop?: boolean
}

export function DefaultTemplate({
  title,
  main,
  backgroundColor,
  autoScrollToTop,
}: DefaultTemplateProps) {
  const containerStyle: CSSProperties = {
    position: 'absolute',
    width: '100vw',
    // height: '100%', // This only colors initial rendered area
    height: '100vh',
    // height: 'calc(100vh - env(safe-area-inset-bottom))',
    // minHeight: 'calc(100% - env(safe-area-inset-bottom))',
    // paddingTop: '16px',
    // paddingLeft: '16px',
    // paddingRight: '16px',
    // paddingBottom: 0,
    // backgroundColor: backgroundColor || '#f2f2f2',
    MozBoxSizing: 'border-box',
    boxSizing: 'border-box',
    top: 0,
    left: 0,
    right: 0,
    // bottom: 0,
    // paddingBottom: 'calc(env(safe-area-inset-bottom))',
    // bottom: 'calc(env(safe-area-inset-bottom))',
    // bottom: '100px',
    // paddingBottom: '100px',
    // paddingBottom: '84px', // 'calc(env(safe-area-inset-bottom))',
    // paddingBottom: 'calc(50px + env(safe-area-inset-bottom))',
    // paddingBottom: 'calc(env(safe-area-inset-bottom))',
  }
  const mainStyle: CSSProperties = {
    MozBoxSizing: 'border-box',
    boxSizing: 'border-box',
    position: 'relative',
    width: '100%',
    // minHeight: '100%',
    height: '100%',
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    // WebkitFontSmoothing: 'antialiased',
    // MozOsxFontSmoothing: 'grayscale',
    // backgroundColor: 'blue',
    backgroundColor: backgroundColor || '#f2f2f2',
    // paddingBottom: '84px', // 'calc(env(safe-area-inset-bottom))',
    // paddingBottom: 'calc(50px + env(safe-area-inset-bottom))',
    // paddingBottom: 'calc(env(safe-area-inset-bottom))',
    // paddingBottom: '100px',
    overflow: 'scroll',
  }
  const topRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (!autoScrollToTop) return
    if (!topRef.current) return
    scrollToTop()
  }, [autoScrollToTop, topRef])

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div style={containerStyle}>
        <div ref={topRef} style={mainStyle}>
          {main}
        </div>
      </div>
    </>
  )
}
