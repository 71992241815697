/**
 * バージョン文字列を比較し対象のバージョンが以前かを調べる
 * https://zenn.dev/nekoniki/articles/c9c4584bc463e593cb94
 * @param checkVer チェック対象バージョン文字列
 * @param baseVer 比較対象バージョン文字列
 * @returns true : 以前かnull / false : 同じか以降
 */
export const isBeforeVersion = (
  checkVer: string | null,
  baseVer: string
): boolean => {
  if (checkVer == null) {
    return true
  }
  // 同じ場合は以前ではないので false リターン
  if (checkVer === baseVer) {
    return false
  }
  // 「xx.xx.xx」の「.」で区切って配列を作る
  const unitCheck = checkVer.split('.')
  const unitBase = baseVer.split('.')
  // 出来た配列の短い方でループをする
  const length = Math.min(unitCheck.length, unitBase.length)
  for (let index = 0; index < length; index += 1) {
    // 文字を数値に変換
    const numCheck = parseInt(unitCheck[index], 10)
    const numBase = parseInt(unitBase[index], 10)
    // チェックよりベースが大きい＝あとのバージョン
    if (numCheck > numBase) {
      return false
    }
    // チェックよりベースが小さい＝前のバージョン
    if (numCheck < numBase) {
      return true
    }
  }
  // チェックしたいバージョンの方に下の番号が付加されていた場合は以降のバージョンとする
  if (unitCheck.length > unitBase.length) {
    return false
  }
  // それ以外、即ちベースバージョンの方に番号が付加されていた場合は以前のバージョンとする
  return true
}
