import React, { CSSProperties } from 'react'
import { Link } from 'react-router-dom'

import { Panel } from '../atoms/Panel'
import { BareParagraph } from '../molecules/BareParagraph'

const styles: { [name: string]: CSSProperties } = {
  paragraph: {
    textDecorationLine: 'underline',
  },
  link: {
    textDecoration: 'none',
  },
}

export function LineAccountUnlinkPanel() {
  return (
    <Panel>
      <Link to="/account/unlink" style={styles.link}>
        <BareParagraph style={styles.paragraph}>
          LINEアカウントの連携を解除
        </BareParagraph>
      </Link>
    </Panel>
  )
}
