import React, { CSSProperties } from 'react'
import {
  isCancelTradeHistory,
  TradeHistoryRecord,
  TransactionType,
} from '../../models/trade'
import { numberWithCommas } from '../../utils/numberWithCommas'
import { calculateTotalFee, discountablePrice } from '../../utils/receipt'
import {
  CreditCardShiharaiDetails,
  GrandTotalCouponDetails,
} from './ReceiptTradeDetails'

const styles: { [name: string]: CSSProperties } = {
  container: {
    paddingTop: '8px',
    fontSize: '12px',
    lineHeight: '18px',
    display: 'grid',
    gridTemplateRows: 'auto',
    paddingBottom: '20px',
    borderBottom: '1px #5B5B5B dashed',
    color: '#282828',
  },
  priceRow: {
    paddingBottom: '8px',
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'space-between',
  },
  innerTaxRow: { paddingTop: '8px', paddingBottom: '0' },
  subTotalDiscountAmountRow: { paddingTop: '24px' },
  priceLabel: {
    justifySelf: 'start',
    textAlign: 'start',
  },
  priceValue: {
    justifySelf: 'end',
    textAlign: 'end',
  },
  totalPriceRow: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '24px',
    display: 'grid',
    paddingBottom: '8px',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'space-between',
  },
  grandTotalPriceRow: {
    paddingBottom: '16px',
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '27px',
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'space-between',
  },
}

function summaryPriceLabel(transactionType: TransactionType) {
  switch (transactionType) {
    case '0000': // 0:購入
    case '0100': // 100:売上(一括取消)
      return '合計'
    case '0002': // 2:買取
      return '買取合計'
    case '0001': // 1:返品
    case '0003': // 3:買取中止
    case '0102': // 102:買取(一括取消)
      return '買取'
    default:
      return ''
  }
}

function taxDetailType(taxType: number) {
  switch (taxType) {
    case 1:
      return '外税'
    case 2:
      return '内税'
    case 3:
      return '非課税'
    default:
      return ''
  }
}

function taxDetailTypeWithConsumption(taxType: number) {
  const taxDetail = taxDetailType(taxType)
  switch (taxDetail) {
    case '外税':
      return '外消費税'
    case '内税':
      return '内消費税'
    default:
      return taxDetail
  }
}

function SubTotal({ record }: { record: TradeHistoryRecord }) {
  const isCancel = isCancelTradeHistory(record)
  return (
    <div style={styles.priceRow}>
      <div style={styles.priceLabel}>小計</div>
      <div style={styles.priceValue}>
        {discountablePrice(record.totalFee, isCancel)}円
      </div>
    </div>
  )
}

function PurchaseTax({ record }: { record: TradeHistoryRecord }) {
  return (
    <>
      {record.taxDetails.map((taxDetail, i) => {
        return (
          <div key={i} style={styles.priceRow}>
            <div style={styles.priceLabel}>
              消費税 (対象 {taxDetailType(taxDetail.taxType)}{' '}
              {taxDetail.transactionAmount}) *{taxDetail.salesTaxRate}%
            </div>
            <div style={styles.priceValue}>
              {numberWithCommas(taxDetail.taxAmount)}円
            </div>
          </div>
        )
      })}
    </>
  )
}

function SoldTax({ record }: { record: TradeHistoryRecord }) {
  return (
    <>
      {record.taxDetails.map((detail) => (
        <div key={detail.taxType} style={styles.priceRow}>
          <div style={styles.priceLabel}>
            ({taxDetailTypeWithConsumption(detail.taxType)}
          </div>
          <div style={styles.priceValue}>
            {numberWithCommas(detail.taxAmount)}円)
          </div>
        </div>
      ))}
    </>
  )
}

function GrandTotalPriceAndPayments({
  record,
}: {
  record: TradeHistoryRecord
}) {
  const { discountTotalAmount, usedPoint, depositAmount, totalFee, isCancel } =
    calculateTotalFee(record)

  return (
    <>
      <div
        style={{
          ...styles.priceRow,
          ...styles.subTotalDiscountAmountRow,
        }}
      >
        <div style={styles.priceLabel}>値引額</div>
        <div style={styles.priceValue}>
          {discountablePrice(discountTotalAmount, !isCancel)}円
        </div>
      </div>
      <div style={styles.priceRow}>
        <div style={styles.priceLabel}>ポイント利用額</div>
        <div style={styles.priceValue}>
          {discountablePrice(usedPoint, !isCancel)}円
        </div>
      </div>

      <GrandTotalCouponDetails
        tradeDetails={record.tradeDetails}
        isCancel={isCancel}
      />
      <div style={styles.grandTotalPriceRow}>
        <div style={styles.priceLabel}>合計金額</div>
        <div style={styles.priceValue}>
          {discountablePrice(totalFee, isCancel)}円
        </div>
      </div>
      <div style={styles.priceRow}>
        <div style={styles.priceLabel}>お預り</div>
        <div style={styles.priceValue}>
          {discountablePrice(depositAmount, isCancel)}円
        </div>
      </div>

      <div style={styles.priceRow}>
        <div style={styles.priceLabel}>お釣り</div>
        <div style={styles.priceValue}>
          {discountablePrice(record.changeAmount)}円
        </div>
      </div>

      <CreditCardShiharaiDetails
        tradeDetails={record.tradeDetails}
        isCancel={isCancel}
      />
    </>
  )
}

function TotalPrice({ record }: { record: TradeHistoryRecord }) {
  const isCancel = isCancelTradeHistory(record)
  const unit = (num: number) => (isCancel ? -1 * num : num)
  return (
    <div style={styles.totalPriceRow}>
      <div style={styles.priceLabel}>
        {`${summaryPriceLabel(record.transactionType)} ${unit(
          record.totalQuantity
        )}点`}
      </div>
      <div style={styles.priceValue}>
        {discountablePrice(record.totalFee, isCancel)}円
      </div>
    </div>
  )
}

/**
 * 0000(購入)。タイトルは「購入」
 * 0100(売上(一括取消))。タイトルは「返品」
 */
function PurchaseSummary({ record }: { record: TradeHistoryRecord }) {
  return (
    <div style={styles.container}>
      <SubTotal record={record} />
      <TotalPrice record={record} />
      <PurchaseTax record={record} />
      <GrandTotalPriceAndPayments record={record} />
    </div>
  )
}

/**
 * 0002(買取)。 タイトルは「売却（買取）」
 * 0001(返品)。タイトルは「返品」
 * 0003(買取中止)。タイトルは「売却（買取）取消」
 * 0102(買取(一括取消)): タイトルは「売却（買取）取消」
 */
function SoldSummary({ record }: { record: TradeHistoryRecord }) {
  return (
    <div style={styles.container}>
      <TotalPrice record={record} />
      <SoldTax record={record} />
    </div>
  )
}

export function ReceiptBodySummary({ record }: { record: TradeHistoryRecord }) {
  switch (record.transactionType) {
    case '0000':
    case '0100':
      return <PurchaseSummary record={record} />
    case '0002':
    case '0001':
    case '0003':
    case '0102':
      return <SoldSummary record={record} />
    default:
      return null
  }
}
