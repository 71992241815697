import { parse } from 'date-fns/parse'
import { format } from 'date-fns/format'

const DATE_FORMAT_1 = 'yyyy-MM-dd'
const DATE_FORMAT_2 = 'yyyy/MM/dd'
const DATE_FORMAT_3 = 'yyyy-MM-dd HH:mm:ss'
const DATE_FORMAT_4 = 'yyyy/MM/dd HH:mm:ss'
const DAY_OF_WEEK_JA_MAP: { [key: string]: string } = {
  Mon: '月',
  Tue: '火',
  Wed: '水',
  Thu: '木',
  Fri: '金',
  Sat: '土',
  Sun: '日',
}

function parseDate(date: string | Date | null | undefined) {
  if (!date) {
    return null
  }

  if (date instanceof Date) {
    return date
  }
  const referenceDate = new Date()
  const d1 = parse(date, DATE_FORMAT_1, referenceDate)
  if (!isNaN(+d1)) return d1
  const d2 = parse(date, DATE_FORMAT_2, referenceDate)
  if (!isNaN(+d2)) return d2
  const d3 = parse(date, DATE_FORMAT_3, referenceDate)
  if (!isNaN(+d3)) return d3
  const d4 = parse(date, DATE_FORMAT_4, referenceDate)
  if (!isNaN(+d4)) return d4
  const d5 = Date.parse(date)
  if (!isNaN(+d5)) return d5

  return -1
}

export function formatDate(dateStr: string | Date | null | undefined) {
  const date = parseDate(dateStr)

  if (!date) {
    return '-'
  }

  try {
    return format(date, 'yyyy/MM/dd')
  } catch (_e: any) {
    return '-'
  }
}

export function formatDateWithDayOfWeek(
  dateStr: string | Date | null | undefined
) {
  const date = parseDate(dateStr)

  if (!date) {
    return '-'
  }

  try {
    const dayOfWeekJa = DAY_OF_WEEK_JA_MAP[format(date, 'eee')]
    return `${format(date, 'yyyy/MM/dd')} (${dayOfWeekJa})`
  } catch (_e: any) {
    return '-'
  }
}

export function formatDateJa(dateStr: string | Date | null | undefined) {
  const date = parseDate(dateStr)

  if (!date) {
    return '-'
  }

  try {
    return format(date, 'yyyy年MM月dd日')
  } catch (_e: any) {
    return '-'
  }
}

export function formatSearchOptionDate(
  dateStr: string | Date | null | undefined
) {
  const date = parseDate(dateStr)

  if (!date) {
    return null
  }

  try {
    return format(date, 'yyyy-MM-dd')
  } catch (_e: any) {
    return null
  }
}

export function daysToMs(days: number) {
  // 60*60*24*1000 = 86400000
  return days * 86400000
}

export function getDate(offsetMs?: number) {
  if (offsetMs) {
    return new Date(Date.now() + offsetMs)
  }
  return new Date()
}

export function getDateStr(date?: Date) {
  return format(date || new Date(), 'yyyy-MM-dd')
}
