import React, { CSSProperties, useCallback } from 'react'

import { useFormik } from 'formik'

import { Panel } from '../atoms/Panel'
import { Field } from '../atoms/Field'
import { FieldGroup } from '../atoms/FieldGroup'
import { InputText } from '../molecules/InputText'
import { InputTextKana } from '../molecules/InputTextKana'
import { InputDate } from '../molecules/InputDate'
import { InputTel } from '../molecules/InputTel'
import { InputPostalcode } from '../molecules/InputPostalcode'
import { Button } from '../atoms/Button'
import { SelectGender } from '../molecules/SelectGender'
import { SelectPrefecture } from '../molecules/SelectPrefecture'
// import { InputEmail } from '../molecules/InputEmail'
import { UpdateMemberParams } from '../../models/member'
import {
  ProfileFormValues,
  validateProfileForm,
} from '../../validations/member'

interface ProfileFormProps {
  defaultValues: ProfileFormValues
  onSubmit: (params: UpdateMemberParams) => void
}

const styles: { [name: string]: CSSProperties } = {
  wrapper: {
    // height: '187px',
    // marginTop: '16px',
  },
  fieldLabel: {
    marginTop: '14.5px',
  },
}

const defaultInitialValues: ProfileFormValues = {
  lastName: '',
  firstName: '',
  lastNameKana: '',
  firstNameKana: '',
  gender: 'other',
  birthday: '',
  phone: '',
  // email: '',
  postalCode: '',
  prefecture: '東京都',
  city: '',
  street: '',
  apartment: '',
}

// const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

function useProfileForm(
  defaultValues: ProfileFormValues,
  onSubmit: (params: UpdateMemberParams) => void
) {
  const handleSubmit = useCallback(
    (values: ProfileFormValues) => {
      const params: UpdateMemberParams = {
        lastName: values.lastName,
        firstName: values.firstName,
        lastNameKana: values.lastNameKana,
        firstNameKana: values.firstNameKana,
        gender: values.gender,
        birthday: values.birthday,
        phone: values.phone,
        // email: values.email,
        postalCode: values.postalCode,
        prefecture: values.prefecture,
        city: values.city,
        street: values.street,
        apartment: values.apartment,
      }
      console.debug('handleSubmit: params:', params)
      onSubmit(params)
      // setSubmitting(true)
    },
    // [onSubmit, setSubmitting]
    [onSubmit]
  )
  const formik = useFormik<ProfileFormValues>({
    initialValues: { ...defaultInitialValues, ...defaultValues },
    onSubmit: handleSubmit,
    // validateOnBlur: true,
    validate: validateProfileForm,
  })

  return {
    formik,
  }
}

export function ProfileForm({ defaultValues, onSubmit }: ProfileFormProps) {
  const { formik } = useProfileForm(defaultValues, onSubmit)

  return (
    <Panel style={styles.wrapper}>
      <form onSubmit={formik.handleSubmit}>
        <FieldGroup>
          <Field
            label="氏名 (姓)"
            style={styles.fieldLabel}
            error={formik.errors.lastName}
            required
          >
            <InputText
              name="lastName"
              value={formik.values.lastName}
              required
              onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
            />
          </Field>
          <Field
            label="氏名 (名)"
            style={styles.fieldLabel}
            error={formik.errors.firstName}
            required
          >
            <InputText
              name="firstName"
              value={formik.values.firstName}
              required
              onChange={formik.handleChange}
            />
          </Field>
        </FieldGroup>
        <FieldGroup>
          <Field
            label="姓カナ"
            style={styles.fieldLabel}
            error={formik.errors.lastNameKana}
            required
          >
            <InputTextKana
              name="lastNameKana"
              value={formik.values.lastNameKana}
              required
              onChange={formik.handleChange}
              setFieldValue={formik.setFieldValue}
            />
          </Field>
          <Field
            label="名カナ"
            style={styles.fieldLabel}
            error={formik.errors.firstNameKana}
            required
          >
            <InputTextKana
              name="firstNameKana"
              value={formik.values.firstNameKana}
              required
              onChange={formik.handleChange}
              setFieldValue={formik.setFieldValue}
            />
          </Field>
        </FieldGroup>
        {/*
        <Field
          label="メールアドレス"
          // helpLabel="※パスワード再発行時に必要となります"
          helpLabel="※会員サイトのマイページから変更してください"
          style={styles.fieldLabel}
          error={formik.errors.email}
          // required
        >
          <InputEmail
            name="email"
            value={formik.values.email}
            readOnly={true}
            required
            onChange={formik.handleChange}
          />
        </Field>
        */}
        <Field
          label="性別"
          style={styles.fieldLabel}
          error={formik.errors.gender}
          required
        >
          <SelectGender
            name="gender"
            value={formik.values.gender}
            onChange={formik.handleChange}
          />
        </Field>
        <Field
          label="生年月日"
          style={styles.fieldLabel}
          error={formik.errors.birthday}
          required
        >
          <InputDate
            name="birthday"
            value={formik.values.birthday}
            required
            onChange={formik.handleChange}
          />
        </Field>
        <Field
          label="郵便番号"
          style={styles.fieldLabel}
          error={formik.errors.postalCode}
          required
        >
          <InputPostalcode
            name="postalCode"
            value={formik.values.postalCode}
            required
            onChange={formik.handleChange}
            setFieldValue={formik.setFieldValue}
          />
        </Field>
        <Field
          label="都道府県"
          style={styles.fieldLabel}
          error={formik.errors.prefecture}
          required
        >
          <SelectPrefecture
            name="prefecture"
            value={formik.values.prefecture}
            onChange={formik.handleChange}
          />
        </Field>
        <Field
          label="市区町村"
          style={styles.fieldLabel}
          error={formik.errors.city}
          required
        >
          <InputText
            name="city"
            value={formik.values.city}
            required
            onChange={formik.handleChange}
          />
        </Field>
        <Field
          label="町名番地"
          style={styles.fieldLabel}
          error={formik.errors.street}
          required
        >
          <InputText
            name="street"
            value={formik.values.street}
            required
            onChange={formik.handleChange}
          />
        </Field>
        <Field
          label="ビルマンション (任意)"
          style={styles.fieldLabel}
          error={formik.errors.apartment}
        >
          <InputText
            name="apartment"
            value={formik.values.apartment}
            onChange={formik.handleChange}
          />
        </Field>
        <Field
          label="電話番号"
          style={styles.fieldLabel}
          error={formik.errors.phone}
          required
        >
          <InputTel
            name="phone"
            value={formik.values.phone}
            required
            onChange={formik.handleChange}
            setFieldValue={formik.setFieldValue}
          />
        </Field>
        <Button
          type="submit"
          variant="black"
          disabled={!formik.isValid}
          style={{ marginTop: '22.5px' }}
        >
          保存する
        </Button>
      </form>
    </Panel>
  )
}
