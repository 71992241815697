import React from 'react'

export function PointHistoryIcon() {
  return (
    <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          stroke="#FF9501"
          strokeWidth="2.7"
          d="M12 9.236v13.527M12 16.625h6.279a3.695 3.695 0 000-7.389H12"
        />
        <path
          stroke="#5B5B5B"
          strokeWidth="2"
          d="M7.283 22.73l-.279 3.99-3.99-.278"
        />
        <path
          d="M16 30c7.732 0 14-6.268 14-14S23.732 2 16 2 2 8.268 2 16c0 4.306 1.947 8.152 5.004 10.721"
          stroke="#5B5B5B"
          strokeWidth="2"
        />
      </g>
    </svg>
  )
}
