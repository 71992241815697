import React, { PropsWithChildren } from 'react'

export interface CheckIconProps {}

export function CheckedIcon({ children }: PropsWithChildren<CheckIconProps>) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>ico_check_on</title>
      <g
        id="ico_check_on"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M24,12 C24,18.627 18.627,24 12,24 C5.373,24 0,18.627 0,12 C0,5.373 5.373,0 12,0 C18.627,0 24,5.373 24,12"
          id="Fill-1"
          fill="#FF9501"
        ></path>
        <polyline
          id="Stroke-3"
          stroke="#FFFFFF"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="6.7041 11.5879 10.3631 15.2469 16.5731 9.0379"
        ></polyline>
      </g>
    </svg>
  )
}
