import React, { CSSProperties, PropsWithChildren } from 'react'

export function FullScreen({ children }: PropsWithChildren<{}>) {
  const style: CSSProperties = {
    zIndex: 100,
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    // background: 'rgba(255, 0, 0, 0.3)',
    backgroundColor: '#f2f2f2',
  }

  return <div style={style}>{children}</div>
}
