// https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript

export function numberWithCommas(x: number): string {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function signedNumberWithCommas(x: number): string {
  const sign = x === 0 ? '' : x < 0 ? '' : '+'

  return `${sign}${numberWithCommas(x)}`
}
