import React, { CSSProperties } from 'react'
import { calcPager } from '../../utils/pager'
import { ArrowNextIcon } from '../atoms/icons/ArrowNextIcon'
import { ArrowNextStopIcon } from '../atoms/icons/ArrowNextStopIcon'

import { ArrowPrevIcon } from '../atoms/icons/ArrowPrevIcon'
import { ArrowPrevStopIcon } from '../atoms/icons/ArrowPrevStopIcon'

interface PagerBarProps {
  totalNum: number
  perPage: number
  currentPage: number // 1~
  paddingTop?: string
  paddingBottom?: string
  onPageClick: (page: number) => void
}

const styles: { [name: string]: CSSProperties } = {
  wrapper: {
    // paddingTop: '6px',
  },
  pager: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    // gap: '7.5px',
    gap: '0px',
  },
  pagerBox: {
    width: '33px',
    height: '33px',
    borderRadius: '4px',
    marginRight: '3.5px',
    marginLeft: '3.5px',
    display: 'grid',
    placeItems: 'center',
  },
  omittedBox: {
    width: '16px',
    border: 'none',
    backgroundColor: 'transpalent',
  },
  enabledBox: {
    backgroundColor: '#5B5B5B',
  },
  disabledBox: {
    backgroundColor: 'rgba(91,91,91,0.3)',
  },
  numberBox: {
    color: '#FFFFFF',
    backgroundColor: '#5B5B5B',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
  },
  numberBoxActive: {
    color: '#5B5B5B',
    backgroundColor: '#FFFFFF',
    border: '1px #5B5B5B solid',
  },
}

interface PagerBoxProps {
  disabled?: boolean
  onClick: () => void
}

function PagerFirstBox({ disabled, onClick }: PagerBoxProps) {
  const style = {
    ...styles.pagerBox,
    ...(disabled ? styles.disabledBox : styles.enabledBox),
  }
  const handleClick = disabled ? undefined : onClick
  return (
    <div style={style} onClick={handleClick}>
      <ArrowPrevStopIcon />
    </div>
  )
}

function PagerPrevBox({ disabled, onClick }: PagerBoxProps) {
  const style = {
    ...styles.pagerBox,
    ...(disabled ? styles.disabledBox : styles.enabledBox),
  }
  const handleClick = disabled ? undefined : onClick
  return (
    <div style={style} onClick={handleClick}>
      <ArrowPrevIcon />
    </div>
  )
}

function PagerNextBox({ disabled, onClick }: PagerBoxProps) {
  const style = {
    ...styles.pagerBox,
    ...(disabled ? styles.disabledBox : styles.enabledBox),
  }
  const handleClick = disabled ? undefined : onClick
  return (
    <div style={style} onClick={handleClick}>
      <ArrowNextIcon />
    </div>
  )
}

function PagerLastBox({ disabled, onClick }: PagerBoxProps) {
  const style = {
    ...styles.pagerBox,
    ...(disabled ? styles.disabledBox : styles.enabledBox),
  }
  const handleClick = disabled ? undefined : onClick
  return (
    <div style={style} onClick={handleClick}>
      <ArrowNextStopIcon />
    </div>
  )
}

function PagerOmittedBox() {
  return <div style={{ ...styles.pagerBox, ...styles.omittedBox }}>...</div>
}

interface PagerNumberBoxProps {
  pageNo: number
  active?: boolean | undefined
  onClick: () => void
}

function PagerNumberBox({ pageNo, active, onClick }: PagerNumberBoxProps) {
  const style = {
    ...styles.pagerBox,
    ...styles.numberBox,
    ...(active && styles.numberBoxActive),
  }

  return (
    <div style={style} onClick={onClick}>
      {pageNo}
    </div>
  )
}

export function PagerBar({
  totalNum,
  perPage,
  currentPage,
  paddingTop,
  paddingBottom,
  onPageClick,
}: PagerBarProps) {
  const { firstPage, lastPage, prevPage, nextPage, pageNumbers } = calcPager(
    totalNum,
    perPage,
    currentPage
  )
  const wrapperStyle: CSSProperties = {
    paddingTop: paddingTop === undefined ? '24px' : paddingTop,
    paddingBottom: paddingBottom === undefined ? '24px' : paddingBottom,
  }

  return (
    <div style={{ ...styles.wrapper, ...wrapperStyle }}>
      <div style={styles.pager}>
        <PagerFirstBox
          disabled={firstPage === currentPage}
          onClick={() => onPageClick(firstPage)}
        />
        <PagerPrevBox
          disabled={prevPage === currentPage}
          onClick={() => onPageClick(prevPage)}
        />
        {pageNumbers[0] !== firstPage && <PagerOmittedBox />}
        {pageNumbers.map((page) => {
          return (
            <PagerNumberBox
              key={page}
              pageNo={page}
              active={currentPage === page}
              onClick={() => onPageClick(page)}
            />
          )
        })}
        {pageNumbers[pageNumbers.length - 1] !== lastPage && (
          <PagerOmittedBox />
        )}
        <PagerNextBox
          disabled={nextPage === currentPage}
          onClick={() => onPageClick(nextPage)}
        />
        <PagerLastBox
          disabled={lastPage === currentPage}
          onClick={() => onPageClick(lastPage)}
        />
      </div>
    </div>
  )
}
