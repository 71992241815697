import React from 'react'

import { RadioGroup, RadioGroupProps } from './RadioGroup'

interface SelectGenderProps {
  name: string
  value: string | undefined
  readOnly?: boolean | undefined
  onChange?: RadioGroupProps['onChange']
}

const genderValues = ['male', 'female', 'other'] // , '無回答']
const genderLabels = ['男性', '女性', 'その他'] //, '無回答']

export function SelectGender({
  name,
  value,
  readOnly,
  onChange,
}: SelectGenderProps) {
  return (
    <RadioGroup
      name={name}
      value={value}
      optionValues={genderValues}
      optionLabels={genderLabels}
      readOnly={readOnly}
      onChange={onChange}
    />
  )
}
