import React, { CSSProperties } from 'react'

import { Panel } from '../atoms/Panel'
import { Announcement } from '../../models/announcement'
import { formatDate } from '../../utils/date'

const styles: { [name: string]: CSSProperties } = {
  background: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#FFFFFF',
  },
  container: {
    display: 'grid',
    // gridTemplateColumns: 'auto 32px',
    // alignContent: 'center',
  },
  announcementDate: {
    paddingTop: '16px',
    fontSize: '10px',
    lineHeight: '15px',
    fontWeight: 'bold',
    color: '#5B5B5B',
  },
  image: {
    paddingTop: '16px',
    width: '100%',
  },
  title: {
    paddingTop: '16px',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '24px',
    color: '#5B5B5B',
  },
  description: {
    whiteSpace: 'break-spaces',
    paddingTop: '16px',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#5B5B5B',
  },
}

interface AnnouncementDetailPanelProps {
  announcement: Announcement
}

export function AnnouncementDetailPanel({
  announcement,
}: AnnouncementDetailPanelProps) {
  // FIXME: format date
  return (
    <>
      <div style={styles.background} />
      <Panel>
        <div style={styles.container}>
          <div style={styles.announcementDate}>
            {formatDate(announcement.announcementDate)}
          </div>
          {announcement.imageUrl && (
            <img
              alt={announcement.title}
              style={styles.image}
              src={announcement.imageUrl}
            />
          )}
          <div style={styles.title}>{announcement.title}</div>
          <div style={styles.description}>{announcement.description}</div>
        </div>
      </Panel>
    </>
  )
}
