import React, { useCallback, useEffect } from 'react'

import { Title } from '../atoms/Title'
import {
  UnlinkLineAccountDescriptionForGuest,
  UnlinkLineAccountDescriptionForNonGuest,
} from '../organisms/UnlinkLineAccountDescription'
import {
  UnlinkLineAccountFormForGuest,
  UnlinkLineAccountFormForNonGuest,
} from '../organisms/UnlinkLineAccountForm'
import { DefaultTemplate } from '../templates/DefaultTemplate'

import { useUnlinkLineAccount } from '../../hooks/login'
import { useMember } from '../../hooks/member'
import { BackNavLinkPanel } from '../organisms/BackNavPanel'
import { LoadingPanel } from '../molecules/LoadingPanel'
import { Padding } from '../atoms/Padding'

interface UnlinkLineAccountPagePresenterProps {
  onSubmit: () => void
}

export function UnlinkLineAccountLoadingPagePresenter() {
  const Main = (
    <>
      <BackNavLinkPanel label="戻る" to="/cardinfo" />
      <Title>
        <div>ふるいちアプリ</div>
        <div>ふるいちポイント会員の連携解除</div>
      </Title>
      <LoadingPanel />
    </>
  )

  return (
    <DefaultTemplate
      title="LINEアカウント連携解除"
      main={Main}
      autoScrollToTop
    />
  )
}

export function UnlinkLineAccountForGuestPagePresenter({
  onSubmit,
}: UnlinkLineAccountPagePresenterProps) {
  const Main = (
    <>
      <BackNavLinkPanel label="戻る" to="/cardinfo" />
      <Title>
        <div>ふるいちアプリ</div>
        <div>ふるいちポイント会員の連携解除</div>
      </Title>
      <Padding bottom="40px">
        <UnlinkLineAccountDescriptionForGuest />
        <UnlinkLineAccountFormForGuest onSubmit={onSubmit} />
      </Padding>
    </>
  )

  return (
    <DefaultTemplate
      title="LINEアカウント連携解除"
      main={Main}
      autoScrollToTop
    />
  )
}

export function UnlinkLineAccountForNonGuestPagePresenter({
  onSubmit,
}: UnlinkLineAccountPagePresenterProps) {
  const Main = (
    <>
      <BackNavLinkPanel label="戻る" to="/cardinfo" />
      <Title>
        <div>ふるいちアプリ</div>
        <div>ふるいちポイント会員の連携解除</div>
      </Title>
      <Padding bottom="40px">
        <UnlinkLineAccountDescriptionForNonGuest />
        <UnlinkLineAccountFormForNonGuest onSubmit={onSubmit} />
      </Padding>
    </>
  )

  return (
    <DefaultTemplate
      title="LINEアカウント連携解除"
      main={Main}
      autoScrollToTop
    />
  )
}

function useUnlinkLineAccountPage() {
  const { member, fetchMember } = useMember()
  const { unlinkLineAccount } = useUnlinkLineAccount()
  const isLoading = !member
  const isGuest = !!member?.isGuest
  const handleSubmit = useCallback(() => {
    return unlinkLineAccount()
  }, [unlinkLineAccount])

  useEffect(() => {
    if (!member) {
      fetchMember()
    }
  }, [member, fetchMember])

  return { isLoading, isGuest, handleSubmit }
}

export function UnlinkLineAccountPage() {
  const { isLoading, isGuest, handleSubmit } = useUnlinkLineAccountPage()

  if (isLoading) {
    return <UnlinkLineAccountLoadingPagePresenter />
  }

  if (isGuest) {
    return <UnlinkLineAccountForGuestPagePresenter onSubmit={handleSubmit} />
  }

  return <UnlinkLineAccountForNonGuestPagePresenter onSubmit={handleSubmit} />
}
