import React from 'react'

export function ArrowNextStopIcon() {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>ico_arrow_nextstop</title>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline
          stroke="#FFFFFF"
          strokeWidth="2.7"
          points="6.376 5.2363 13.14 12.0003 6.376 18.7633"
        ></polyline>
        <line
          x1="17.6592"
          y1="5.2363"
          x2="17.6592"
          y2="18.7633"
          stroke="#FFFFFF"
          strokeWidth="2.7"
        ></line>
      </g>
    </svg>
  )
}
