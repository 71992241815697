import { fork, call, put, select, takeEvery } from 'redux-saga/effects'

import { tokenSelector, apiHostSelector } from '../modules/login'
import {
  PointActionTypes,
  PointActionCreators,
  FetchPointAction,
  FetchPointHistoryAction,
} from '../modules/point'
import { fetchCurrentPoints, fetchPointHistory } from '../utils/api'

export function* fetchPointSaga(
  action: FetchPointAction
): Generator<any, void, any> {
  try {
    const apiHost = yield select(apiHostSelector)
    const token = yield select(tokenSelector)
    yield put(PointActionCreators.fetchPointRequestAction())
    const data = yield call(fetchCurrentPoints, apiHost, token)
    yield put(PointActionCreators.fetchPointSuccessAction(data))
  } catch (error) {
    yield put(PointActionCreators.fetchPointFailureAction(error))
  }
}

export function* fetchPointHistorySaga(
  action: FetchPointHistoryAction
): Generator<any, void, any> {
  try {
    const options = action.payload.options
    const apiHost = yield select(apiHostSelector)
    const token = yield select(tokenSelector)
    yield put(PointActionCreators.fetchPointHistoryRequestAction(options))
    const data = yield call(fetchPointHistory, apiHost, token, options)
    const [meta, records] = data
    yield put(PointActionCreators.fetchPointHistorySuccessAction(meta, records))
  } catch (error) {
    yield put(PointActionCreators.fetchPointHistoryFailureAction(error))
  }
}

function* handleFetchPointSaga() {
  yield takeEvery(PointActionTypes.FETCH_POINT, fetchPointSaga)
}

function* handleFetchPointHistorySaga() {
  yield takeEvery(PointActionTypes.FETCH_POINT_HISTORY, fetchPointHistorySaga)
}

export function* pointSagas() {
  yield fork(handleFetchPointSaga)
  yield fork(handleFetchPointHistorySaga)
}
