import React, { CSSProperties } from 'react'

import { IconButton } from './IconButton'
import { PointHistoryIcon } from '../atoms/icons/PointHistoryIcon'

function Label() {
  const style: CSSProperties = {
    fontSize: '12px',
    fontWeight: 'bold',
    textAlign: 'center',
  }
  return (
    <>
      <div style={style}>ポイント</div>
      <div style={style}>ご利用明細</div>
    </>
  )
}

export function PointHistoryButton() {
  return <IconButton icon={PointHistoryIcon} label={Label} />
}
