import React, { CSSProperties } from 'react'

import { ReservationListItem } from '../molecules/ReservationListItem'
import { EmptyListPanel } from '../molecules/EmptyListPanel'
import { Reservation } from '../../models/reservation'

interface ReservationListProps {
  reservations: Reservation[]
  dateStr: string
  onReservationClick: (reservation: Reservation) => void
}

const styles: { [name: string]: CSSProperties } = {
  container: {
    borderTop: '1px solid #D8D8D8',
  },
}

export function ReservationList({
  reservations,
  dateStr,
  onReservationClick,
}: ReservationListProps) {
  if (reservations.length === 0) {
    return <EmptyListPanel label="現在予約はありません。" />
  }

  return (
    <div style={styles.container}>
      {reservations.map((reservation, i) => {
        return (
          <ReservationListItem
            key={i}
            dateStr={dateStr}
            onClick={onReservationClick}
            reservation={reservation}
          />
        )
      })}
    </div>
  )
}
