import React, { CSSProperties } from 'react'

interface ErrorMessageProps {
  message: string
  style?: CSSProperties
}

export function ErrorMessage({ message, style }: ErrorMessageProps) {
  const styles: { [name: string]: CSSProperties } = {
    message: {
      display: 'block',
      color: '#cf1414',
      fontSize: '12px',
      lineHeight: '18px',
    },
  }

  return <div style={{ ...styles.message, ...style }}>{message}</div>
}
