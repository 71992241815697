import React from 'react'

import { Panel } from '../atoms/Panel'
import { InfoListItemHeader, InfoListItem } from '../molecules/InfoList'

// function PointCardQAndA() {
//   const styles: { [name: string]: CSSProperties } = {
//     description: {
//       fontSize: '12px',
//       lineHeight: '16px',
//       fontWeight: 'bold',
//       color: '#282828',
//       paddingTop: '11px',
//       paddingLeft: '16px',
//       paddingRight: '16px',
//       paddingBottom: '16px',
//     },
//     subdescription: {
//       fontSize: '12px',
//       lineHeight: '16px',
//       fontWeight: 'normal',
//       color: '#282828',
//       paddingTop: '11px',
//       paddingLeft: '16px',
//       paddingRight: '16px',
//       paddingBottom: '16px',
//     },
//   }
//   return (
//     <Panel>
//       <div>
//         <RoundedListItemHeader>
//           <div style={styles.description}>
//             古本市場、ふる1、ブック・スクウェア、3Bee、トレカパーク店舗にてご購入時・買取時に貯めたり、お使い頂ける共通ポイントです。
//           </div>
//         </RoundedListItemHeader>
//         <RoundedListItem>
//           <div style={styles.subdescription}>
//             ポイント機能は入会金・年会費無料。無料で発行いたします。発行したその日からポイントを貯めることができます。
//           </div>
//         </RoundedListItem>
//       </div>
//       <RoundedListItem>
//         <div style={styles.description}>
//           お買上・買取に応じてポイント還元、ポイントは1ポイント＝1円として利用可能。
//         </div>
//       </RoundedListItem>
//       <RoundedListItem>
//         <div style={styles.subdescription}>
//           1ポイントから無駄なく使えます。お会計の際に「ポイントを使います」とお申し出下さい。
//         </div>
//       </RoundedListItem>
//       <RoundedListItem>
//         <div style={styles.description}>貯まったポイントは即日ご利用可能。</div>
//       </RoundedListItem>
//       <RoundedListItemFooter>
//         <div style={styles.subdescription}>
//           次回以降のお買い物以後いつでも使え、現金との併用も可能。また、現金でもクレジットカード・ICカードでのお支払いでもポイントを進呈。
//         </div>
//       </RoundedListItemFooter>
//       <Paragraph>
//         {`※テイツーカード機能有効のテイツーカード・テイツーポイントカードをお持ちであれば、どなたでもご利用頂けます。
// ※テイツーカード・テイツーポイントカードの提示が無い場合はポイント付与・利用出来ません。
// ※ポイントでご購入の場合は、そのポイントご購入分に対するポイント進呈はございません。
// ※ICカード（QuiCPay、Edy、Suica等）取扱有無については店舗により異なります。
// ※クレジットカード・ICカードについては一部取扱外店舗もございます。`}
//       </Paragraph>
//     </Panel>
//   )
// }

interface CardInfoCardNumberPanelProps {
  cardNo: string | undefined
}

export function CardInfoCardNumberPanel({
  cardNo,
}: CardInfoCardNumberPanelProps) {
  return (
    <Panel>
      <InfoListItemHeader label="カード番号" rounded />
      <InfoListItem value={cardNo || ''} rounded />
    </Panel>
  )
}
