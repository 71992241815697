import React from 'react'

export function CloseIcon() {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>ico_no</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        L{' '}
        <line
          x1="8.8179"
          y1="15.1822"
          x2="15.1819"
          y2="8.8182"
          stroke="#5B5B5B"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></line>
        <line
          x1="15.1821"
          y1="15.1822"
          x2="8.8181"
          y2="8.8182"
          stroke="#5B5B5B"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></line>
        <path
          d="M12,0 C5.373,0 0,5.373 0,12 C0,18.627 5.373,24 12,24 C18.627,24 24,18.627 24,12 C24,5.373 18.627,0 12,0 M12,3 C16.962,3 21,7.037 21,12 C21,16.963 16.962,21 12,21 C7.038,21 3,16.963 3,12 C3,7.037 7.038,3 12,3"
          fill="#5B5B5B"
        ></path>
      </g>
    </svg>
  )
}
