import React, { CSSProperties, useCallback, useEffect, useState } from 'react'

import { useFormik } from 'formik'

import { Padding } from '../atoms/Padding'
import { Panel } from '../atoms/Panel'
import { Field } from '../atoms/Field'
import { ReadOnlyText } from '../atoms/ReadOnlyText'
import { InputText } from '../molecules/InputText'
import { InputDate } from '../molecules/InputDate'
import { InputTel } from '../molecules/InputTel'
import { InputPostalcode } from '../molecules/InputPostalcode'
import { InputPassword } from '../molecules/InputPassword'
import { SelectPrefecture } from '../molecules/SelectPrefecture'
import { Button } from '../atoms/Button'
import { Checkbox } from '../molecules/Checkbox'
import { OpenAgreementButton } from '../molecules/OpenAgreementButton'
import { SelectGender } from '../molecules/SelectGender'
import { InputTextKana } from '../molecules/InputTextKana'
import {
  FullScreenMembershipAgreement,
  useMembershipAgreement,
} from './FullScreenMembershipAgreement'
import {
  FullScreenPrivacyPolicy,
  usePrivacyPolicy,
} from './FullScreenPrivacyPolicy'
import { FieldGroup } from '../atoms/FieldGroup'
// import { InputEmail } from '../molecules/InputEmail'
import { CreateMemberParams } from '../../models/member'
import {
  SignUpFormValues,
  validateSignUpForm,
  MIN_PASSWORD_LENGTH,
  MAX_PASSWORD_LENGTH,
} from '../../validations/member'
import { useScroll } from '../../hooks/scroll'

interface SignUpFormProps {
  cardNo: string
  defaultValues?: SignUpFormValues
  defaultConfirming?: boolean
  onSubmit: (params: CreateMemberParams) => void
}

const styles: { [name: string]: CSSProperties } = {
  wrapper: {
    // height: '187px',
    marginTop: '16px',
  },
  fieldLabel: {
    marginTop: '14.5px',
  },
}

const defaultInitialValues: SignUpFormValues = {
  lastName: '',
  firstName: '',
  lastNameKana: '',
  firstNameKana: '',
  // sex: 0, // 性別(0:不明 1:男性 2:女性)
  gender: 'other',
  birthday: '1990-01-01', // 誕生日(YYYY-MM-DD)
  phone: '', // 電話(ハイフンなし)
  // email: '',
  postalCode: '',
  prefecture: '東京都',
  city: '',
  street: '',
  apartment: '',
  password: '',
  privacyPolicyAgreed: false,
}
const passwordHelp = `(英数字を含む半角英数字${MIN_PASSWORD_LENGTH}桁以上${MAX_PASSWORD_LENGTH}桁以内)

※ふるいちポイント会員マイページ（Web）へログインするためのパスワードです。`

// const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

function useSignUpForm(
  defaultValues: SignUpFormValues | undefined,
  defaultConfirming: boolean | undefined,
  onSubmit: (values: CreateMemberParams) => void
) {
  const [confirming, setConfirming] = useState<boolean>(
    defaultConfirming || false
  )
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { resetScroll } = useScroll()
  const handleSubmit = useCallback(
    (values: SignUpFormValues) => {
      const params: CreateMemberParams = {
        lastName: values.lastName,
        firstName: values.firstName,
        lastNameKana: values.lastNameKana,
        firstNameKana: values.firstNameKana,
        gender: values.gender,
        birthday: values.birthday,
        phone: values.phone,
        // email: values.email,
        postalCode: values.postalCode,
        password: values.password,
        prefecture: values.prefecture,
        city: values.city,
        street: values.street,
        apartment: values.apartment,
      }
      onSubmit(params)
      setSubmitting(true)
    },
    [onSubmit, setSubmitting]
  )
  const formik = useFormik<SignUpFormValues>({
    initialValues: { ...defaultInitialValues, ...defaultValues },
    onSubmit: handleSubmit,
    // validateOnBlur: true,
    validate: validateSignUpForm,
  })
  const handleConfirmClick = useCallback(() => {
    try {
      formik.validateForm()
      if (formik.isValid) {
        // 入力不備なし
        setConfirming(true)
      }
    } catch (error) {
      // TODO: display errors
      console.error('validateForm: error:', error)
    }
  }, [formik])
  const isValid = formik.isValid && formik.values.privacyPolicyAgreed

  useEffect(() => {
    resetScroll()
  }, [confirming, resetScroll])

  return { formik, confirming, submitting, isValid, handleConfirmClick }
}

export function SignUpForm({
  cardNo,
  defaultValues,
  defaultConfirming,
  onSubmit,
}: SignUpFormProps) {
  const { formik, confirming, submitting, isValid, handleConfirmClick } =
    useSignUpForm(defaultValues, defaultConfirming, onSubmit)
  const {
    membershipAgreementVisibility,
    handleMembershipAgreementOpen,
    haneldMembershipAgreementClose,
  } = useMembershipAgreement()
  const {
    privacyPolicyVisibility,
    handlePrivacyPolicyOpen,
    haneldPrivacyPolicyClose,
  } = usePrivacyPolicy()

  return (
    <>
      {membershipAgreementVisibility && (
        <FullScreenMembershipAgreement
          backLabel="戻る"
          onClose={haneldMembershipAgreementClose}
        />
      )}
      {privacyPolicyVisibility && (
        <FullScreenPrivacyPolicy
          backLabel="戻る"
          onClose={haneldPrivacyPolicyClose}
        />
      )}
      <Panel style={styles.wrapper}>
        <form onSubmit={formik.handleSubmit}>
          <Field label="カード番号" style={styles.fieldLabel}>
            <ReadOnlyText value={cardNo} />
          </Field>
          <FieldGroup>
            <Field
              label="氏名 (姓)"
              style={styles.fieldLabel}
              readOnly={confirming}
              error={formik.errors.lastName}
              required
            >
              <InputText
                name="lastName"
                value={formik.values.lastName}
                readOnly={confirming}
                required
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
              />
            </Field>
            <Field
              label="氏名 (名)"
              style={styles.fieldLabel}
              readOnly={confirming}
              error={formik.errors.firstName}
              required
            >
              <InputText
                name="firstName"
                value={formik.values.firstName}
                readOnly={confirming}
                required
                onChange={formik.handleChange}
              />
            </Field>
          </FieldGroup>
          <FieldGroup>
            <Field
              label="姓カナ"
              style={styles.fieldLabel}
              readOnly={confirming}
              error={formik.errors.lastNameKana}
              required
            >
              <InputTextKana
                name="lastNameKana"
                value={formik.values.lastNameKana}
                readOnly={confirming}
                required
                onChange={formik.handleChange}
                setFieldValue={formik.setFieldValue}
              />
            </Field>
            <Field
              label="名カナ"
              style={styles.fieldLabel}
              readOnly={confirming}
              error={formik.errors.firstNameKana}
              required
            >
              <InputTextKana
                name="firstNameKana"
                value={formik.values.firstNameKana}
                readOnly={confirming}
                required
                onChange={formik.handleChange}
                setFieldValue={formik.setFieldValue}
              />
            </Field>
          </FieldGroup>
          {/*
          <Field
            label="メールアドレス"
            helpLabel="※パスワード再発行時に必要となります"
            style={styles.fieldLabel}
            readOnly={confirming}
            error={formik.errors.email}
            required
          >
            <InputEmail
              name="email"
              value={formik.values.email}
              readOnly={confirming}
              required
              onChange={formik.handleChange}
            />
          </Field>
          */}
          <Field
            label="性別"
            style={styles.fieldLabel}
            readOnly={confirming}
            error={formik.errors.gender}
            required
          >
            <SelectGender
              name="gender"
              value={formik.values.gender}
              readOnly={confirming}
              onChange={formik.handleChange}
            />
          </Field>
          <Field
            label="生年月日"
            style={styles.fieldLabel}
            readOnly={confirming}
            error={formik.errors.birthday}
            required
          >
            <InputDate
              name="birthday"
              value={formik.values.birthday}
              readOnly={confirming}
              required
              onChange={formik.handleChange}
            />
          </Field>
          <Field
            label="郵便番号"
            style={styles.fieldLabel}
            readOnly={confirming}
            error={formik.errors.postalCode}
            required
          >
            <InputPostalcode
              name="postalCode"
              value={formik.values.postalCode}
              readOnly={confirming}
              required
              onChange={formik.handleChange}
              setFieldValue={formik.setFieldValue}
            />
          </Field>
          <Field
            label="都道府県"
            style={styles.fieldLabel}
            readOnly={confirming}
            error={formik.errors.prefecture}
            required
          >
            <SelectPrefecture
              name="prefecture"
              value={formik.values.prefecture}
              readOnly={confirming}
              onChange={formik.handleChange}
            />
          </Field>
          <Field
            label="市区町村"
            style={styles.fieldLabel}
            readOnly={confirming}
            error={formik.errors.city}
            required
          >
            <InputText
              name="city"
              value={formik.values.city}
              readOnly={confirming}
              required
              onChange={formik.handleChange}
            />
          </Field>
          <Field
            label="町名番地"
            style={styles.fieldLabel}
            readOnly={confirming}
            error={formik.errors.street}
            required
          >
            <InputText
              name="street"
              value={formik.values.street}
              readOnly={confirming}
              required
              onChange={formik.handleChange}
            />
          </Field>
          <Field
            label={confirming ? 'ビルマンション' : 'ビルマンション (任意)'}
            style={styles.fieldLabel}
            readOnly={confirming}
            error={formik.errors.apartment}
          >
            <InputText
              name="apartment"
              value={formik.values.apartment}
              readOnly={confirming}
              onChange={formik.handleChange}
            />
          </Field>
          <Field
            label="電話番号"
            style={styles.fieldLabel}
            readOnly={confirming}
            error={formik.errors.phone}
            required
          >
            <InputTel
              name="phone"
              value={formik.values.phone}
              readOnly={confirming}
              required
              onChange={formik.handleChange}
              setFieldValue={formik.setFieldValue}
            />
          </Field>
          <Field
            label="パスワード"
            helpLabel={confirming ? undefined : passwordHelp}
            style={styles.fieldLabel}
            readOnly={confirming}
            error={formik.errors.password}
            required
          >
            <InputPassword
              name="password"
              value={formik.values.password}
              readOnly={confirming}
              required
              onChange={formik.handleChange}
            />
          </Field>
          {confirming ? (
            <Button
              type="submit"
              variant="black"
              disabled={submitting}
              style={{ marginTop: '22.5px' }}
            >
              本登録する
            </Button>
          ) : (
            <>
              <Padding top="22.5px">
                <OpenAgreementButton onClick={handleMembershipAgreementOpen}>
                  会員規約を読む
                </OpenAgreementButton>
              </Padding>
              <Padding top="16px">
                <OpenAgreementButton onClick={handlePrivacyPolicyOpen}>
                  個人情報保護方針を読む
                </OpenAgreementButton>
              </Padding>
              <Padding top={0}>
                <Checkbox
                  name="privacyPolicyAgreed"
                  value="agreed"
                  checked={formik.values.privacyPolicyAgreed}
                  onChange={formik.handleChange}
                >
                  個人情報保護方針に同意する
                </Checkbox>
              </Padding>
              <Button
                type="button"
                variant="black"
                onClick={handleConfirmClick}
                disabled={!isValid}
                style={{ marginTop: '22.5px' }}
              >
                確認する
              </Button>
            </>
          )}
        </form>
      </Panel>
    </>
  )
}
