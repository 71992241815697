import React, { CSSProperties, PropsWithChildren } from 'react'

interface FieldLabelProps {
  style?: CSSProperties
}

export function FieldGroup({
  style: style_,
  children,
}: PropsWithChildren<FieldLabelProps>) {
  const style: CSSProperties = {
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '8px',
    ...style_,
  }

  return <div style={style}>{children}</div>
}
