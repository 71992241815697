import React, { CSSProperties } from 'react'

import { useFormik } from 'formik'

import { Panel } from '../atoms/Panel'
import { Button } from '../atoms/Button'
import { Checkbox } from '../molecules/Checkbox'
import { Padding } from '../atoms/Padding'

const styles: { [name: string]: CSSProperties } = {
  wrapper: {
    marginTop: '16px',
  },
  fieldLabel: {
    marginTop: '14.5px',
  },
  agreementText: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '21px',
  },
  agreementStrongText: {
    color: '#EA3F0C',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '21px',
  },
}

type UnlinkLineAccountFormValuesForGuest = {
  agreed: boolean
}

type UnlinkLineAccountFormValuesForNonGuest = {
  agreed1: boolean
  agreed2: boolean
  agreed3: boolean
}

interface UnlinkLineAccountFormProps {
  onSubmit: () => void
}

export function UnlinkLineAccountFormForGuest({
  onSubmit,
}: UnlinkLineAccountFormProps) {
  const formik = useFormik<UnlinkLineAccountFormValuesForGuest>({
    initialValues: {
      agreed: false,
    },
    onSubmit: (values) => {
      onSubmit()
    },
  })
  const agreed = formik.values.agreed

  return (
    <>
      <Panel style={styles.wrapper}>
        <form onSubmit={formik.handleSubmit}>
          <Padding top={0}>
            <Checkbox
              name="agreed"
              value="agreed"
              checked={formik.values.agreed}
              onChange={formik.handleChange}
            >
              <div style={styles.agreementText}>
                同一のふるいちポイントカード番号でログインできなくなることを理解しました。
              </div>
            </Checkbox>
          </Padding>
          <Button
            type="submit"
            variant="red"
            disabled={!agreed}
            style={{ marginTop: '22.5px' }}
          >
            LINE連携を解除する
          </Button>
        </form>
      </Panel>
    </>
  )
}

export function UnlinkLineAccountFormForNonGuest({
  onSubmit,
}: UnlinkLineAccountFormProps) {
  const formik = useFormik<UnlinkLineAccountFormValuesForNonGuest>({
    initialValues: {
      agreed1: false,
      agreed2: false,
      agreed3: false,
    },
    onSubmit: (values) => {
      onSubmit()
    },
  })
  const agreedAll =
    formik.values.agreed1 && formik.values.agreed2 && formik.values.agreed3

  return (
    <>
      <Panel style={styles.wrapper}>
        <form onSubmit={formik.handleSubmit}>
          <Padding top={0}>
            <Checkbox
              name="agreed1"
              value="agreed1"
              checked={formik.values.agreed1}
              onChange={formik.handleChange}
            >
              <div style={styles.agreementStrongText}>
                解除された場合、ポイントは失効します。復元はできません。
              </div>
            </Checkbox>
          </Padding>
          <Padding top={16}>
            <Checkbox
              name="agreed2"
              value="agreed2"
              checked={formik.values.agreed2}
              onChange={formik.handleChange}
            >
              <div style={styles.agreementText}>
                ふるいちアプリとの連携を解除します。本登録をしている場合、一度連携を解除すると同一のふるいちポイントカード番号では再度ログインできなくなるためご注意ください。
              </div>
            </Checkbox>
          </Padding>
          <Padding top={16}>
            <Checkbox
              name="agreed3"
              value="agreed3"
              checked={formik.values.agreed3}
              onChange={formik.handleChange}
            >
              <div style={styles.agreementText}>
                本登録をしている場合、LINE連携を解除しても本会員登録が削除されないことをご注意ください。
              </div>
            </Checkbox>
          </Padding>
          <Padding top={16} left={32}>
            退会はWEB版のふるいちポイントカードページでのみ行うことができます。
          </Padding>
          <Padding top={16} left={32}>
            ふるいちポイントカードのWEBページには登録した
            <br />
            ・メールアドレス
            <br />
            ・パスワード
            <br />
            上記でログインが可能です。
          </Padding>
          <Button
            type="submit"
            variant="red"
            disabled={!agreedAll}
            style={{ marginTop: '22.5px' }}
          >
            LINE連携を解除する
          </Button>
        </form>
      </Panel>
    </>
  )
}
