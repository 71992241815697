import React, { CSSProperties, PropsWithChildren } from 'react'

import { Button, ButtonLink } from '../atoms/Button'
import { LinkIcon } from '../atoms/icons/LinkIcon'

interface OpenAgreementButtonProps {
  onClick: () => void
}

const styles: { [name: string]: CSSProperties } = {
  button: {
    height: '48px',
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    alignItems: 'center',
  },
  label: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '24px',
    paddingLeft: '16px',
    justifySelf: 'left',
  },
  link: {
    paddingRight: '16px',
    justifySelf: 'right',
    display: 'grid',
    placeContent: 'center',
  },
}

export function OpenAgreementButton({
  onClick,
  children,
}: PropsWithChildren<OpenAgreementButtonProps>) {
  return (
    <Button variant="lightgray" onClick={onClick}>
      <div style={styles.button}>
        <div style={styles.label}>{children}</div>
        <div style={styles.link}>
          <LinkIcon />
        </div>
      </div>
    </Button>
  )
}

interface OpenAgreementButtonLinkProps {
  to: string
}

export function OpenAgreementButtonLink({
  to,
  children,
}: PropsWithChildren<OpenAgreementButtonLinkProps>) {
  const style: CSSProperties = {
    justifyItems: 'normal',
  }

  return (
    <ButtonLink variant="lightgray" to={to} style={style}>
      <div style={styles.button}>
        <div style={styles.label}>{children}</div>
        <div style={styles.link}>
          <LinkIcon />
        </div>
      </div>
    </ButtonLink>
  )
}
