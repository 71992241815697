import React, { CSSProperties, ReactElement, useMemo } from 'react'

import { MemberRank, MemberRankInfo } from '../../models/member'
import { formatDate } from '../../utils/date'
import { numberWithCommas } from '../../utils/numberWithCommas'

interface GuideMessageBaseProps {
  title: string | ReactElement
  body: ReactElement | undefined
}

function GuideMessageBase({ title, body }: GuideMessageBaseProps) {
  const styles = useMemo<{ [name: string]: CSSProperties }>(
    () => ({
      guideContainer: {
        width: '100%',
        height: '94px',
        marginTop: '1px',
        background: '#fff',
        display: 'grid',
        // gridTemplateColumns: '67px auto',
      },
      logoImage: {
        alignSelf: 'center',
        justifySelf: 'center',
      },
      message: {
        display: 'grid',
        // gridTemplateRows: '22px 15px auto',
        gridTemplateRows: '22px auto',
        // marginRight: '24px',
        paddingTop: '8px',
        paddingRight: '16px',
        paddingLeft: '16px',
        // alignSelf: 'center',
      },
      messageTitle: {
        alignSelf: 'end',
        fontSize: '14px',
        fontWeight: 'bold',
      },
      messageBody: {},
    }),
    []
  )

  return (
    <div style={styles.guideContainer}>
      <div style={styles.message}>
        <div style={styles.messageTitle}>{title}</div>
        <div style={styles.messageBody}>{body}</div>
      </div>
    </div>
  )
}

function GuideMessageForGuest() {
  const styles: { [name: string]: CSSProperties } = {
    container: {
      display: 'grid',
      gridTemplateRows: '1fr',
    },
    messageTitle: {
      color: '#0cc81d',
    },
    messageBody: {
      marginTop: '8px',
      alignSelf: 'center',
      color: '#282828',
      fontSize: '12px',
      // lineHeight: '18px',
    },
  }

  return (
    <GuideMessageBase
      title={<div style={styles.messageTitle}>ゲスト</div>}
      body={
        <div style={styles.container}>
          <div style={styles.messageBody}>
            会員サービスの利用に登録が必要なものがございます。上記より「会員登録」をおこなってください。
          </div>
        </div>
      }
    />
  )
}

function GuideMessageForGeneral({
  memberRankInfo,
}: {
  memberRankInfo: MemberRankInfo | undefined
}) {
  const styles: { [name: string]: CSSProperties } = {
    messageTitle: {
      color: '#f06400',
    },
    messageContainer: {
      paddingTop: '4px',
      display: 'grid',
      gridTemplateRows: '15px auto',
    },
    messageRankInfo: {
      alignSelf: 'end',
      fontSize: '10px',
      lineHeight: '15px',
    },
    messageBody: {
      paddingTop: '4px',
      alignSelf: 'center',
      color: '#282828',
      fontSize: '12px',
      lineHeight: '14px',
    },
  }

  // return (
  //   <GuideMessageBase
  //     title={<div style={styles.messageTitle}>一般会員</div>}
  //     body={
  //       memberRankInfo && (
  //         <div style={styles.messageContainer}>
  //           <div style={styles.messageRankInfo}>
  //             ランク判定 {formatDate(memberRankInfo.from)} -{' '}
  //             {formatDate(memberRankInfo.to)}
  //           </div>
  //           <div style={styles.messageBody}>
  //             判定期にあと<strong>{memberRankInfo.needVisit}回</strong>ご来店と
  //             <strong>
  //               {numberWithCommas(memberRankInfo.needPoint)}ポイント
  //             </strong>
  //             ({numberWithCommas(memberRankInfo.needPrice)}
  //             円)購入で「VIP会員」にランクアップ
  //           </div>
  //         </div>
  //       )
  //     }
  //   />
  // )
  // ランク判定 2020/10/01 - 2020/10/31
  // 判定期にあと3回ご来店と300ポイント(0円)購入で「VIP会員」にランクアップ
  // ↓
  // ランク判定 2020/10/01 - 2020/10/31
  // 判定期にあと0円以上購入で「VIP会員」にランクアップ
  return (
    <GuideMessageBase
      title={<div style={styles.messageTitle}>一般会員</div>}
      body={
        memberRankInfo && (
          <div style={styles.messageContainer}>
            <div style={styles.messageRankInfo}>
              ランク判定 {formatDate(memberRankInfo.from)} -{' '}
              {formatDate(memberRankInfo.to)}
            </div>
            <div style={styles.messageBody}>
              {memberRankInfo.needPrice > 0 ? (
                <>
                  判定期にあと
                  <strong>
                    {numberWithCommas(memberRankInfo.needPrice)}円
                  </strong>
                  以上購入で「VIP会員」にランクアップ
                </>
              ) : (
                <>来月は（VIP会員）です。</>
              )}
            </div>
          </div>
        )
      }
    />
  )
}

function GuideMessageForVip({
  memberRankInfo,
}: {
  memberRankInfo: MemberRankInfo | undefined
}) {
  const styles: { [name: string]: CSSProperties } = {
    messageTitle: {
      color: '#cf1414',
    },
    messageContainer: {
      paddingTop: '4px',
      display: 'grid',
      gridTemplateRows: '15px auto',
    },
    messageRankInfo: {
      alignSelf: 'end',
      fontSize: '10px',
      lineHeight: '15px',
    },
    messageBody: {
      paddingTop: '4px',
      alignSelf: 'center',
      color: '#282828',
      fontSize: '12px',
      lineHeight: '14px',
    },
  }

  // return (
  //   <GuideMessageBase
  //     title={<div style={styles.messageTitle}>VIP会員</div>}
  //     body={
  //       memberRankInfo && (
  //         <div style={styles.messageContainer}>
  //           <div style={styles.messageRankInfo}>
  //             ランク判定 {formatDate(memberRankInfo.from)} -{' '}
  //             {formatDate(memberRankInfo.to)}
  //           </div>
  //           <div style={styles.messageBody}>
  //             判定期にあと<strong>{memberRankInfo.needVisit}回</strong>ご来店と
  //             <strong>
  //               {numberWithCommas(memberRankInfo.needPoint)}ポイント
  //             </strong>
  //             ({numberWithCommas(memberRankInfo.needPrice)}
  //             円)購入で「VIP会員」を維持
  //           </div>
  //         </div>
  //       )
  //     }
  //   />
  // )
  // ランク判定 2020/10/01 - 2020/10/31
  // 判定期にあと3回ご来店と300ポイント(0円)購入で「VIP会員」を維持
  // ↓
  // ランク判定 2020/10/01 - 2020/10/31
  // 判定期にあと0円以上購入で「VIP会員」を維持
  return (
    <GuideMessageBase
      title={<div style={styles.messageTitle}>VIP会員</div>}
      body={
        memberRankInfo && (
          <div style={styles.messageContainer}>
            <div style={styles.messageRankInfo}>
              ランク判定 {formatDate(memberRankInfo.from)} -{' '}
              {formatDate(memberRankInfo.to)}
            </div>
            <div style={styles.messageBody}>
              {memberRankInfo.needPrice > 0 ? (
                <>
                  判定期にあと
                  <strong>
                    {numberWithCommas(memberRankInfo.needPrice)}円
                  </strong>
                  以上購入で「VIP会員」を維持
                </>
              ) : (
                <>来月も（VIP会員）です。</>
              )}
            </div>
          </div>
        )
      }
    />
  )
}

interface GuideMessageProps {
  memberRank: MemberRank
  memberRankInfo: MemberRankInfo | undefined
}

export function GuideMessage({
  memberRank,
  memberRankInfo,
}: GuideMessageProps) {
  if (memberRank === 'guest') {
    return <GuideMessageForGuest />
  }
  if (memberRank === 'general') {
    return <GuideMessageForGeneral memberRankInfo={memberRankInfo} />
  }
  if (memberRank === 'vip') {
    return <GuideMessageForVip memberRankInfo={memberRankInfo} />
  }
  return null
}
