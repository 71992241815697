//                         status   HTTP Status
// 正常終了                      0  200
// linetokenパラメータがない    200   400
// LINE IDがCRMに未登録の場合   100   200

import { getNow, minutesToMilliSeconds } from '../utils/datetime'

// LINE IDが取得できなかった場合 400   400
interface LoginRawResponse {
  status: number // 0 | 100 | 200 | 400
  token: string
  token_type: 'bearer'
  expire_in: number
}

export interface LoginResponse {
  token: string
  tokenType: string
  expireIn: number
  // extra
  registrationRequired: boolean
  expiresAt: Date | undefined
}

export function fromLoginRawResponse(
  rawResponse: LoginRawResponse
): LoginResponse {
  const expiresInMin = Number(rawResponse.expire_in)
  const login: LoginResponse = {
    token: rawResponse.token,
    tokenType: rawResponse.token_type,
    expireIn: rawResponse.expire_in,
    registrationRequired: rawResponse.status !== 0,
    expiresAt: new Date(getNow() + minutesToMilliSeconds(expiresInMin)),
  }

  return login
}
