import React, { CSSProperties } from 'react'

interface ReadOnlyTextProps {
  value: string | undefined
}

const styles: { [name: string]: CSSProperties } = {
  value: {
    marginTop: '16px',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
  },
}

export function ReadOnlyText({ value }: ReadOnlyTextProps) {
  return <div style={styles.value}>{value || '　'}</div>
}
