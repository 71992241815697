import React, { CSSProperties } from 'react'

import { Announcement } from '../../models/announcement'
import { formatDate } from '../../utils/date'
import { Panel } from '../atoms/Panel'
import { RoundedListItemFooter, RoundedListItemHeader } from './RoundedList'

interface AnnouncementListItemProps {
  announcement: Announcement
  onClick: () => void
}

const styles: { [name: string]: CSSProperties } = {
  container: {
    cursor: 'pointer',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  image: {
    // 343x126
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    width: '100%',
  },
  header: {
    margin: 0,
    border: 0,
  },
  footer: {
    backgroundColor: '#FFFFFF',
    margin: 0,
    border: 0,
  },
  announcementArea: {
    boxSizing: 'content-box',
    paddingTop: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: '16px',
    overflow: 'hidden',
    whiteSpace: 'pre-wrap',
    textOverflow: 'ellipsis',
    display: 'grid',
    alignItems: 'normal',
  },
  announcementDate: {
    fontSize: '12px',
    lineHeight: '18px',
    color: '#5B5B5B',
  },
  title: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '21px',
    color: '#5B5B5B',
  },
  readTitle: {
    fontWeight: 'normal',
  },
  unreadTitle: {
    fontWeight: 'bold',
  },
}

const emptyAnnouncementImageUrl = '/images/coupon-empty@3x.png'

export function AnnouncementListItem({
  announcement,
  onClick,
}: AnnouncementListItemProps) {
  const titleStyle: CSSProperties = {
    ...styles.title,
    // ...(announcement.isRead ? styles.readTitle : styles.unreadTitle),
  }

  // onClick={onClick}
  return (
    <div style={styles.container} onClick={onClick}>
      <Panel>
        <RoundedListItemHeader style={styles.header}>
          <img
            alt={announcement.title}
            style={styles.image}
            src={announcement.imageUrl || emptyAnnouncementImageUrl}
          />
        </RoundedListItemHeader>
        <RoundedListItemFooter style={styles.footer}>
          <div style={styles.announcementArea}>
            <div style={styles.announcementDate}>
              {formatDate(announcement.announcementDate)}
            </div>
            <div style={titleStyle}>{announcement.title}</div>
          </div>
        </RoundedListItemFooter>
      </Panel>
    </div>
  )
}
