import React, { useMemo, useEffect, useCallback } from 'react'

import { BackNavLinkPanel } from '../organisms/BackNavPanel'
import { DefaultTemplate } from '../templates/DefaultTemplate'
import { SubtitleWithPager } from '../molecules/SubtitleWithPager'
import { PagerBar } from '../molecules/PagerBar'
import { PointHistoryList } from '../organisms/PointHistoryList'
import {
  PointHistoryRecord,
  PointHistorySearchOptions,
  TotalPoint,
} from '../../models/point'
import { LoadingPanel } from '../molecules/LoadingPanel'
import { usePointHistory } from '../../hooks/point'
import { useScroll } from '../../hooks/scroll'
import { PointHistorySummaryPanel } from '../organisms/PointHistorySummaryPanel'

interface PointHistoryPagePresenterProps {
  totalNum: number
  perPage: number
  currentPage: number
  records: PointHistoryRecord[]
  totalPoint: TotalPoint
  onPageClick: (page: number) => void
}

export function PointHistoryPageLoadingPresenter() {
  const Main = useMemo(() => {
    return (
      <>
        <BackNavLinkPanel label="戻る" to="/" />
        <LoadingPanel />
      </>
    )
  }, [])

  return (
    <DefaultTemplate title="ポイントご利用明細" main={Main} autoScrollToTop />
  )
}

export function PointHistoryPagePresenter({
  totalNum,
  perPage,
  currentPage,
  records,
  totalPoint,
  onPageClick,
}: PointHistoryPagePresenterProps) {
  const Main = useMemo(() => {
    const numPages = Math.ceil(totalNum / perPage)
    return (
      <>
        <BackNavLinkPanel label="戻る" to="/" />
        <PointHistorySummaryPanel totalPoint={totalPoint} />
        <SubtitleWithPager
          text="ポイントご利用明細"
          totalNum={totalNum}
          perPage={perPage}
          currentPage={currentPage}
        />
        <PointHistoryList records={records} />
        {numPages > 1 && (
          <PagerBar
            totalNum={totalNum}
            perPage={perPage}
            currentPage={currentPage}
            onPageClick={onPageClick}
          />
        )}
      </>
    )
  }, [totalNum, perPage, currentPage, records, totalPoint, onPageClick])

  return (
    <DefaultTemplate title="ポイントご利用明細" main={Main} autoScrollToTop />
  )
}

function usePointHistoryPage() {
  const { meta, records, point, fetchPointHistory, fetchTotalPoints } =
    usePointHistory()
  const perPage = 10
  const { resetScroll } = useScroll()
  const handlePageClick = useCallback(
    (page: number) => {
      const searchOptions: PointHistorySearchOptions = {
        count: perPage,
        page,
      }
      fetchPointHistory(searchOptions)
    },
    [fetchPointHistory]
  )

  useEffect(() => {
    fetchTotalPoints()
  }, [fetchTotalPoints])
  useEffect(() => {
    const searchOptions: PointHistorySearchOptions = {
      count: perPage,
      page: 1,
    }
    fetchPointHistory(searchOptions)
  }, [fetchPointHistory])
  useEffect(() => {
    resetScroll()
  }, [meta, resetScroll])

  return {
    meta,
    records,
    point,
    handlePageClick,
  }
}

export function PointHistoryPage() {
  const { meta, records, point, handlePageClick } = usePointHistoryPage()

  if (!(meta && records && point)) {
    return <PointHistoryPageLoadingPresenter />
  }

  return (
    <PointHistoryPagePresenter
      totalNum={meta.dataCount}
      perPage={meta.perPage}
      currentPage={meta.pageNo}
      records={records}
      totalPoint={point}
      onPageClick={handlePageClick}
    />
  )
}
