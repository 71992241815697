import React, { CSSProperties } from 'react'

import { LinkIcon } from '../atoms/icons/LinkIcon'

interface OpenLinkProps {
  label: string
  to?: string
  onClick?: () => void
}

const styles: { [name: string]: CSSProperties } = {
  container: {
    width: '100%',
    height: '25px',
    borderRadius: '8px',
    position: 'relative',
  },
  message: {
    position: 'absolute',
    top: '5px',
    right: '30px',
    fontSize: '12px',
    color: '#282828',
    textDecorationLine: 'underline',
  },
  icon: {
    position: 'absolute',
    right: 0,
    color: '#0cc81d',
    fontSize: '14px',
  },
}

export function OpenLink({ label, onClick }: OpenLinkProps) {
  return (
    <div style={styles.container} onClick={onClick}>
      <div style={styles.message}>{label}</div>
      <div style={styles.icon}>
        <LinkIcon />
      </div>
    </div>
  )
}
