import React, { CSSProperties } from 'react'

import { Panel } from '../atoms/Panel'

const styles: { [name: string]: CSSProperties } = {
  wrapper1: {
    height: '64px',
    backgroundColor: '#ff9501',
    paddingTop: '8px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  title: {
    color: '#fff',
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  wrapper2: {
    // height: '64px',
    // backgroundColor: '#fff',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  message: {
    fontSize: '14px',
    lineHeight: '21px',
    textAlign: 'left',
  },
}

export function SignUpHelpPanel() {
  return (
    <>
      <Panel fullWidth style={styles.wrapper1}>
        <div style={styles.title}>ふるいちポイント会員</div>
        <div style={styles.title}>本会員登録の作成</div>
      </Panel>
      <Panel style={styles.wrapper2}>
        <div style={styles.message}>
          以下の内容をご入力のうえ、会員規約と個人情報保護方針をお読みいただき、同意いただける方は、同意するにチェックを入れて「確認する」ボタンを押してください。
        </div>
      </Panel>
    </>
  )
}
