import React, { CSSProperties } from 'react'

import { Panel } from '../atoms/Panel'
import { RoundedPanel } from '../molecules/RoundedPanel'

const styles: { [name: string]: CSSProperties } = {
  firstMessage: { fontSize: '12px' },
  secondMessage: { fontSize: '12px', fontWeight: 'bold' },
}

export function AboutTradeHistoryPage() {
  return (
    <Panel paddingTop="12px" paddingBottom="12px">
      <RoundedPanel>
        <div style={styles.firstMessage}>
          ●購買履歴は履歴発生の翌日以降、ご確認いただけます。
        </div>
        <br />
        <div>
          <span style={styles.secondMessage}>
            ※表示される履歴は店舗でのご購入、買取時に発行されるレシートと一部表示内容が異なることがございます。
          </span>
        </div>
      </RoundedPanel>
    </Panel>
  )
}
