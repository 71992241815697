import React, { CSSProperties } from 'react'

import { Panel } from '../atoms/Panel'

const styles: { [name: string]: CSSProperties } = {
  wrapper: {
    borderTop: '1px #D8D8D8 solid',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  message: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '21px',
    textAlign: 'left',
    paddingBottom: '24px',
    color: '#5B5B5B',
  },
  message2: {
    fontSize: '12px',
    lineHeight: '21px',
    textAlign: 'left',
  },
}

export function SupportPanel() {
  return (
    <>
      <Panel style={styles.wrapper}>
        <div style={styles.message}>お客様窓口</div>
        <div style={styles.message2}>電話番号: 0570-087-097</div>
        <div style={styles.message2}>E-mail: customer@tay2.co.jp</div>
        <div style={styles.message2}>受付時間: 10:00～20:01</div>
      </Panel>
    </>
  )
}
