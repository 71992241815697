import React, { CSSProperties } from 'react'

import { DefaultTemplate } from '../templates/DefaultTemplate'
import { YoshioKunIcon } from '../atoms/YoshioKunIcon'
import { appVersion } from '../../utils/appInfo'

interface LoadingPageProps {
  status?: 'initializing-liff' | 'initializing-api' | 'logging-in' | undefined
  errorMessages?: string[] | undefined
}

function statusMessage(status: LoadingPageProps['status']) {
  if (status === 'initializing-liff') return '⚫︎⚪︎⚪︎⚪︎' // 'Initializing LIFF...'
  if (status === 'initializing-api') return '⚫︎⚫︎⚪︎⚪︎' // 'Initializing API...'
  if (status === 'logging-in') return '⚫︎⚫︎⚫︎⚪︎' // 'Logging In...'
  return '⚪︎⚪︎⚪︎⚪︎' // 'Loading...'
}

const styles: { [name: string]: CSSProperties } = {
  container: {
    width: '100vw',
    height: '66vh',
    display: 'grid',
    placeItems: 'center',
  },
  loadingIndicator: {
    display: 'grid',
    placeItems: 'center',
  },
  status: {
    paddingTop: '0px',
  },
  version: {
    paddingTop: '2px',
  },
  errors: {
    position: 'fixed',
    bottom: '0',
    height: '33vh',
    width: '100%',
    // border: 'solid 1px red',
    padding: '50px',
  },
  error: {
    textAlign: 'center',
    color: '#FF0000',
  },
}

export function LoadingPage({ status, errorMessages }: LoadingPageProps) {
  const Main = (
    <>
      <div style={styles.container}>
        <div style={styles.loadingIndicator}>
          <YoshioKunIcon />
          <div style={styles.status}>{statusMessage(status)}</div>
          <div style={styles.version}>v{appVersion}</div>
          <div style={styles.errors}>
            {errorMessages &&
              errorMessages.map((errorMessage, i) => {
                return (
                  <div key={i} style={styles.error}>
                    {errorMessage}
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </>
  )

  return <DefaultTemplate title="ふるいちアプリ" main={Main} autoScrollToTop />
}
