import React, { CSSProperties, PropsWithChildren } from 'react'

interface BareParagraphProps {
  style?: CSSProperties
}

export function BareParagraph({
  style,
  children,
}: PropsWithChildren<BareParagraphProps>) {
  const pStyle: CSSProperties = {
    fontSize: '10px',
    lineHeight: '14px',
    paddingTop: '14px',
    color: '#282828',
    whiteSpace: 'pre-wrap',
    ...style,
  }

  return <p style={pStyle}>{children}</p>
}
