import {
  Announcement,
  AnnouncementMeta,
  AnnouncementsSearchOptions,
} from '../models/announcement'
import { ResetActionTypes, ResetStoreAction } from './reset'

// Action Types

const FETCH_ANNOUNCEMENTS = 'FETCH_ANNOUNCEMENTS' as const
const FETCH_ANNOUNCEMENTS_REQUEST = 'FETCH_ANNOUNCEMENTS_REQUEST' as const
const FETCH_ANNOUNCEMENTS_SUCCESS = 'FETCH_ANNOUNCEMENTS_SUCCESS' as const
const FETCH_ANNOUNCEMENTS_FAILURE = 'FETCH_ANNOUNCEMENTS_FAILURE' as const
const UPDATE_ANNOUNCEMENT_AS_READ = 'UPDATE_ANNOUNCEMENT_AS_READ' as const
const UPDATE_ANNOUNCEMENT_AS_READ_REQUEST =
  'UPDATE_ANNOUNCEMENT_AS_READ_REQUEST' as const
const UPDATE_ANNOUNCEMENT_AS_READ_SUCCESS =
  'UPDATE_ANNOUNCEMENT_AS_READ_SUCCESS' as const
const UPDATE_ANNOUNCEMENT_AS_READ_FAILURE =
  'UPDATE_ANNOUNCEMENT_AS_READ_FAILURE' as const

export const AnnouncementActionTypes = {
  FETCH_ANNOUNCEMENTS,
  // FETCH_ANNOUNCEMENTS_REQUEST,
  // FETCH_ANNOUNCEMENTS_SUCCESS,
  // FETCH_ANNOUNCEMENTS_FAILURE,
  UPDATE_ANNOUNCEMENT_AS_READ,
  // UPDATE_ANNOUNCEMENT_AS_READ_REQUEST,
  // UPDATE_ANNOUNCEMENT_AS_READ_SUCCESS,
  // UPDATE_ANNOUNCEMENT_AS_READ_FAILURE,
}

// Action Creators

export function fetchAnnouncementsAction(options: AnnouncementsSearchOptions) {
  return {
    type: FETCH_ANNOUNCEMENTS,
    payload: { options },
  }
}

function fetchAnnouncementsRequestAction(options: AnnouncementsSearchOptions) {
  return {
    type: FETCH_ANNOUNCEMENTS_REQUEST,
    payload: { options },
  }
}

function fetchAnnouncementsSuccessAction(
  meta: AnnouncementMeta,
  announcements: Announcement[]
) {
  return {
    type: FETCH_ANNOUNCEMENTS_SUCCESS,
    payload: { meta, announcements },
  }
}

function fetchAnnouncementsFailureAction(error: any) {
  return {
    type: FETCH_ANNOUNCEMENTS_FAILURE,
    payload: { error },
  }
}

export function updateAnnouncementAsReadAction(mAnnouncementId: number) {
  return {
    type: UPDATE_ANNOUNCEMENT_AS_READ,
    payload: { mAnnouncementId },
  }
}

function updateAnnouncementAsReadRequestAction(mAnnouncementId: number) {
  return {
    type: UPDATE_ANNOUNCEMENT_AS_READ_REQUEST,
    payload: { mAnnouncementId },
  }
}

function updateAnnouncementAsReadSuccessAction(mAnnouncementId: number) {
  return {
    type: UPDATE_ANNOUNCEMENT_AS_READ_SUCCESS,
    payload: { mAnnouncementId },
  }
}

function updateAnnouncementAsReadFailureAction(error: any) {
  return {
    type: UPDATE_ANNOUNCEMENT_AS_READ_FAILURE,
    payload: { error },
  }
}

export const AnnouncementActionCreators = {
  fetchAnnouncementsAction,
  fetchAnnouncementsRequestAction,
  fetchAnnouncementsSuccessAction,
  fetchAnnouncementsFailureAction,
  updateAnnouncementAsReadAction,
  updateAnnouncementAsReadRequestAction,
  updateAnnouncementAsReadSuccessAction,
  updateAnnouncementAsReadFailureAction,
}

// Actions

export type FetchAnnouncementsAction = ReturnType<
  typeof fetchAnnouncementsAction
>

export type UpdateAnnouncementAsReadAction = ReturnType<
  typeof updateAnnouncementAsReadAction
>

type AnnouncementAction =
  | FetchAnnouncementsAction
  | ReturnType<typeof fetchAnnouncementsSuccessAction>
  | ReturnType<typeof fetchAnnouncementsSuccessAction>
  | ReturnType<typeof fetchAnnouncementsFailureAction>
  | UpdateAnnouncementAsReadAction
  | ReturnType<typeof updateAnnouncementAsReadRequestAction>
  | ReturnType<typeof updateAnnouncementAsReadSuccessAction>
  | ReturnType<typeof updateAnnouncementAsReadFailureAction>
  | ResetStoreAction

// State

interface AnnouncementState {
  meta: AnnouncementMeta | undefined
  announcements: Announcement[] | undefined
}

const initialState: AnnouncementState = {
  meta: undefined,
  announcements: undefined,
}

// Selector

export function announcementsSelector(rootState: {
  announcement: AnnouncementState
}) {
  return rootState.announcement.announcements
}

export function announcementMetaSelector(rootState: {
  announcement: AnnouncementState
}) {
  return rootState.announcement.meta
}

// Reducer

export function announcementReducer(
  state = initialState,
  action: AnnouncementAction
): AnnouncementState {
  switch (action.type) {
    case FETCH_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        meta: action.payload.meta,
        announcements: action.payload.announcements,
      }
    case UPDATE_ANNOUNCEMENT_AS_READ_SUCCESS:
      return state
    case ResetActionTypes.RESET_STORE:
      return initialState
    default:
      return state
  }
}
