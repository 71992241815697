import React from 'react'

import { Panel } from '../atoms/Panel'
import { HelpLink } from '../molecules/HelpLink'

export function PointCardHelpLinkPanel() {
  return (
    <Panel paddingTop={8}>
      <HelpLink label="ポイントカードについて" to="/about/pointcard" />
    </Panel>
  )
}
