import React, { CSSProperties, useCallback } from 'react'

import { CouponListItem } from '../molecules/CouponListItem'
import { EmptyListPanel } from '../molecules/EmptyListPanel'
import { Coupon } from '../../models/coupon'

interface CouponListProps {
  coupons: Coupon[]
  selectedCoupons: { [couponCode: string]: Coupon }
  dateStr: string
  onToggleSelect: (coupon: Coupon) => void
}

const styles: { [name: string]: CSSProperties } = {
  container: {
    borderTop: '1px solid #D8D8D8',
  },
}

export function CouponList({
  coupons,
  selectedCoupons,
  dateStr,
  onToggleSelect,
}: CouponListProps) {
  const handleToggleSelect = useCallback(
    (coupon: Coupon) => {
      onToggleSelect(coupon)
    },
    [onToggleSelect]
  )

  if (coupons.length === 0) {
    return <EmptyListPanel label="現在クーポンはありません。" />
  }

  return (
    <div style={styles.container}>
      {coupons.map((coupon, i) => {
        return (
          <CouponListItem
            key={i}
            coupon={coupon}
            selected={!!selectedCoupons[coupon.couponCode]}
            dateStr={dateStr}
            onToggleSelect={handleToggleSelect}
          />
        )
      })}
    </div>
  )
}
