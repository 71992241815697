import React from 'react'

import { BackNavLinkPanel } from '../organisms/BackNavPanel'
import { DefaultTemplate } from '../templates/DefaultTemplate'
import { AboutPointCardPanel } from '../organisms/AboutPointCardPanel'

export function AboutPointCardPagePresenter() {
  const Main = (
    <>
      <BackNavLinkPanel label="戻る" to="/pointcard" />
      <AboutPointCardPanel />
    </>
  )

  return (
    <DefaultTemplate
      title="ポイントカードについて"
      main={Main}
      autoScrollToTop
    />
  )
}

export function AboutPointCardPage() {
  return <AboutPointCardPagePresenter />
}
