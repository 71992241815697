import React, { CSSProperties, PropsWithChildren, ReactElement } from 'react'

interface FieldLabelProps {
  label: string | ReactElement
  required?: boolean
  helpLabel?: string
  readOnly?: boolean
  error?: string
  style?: CSSProperties
  inputStyle?: CSSProperties
}

export function Field({
  label,
  required,
  helpLabel,
  readOnly,
  error,
  style,
  inputStyle,
  children,
}: PropsWithChildren<FieldLabelProps>) {
  const styles: { [name: string]: CSSProperties } = {
    labelGroup: {
      display: 'block',
    },
    label: {
      fontSize: '12px',
      lineHeight: '18px',
      marginLeft: '1.5px',
      marginBottom: '6.5px',
    },
    requiredLabel: {
      color: '#cf1414',
    },
    input: {
      display: 'grid',
      ...inputStyle,
    },
    helpLabel: {
      display: 'block',
      whiteSpace: 'pre-wrap',
      fontSize: '12px',
      lineHeight: '18px',
    },
    error: {
      color: '#cf1414',
      fontSize: '12px',
      lineHeight: '18px',
    },
  }

  return (
    <div style={style}>
      <label>
        <div style={styles.labelGroup}>
          <span style={styles.label}>
            {label}
            {required && !readOnly && (
              <span>
                {' '}
                (<span style={styles.requiredLabel}>必須</span>)
              </span>
            )}
          </span>
        </div>
        <div style={styles.input}>{children}</div>
        {error && <div style={styles.error}>{error}</div>}
        <div style={styles.helpLabel}>{helpLabel}</div>
      </label>
    </div>
  )
}
