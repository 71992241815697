import React, { CSSProperties, PropsWithChildren } from 'react'

import { MemberRank } from '../../models/member'

import { BannerLogo } from '../atoms/BannerLogo'
import { numberWithCommas } from '../../utils/numberWithCommas'
import { YoshioKunIconPointCard } from '../atoms/YoshioKunIcon'

interface PointPaneProps {
  memberRank: MemberRank
  isEmployee: boolean
}

interface PointValueProps {
  points: number | undefined
}

const backgroundColorPalette: { [name: string]: string } = {
  guest: '#28d95d',
  general: '#ff9500',
  vip: '#d94d28',
  employee: '#019cff',
}

function PointPane({
  memberRank,
  isEmployee,
  children,
}: PropsWithChildren<PointPaneProps>) {
  const style: CSSProperties = {
    backgroundColor:
      backgroundColorPalette[isEmployee ? 'employee' : memberRank],
    height: '73px',
  }
  return <div style={style}>{children}</div>
}

function PointLogo({ memberRank, isEmployee }: PointPaneProps) {
  const style: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    marginTop: '0px',
    marginLeft: '10px',
  }

  return (
    <div style={style}>
      <YoshioKunIconPointCard memberRank={memberRank} isEmployee={isEmployee} />
    </div>
  )
}

function BannerLogoOnTopRight() {
  const style: CSSProperties = {
    position: 'absolute',
    top: 0,
    right: 0,
    marginTop: '8px',
    marginRight: '12px',
    // border: '1px solid purple',
  }
  return (
    <div style={style}>
      <BannerLogo height={24} />
    </div>
  )
}

function PointValue({ points }: PointValueProps) {
  const styles: { [name: string]: CSSProperties } = {
    container: {
      height: '100%',
      display: 'grid',
      gridTemplateColumns: 'auto auto auto',
      // gridAutoRows: '100%', // '73px',
      gridGap: '8px',
      justifyContent: 'end',
      alignItems: 'end',
      color: '#ffffff',
      paddingRight: '16px',
      // border: '1px solid red',
    },
    prefix: {
      fontSize: '14px',
      lineHeight: '21px',
      marginBottom: '8px',
      // border: '1px solid blue',
    },
    value: {
      fontSize: '32px',
      fontWeight: 'bold',
      lineHeight: '38px',
      marginBottom: '0px',
      // border: '1px solid blue',
    },
    unit: {
      fontSize: '14px',
      lineHeight: '21px',
      marginBottom: '8px',
      // border: '1px solid blue',
    },
  }
  const formattedPoints = points === undefined ? '-' : numberWithCommas(points)

  return (
    <div style={styles.container}>
      <div style={styles.prefix}>累計</div>
      <div style={styles.value}>{formattedPoints}</div>
      <div style={styles.unit}>ポイント</div>
    </div>
  )
}

interface PointCardPointsInfoProps {
  memberRank: MemberRank
  isEmployee: boolean
  points: number | undefined
}

export function PointCardPointsInfo({
  memberRank,
  isEmployee,
  points,
}: PointCardPointsInfoProps) {
  return (
    <PointPane memberRank={memberRank} isEmployee={isEmployee}>
      <PointLogo memberRank={memberRank} isEmployee={isEmployee} />
      <BannerLogoOnTopRight />
      <PointValue points={points} />
    </PointPane>
  )
}
