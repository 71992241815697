import { useCallback, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { LoginActionCreators, loginStatusSelector } from '../modules/login'
import { getNow } from '../utils/datetime'
import { useLiff } from './liff'
import { ErrorResponse } from '../models/error'

// 1. LIFF の初期化
// 2. LINE にログイン
// 3. CRM にログイン
// 4. もし CRM 未登録なら:
//   1. 同意画面を出す
//   2. 同意後に CRM に登録
// 5. 会員の情報を取得
// 6. 会員のランクによって表示を変える

function buildCrmErrorMessages(error: ErrorResponse | undefined) {
  if (process.env.REACT_APP_ENABLE_ERROR_INFO === 'true') {
    if (error) {
      if (!error.httpStatus) {
        return [`Status: ${error.status}`, error.message]
      }
      return [
        `HTTP Status: ${error.httpStatus}`,
        `Status: ${error.status}`,
        error.message,
      ]
    }
  }
  return undefined
}

export function useCrmLogin() {
  const dispatch = useDispatch()
  const { liff, accessToken } = useLiff()
  const postLogin = useCallback(() => {
    return dispatch(LoginActionCreators.postLogin(accessToken!))
  }, [dispatch, accessToken])
  const {
    token,
    loginRequired,
    registrationRequired,
    expiresAt,
    loginFailed,
    error,
  } = useSelector(loginStatusSelector, shallowEqual)
  const errorMessages = buildCrmErrorMessages(error)

  useEffect(() => {
    if (token) return
    if (loginFailed) {
      console.debug('redirect to LINE login page due to accessToken expiration')
      window.alert('アクセスが集中しております。\n時間をおいてお試しください')
      liff.login() // LINE accessToken の有効期限が切れているのでログイン画面に飛ばす
    } else if (loginRequired) {
      postLogin()
    }
  }, [loginRequired, token, postLogin, liff, loginFailed])

  // CRM token の有効期限切れ3分前に token を更新しにいく
  useEffect(() => {
    if (!token) return
    if (!expiresAt) return
    const refreshPlayMs = 3 * 60 * 1000 // 3分
    const milliseconds = expiresAt.getTime() - getNow() - refreshPlayMs
    // console.debug(
    //   `Refresh CRM token after ${milliseconds}[ms]  ${milliseconds / 1000}[s]`
    // )
    const handler = setTimeout(
      () => {
        postLogin()
      },
      milliseconds >= 0 ? milliseconds : 50 * 60 * 1000
    )
    return () => clearTimeout(handler)
  }, [token, expiresAt, postLogin])

  return { isLoggedIn: !!token, registrationRequired, errorMessages }
}
