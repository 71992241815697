import React, {
  useState,
  PropsWithChildren,
  useEffect,
  useCallback,
} from 'react'
import { useDispatch } from 'react-redux'

import { LoadingPage } from '../components/pages/LoadingPage'
import { LoginActionCreators } from '../modules/login'

function useApiWithRedux(apiHost: string) {
  const dispatch = useDispatch()
  const [initialized, setInitialized] = useState<boolean>(false)
  const saveApiHost = useCallback(
    (apiHost: string) => {
      return dispatch(LoginActionCreators.saveApiHost(apiHost))
    },
    [dispatch]
  )

  useEffect(() => {
    if (apiHost) {
      saveApiHost(apiHost)
      setInitialized(true)
    }
  }, [apiHost, setInitialized, saveApiHost])

  return initialized
}

interface ApiProviderProps {
  apiHost: string
}

export function ApiProvider({
  apiHost,
  children,
}: PropsWithChildren<ApiProviderProps>) {
  const initialized = useApiWithRedux(apiHost)

  if (initialized) {
    return <>{children}</>
  }
  return <LoadingPage status="initializing-api" />
}
