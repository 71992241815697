import React, {
  PropsWithChildren,
  ButtonHTMLAttributes,
  CSSProperties,
} from 'react'
import { Link } from 'react-router-dom'

interface ButtonProps {
  variant: 'black' | 'lightgray' | 'whiteOrange' | 'orange' | 'red'
  style?: CSSProperties
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
  disabled?: boolean
  onClick?: ButtonHTMLAttributes<HTMLButtonElement>['onClick']
}

interface ButtonLinkProps {
  variant: 'black' | 'lightgray' | 'whiteOrange' | 'orange' | 'red'
  style?: CSSProperties
  to: string
}

const variantStyles: {
  [variant: string]: { [name: string]: CSSProperties }
} = {
  base: {
    button: {
      position: 'relative',
      width: '100%',
      height: '48px',
      borderRadius: '8px',
      borderWidth: 0,
    },
    caption: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  black: {
    button: {
      backgroundColor: '#333',
      filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.5))',
    },
    buttonDisabled: {
      backgroundColor: '#777',
    },
    caption: {
      color: '#fff',
    },
    captionDisabled: {
      color: '#aaa',
    },
  },
  lightgray: {
    button: {
      backgroundColor: '#fff',
      filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.4))',
    },
    caption: {
      color: '#5b5b5b',
    },
  },
  whiteOrange: {
    button: {
      borderColor: '#ff9501',
      borderStyle: 'solid',
      borderWidth: 0,
      borderBottomWidth: '4px',
      backgroundColor: '#ffffff',
    },
    caption: {
      color: '#5b5b5b',
    },
  },
  orange: {
    button: {
      borderColor: '#ff6b01',
      borderStyle: 'solid',
      borderWidth: 0,
      borderBottomWidth: '4px',
      backgroundColor: '#ff9501',
    },
    caption: {
      color: '#fff',
    },
  },
  red: {
    button: {
      backgroundColor: '#EA3F0C',
      filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.5))',
    },
    buttonDisabled: {
      backgroundColor: '#777777',
    },
    caption: {
      color: '#fff',
    },
    captionDisabled: {
      color: '#AAAAAA',
    },
  },
}

export function Button({
  variant,
  style,
  type,
  disabled,
  onClick,
  children,
}: PropsWithChildren<ButtonProps>) {
  const baseStyles = variantStyles['base']
  const styles = variantStyles[variant]
  const buttonStyle = {
    ...baseStyles.button,
    ...styles.button,
    ...style,
    ...(disabled && styles.buttonDisabled),
  }
  const captionStyle = {
    ...baseStyles.caption,
    ...styles.caption,
    ...(disabled && styles.captionDisabled),
  }

  return (
    <button
      type={type || 'button'}
      disabled={disabled}
      onClick={onClick}
      style={buttonStyle}
    >
      <div style={captionStyle}>{children}</div>
    </button>
  )
}

export function ButtonLink({
  variant,
  style,
  to,
  children,
}: PropsWithChildren<ButtonLinkProps>) {
  const baseStyles = variantStyles['base']
  const styles = variantStyles[variant]
  const linkStyle: CSSProperties = {
    textDecoration: 'none',
  }
  const buttonStyle = {
    ...baseStyles.button,
    ...styles.button,
    display: 'grid',
    placeItems: 'center',
    ...style,
  }
  const captionStyle = {
    ...baseStyles.caption,
    ...styles.caption,
  }

  return (
    <Link to={to} style={linkStyle}>
      <div style={buttonStyle}>
        <div style={captionStyle}>{children}</div>
      </div>
    </Link>
  )
}
