import {
  filterTradeDetailByType,
  isCancelTradeHistory,
  TradeHistoryRecord,
  TradeHistoryRecordDetailOfCoupon,
} from '../models/trade'
import { numberWithCommas } from './numberWithCommas'
/**
 * 取引内容に応じて値がマイナスに変わる可能性がある値に使用する。
 * 下記のようなケースが該当する
 * ・明細種別: 0(商品) → レシートが取消(返品、売却買取取消など)の場合、マイナス表示する必要がある
 * ・明細種別: 4(割引) → 取引種別が購入、買取の場合はマイナス表示、取消の場合はプラス表示する必要がある
 */
export const discountablePrice = (price: number, isCancel?: boolean) =>
  numberWithCommas(isCancel ? -1 * price : price)

/**
 * レシートの合計金額および、合計金額の表示に必要な一連の値を返す
 */
export const calculateTotalFee = (record: TradeHistoryRecord) => {
  const { tradeDetails } = record
  const isCancel = isCancelTradeHistory(record)
  const tempUsedPoint =
    filterTradeDetailByType(3, tradeDetails).find(
      // paymentTransactionType:0002 はポイント利用額
      (d) => d.paymentTransactionType === '0002'
    )?.otherPayment || 0
  const usedPoint = isCancel ? record.acquisitionPoint : tempUsedPoint

  const couponDetails = filterTradeDetailByType(1, tradeDetails)
  const couponTotalDiscountAmount = reduceCouponDiscountDetails(couponDetails, [
    '98',
  ]).reduce((acum, cur) => acum + cur.couponDiscountAmount, 0)
  const discountTotalAmount = usedPoint + couponTotalDiscountAmount
  const totalFee = record.totalFee - discountTotalAmount
  // TODO: お預かり金額としてどのような計算が必要なのか質問中。
  // 少なくともtransactionNo: 031318に関しては下記の計算では整合性が合わない
  const depositAmount = isCancel
    ? record.depositAmount + discountTotalAmount
    : record.depositAmount - discountTotalAmount

  return {
    discountTotalAmount,
    usedPoint,
    couponTotalDiscountAmount,
    totalFee,
    depositAmount,
    isCancel,
  }
}

/**
 *
 * detail_type:1 のクーポンをクーポンID毎に合計金額をまとめる
 * @param tradeDetails 取引明細一覧
 * @param couponPrefixNos クーポンのcouponNoが97,99始まりなら商品に対して、
 *   98 始まりなら会計に対して適応されるクーポン
 */
export const reduceCouponDiscountDetails = (
  couponDetails: TradeHistoryRecordDetailOfCoupon[],
  couponPrefixNos: Array<'97' | '98' | '99'>
): TradeHistoryRecordDetailOfCoupon[] => {
  return (
    couponDetails
      .filter((detail) => detail.benefitType === 0 || detail.benefitType === 1)
      .filter((detail) =>
        couponPrefixNos.some((no) => detail.couponNo.startsWith(no))
      )
      // クーポンNo毎にcouponDiscountAmountを合算する
      .reduce((acum, cur) => {
        const foundIndex = acum.findIndex(
          (detail) => detail.couponNo === cur.couponNo
        )
        if (foundIndex >= 0) {
          acum[foundIndex] = {
            ...acum[foundIndex],
            couponDiscountAmount:
              acum[foundIndex].couponDiscountAmount + cur.couponDiscountAmount,
          }
        } else {
          acum.push(cur)
        }
        return acum
      }, [] as TradeHistoryRecordDetailOfCoupon[])
  )
}
