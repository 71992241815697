import React, { CSSProperties } from 'react'

import { Panel } from '../atoms/Panel'
import {
  isCancelTradeHistory,
  TradeHistoryRecord,
  TransactionType,
} from '../../models/trade'
import { numberWithCommas } from '../../utils/numberWithCommas'
import { formatDate, formatDateJa } from '../../utils/date'
import { ReceiptBodySummary } from '../molecules/ReceiptBodySummary'
import {
  PointGainDetails,
  ShohinAndDiscountDetails,
} from '../molecules/ReceiptTradeDetails'

function receiptTitle(transactionType: TransactionType) {
  switch (transactionType) {
    case '0000': // 0:購入
      return '購入'
    case '0001': // 1:返品
    case '0100': // 100:売上(一括取消)
      return '返品'
    case '0002': // 2:買取
      return '売却（買取）'
    case '0003': // 3:買取中止
    case '0102': // 102:買取(一括取消) FIXME: こっちは個別の取り消しはないの？？
      return '売却（買取）取消'
    default:
      return '-'
  }
}

function ReceiptTitle({
  transactionType,
}: {
  transactionType: TransactionType
}) {
  const style: CSSProperties = {
    padding: '24px',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '24px',
    display: 'grid',
    placeItems: 'center',
    color: '#5B5B5B',
  }
  const title = receiptTitle(transactionType)

  // 伝票取り消し
  return <div style={style}>{title}</div>
}

function ReceiptHeader({ record }: { record: TradeHistoryRecord }) {
  const styles: { [name: string]: CSSProperties } = {
    container: {
      fontSize: '12px',
      lineHeight: '18px',
      paddingBottom: '16px',
      borderBottom: '1px #5B5B5B dashed',
      color: '#282828',
    },
    row: {
      paddingBottom: '8px',
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      justifyContent: 'space-between',
    },
    deleteOriginContainer: {
      paddingBottom: '16px',
      borderBottom: '1px #5B5B5B dashed',
      marginBottom: '24px',
    },
    registerName: {
      paddingRight: '16px',
    },
  }

  const isCancel = isCancelTradeHistory(record)
  const storeInfo = (
    <div style={styles.row}>
      <div>{record.storeName}</div>
      <div>Tel {record.telNumber}</div>
    </div>
  )
  const deleteRegisterOrigin = (
    <div style={styles.deleteOriginContainer}>
      <div style={styles.row}>
        <div>（取消元レシート）{record.registerName}</div>
        <div>No. {record.transactionNo}</div>
      </div>
    </div>
  )
  const registerAndStaff = (
    <div style={styles.row}>
      <div>
        <span style={styles.registerName}>{record.registerName}</span>担
        {record.staffCode}
      </div>
      <div>No. {record.transactionNo}</div>
    </div>
  )

  return (
    <div style={styles.container}>
      {!isCancel && storeInfo}
      {isCancel && deleteRegisterOrigin}
      {registerAndStaff}
    </div>
  )
}

function ReceiptBodyList({ record }: { record: TradeHistoryRecord }) {
  const { tradeDetails } = record
  const isCancel = isCancelTradeHistory(record)
  return (
    <ShohinAndDiscountDetails tradeDetails={tradeDetails} isCancel={isCancel} />
  )
}

function ReceiptBody({ record }: { record: TradeHistoryRecord }) {
  const styles: { [name: string]: CSSProperties } = {
    container: {
      paddingTop: '24px',
    },
  }

  return (
    <div style={styles.container}>
      <ReceiptBodyList record={record} />
      <ReceiptBodySummary record={record} />
    </div>
  )
}

function ReceiptFooter({ record }: { record: TradeHistoryRecord }) {
  const styles: { [name: string]: CSSProperties } = {
    container: {
      paddingTop: '24px',
      fontSize: '12px',
      lineHeight: '18px',
      display: 'grid',
      gridTemplateRows: 'auto',
      paddingBottom: '24px',
      borderBottom: '1px #5B5B5B dashed',
    },
    storeRow: {
      paddingBottom: '24px',
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      justifyContent: 'space-between',
    },
    pointRow: {
      paddingBottom: '8px',
      display: 'grid',
      gridTemplateColumns: 'auto 77px 64px',
    },
    pointLabel: {
      justifySelf: 'start',
      textAlign: 'start',
    },
    pointValue: {
      justifySelf: 'end',
      textAlign: 'end',
    },
    pointPadding: {
      justifySelf: 'start',
      textAlign: 'start',
    },
    row: {
      paddingBottom: '8px',
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      justifyContent: 'space-between',
    },
    lastRow: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      justifyContent: 'space-between',
    },
  }

  const { tradeDetails } = record

  return (
    <div style={styles.container}>
      <div style={styles.row}>
        <div>店舗No.{record.storeCode}</div>
        <div>
          {formatDateJa(record.systemDate)} {record.systemTime.substr(0, 5)}
        </div>
      </div>
      <div style={styles.row}>
        <div>カード番号 {record.cardNo}</div>
      </div>
      <div style={styles.pointRow}>
        <div style={styles.pointLabel}>今回ポイント</div>
        <div style={styles.pointValue}>
          {numberWithCommas(record.acquisitionPoint)}
        </div>
        <div style={styles.pointPadding} />
      </div>
      <div style={styles.pointRow}>
        <div style={styles.pointLabel}>内訳</div>
        <div style={styles.pointPadding} />
      </div>
      <PointGainDetails tradeDetails={tradeDetails} />

      <div style={styles.pointRow}>
        <div style={styles.pointLabel}>現在ポイント</div>
        <div style={styles.pointValue}>
          {numberWithCommas(record.currentPoint)}
        </div>
        <div style={styles.pointPadding} />
      </div>

      <div style={styles.lastRow}>
        <div>有効期限　{formatDate(record.pointExpireDate)}</div>
        <div />
      </div>
    </div>
  )
}

export function Receipt({ record }: { record: TradeHistoryRecord }) {
  const style: CSSProperties = {
    backgroundColor: '#FFFFFF',
  }

  return (
    <Panel style={style}>
      <ReceiptTitle transactionType={record.transactionType} />
      <ReceiptHeader record={record} />
      <ReceiptBody record={record} />
      <ReceiptFooter record={record} />
    </Panel>
  )
}
