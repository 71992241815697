import React, { CSSProperties, PropsWithChildren } from 'react'
import { Button } from '../atoms/Button'

const styles: { [name: string]: CSSProperties } = {
  shadow: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 100,
  },
  header: {
    display: 'flex',
    marginBottom: '16px',
  },
  title: {
    margin: 'auto 8px',
    fontSize: '1rem',
    fontWeight: 700,
  },
  contents: {
    position: 'absolute',
    backgroundColor: 'white',
    height: 'fit-content',
    margin: '32px 16px',
    padding: '16px',
    borderRadius: '8px',
    bottom: 0,
    left: 0,
    right: 0,
  },
  button: {
    marginTop: '1rem',
    filter: 'none',
  },
}

interface ModalProps {
  isOpen: boolean
  title: string
  actionButtonTitle?: string
  onClose: () => void
  onActionButtonClick?: () => void
}

export function Modal({
  isOpen,
  title,
  actionButtonTitle,
  onClose,
  onActionButtonClick,
  children,
}: PropsWithChildren<ModalProps>) {
  return isOpen ? (
    <div style={styles.shadow}>
      <div style={styles.contents}>
        <div style={styles.header}>
          <div style={styles.title}>{title}</div>
        </div>
        {children}
        {actionButtonTitle != null && (
          <Button
            style={styles.button}
            variant="red"
            onClick={onActionButtonClick}
          >
            {actionButtonTitle}
          </Button>
        )}
        <Button style={styles.button} variant="black" onClick={onClose}>
          閉じる
        </Button>
      </div>
    </div>
  ) : (
    <></>
  )
}
