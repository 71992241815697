import React, { CSSProperties, PropsWithChildren } from 'react'

interface TitleProps {
  text?: string | string[]
}

const styles: { [name: string]: CSSProperties } = {
  grid: {
    height: '64px',
    backgroundColor: '#ff9501',
    display: 'grid',
    placeItems: 'center',
  },
  title: {
    color: '#fff',
    fontSize: '16px',
    // lineHeight: '24px',
    fontWeight: 'bold',
    display: 'grid',
    placeItems: 'center',
  },
}

export function Title({ text, children }: PropsWithChildren<TitleProps>) {
  return (
    <div style={styles.grid}>
      <div style={styles.title}>
        {text
          ? Array.isArray(text)
            ? text.map((t, i) => {
                return <span key={i}>{t}</span>
              })
            : text
          : children}
      </div>
    </div>
  )
}
