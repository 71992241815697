import React, { CSSProperties, useCallback } from 'react'

import {
  RoundedListItemHeader,
  RoundedListItem,
  RoundedListItemFooter,
} from './RoundedList'
import { Panel } from '../atoms/Panel'
import { Checkbox } from './Checkbox'
import { Coupon, isUnavailableYet } from '../../models/coupon'
import { CloseIcon } from '../atoms/icons/CloseIcon'
import { formatDate } from '../../utils/date'

const styles: { [name: string]: CSSProperties } = {
  header: {
    backgroundColor: '#CCCCCC',
    display: 'grid',
    placeItems: 'center',
  },
  image: {
    // 343x126
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    width: '100%',
  },
  noImage: {
    width: '100%',
    height: '100px',
    display: 'grid',
    placeItems: 'center',
  },
  couponName: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '16px',
    paddingTop: '13px',
    paddingLeft: '16px',
    paddingRight: '16px',
    display: 'grid',
    gridTemplateColumns: 'auto',
    justifyContent: 'start',
  },
  description: {
    whiteSpace: 'break-spaces',
    fontSize: '12px',
    lineHeight: '14px',
    paddingTop: '13px',
    paddingLeft: '16px',
    paddingRight: '16px',
    display: 'grid',
    gridTemplateColumns: 'auto',
    justifyContent: 'start',
  },
  usage: {
    paddingTop: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: '16px',
    display: 'grid',
    gridTemplateColumns: 'auto 1px auto',
    justifyContent: 'space-between',
    alignContent: 'center',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  expiration: {},
  unavailableYet: {
    color: '#EA3F0C',
  },
  count: {},
  check: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}

const emptyCouponImageUrl = '/images/coupon-empty@3x.png'

function UnavailableYetCouponButton() {
  const style: CSSProperties = {
    minHeight: '51px',
    display: 'grid',
    gridTemplateColumns: '32px 1fr',
    alignItems: 'center',
  }

  return (
    <div style={style}>
      <CloseIcon />
      <span>このクーポンはまだ利用できません</span>
    </div>
  )
}

function DisabledCouponButton() {
  const style: CSSProperties = {
    minHeight: '51px',
    display: 'grid',
    gridTemplateColumns: '32px 1fr',
    alignItems: 'center',
  }

  return (
    <div style={style}>
      <CloseIcon />
      <span>使用済みクーポン</span>
    </div>
  )
}

function DisabledLayoer() {
  const style: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  }

  return <div style={style} />
}

interface CouponListItemProps {
  coupon: Coupon
  selected: boolean
  dateStr: string
  onToggleSelect: (coupon: Coupon) => void
}

export function CouponListItem({
  coupon,
  selected,
  dateStr,
  onToggleSelect,
}: CouponListItemProps) {
  const handleClick = useCallback(() => {
    onToggleSelect(coupon)
  }, [coupon, onToggleSelect])
  const unavailableYet = isUnavailableYet(coupon, dateStr)

  return (
    <Panel style={{ marginTop: '8px', marginBottom: '8px' }}>
      <RoundedListItemHeader style={styles.header}>
        <img
          alt={coupon.couponName}
          style={styles.image}
          src={coupon.imageUrl || emptyCouponImageUrl}
        />
      </RoundedListItemHeader>
      <RoundedListItem>
        <div style={styles.couponName}>{coupon.couponName}</div>
        <div style={styles.description}>{coupon.description}</div>
        <div style={styles.usage}>
          <div style={styles.expiration}>
            {unavailableYet ? (
              <div style={styles.unavailableYet}>
                {formatDate(coupon.couponStartDate)} から有効
              </div>
            ) : (
              <div>{formatDate(coupon.couponExpireDate)} まで有効</div>
            )}
          </div>
          <div>|</div>
          <div style={styles.count}>
            {coupon.maxUseCount === 0 ? (
              <div>期間中 何度でも利用可</div>
            ) : (
              <div>{coupon.maxUseCount}回まで利用可</div>
            )}
          </div>
        </div>
      </RoundedListItem>
      <RoundedListItemFooter>
        <div style={styles.check}>
          {unavailableYet ? (
            <UnavailableYetCouponButton />
          ) : coupon.canUse ? (
            <Checkbox
              name={coupon.couponCode}
              value={coupon.couponCode}
              checked={selected}
              onChange={handleClick}
            >
              このクーポンを選択
            </Checkbox>
          ) : (
            <DisabledCouponButton />
          )}
        </div>
      </RoundedListItemFooter>
      {!coupon.canUse && <DisabledLayoer />}
    </Panel>
  )
}
