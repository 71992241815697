import { fork, call, put, select, takeEvery } from 'redux-saga/effects'
import { History } from 'history'

import {
  LoginActionTypes,
  LoginActionCreators,
  PostLoginAction,
  LinkLineAccountAction,
  apiHostSelector,
  UnlinkLineAccountAction,
  tokenSelector,
} from '../modules/login'
import { ResetActionCreators } from '../modules/reset'
import { login, linkLineAccount, unlinkLineAccount } from '../utils/api'
import { ErrorResponse } from '../models/error'

export function* postLoginSaga(
  action: PostLoginAction
): Generator<any, void, any> {
  try {
    const linetoken = action.payload.linetoken
    const apiHost = yield select(apiHostSelector)
    yield put(LoginActionCreators.postLoginRequest())
    const data = yield call(login, apiHost, linetoken)
    if (data.status === 100) {
      yield put(LoginActionCreators.postLoginRegistrationRequiredSuccess(data))
    } else {
      yield put(LoginActionCreators.postLoginSuccess(data))
    }
  } catch (error: unknown) {
    yield put(LoginActionCreators.postLoginFailure(error as ErrorResponse))
  }
}

export function* linkLineAccountSaga(
  action: LinkLineAccountAction
): Generator<any, void, any> {
  try {
    const linetoken = action.payload.linetoken
    const apiHost = yield select(apiHostSelector)
    yield put(LoginActionCreators.linkLineAccountRequest())
    yield call(linkLineAccount, apiHost, linetoken)
    yield put(LoginActionCreators.linkLineAccountSuccess())
  } catch (error) {
    yield put(LoginActionCreators.linkLineAccountFailure(error))
  }
}

export function* unlinkLineAccountSaga(
  history: History,
  action: UnlinkLineAccountAction
): Generator<any, void, any> {
  try {
    const apiHost = yield select(apiHostSelector)
    const token = yield select(tokenSelector)
    yield put(LoginActionCreators.unlinkLineAccountRequest())
    yield call(unlinkLineAccount, apiHost, token)
    yield put(LoginActionCreators.unlinkLineAccountSuccess())
    yield put(ResetActionCreators.resetStoreAction())
    yield call(history.push, '/')
  } catch (error) {
    yield put(LoginActionCreators.unlinkLineAccountFailure(error))
  }
}

function* handlePostLoginSaga() {
  yield takeEvery(LoginActionTypes.POST_LOGIN, postLoginSaga)
}

function* handleLinkLineAccountSaga() {
  yield takeEvery(LoginActionTypes.LINK_LINE_ACCOUNT, linkLineAccountSaga)
}

function* handleUnlinkLineAccountSaga(history: History) {
  yield takeEvery(
    LoginActionTypes.UNLINK_LINE_ACCOUNT,
    unlinkLineAccountSaga,
    history
  )
}

export function* loginSagas(context: { history: History }) {
  yield fork(handlePostLoginSaga)
  yield fork(handleLinkLineAccountSaga)
  yield fork(handleUnlinkLineAccountSaga, context.history)
}
