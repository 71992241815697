import React, { CSSProperties, useCallback } from 'react'

import {
  RoundedListItemHeader,
  RoundedListItem,
  RoundedListItemFooter,
} from './RoundedList'
import { Panel } from '../atoms/Panel'
import { Reservation } from '../../models/reservation'
import { BarcodeIcon } from '../atoms/icons/BarcodeIcon'
import { formatDateWithDayOfWeek } from '../../utils/date'
import { numberWithCommas } from '../../utils/numberWithCommas'

interface ReservationStatusProps {
  statusText: string // '予約中' | '店舗取置中'
}

function ReservationStatus({ statusText }: ReservationStatusProps) {
  const styles: { [name: string]: CSSProperties } = {
    container: {},
    badge: {
      paddingTop: '5px',
      paddingBottom: '5px',
      color: '#FFFFFF',
      backgroundColor:
        statusText === '予約中'
          ? '#FF6B01'
          : statusText === '店舗取置中'
          ? '#CF1414'
          : '#777777',
      borderRadius: '4px',
      display: 'grid',
      placeItems: 'center',
    },
    text: {
      fontSize: '10px',
      fontWeight: 'bold',
    },
  }

  return (
    <div style={styles.container}>
      <div style={styles.badge}>
        <div style={styles.text}>{statusText}</div>
      </div>
    </div>
  )
}

interface ReservationListItemHeaderProps {
  reservation: Reservation
}

function ReservationListItemHeader({
  reservation,
}: ReservationListItemHeaderProps) {
  const styles: { [name: string]: CSSProperties } = {
    header: {
      fontWeight: 'bold',
      paddingTop: '12px',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '12px',
      backgroundColor: '#FFFFFF',
      display: 'grid',
      gridTemplateColumns: '30px auto 64px',
      alignContent: 'center',
    },
    headerLabel: {
      fontSize: '10px',
      lineHeight: '15px',
      display: 'flex',
      alignItems: 'center',
      color: '#5B5B5B',
    },
    headerName: {
      color: '#282828',
      fontSize: '12px',
      paddingLeft: '16px',
      paddingRight: '16px',
      display: 'flex',
      alignItems: 'center',
    },
  }

  return (
    <RoundedListItemHeader style={styles.header}>
      <div style={styles.headerLabel}>商品名</div>
      <div style={styles.headerName}>{reservation.productName}</div>
      <ReservationStatus statusText={reservation.reserveStatus} />
    </RoundedListItemHeader>
  )
}

interface ReservationListItemDetailProps {
  reservation: Reservation
}

function ReservationListItemDetail({
  reservation,
}: ReservationListItemDetailProps) {
  const styles: { [name: string]: CSSProperties } = {
    rows: {
      fontWeight: 'bold',
      display: 'grid',
    },
    row: {
      paddingTop: '4px',
      paddingBottom: '4px',
      display: 'grid',
      gridTemplateColumns: '80px auto',
      alignContent: 'end',
    },
    label: {
      color: '#5B5B5B',
      fontSize: '10px',
      paddingLeft: '12px',
    },
    value: {
      fontSize: '12px',
      color: '#282828',
    },
    janValue: {
      fontSize: '10px',
      color: '#282828',
    },
    priceRow: {
      color: '#5B5B5B',
      display: 'flex',
      justifyContent: 'flex-start',
      fontWeight: 'bold',
    },
    priceListPrice: {
      fontSize: '10px',
      paddingTop: '4px',
      paddingBottom: '4px',
      paddingLeft: '12px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: '#5B5B5B',
    },
    priceListPriceValue: {
      fontSize: '12px',
      paddingLeft: '0.5em',
    },
    priceListPriceUnit: {
      paddingLeft: '0.5em',
    },
    priceReservationPrice: {
      paddingLeft: '16px',
      fontSize: '10px',
      fontWeight: 'bold',
    },
    priceReservationPriceValue: {
      // TODO: 定価を表示する際は下記コメントアウト部分を復活させる
      // paddingLeft: '0.5em',
      fontSize: '18px',
      color: '#CF1414',
    },
    priceReservationPriceUnit: {
      paddingLeft: '0.5em',
    },
  }
  // TODO: 定価を表示する際は下記コメントアウト部分を復活させる
  // const formattedListPrice = numberWithCommas(reservation.regularPrice) // 定価（税込）
  const formattedReservationPrice = numberWithCommas(reservation.unitPrice) // 予約価格（税込）
  const grandTotalPrice = numberWithCommas(reservation.detailTotalFee) // 合計金額（税込）

  return (
    <RoundedListItem paddingTop="12px" paddingBottom="12px">
      <div style={styles.rows}>
        <div style={styles.row}>
          <div style={styles.label}>JAN</div>
          <div style={styles.janValue}>{reservation.productCode}</div>
        </div>
        <div style={styles.row}>
          <div style={styles.label}>予約価格</div>
          <div style={styles.janValue}>
            <span style={styles.priceReservationPriceValue}>
              {formattedReservationPrice}
            </span>
            <span style={styles.priceReservationPriceUnit}>円(税込)</span>
          </div>
        </div>
        {/* TODO: 定価を表示する際は下記コメントアウト部分を復活させる */}
        {/* <div style={styles.priceRow}>
          <div style={styles.priceListPrice}>
            <span>定価</span>
            <span style={styles.priceListPriceValue}>{formattedListPrice}</span>
            <span style={styles.priceListPriceUnit}>円(税込)</span>
          </div>
          <div style={styles.priceReservationPrice}>
            <span>予約価格</span>
            <span style={styles.priceReservationPriceValue}>
              {formattedReservationPrice}
            </span>
            <span style={styles.priceReservationPriceUnit}>円(税込)</span>
          </div>
        </div> */}
        <div style={styles.row}>
          <div style={styles.label}>数量</div>
          <div style={styles.value}>{reservation.quantity}</div>
        </div>
        <div style={styles.row}>
          <div style={styles.label}>合計金額</div>
          <div style={styles.value}>{`${grandTotalPrice} 円(税込)`}</div>
        </div>
      </div>
    </RoundedListItem>
  )
}

interface ReservationListItemReservationInfoProps {
  reservation: Reservation
}

function ReservationListItemReservationInfo({
  reservation,
}: ReservationListItemReservationInfoProps) {
  const styles: { [name: string]: CSSProperties } = {
    rows: {
      display: 'grid',
    },
    row: {
      fontWeight: 'bold',
      paddingTop: '4px',
      paddingBottom: '4px',
      display: 'grid',
      gridTemplateColumns: '80px auto',
    },
    label: {
      color: '#5B5B5B',
      fontSize: '10px',
      paddingLeft: '12px',
    },
    value: {
      color: '#282828',
      fontSize: '12px',
    },
    comment: {
      fontSize: '10px',
      color: '#5B5B5B',
      paddingLeft: '12px',
      paddingBottom: '4px',
    },
  }
  const formattedReleaseDate = formatDateWithDayOfWeek(reservation.releaseDate)
  const formattedLayawayDate = formatDateWithDayOfWeek(reservation.layawayDate)

  return (
    <RoundedListItem paddingTop="12px" paddingBottom="12px">
      <div style={styles.rows}>
        <div style={styles.row}>
          <div style={styles.label}>ご予約店舗</div>
          <div style={styles.value}>{reservation.storeName}</div>
        </div>
        <div style={styles.row}>
          <div style={styles.label}>発売予定日</div>
          <div style={styles.value}>{formattedReleaseDate}</div>
        </div>
        <div style={styles.comment}>※予約後に発売日が変わることがあります</div>
        <div style={styles.row}>
          <div style={styles.label}>引取期限</div>
          <div style={styles.value}>{formattedLayawayDate}</div>
        </div>
      </div>
    </RoundedListItem>
  )
}

interface ReservationListItemFooterProps {
  reservation: Reservation
  onClick: (reservation: Reservation) => void
}

function ReservationListItemFooter({
  reservation,
  onClick,
}: ReservationListItemFooterProps) {
  const handleClick = useCallback(() => {
    console.debug('onClick: ', reservation)
    onClick(reservation)
  }, [reservation, onClick])
  const styles: { [name: string]: CSSProperties } = {
    button: {
      width: '100%',
      paddingTop: '4px',
      paddingBottom: '4px',
    },
    container: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      alignItems: 'center',
    },
    icon: {
      paddingTop: '4px',
      paddingRight: '4px',
      justifySelf: 'right',
    },
    caption: {
      fontSize: '14px',
      color: '#FFFFFF',
      justifySelf: 'left',
    },
  }

  return (
    <RoundedListItemFooter backgroundColor="#FF9000">
      <button style={styles.button} onClick={handleClick}>
        <div style={styles.container}>
          <div style={styles.icon}>
            <BarcodeIcon />
          </div>
          <div style={styles.caption}>予約コード表示</div>
        </div>
      </button>
    </RoundedListItemFooter>
  )
}

interface ReservationListItemProps {
  reservation: Reservation
  dateStr: string
  onClick: (reservation: Reservation) => void
}

export function ReservationListItem({
  reservation,
  dateStr,
  onClick,
}: ReservationListItemProps) {
  return (
    <Panel style={{ marginTop: '8px', marginBottom: '8px' }}>
      <ReservationListItemHeader reservation={reservation} />
      <ReservationListItemDetail reservation={reservation} />
      <ReservationListItemReservationInfo reservation={reservation} />
      <ReservationListItemFooter reservation={reservation} onClick={onClick} />
    </Panel>
  )
}
