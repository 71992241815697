import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import {
  MembershipAgreementPage,
  OtherItemMembershipAgreementPage,
} from './components/pages/MembershipAgreementPage'
import {
  PrivacyPolicyPage,
  OtherItemPrivacyPolicyPage,
} from './components/pages/PrivacyPolicyPage'
import { SignUpPage } from './components/pages/SignUpPage'
import { UnlinkLineAccountPage } from './components/pages/UnlinkLineAccountPage'
import { PointCardPage } from './components/pages/PointCardPage'
import { AboutPointCardPage } from './components/pages/AboutPointCardPage'
import { CardInfoPage } from './components/pages/CardInfoPage'
import { CardInfoProfileEditPage } from './components/pages/CardInfoProfileEditPage'
import { AnnouncementsPage } from './components/pages/AnnouncementsPage'
import { TradeHistoryPage } from './components/pages/TradeHistoryPage'
import { PointHistoryPage } from './components/pages/PointHistoryPage'
import { CouponsPage } from './components/pages/CouponsPage'
import { ReservationsPage } from './components/pages/ReservationsPage'
import { OtherItemsPage } from './components/pages/OtherItemsPage'

export function PrivateRoutes() {
  return (
    <>
      <Switch>
        <Route exact path="/account/membership-agreement">
          <MembershipAgreementPage />
        </Route>
        <Route exact path="/account/privacy-policy">
          <PrivacyPolicyPage />
        </Route>
        <Route exact path="/account/sign-up">
          <SignUpPage />
        </Route>
        <Route exact path="/account/unlink">
          <UnlinkLineAccountPage />
        </Route>
        <Route exact path="/pointcard">
          <PointCardPage />
        </Route>
        <Route exact path="/about/pointcard">
          <AboutPointCardPage />
        </Route>
        <Route exact path="/cardinfo">
          <CardInfoPage />
        </Route>
        <Route exact path="/cardinfo/profile/edit">
          <CardInfoProfileEditPage />
        </Route>
        <Route exact path="/announcements">
          <AnnouncementsPage />
        </Route>
        <Route exact path="/trade/history">
          <TradeHistoryPage />
        </Route>
        <Route exact path="/point/history">
          <PointHistoryPage />
        </Route>
        <Route exact path="/coupons">
          <CouponsPage />
        </Route>
        <Route exact path="/reservations">
          <ReservationsPage />
        </Route>
        <Route exact path="/others">
          <OtherItemsPage />
        </Route>
        <Route exact path="/others/membership-agreement">
          <OtherItemMembershipAgreementPage />
        </Route>
        <Route exact path="/others/privacy-policy">
          <OtherItemPrivacyPolicyPage />
        </Route>
        <Route>
          <Redirect to="/pointcard" />
        </Route>
      </Switch>
    </>
  )
}
