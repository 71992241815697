import React from 'react'

import { ComponentPropsWithoutRef, CSSProperties, FC } from 'react'
import { Modal } from '../molecules/Modal'
import { OpenLink } from '../molecules/OpenLink'

const styles: { [name: string]: CSSProperties } = {
  img: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '16px',
  },
  subTitle: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 600,
    paddingBottom: '16px',
  },
  mainText: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 'lighter',
    paddingBottom: '12px',
  },
  subText: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 'lighter',
  },
}

const minimalVersion: string = '13.20.0'

export interface HomeShortcutModalProps
  extends ComponentPropsWithoutRef<'div'> {
  showShortcutModal: boolean
  isShortcutAvailable: boolean
  closeShortcutModal: () => void
  onHomeShortcutClick: () => void
  onLinkClick: () => void
}

export const HomeShortcutModal: FC<HomeShortcutModalProps> = (props) => {
  const {
    showShortcutModal,
    isShortcutAvailable,
    closeShortcutModal,
    onHomeShortcutClick,
    onLinkClick,
  } = props

  return (
    <Modal
      isOpen={showShortcutModal}
      title="ホーム画面にショートカットを追加する"
      actionButtonTitle={isShortcutAvailable ? '追加設定へ進む' : undefined}
      onClose={closeShortcutModal}
      onActionButtonClick={onHomeShortcutClick}
    >
      <div data-test="shortcutModalText">
        <div style={styles.mainText}>
          スマートフォンのホーム画面にショートカットを追加することで、会員証をすばやく開くことが出来ます。
        </div>
        <div style={styles.img}>
          <img
            alt="ショートカットを追加"
            src="/images/home-shortcut.png"
            width="226"
            height="192"
          />
        </div>
        {isShortcutAvailable ? (
          <>
            <div style={styles.subTitle}>iPhone をお使いのお客様</div>
            <div style={styles.subText}>
              iPhone の場合、ショートカット追加はデフォルトブラウザを Safari
              にしておく必要がございます。
            </div>
          </>
        ) : (
          <div data-test="notYetLineApplicationText">
            <div style={styles.subTitle}>
              この機能は LINE バージョンが {minimalVersion} 以上で有効です。
            </div>
            <div style={styles.mainText}>
              お手持ちの LINE を {minimalVersion} 以上に
              バージョンアップしてからお使いください。
            </div>
            <OpenLink onClick={onLinkClick} label="最新版にアップデート" />
          </div>
        )}
      </div>
    </Modal>
  )
}
