import { useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import {
  ReservationActionCreators,
  reservationMetaSelector,
  reservationsSelector,
} from '../modules/reservation'
import { ReservationsSearchOptions } from '../models/reservation'
import { daysToMs, formatSearchOptionDate, getDate } from '../utils/date'

export function useReservations() {
  const dispatch = useDispatch()
  const fetchReservations = useCallback(
    (searchOptions: ReservationsSearchOptions) => {
      const from = formatSearchOptionDate(getDate())
      const to = formatSearchOptionDate(getDate(daysToMs(7)))
      console.debug('FIXME: specify fetchReservations: from, to:', from, to)
      return dispatch(
        ReservationActionCreators.fetchReservationsAction(searchOptions)
      )
    },
    [dispatch]
  )
  const reservationMeta = useSelector(reservationMetaSelector, shallowEqual)
  const reservations = useSelector(reservationsSelector, shallowEqual)

  return {
    reservationMeta,
    reservations,
    fetchReservations,
  }
}
