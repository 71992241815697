import React, {
  PropsWithChildren,
  ButtonHTMLAttributes,
  CSSProperties,
} from 'react'

interface ButtonProps {
  style?: CSSProperties
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
  disabled?: boolean
  onClick?: ButtonHTMLAttributes<HTMLButtonElement>['onClick']
}

const variantStyles: {
  [variant: string]: { [name: string]: CSSProperties }
} = {
  base: {
    button: {
      position: 'relative',
      width: '100%',
      height: '64px',
      borderWidth: 0,
    },
    caption: {
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '24px',
    },
  },
  orange: {
    button: {
      backgroundColor: '#FF9501',
    },
    buttonDisabled: {
      backgroundColor: '#D8D7D7',
    },
    caption: {
      color: '#fff',
    },
    captionDisabled: {
      color: '#fff',
    },
  },
}

export function FullWidthButton({
  style,
  type,
  disabled,
  onClick,
  children,
}: PropsWithChildren<ButtonProps>) {
  const baseStyles = variantStyles['base']
  const styles = variantStyles['orange']
  const buttonStyle = {
    ...baseStyles.button,
    ...styles.button,
    ...style,
    ...(disabled && styles.buttonDisabled),
  }
  const captionStyle = {
    ...baseStyles.caption,
    ...styles.caption,
    ...(disabled && styles.captionDisabled),
  }

  return (
    <button
      type={type || 'button'}
      disabled={disabled}
      onClick={onClick}
      style={buttonStyle}
    >
      <div style={captionStyle}>{children}</div>
    </button>
  )
}
