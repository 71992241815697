interface ReservationsRawResponseRecord {
  id: number // ID
  transaction_no: string // 取引NO
  store_code: string // 店舗番号
  store_name: string // 店舗名
  tel_number: string // 店舗電話番号
  reserve_date: string // 予約日付
  reserve_time: string // 予約時刻
  card_no: string // カード番号
  serial_number: number // 連番
  reserve_status: string // 予約ステータス
  reserve_no: string // 予約申込コード
  product_code: string // 商品番号（JANコード）
  product_name: string // 商品名
  regular_price: number // 定価（税込）
  unit_price: number // 予約価格（税込）
  quantity: number // 数量
  detail_total_fee: number // 合計金額（税込）
  layaway_date: string // 取置日時
  release_date: string // 発売予定
}

export interface ReservationsRawResponse {
  status: 0 | 100 // 正常終了=0, トークン有効期限切れ=200
  data_count: number
  per_page: number
  page_no: number
  page_count: number
  histories: ReservationsRawResponseRecord[]
}

export interface ReservationMeta {
  dataCount: number
  perPage: number
  pageNo: number
  pageCount: number
}

export interface Reservation {
  id: number // ID
  transactionNo: string // 取引NO
  storeCode: string // 店舗番号
  storeName: string // 店舗名
  telNumber: string // 店舗電話番号
  reserveDate: string // 予約日付
  reserveTime: string // 予約時刻
  cardNo: string // カード番号
  serialNumber: number // 連番
  reserveStatus: string // 予約ステータス
  reserveNo: string // 予約申込コード
  productCode: string // 商品番号（JANコード）
  productName: string // 商品名
  regularPrice: number // 定価（税込）
  unitPrice: number // 予約価格（税込）
  quantity: number // 数量
  detailTotalFee: number // 合計金額（税込）
  layawayDate: string // 取置日時
  releaseDate: string // 発売予定
}

export interface ReservationsResponse {
  status: 0 | 100 // 正常終了=0, トークン有効期限切れ=200
  meta: ReservationMeta
  reservations: Reservation[]
}

export interface ReservationsSearchOptions {
  count?: number // ページ当たりの表示件数 未指定の場合全件取得
  page?: number // ページ番号 未指定の場合1
  sort?: number // // ソート(0:日付で降順 1:日付で昇順) 未指定の場合降順
  from?: string // 取得期間開始日(YYYY-MM-DD) 未指定の場合絞らない
  to?: string // 取得期間終了日(YYYY-MM-DD) 未指定の場合絞らない
}

export function parseReservationsSearchOptions(
  options: ReservationsSearchOptions
) {
  return {
    count: String(options.count || 100),
    page: String(options.page || 1),
    sort: String(options.sort || 0), // 0:日付で降順 1:日付で昇順
    ...(options.from ? { from: String(options.from) } : undefined),
    ...(options.to ? { to: String(options.to) } : undefined),
  }
}

export function fromReservationsRawResponse(
  rawResponse: ReservationsRawResponse
): ReservationsResponse {
  const status = rawResponse.status
  const meta = {
    dataCount: rawResponse.data_count,
    perPage: rawResponse.per_page,
    pageNo: rawResponse.page_no,
    pageCount: rawResponse.page_count,
  }
  const reservations = rawResponse.histories.map((record) => {
    const reservation: Reservation = {
      id: record.id,
      transactionNo: record.transaction_no,
      storeCode: record.store_code,
      storeName: record.store_name,
      telNumber: record.tel_number,
      reserveDate: record.reserve_date,
      reserveTime: record.reserve_time,
      cardNo: record.card_no,
      serialNumber: record.serial_number,
      reserveStatus: record.reserve_status,
      reserveNo: record.reserve_no,
      productCode: record.product_code,
      productName: record.product_name,
      regularPrice: record.regular_price,
      unitPrice: record.unit_price,
      quantity: record.quantity,
      detailTotalFee: record.detail_total_fee,
      layawayDate: record.layaway_date,
      releaseDate: record.release_date,
    }
    return reservation
  })

  return { status, meta, reservations }
}
