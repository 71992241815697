import React from 'react'

import { BackNavLinkPanel } from '../organisms/BackNavPanel'
import { DefaultTemplate } from '../templates/DefaultTemplate'
import { PrivacyPolicyPanel } from '../organisms/PrivacyPolicyPanel'
import { Padding } from '../atoms/Padding'

interface PrivacyPolicyPagePresenterProps {
  backTo: string
}

export function PrivacyPolicyPagePresenter({
  backTo,
}: PrivacyPolicyPagePresenterProps) {
  const Main = (
    <>
      <BackNavLinkPanel label="戻る" to={backTo} />
      <Padding bottom="40px">
        <PrivacyPolicyPanel />
      </Padding>
    </>
  )

  return (
    <DefaultTemplate title="個人情報保護方針" main={Main} autoScrollToTop />
  )
}

export function PrivacyPolicyPage() {
  return <PrivacyPolicyPagePresenter backTo="/" />
}

export function OtherItemPrivacyPolicyPage() {
  return <PrivacyPolicyPagePresenter backTo="/others" />
}
