import React from 'react'

interface BellIconProps {
  badgeCount?: number | undefined
}

export function BellIcon({ badgeCount }: BellIconProps) {
  return (
    <svg
      width="48"
      height="32"
      viewBox="0 0 48 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="ico_bell_1digits"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M19.7061,25.333 C20.2051,27.247 21.9301,28.667 24.0001,28.667 C26.0701,28.667 27.7951,27.247 28.2941,25.333"
          id="Stroke-2"
          stroke="#FF9501"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M34.3164,19.0742 L34.3164,19.0742 C33.8784,18.4232 33.4384,17.6822 33.1834,17.1462 C32.8064,16.3522 32.2534,14.6382 31.9534,13.3382 L30.8174,8.4092 C30.5174,7.1082 29.2844,5.5772 28.0774,5.0062 C28.0774,5.0062 25.4984,3.5722 25.2904,3.2112 C25.0814,2.8492 24.7064,2.5532 24.4554,2.5532 L23.5454,2.5532 C23.2944,2.5532 22.9194,2.8492 22.7104,3.2112 C22.5024,3.5722 19.9224,5.0062 19.9224,5.0062 C18.7154,5.5772 17.4824,7.1082 17.1824,8.4092 L16.0464,13.3382 C15.7474,14.6382 15.1934,16.3522 14.8164,17.1462 C14.5614,17.6822 14.1224,18.4232 13.6834,19.0742 C13.2954,19.5722 13.0744,20.1052 13.0744,20.6622 C13.0744,23.3442 17.9654,25.5172 24.0004,25.5172 C30.0344,25.5172 34.9254,23.3442 34.9254,20.6622 C34.9254,20.1052 34.7044,19.5722 34.3164,19.0742 Z"
          id="Stroke-4"
          stroke="#5B5B5B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        {badgeCount &&
          (badgeCount < 10 ? (
            <>
              <circle id="Oval" fill="#CF1414" cx="32" cy="8" r="8"></circle>
              <text x="27.5" y="13.5" style={{ fill: '#FFFFFF' }}>
                {badgeCount}
              </text>
            </>
          ) : (
            <>
              <rect
                id="Rectangle"
                fill="#CF1414"
                x="24"
                y="0"
                width="24"
                height="16"
                rx="8"
              ></rect>
              <text x="26" y="13.5" style={{ fill: '#FFFFFF' }}>
                {badgeCount}
              </text>
            </>
          ))}
      </g>
    </svg>
  )
}
