import React, { CSSProperties, PropsWithChildren } from 'react'

interface PanelProps {
  paddingTop?: number | string
  paddingBottom?: number | string
  fullWidth?: boolean
  style?: CSSProperties
  gridStyle?: CSSProperties
}

export function Panel({
  paddingTop,
  paddingBottom,
  fullWidth,
  style,
  gridStyle,
  children,
}: PropsWithChildren<PanelProps>) {
  const styles: { [name: string]: CSSProperties } = {
    outer: {
      position: 'relative',
      boxSizing: 'border-box',
      width: '100%',
      paddingTop: paddingTop || 0,
      paddingLeft: fullWidth ? 0 : '16px',
      paddingRight: fullWidth ? 0 : '16px',
      paddingBottom: paddingBottom || 0,
      ...style,
    },
    inner: {
      position: 'relative',
      boxSizing: 'border-box',
      width: '100%',
      ...gridStyle,
    },
  }

  return (
    <div style={styles.outer}>
      <div style={styles.inner}>{children}</div>
    </div>
  )
}
