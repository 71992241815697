import React, { CSSProperties, ReactElement } from 'react'

interface IconButtonProps {
  icon: () => ReactElement
  label: () => ReactElement
}

const styles: { [name: string]: CSSProperties } = {
  button: {
    display: 'grid',
  },
  icon: {
    justifySelf: 'center', // 中身を真ん中寄せ
  },
  label: {
    alignSelf: 'start',
    justifySelf: 'center', // 中身を真ん中寄せ
  },
}

export function IconButton({ icon: Icon, label: Label }: IconButtonProps) {
  return (
    <div style={styles.button}>
      <div style={styles.icon}>
        <Icon />
      </div>
      <div style={styles.label}>
        <Label />
      </div>
    </div>
  )
}
