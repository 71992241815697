export function scrollToTop() {
  // FIXME: To avoid following error in storyshots
  // Error: Not implemented: window.scroll
  if (process.env.NODE_ENV !== 'test') {
    window.scroll(0, 0)
  }
}

// y: 0 is top, pxels from top
export function scrollToY(y: number) {
  // FIXME: To avoid following error in storyshots
  // Error: Not implemented: window.scroll
  if (process.env.NODE_ENV !== 'test') {
    window.scroll(0, y)
  }
}

export function getScrollY(): number {
  if (process.env.NODE_ENV !== 'test') {
    return window.scrollY
  } else {
    return 0
  }
}
