import React, { CSSProperties } from 'react'

const styles: { [name: string]: CSSProperties } = {
  wrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  img: {
    width: '150px',
    height: '146px',
  },
}

export function YoshioKunFaceIcon() {
  return (
    <div style={styles.wrapper}>
      <img
        style={styles.img}
        src="/images/yoshio-kun-face@3x.png"
        alt="よしおくん"
      />
    </div>
  )
}
