import React, { CSSProperties } from 'react'

import { Link } from 'react-router-dom'

import { LinkIcon } from '../atoms/icons/LinkIcon'

interface HelpLinkProps {
  label: string
  to: string
}

const styles: { [name: string]: CSSProperties } = {
  container: {
    width: '100%',
    height: '25px',
    borderRadius: '8px',
    // background: '#fff',
    position: 'relative',
  },
  message: {
    position: 'absolute',
    top: '5px',
    right: '30px',
    fontSize: '12px',
    color: '#282828',
    textDecorationLine: 'underline',
  },
  icon: {
    position: 'absolute',
    right: 0,
    color: '#0cc81d',
    fontSize: '14px',
  },
}

export function HelpLink({ label, to }: HelpLinkProps) {
  return (
    <div style={styles.container}>
      <Link to={to}>
        <div style={styles.message}>{label}</div>
        <div style={styles.icon}>
          <LinkIcon />
        </div>
      </Link>
    </div>
  )
}
