import React from 'react'

interface CouponIconProps {
  badgeCount?: number | undefined
}

export function CouponIcon({ badgeCount }: CouponIconProps) {
  return (
    <svg
      width="56"
      height="32"
      viewBox="-8 0 48 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path
          d="M27 16.02a4 4 0 014-4V6.668H1v18.667h30V20.02a4 4 0 01-4-4z"
          stroke="#5B5B5B"
        />
        <path stroke="#FF9501" d="M6.667 12H16" />
        {badgeCount &&
          (badgeCount < 10 ? (
            <>
              <circle id="Oval" fill="#CF1414" cx="28" cy="8" r="8"></circle>
              <text x="23.5" y="13.5" style={{ fill: '#FFFFFF' }}>
                {badgeCount}
              </text>
            </>
          ) : (
            <>
              <rect
                id="Rectangle"
                fill="#CF1414"
                x="20"
                y="0"
                width="24"
                height="16"
                rx="8"
              ></rect>
              <text x="22" y="13.5" style={{ fill: '#FFFFFF' }}>
                {badgeCount}
              </text>
            </>
          ))}
      </g>
    </svg>
  )
}

export function CouponIconOld() {
  return (
    <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path
          d="M27 16.02a4 4 0 014-4V6.668H1v18.667h30V20.02a4 4 0 01-4-4z"
          stroke="#5B5B5B"
        />
        <path stroke="#FF9501" d="M6.667 12H16" />
      </g>
    </svg>
  )
}
