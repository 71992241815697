import React from 'react'

export function RadioOnIcon() {
  return (
    <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <circle
          stroke="#D8D8D8"
          strokeWidth="3"
          fill="#FFF"
          cx="16"
          cy="16"
          r="14"
        />
        <circle fill="#F06400" cx="16" cy="16" r="8" />
      </g>
    </svg>
  )
}
