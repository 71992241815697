import { fork, call, put, select, takeEvery } from 'redux-saga/effects'

import {
  TradeActionTypes,
  TradeActionCreators,
  FetchTradeHistoryAction,
} from '../modules/trade'
import { fetchTradeHistory } from '../utils/api'
import { tokenSelector, apiHostSelector } from '../modules/login'

export function* fetchTradeHistorySaga(
  action: FetchTradeHistoryAction
): Generator<any, void, any> {
  try {
    const options = action.payload.options
    const apiHost = yield select(apiHostSelector)
    const token = yield select(tokenSelector)
    yield put(TradeActionCreators.fetchTradeHistoryRequestAction(options))
    const data = yield call(fetchTradeHistory, apiHost, token, options)
    const meta = data[0]
    const histories = data[1]
    yield put(
      TradeActionCreators.fetchTradeHistorySuccessAction(meta, histories)
    )
  } catch (error) {
    yield put(TradeActionCreators.fetchTradeHistoryFailureAction(error))
  }
}

function* handleFetchTradeHistorySaga() {
  yield takeEvery(TradeActionTypes.FETCH_TRADE_HISTORY, fetchTradeHistorySaga)
}

export function* tradeSagas() {
  yield fork(handleFetchTradeHistorySaga)
}
