import React from 'react'

export function LineOfficialAccountIcon() {
  return (
    <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path
          d="M16 4.666c-3.476.015-8.033.487-10.306 1.772v7.759c0 3.989 1.44 8.976 4.432 11.193 2.652 1.964 4.175 3.053 5.873 3.276 1.7-.223 3.222-1.312 5.876-3.276 2.99-2.217 4.431-7.204 4.431-11.193V6.438C24.032 5.153 19.477 4.681 16 4.666"
          stroke="#5B5B5B"
        />
        <path
          stroke="#FF9501"
          d="M16 9.652l1.535 3.896 4.145.231-3.197 2.69 1.027 3.988L16 18.273l-3.51 2.184 1.027-3.989-3.197-2.689 4.144-.23z"
        />
      </g>
    </svg>
  )
}
