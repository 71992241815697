import React from 'react'

import { InputProps, Input } from '../atoms/Input'
import { formatDate } from '../../utils/date'

export function InputDate({
  name,
  value,
  readOnly,
  onChange,
  ...rest
}: InputProps) {
  if (readOnly) {
    const formattedDate = formatDate(value)
    return (
      <Input
        type="text"
        name={name}
        value={formattedDate}
        readOnly={readOnly}
        onChange={onChange}
        {...rest}
      />
    )
  }
  return (
    <Input
      type="date"
      name={name}
      value={value}
      readOnly={readOnly}
      onChange={onChange}
      {...rest}
    />
  )
}
