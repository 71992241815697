import React, { CSSProperties } from 'react'

import { Panel } from '../atoms/Panel'

const title = '株式会社テイツー個人情報保護方針'

const styles: { [name: string]: CSSProperties } = {
  container: {
    paddingTop: '18px',
    backgroundColor: '#fff',
  },
  title: {
    margin: 0,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#282828',
    textAlign: 'center',
  },
  text: {
    padding: 18,
    fontSize: '14px',
    whiteSpace: 'pre-wrap',
  },
}

export function PrivacyPolicyPanel() {
  return (
    <Panel paddingTop={18}>
      <div style={styles.container}>
        <h3 style={styles.title}>{title}</h3>
        <div style={styles.text}>
          株式会社テイツー（以下「当社」といいます。）は、当社のサービスを利用する方（以下「お客様」といいます。）の個人情報の取扱いについて、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、全従業員に個人情報保護の重要性を認識させるとともにその取組みを徹底させることにより、個人情報の保護を推進します。
          {'\n'}
          {'\n'}第 1 条（個人情報）{'\n'}
          「個人情報」とは、個人情報の保護に関する法律（平成十五年法律第五十七号、以下「個人情報保護法」といいます。）にいう「個人情報」を指し、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別できるもの又は個人識別符号が含まれるものを指します。
          {'\n'}
          {'\n'}第 2 条（個人情報の取得と利用）{'\n'}
          当社は、以下の目的に必要な範囲で、お客様の個人情報を取得し、取得した情報を利用させていただきます。以下の目的の範囲を超えて個人情報を利用する場合には、事前に適切な方法でお客様からの同意を得るものとします。
          {'\n'}
          (1)
          当社が提供するサービス（以下「当社サービス」といいます。）を提供するため
          {'\n'}
          (2) 当社サービスの内容を改良・改善し、又は新しいサービスを開発するため
          {'\n'}
          (3)
          当社サービスの新機能、更新情報、キャンペーン等及び当社が提供する他のサービスのご案内（電子メール、チラシ、その他のダイレクトメールの送付を含む）のため
          {'\n'}
          (4) メンテナンス、重要なお知らせなど必要に応じたご連絡のため{'\n'}
          (5)
          当社サービスに関するお客様からのご意見、お問い合わせ等に回答するため（本人確認を行うことを含む）
          {'\n'}
          (6) 当社サービスの利用状況をお客様にご報告するため{'\n'}
          (7)
          当社サービスに関するアンケート・取材等のご協力依頼や各種イベントへのご参加をお願いし、又はその結果などをご報告するため
          {'\n'}
          (8)
          当社サービスの利用履歴等を調査・分析し、その結果を当社サービスの改良・開発や広告の配信に利用するため
          {'\n'}
          (9)
          お客様の承諾・申し込みに基づく、当社主催イベントの参加企業等への個人情報の提供
          {'\n'}
          (10)
          利用規約に違反したお客様や、不正・不当な目的で当社サービスを利用しようとするお客様の特定をし、ご利用をお断りするため
          {'\n'}
          {'\n'}第 3 条（個人情報の管理と保護）{'\n'}
          個人情報の管理は、厳重に行うこととし、次に掲げる場合を除き、お客様の同意がない限り、第三者に対しデータを開示・提供することはいたしません。
          また、安全性を考慮し、個人情報への不正アクセス、個人情報の紛失、破壊、改ざん及び漏えい等のリスクに対する予防並びに是正に関する対策を講じます。
          {'\n'}
          (1)
          人の生命、身体又は財産の保護のために必要がある場合であって、お客様の同意を得ることが困難である場合
          {'\n'}
          (2)
          公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、お客様の同意を得ることが困難である場合
          {'\n'}
          (3)
          国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、お客様の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
          {'\n'}
          (4)
          業務を円滑に遂行するため、利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託する場合
          {'\n'}
          (5) 合併その他の事由による事業の承継に伴って個人情報が提供される場合
          {'\n'}
          (6)
          個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的及び当該個人情報の管理について責任を有する者の氏名又は名称について、あらかじめお客様に通知し、又はお客様が容易に知り得る状態に置いた場合
          {'\n'}
          (7) その他法令で認められる場合{'\n'}
          {'\n'}第 4 条（個人情報の取扱いの委託）{'\n'}
          当社は、利用目的の達成に必要な範囲内において、個人情報の取扱いの全部又は一部を委託する場合がございます。この場合、当社は、委託先としての適格性を十分審査するとともに、契約にあたって守秘義務に関する事項等を定め、委託先に対する必要かつ適切な監督を行います。
          {'\n'}
          {'\n'}第 5 条（個人情報の共同利用）{'\n'}
          当社は、利用目的の達成に必要な範囲で、お客様の個人情報を以下の範囲及び目的で共同利用することがございます。
          {'\n'}
          {'\n'}
          〈共同利用の目的〉{'\n'}
          ・当社グループを総合したサービスの提供{'\n'}
          ・当社グループを総合したリスク管理{'\n'}
          ・当社グループ企業のサービスその他の情報の案内{'\n'}
          ・その他、第2条に記載の利用目的のため{'\n'}
          {'\n'}
          〈共同利用する個人情報の範囲〉{'\n'}
          ・古物取引に関するご連絡・お問い合わせに必要となる事項{'\n'}
          ・CS活動に必要となる事項{'\n'}
          ・当社グループにおけるサービスの利用動向等の統計的資料作成に必要となる事項
          {'\n'}
          ・その他ご本人様のすべての個人情報{'\n'}
          〈共同利用する者の範囲〉{'\n'}
          ・株式会社山徳（当社子会社）{'\n'}
          〈個人情報の管理について責任を有する者〉{'\n'}
          ・株式会社テイツー{'\n'}
          {'\n'}第 6 条（外国にある第三者への提供）{'\n'}
          当社は、当社が実施するオンライン販売サービス等の実施するにあたり、クレジットカード等のペイメントカードの不正利用等の詐欺行為の検知および予防を目的として、機械学習を利用した不正取引防止サービスを利用しています。これに伴い、当社は、個人情報保護法の定めるところに基づき、お客様の承諾を得たうえで、お客様の個人情報を外国にある第三者に提供する場合があります。詳しくは、「ふるいちオンラインご利用規約」をご覧ください。
          {'\n'}
          {'\n'}第 7 条（個人情報の開示）{'\n'}
          当社は、お客様（本人に限る。本条において以下同じ）から個人情報の開示を求められたときは、お客様に対し、遅滞なくこれを開示します。但し、開示することにより次のいずれかに該当する場合は、その全部又は一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。
          {'\n'}
          (1)
          お客様又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
          {'\n'}
          (2) 当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合{'\n'}
          (3) その他法令に違反することとなる場合{'\n'}
          {'\n'}第 8 条（個人情報の訂正及び削除）{'\n'}
          1.
          当社の保有する個人情報が誤った情報である場合には、お客様の請求により、当社が定める手続きに従い個人情報の訂正又は削除を行います。
          {'\n'}
          2.
          当社は、お客様から前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正又は削除を行い、これをお客様に通知します。
          {'\n'}
          {'\n'}第 9 条（個人情報の利用停止等）{'\n'}
          当社は、お客様から、以下の各号に定める場合に該当するとして、個人情報の利用の停止又は消去（以下「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、法令に従って、個人情報の利用停止等を行い、その旨お客様に通知します。但し、個人情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、お客様の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じます。
          {'\n'}
          (1) 利用目的の範囲を超えて取り扱われている場合{'\n'}
          (2) 不正の手段により取得された個人情報である場合{'\n'}
          (3)
          違法又は不当な行為を助長し、又は誘発するおそれがある方法により利用されている場合
          {'\n'}
          (4) 当社がお客様の個人情報を利用する必要がなくなった場合{'\n'}
          (5)
          要配慮個人情報が含まれる個人情報の漏えい、滅失若しくは毀損（以下「漏えい等」といいます。）が発生し、又は発生したおそれがある場合
          {'\n'}
          (6)
          不正に利用されることにより財産的被害が生じるおそれがある個人情報の漏えい等が発生し、又は発生したおそれがある場合
          {'\n'}
          (7)
          不正の目的をもって行われたおそれがある個人情報の漏えい等が発生し、又は発生したおそれがある場合
          {'\n'}
          (8)
          個人情報にかかる本人の数が千人を超える漏えい等が発生し、又は発生したおそれがある場合
          {'\n'}
          (9)
          当該個人情報の取扱いによりお客様の権利又は正当な利益が害されるおそれがある場合
          {'\n'}
          {'\n'}第 10 条（プライバシーポリシーの変更手続）{'\n'}
          当社は本ポリシーの内容を適宜見直し、その改善に努めます。本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、変更することができるものとします。変更後のプライバシーポリシーは、当社所定の方法により、お客様に通知し、又は当社ウェブサイトに掲載したときから効力を生じるものとします。
          {'\n'}
          {'\n'}第 11 条（法令、規範の遵守）{'\n'}
          当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守します。
          {'\n'}
          {'\n'}第 12 条（苦情及び相談への対応）{'\n'}
          当社は、個人情報の取扱いに関するお客様からの苦情、相談を受け付け、適切かつ迅速に対応いたします。また、お客様からの当該個人情報の開示、訂正、追加、削除、利用又は提供の拒否などのご要望に対しても、迅速かつ適切に対応いたします。
          {'\n'}
          {'\n'}第 13 条（cookie(クッキー)の使用について）{'\n'}
          当社は、お客様によりよいサービスを提供するため、cookie
          （クッキー）を使用することがありますが、これにより個人を特定できる情報の収集を行えるものではなく、お客様のプライバシーを侵害することはございません。また、cookie
          （クッキー）の受け入れを希望されない場合は、ブラウザの設定で変更することができますが、その場合、当社が想定しているサービスの一部が受けられないことにより当サービスの利便性を損なう場合がありますので、予めご了承ください。
          {'\n'}
          {'\n'}第 14 条（SSLの使用について）{'\n'}
          当社は、お客様が個人情報を入力して頂く際に、セキュリティ確保のため、これらの情報が傍受、妨害または改ざんされることを防ぐ目的でSSL（Secure
          Sockets Layer）技術を使用しております。{'\n'}
          {'\n'}第 15 条（お問い合わせ窓口）{'\n'}
          当社の個人情報の取扱に関するお問い合せは下記までご連絡ください。{'\n'}
          株式会社テイツー　お客様対応窓口{'\n'}〒
          537-0011　【住　所】大阪府大阪市東成区東今里2-1-4{'\n'}
          TEL: 0570-087-097　{'\n'}
          Mail: customer@tay2.co.jp{'\n'}
          {'\n'}
          {'\n'}
          ２０２２年9月１日改定{'\n'}
        </div>
      </div>
    </Panel>
  )
}
