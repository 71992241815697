import React from 'react'

import { OtherItemList } from '../organisms/OtherItemList'
import { DefaultTemplate } from '../templates/DefaultTemplate'
import { Padding } from '../atoms/Padding'
import { BackNavLinkPanel } from '../organisms/BackNavPanel'

interface OtherItemsPagePresenterProps {
  toMembershipAgreement: string
  toPrivacyPolicy: string
}

export function OtherItemsPagePresenter({
  toMembershipAgreement,
  toPrivacyPolicy,
}: OtherItemsPagePresenterProps) {
  const Main = (
    <>
      <BackNavLinkPanel label="戻る" to="/" />
      <Padding bottom="40px">
        <OtherItemList
          toMembershipAgreement={toMembershipAgreement}
          toPrivacyPolicy={toPrivacyPolicy}
        />
      </Padding>
    </>
  )

  return <DefaultTemplate title="その他メニュー" main={Main} autoScrollToTop />
}

export function OtherItemsPage() {
  return (
    <OtherItemsPagePresenter
      toMembershipAgreement="/others/membership-agreement"
      toPrivacyPolicy="/others/privacy-policy"
    />
  )
}
