export interface TradeHistoryMeta {
  dataCount: number
  perPage: number
  pageNo: number
  pageCount: number
}

// 明細種別: 商品は0
interface TradeHistoryRawResponseRecordDetailOfShohin {
  detail_type: 0 // 明細種別 商品は0
  serial_number: number // 連番 関連商品番号（related_product_number）の　紐づけに使用
  product_code: string // number // 商品番号
  product_name: string // 商品名 商品番号を使用して商品マスタテーブルから取得
  unit_price: number // 単価
  quantity: number // 数量
  detail_total_fee: number // 合計金額
  tax_type_text: string // 商品ごとの税の種類。例えば内税とか、税なしなどの表記用
}

// 明細種別: クーポンは1
interface TradeHistoryRawResponseRecordDetailOfCoupon {
  detail_type: 1 // 明細種別 クーポンは1
  target_division: number // 対象区分 0：商品単位 1：取引単位
  coupon_no: string // クーポンNO
  coupon_name: string // クーポン名称
  benefit_type: number // 特典種別 0：割引（売上）／割増（買取）1：値引（売上）／値増（買取）2：ポイント加算（売上／買取）3：ポイントアップ率（売上／買取）
  benefits: number // 特典内容 0:割引／割増：1～99% 　1:値引／値増：1円～ 　2:加算ポイント：1ポイント～ 　3:ポイントアップ率：1～9999%
  coupon_discount_amount: number // クーポン割引額 benefit_type　0or1
  coupon_point: number // クーポンポイント benefit_type　2or3
}

// 明細種別: ポイントは2
interface TradeHistoryRawResponseRecordDetailOfPoint {
  detail_type: 2 // 明細種別 ポイントは2
  point_name: string // ポイント名称
  point: number // ポイント数
}

// 明細種別: 支払いは3
interface TradeHistoryRawResponseRecordDetailOfShiharai {
  detail_type: 3 // 明細種別 支払いは3
  payment_transaction_type: string // 支払い取引種別 0001スタンプ0002ポイント0003その他0004金券0006クレジット
  kid: string // KID
  credit_company_name: string // クレジット会社名漢字 kidを使用してクレジット会社マスタテーブルから取得
  other_payment: number // その他支払い金額
}

// 明細種別: 割引は4
interface TradeHistoryRawResponseRecordDetailOfDiscount {
  detail_type: 4 // 明細種別 割引は4
  target_division: number // 対象区分 0：商品単位 1：取引単位
  discount_name: string // 割引名称
  discount_amount: number // 割引額
  related_product_number: number // 関連商品番号 シーケンスか連番を紐づけに使用
  product_name: string // 関連商品名 related_product_numberを使用して紐づく商品を取得
}

// 明細種別: 商品は0, クーポンは1, ポイントは2, 支払いは3, 	割引は4
type TradeHistoryRawResponseRecordDetail =
  | TradeHistoryRawResponseRecordDetailOfShohin
  | TradeHistoryRawResponseRecordDetailOfCoupon
  | TradeHistoryRawResponseRecordDetailOfPoint
  | TradeHistoryRawResponseRecordDetailOfShiharai
  | TradeHistoryRawResponseRecordDetailOfDiscount

interface TradeHistoryRawResponseRecordGuaranteeDetail {
  security_guarantee_id: string
  security_guarantee_jan: string
  target_product_code: string
  target_product_standard_no: string
  target_product_name: string
  target_product_content: string
  security_guarantee_expire_date: string
}

interface TradeHistoryRawResponseRecordTaxDetail {
  tax_type: number
  sales_tax_rate: number
  transaction_amount: number
  tax_amount: number
}

/**
 * 0000: 購入
 * 0001: 返品
 * 0002: 買取
 * 0003: 買取中止
 * 0100: 売上(一括取消)
 * 0102: 買取(一括取消)
 */
export type TransactionType =
  | '0000'
  | '0001'
  | '0002'
  | '0003'
  | '0100'
  | '0102'

interface TradeHistoryRawResponseRecord {
  id: number // ID
  transaction_no: string // 取引NO
  store_code: string // 店舗番号
  store_name: string // 店舗名 店舗番号を使用して拠点マスタから取得
  tel_number: string // number // 店舗電話番号 店舗番号を使用して拠点マスタから取得
  register_name: string // レジ名称 ”レジ” ＋ POS番号？
  sales_date: string // 売上日付
  sales_time: string // 売上時刻
  /**
   * 0000: 購入
   *
   * 0001: 返品
   *
   * 0002: 買取
   *
   * 0003: 買取中止
   *
   * 0100: 売上(一括取消)
   *
   * 0102: 買取(一括取消)
   */
  transaction_type: TransactionType
  transaction_type_name: string // 取引種別 transaction_typeに対応した名称（シート：売買履歴　行：30）
  staff_code: string // 担当者コード
  member_no: string // 顧客番号
  card_no: string // カード番号 顧客番号を使用して会員カードテーブルから取得
  total_quantity: number // 合計個数 明細のquantityと区別するために処理内でリネーム
  subtotal_tax_not_included: number // 金額(税別) 取引種別により出し分ける 明細の合計額
  total_fee: number // 金額(課税後 割引前) 取引種別により出し分ける
  subtotal_value_discount_amount: number // 割引額 cash_handling_amount - total_fee
  cash_handling_amount: number // 合計金額(課税後 割引後)　現金取り扱い金額
  deposit_amount: number // 預り金額
  change_amount: number // 釣銭金額
  system_date: string // システム日付
  system_time: string // システム時刻
  current_point: number // 現在ポイント
  used_point: number // 使用ポイント
  acquisition_point: number // 獲得ポイント
  point_expire_date: string // ポイント有効期限
  total_consumption_tax: number // 消費税合計額 移行データのみで使用
  trade_details: TradeHistoryRawResponseRecordDetail[]
  guarantee_details: TradeHistoryRawResponseRecordGuaranteeDetail[]
  tax_details: TradeHistoryRawResponseRecordTaxDetail[]
}

export interface TradeHistoryRawResponse {
  status: 0 | 100 // 正常終了=0, トークン有効期限切れ=200
  data_count: number
  per_page: number
  page_no: number
  page_count: number
  histories: TradeHistoryRawResponseRecord[]
}

/**
 * 明細種別
 *
 * 商品は0
 *
 * クーポンは1
 *
 * ポイントは2
 *
 * 支払いは3
 *
 * 割引は4
 */
type TradeDetailType = 0 | 1 | 2 | 3 | 4

interface TradeHistoryRecordDetailBase {
  detailType: TradeDetailType
}

// 明細種別: 商品は0
export interface TradeHistoryRecordDetailOfShohin
  extends TradeHistoryRecordDetailBase {
  detailType: 0 // 明細種別 商品は0
  serialNumber: number // 連番 関連商品番号（related_product_number）の　紐づけに使用
  productCode: string // number // 商品番号
  productName: string // 商品名 商品番号を使用して商品マスタテーブルから取得
  unitPrice: number // 単価
  quantity: number // 数量
  detailTotalFee: number // 合計金額
  taxTypeText: string // 商品ごとの税の種類。例えば内税とか、税なしなどの表記用
}

// 明細種別: クーポンは1
export interface TradeHistoryRecordDetailOfCoupon
  extends TradeHistoryRecordDetailBase {
  detailType: 1 // 明細種別 クーポンは1
  targetDivision: number // 対象区分 0：商品単位 1：取引単位
  couponNo: string // クーポンNO
  couponName: string // クーポン名称
  benefitType: number // 特典種別 0：割引（売上）／割増（買取）1：値引（売上）／値増（買取）2：ポイント加算（売上／買取）3：ポイントアップ率（売上／買取）
  benefits: number // 特典内容 0:割引／割増：1～99% 　1:値引／値増：1円～ 　2:加算ポイント：1ポイント～ 　3:ポイントアップ率：1～9999%
  couponDiscountAmount: number // クーポン割引額 benefit_type　0or1
  couponPoint: number // クーポンポイント benefit_type　2or3
}

// 明細種別: ポイントは2
export interface TradeHistoryRecordDetailOfPoint
  extends TradeHistoryRecordDetailBase {
  detailType: 2 // 明細種別 ポイントは2
  pointName: string // ポイント名称
  point: number // ポイント数
}

// 明細種別: 支払いは3
export interface TradeHistoryRecordDetailOfShiharai
  extends TradeHistoryRecordDetailBase {
  detailType: 3 // 明細種別 支払いは3
  paymentTransactionType: string // 支払い取引種別 0001スタンプ0002ポイント0003その他0004金券0006クレジット
  kid: string // KID
  creditCompanyName: string // クレジット会社名漢字 kidを使用してクレジット会社マスタテーブルから取得
  otherPayment: number // その他支払い金額
}

// 明細種別: 割引は4
export interface TradeHistoryRecordDetailOfDiscount
  extends TradeHistoryRecordDetailBase {
  detailType: 4 // 明細種別 割引は4
  targetDivision: number // 対象区分 0：商品単位 1：取引単位
  discountName: string // 割引名称
  discountAmount: number // 割引額
  relatedProductNumber: number // 関連商品番号 シーケンスか連番を紐づけに使用
  productName: string // 関連商品名 related_product_numberを使用して紐づく商品を取得
}

// export interface TradeHistoryRecordDetail {
//   productCode: string
//   productName: string
//   quantity: number
//   unitPrice: number
//   detailTotalFee: number
//   // tax: number
//   additionalPoints: number
// }

// 明細種別: 商品は0, クーポンは1, ポイントは2, 支払いは3, 	割引は4
export type TradeHistoryRecordDetail =
  | TradeHistoryRecordDetailOfShohin
  | TradeHistoryRecordDetailOfCoupon
  | TradeHistoryRecordDetailOfPoint
  | TradeHistoryRecordDetailOfShiharai
  | TradeHistoryRecordDetailOfDiscount

/**
 *
 * @param detailType 明細種別
 * @param tradeDetails 売買履歴明細
 * @returns 明細種別によってfilterされた売買履歴明細一覧を返す
 */
export const filterTradeDetailByType = <
  T extends TradeDetailType | TradeDetailType[],
  S extends PickedTradeDetails<T>
>(
  detailType: T,
  tradeDetails: TradeHistoryRecordDetail[]
): S[] =>
  Array.isArray(detailType)
    ? tradeDetails.filter((detail): detail is S =>
        detailType.some((d) => detail.detailType === d)
      )
    : tradeDetails.filter(
        (detail): detail is S => detail.detailType === detailType
      )

type PickedTradeDetails<T> = T extends TradeDetailType[]
  ? PickTradeDetailsByTypes<T>[number]
  : T extends TradeDetailType
  ? PickTradeDetailByType<T>
  : never

type PickTradeDetailsByTypes<T> = {
  [P in keyof T]: T[P] extends TradeDetailType
    ? PickTradeDetailByType<T[P]>
    : never
}

type PickTradeDetailByType<T extends TradeDetailType> = T extends 0
  ? TradeHistoryRecordDetailOfShohin
  : T extends 1
  ? TradeHistoryRecordDetailOfCoupon
  : T extends 2
  ? TradeHistoryRecordDetailOfPoint
  : T extends 3
  ? TradeHistoryRecordDetailOfShiharai
  : T extends 4
  ? TradeHistoryRecordDetailOfDiscount
  : never

export interface TradeHistoryRecordGuaranteeDetail {
  securityGuaranteeId: string
  securityGuaranteeJan: string
  targetProductCode: string
  targetProductStandardNo: string
  targetProductName: string
  targetProductContent: string
  securityGuaranteeExpireDate: string
}

export interface TradeHistoryRecordTaxDetail {
  taxType: number // 税種別: 項目によって、処理内で決定 (1:外税 2:内税 3:非課税)
  salesTaxRate: number //	消費税率(%)
  transactionAmount: number // 取引金額
  taxAmount: number // 消費税額
}

export interface TradeHistoryRecord {
  id: number // ID
  transactionNo: string // 取引NO
  storeCode: string // 店舗番号
  storeName: string // 店舗名 店舗番号を使用して拠点マスタから取得
  telNumber: string // number // 店舗電話番号 店舗番号を使用して拠点マスタから取得
  registerName: string // レジ名称 ”レジ” ＋ POS番号？
  salesDate: string // 売上日付
  salesTime: string // 売上時刻
  /**
   * 0000: 購入
   *
   * 0001: 返品
   *
   * 0002: 買取
   *
   * 0003: 買取中止
   *
   * 0100: 売上(一括取消)
   *
   * 0102: 買取(一括取消)
   */
  transactionType: TransactionType
  transactionTypeName: string // 取引種別 transaction_typeに対応した名称（シート：売買履歴　行：30）
  staffCode: string // 担当者コード
  memberNo: string // 顧客番号
  cardNo: string // カード番号 顧客番号を使用して会員カードテーブルから取得
  totalQuantity: number // 合計個数 明細のquantityと区別するために処理内でリネーム
  subtotalTaxNotIncluded: number // 金額(税別) 取引種別により出し分ける 明細の合計額
  totalFee: number // 金額(課税後 割引前) 取引種別により出し分ける
  subtotalValueDiscountAmount: number // 割引額 cash_handling_amount - total_fee
  cashHandlingAmount: number // 合計金額(課税後 割引後)　現金取り扱い金額
  depositAmount: number // 預り金額
  changeAmount: number // 釣銭金額
  systemDate: string // システム日付
  systemTime: string // システム時刻
  currentPoint: number // 現在ポイント
  usedPoint: number // 使用ポイント
  acquisitionPoint: number // 獲得ポイント
  pointExpireDate: string // ポイント有効期限
  tradeDetails: TradeHistoryRecordDetail[]
  guaranteeDetails: TradeHistoryRecordGuaranteeDetail[]
  taxDetails: TradeHistoryRecordTaxDetail[]
}

function fromTradeHistoryRawResponseRecordDetail(
  tradeHistoryRawResponseRecordDetail: TradeHistoryRawResponseRecordDetail
): TradeHistoryRecordDetail {
  const detail = tradeHistoryRawResponseRecordDetail
  switch (detail.detail_type) {
    case 0:
      return {
        detailType: 0,
        serialNumber: detail.serial_number,
        productCode: detail.product_code,
        productName: detail.product_name,
        unitPrice: detail.unit_price,
        quantity: detail.quantity,
        detailTotalFee: detail.detail_total_fee,
        taxTypeText: detail.tax_type_text,
      }
    case 1:
      return {
        detailType: 1,
        targetDivision: detail.target_division,
        couponNo: detail.coupon_no,
        couponName: detail.coupon_name,
        benefitType: detail.benefit_type,
        benefits: detail.benefits,
        couponDiscountAmount: detail.coupon_discount_amount,
        couponPoint: detail.coupon_point,
      }
    case 2:
      return {
        detailType: 2,
        pointName: detail.point_name,
        point: detail.point,
      }
    case 3:
      return {
        detailType: 3,
        paymentTransactionType: detail.payment_transaction_type,
        kid: detail.kid,
        creditCompanyName: detail.credit_company_name,
        otherPayment: detail.other_payment,
      }
    case 4:
      return {
        detailType: 4,
        targetDivision: detail.target_division,
        discountName: detail.discount_name,
        discountAmount: detail.discount_amount,
        relatedProductNumber: detail.related_product_number,
        productName: detail.product_name,
      }
  }
}

function fromTradeHistoryRawResponseRecord(
  tradeHistoryRawResponseRecord: TradeHistoryRawResponseRecord
): TradeHistoryRecord {
  const record = tradeHistoryRawResponseRecord
  return {
    id: record.id,
    transactionNo: record.transaction_no,
    storeCode: record.store_code,
    storeName: record.store_name,
    telNumber: record.tel_number,
    registerName: record.register_name,
    salesDate: record.sales_date,
    salesTime: record.sales_time,
    transactionType: record.transaction_type,
    transactionTypeName: record.transaction_type_name,
    staffCode: record.staff_code,
    memberNo: record.member_no,
    cardNo: record.card_no,
    totalQuantity: record.total_quantity,
    subtotalTaxNotIncluded: record.subtotal_tax_not_included,
    totalFee: record.total_fee,
    subtotalValueDiscountAmount: record.subtotal_value_discount_amount,
    cashHandlingAmount: record.cash_handling_amount,
    depositAmount: record.deposit_amount,
    changeAmount: record.change_amount,
    systemDate: record.system_date,
    systemTime: record.system_time,
    currentPoint: record.current_point,
    usedPoint: record.used_point,
    acquisitionPoint: record.acquisition_point,
    pointExpireDate: record.point_expire_date,
    tradeDetails: record.trade_details
      ? record.trade_details.map((detail) =>
          fromTradeHistoryRawResponseRecordDetail(detail)
        )
      : [],
    guaranteeDetails: record.guarantee_details.map((detail) => {
      return {
        securityGuaranteeId: detail.security_guarantee_id,
        securityGuaranteeJan: detail.security_guarantee_jan,
        targetProductCode: detail.target_product_code,
        targetProductStandardNo: detail.target_product_standard_no,
        targetProductName: detail.target_product_name,
        targetProductContent: detail.target_product_content,
        securityGuaranteeExpireDate: detail.security_guarantee_expire_date,
      }
    }),
    taxDetails: record.tax_details.map((detail) => {
      return {
        taxType: detail.tax_type,
        salesTaxRate: detail.sales_tax_rate,
        transactionAmount: detail.transaction_amount,
        taxAmount: detail.tax_amount,
      }
    }),
  }
}

export interface TradeHistorySearchOptions {
  count?: number // ページ当たりの表示件数 未指定の場合全件取得
  page?: number // ページ番号 未指定の場合1
  sort?: number // ソート(0:日付で降順 1:日付で昇順) 未指定の場合降順
  from?: string // 取得期間開始日(YYYY-MM-DD) 未指定の場合絞らない
  to?: string // 取得期間終了日(YYYY-MM-DD) 未指定の場合絞らない
}

export type TradeHistorySearchOptionsString = {
  // Pは型の定義に必要
  [P in keyof TradeHistorySearchOptions]: string
}

export function parseTradeHistorySearchOptions(
  options: TradeHistorySearchOptions
) {
  return {
    count: String(options.count || 100),
    page: String(options.page || 1),
    sort: String(options.sort || 0), // 0:日付で降順 1:日付で昇順
    ...(options.from ? { from: String(options.from) } : undefined),
    ...(options.to ? { to: String(options.to) } : undefined),
  }
}

// export function fromTradeHistoryRawResponse(
//   rawResponse: TradeHistoryRawResponse
// ): [TradeMeta, TradeHistoryRecord[]] {
export function fromTradeHistoryRawResponse(
  rawResponse: TradeHistoryRawResponse
) {
  const meta: TradeHistoryMeta = {
    dataCount: rawResponse.data_count,
    perPage: rawResponse.per_page,
    pageNo: rawResponse.page_no,
    pageCount: rawResponse.page_count,
  }
  const histories: TradeHistoryRecord[] = rawResponse.histories.map(
    (tradeHistory) => {
      return fromTradeHistoryRawResponseRecord(tradeHistory)
    }
  )

  return [meta, histories]
}

/**
 * 購買履歴詳細の取引種別は大まかに下記の４タイプに分けられる
 *
 * 1. 購入(transaction_type: 0000)
 * 2. 買取(transaction_type: 0002)
 * 3. 購入取消(transaction_type: 0001, 0100)
 * 4. 買取取消(transaction_type: 0003, 0102)
 *
 * この関数では取引種別が取消の場合true、そうでない場合はfalseを返す
 */
export function isCancelTradeHistory(record: TradeHistoryRecord): boolean {
  return (
    record.transactionType === '0001' ||
    record.transactionType === '0100' ||
    record.transactionType === '0003' ||
    record.transactionType === '0102'
  )
}
