import React, { CSSProperties } from 'react'

import { useFormik } from 'formik'

import { Panel } from '../atoms/Panel'
import { Button } from '../atoms/Button'
import { Checkbox } from '../molecules/Checkbox'
import { Padding } from '../atoms/Padding'

type MigrateFormValues = {
  onlineAccountIntegrationAgreed: boolean
  pointTotalingAgreed: boolean
  onlineAccountCardNoIntegrationAgreed: boolean
  unableRestoreAgreed: boolean
}

const styles: { [name: string]: CSSProperties } = {
  wrapper: {
    marginTop: '16px',
  },
  fieldLabel: {
    marginTop: '14.5px',
  },
  supplementMessage: {
    fontSize: '12px',
    color: '#282828',
  },
}

const defaultInitialValues: MigrateFormValues = {
  onlineAccountIntegrationAgreed: false,
  pointTotalingAgreed: false,
  onlineAccountCardNoIntegrationAgreed: false,
  unableRestoreAgreed: false,
}

interface MigrateFormProps {
  onSubmit: () => void
}

export function MigrateForm({ onSubmit }: MigrateFormProps) {
  const formik = useFormik<MigrateFormValues>({
    initialValues: defaultInitialValues,
    onSubmit: (values) => {
      console.debug('values:', values)
      onSubmit()
    },
  })

  const agreed =
    formik.values.onlineAccountIntegrationAgreed &&
    formik.values.pointTotalingAgreed &&
    formik.values.onlineAccountCardNoIntegrationAgreed &&
    formik.values.unableRestoreAgreed

  return (
    <Panel style={styles.wrapper}>
      <form onSubmit={formik.handleSubmit}>
        <Padding top={0}>
          <Checkbox
            name="onlineAccountIntegrationAgreed"
            value="agreed"
            checked={formik.values.onlineAccountIntegrationAgreed}
            onChange={formik.handleChange}
          >
            連携された場合、以降はふるいちオンラインアカウントでふるいちアプリをご利用となります。
            <br />
            （共通カード番号でご利用）
          </Checkbox>
        </Padding>
        <Padding top={0}>
          <Checkbox
            name="pointTotalingAgreed"
            value="agreed"
            checked={formik.values.pointTotalingAgreed}
            onChange={formik.handleChange}
          >
            現在の保有ポイントは、ふるいちオンラインアカウントに合算されます。
          </Checkbox>
        </Padding>
        <Padding top={0}>
          <Checkbox
            name="onlineAccountCardNoIntegrationAgreed"
            value="agreed"
            checked={formik.values.onlineAccountCardNoIntegrationAgreed}
            onChange={formik.handleChange}
          >
            連携後のカード番号はふるいちオンラインの番号となります。
          </Checkbox>
        </Padding>
        <Padding top={0}>
          <Checkbox
            name="unableRestoreAgreed"
            value="agreed"
            checked={formik.values.unableRestoreAgreed}
            onChange={formik.handleChange}
          >
            連携すると連携前の状態に戻すことはできません。
          </Checkbox>
        </Padding>
        <Button
          type="submit"
          variant="black"
          disabled={!agreed}
          style={
            agreed
              ? { marginTop: '22.5px', backgroundColor: '#EA3F0C' }
              : { marginTop: '22.5px' }
          }
        >
          連携する
        </Button>
      </form>
      <Padding top={16}>
        <span style={styles.supplementMessage}>
          ※連携せずふるいちアプリをご利用いただく場合は左上の「戻る」を押していただき、別のメールアドレスをご登録ください。
        </span>
      </Padding>
    </Panel>
  )
}
