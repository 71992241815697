import React, { InputHTMLAttributes, CSSProperties, useMemo } from 'react'

import { RadioOnIcon } from '../atoms/icons/RadioOnIcon'
import { RadioOffIcon } from '../atoms/icons/RadioOffIcon'
import { InputText } from './InputText'

export interface RadioGroupProps {
  name: string
  value: string | undefined
  optionValues: string[]
  optionLabels: string[]
  readOnly?: boolean | undefined
  onChange?: InputHTMLAttributes<HTMLInputElement>['onChange']
}

const styles: { [name: string]: CSSProperties } = {
  fieldset: {
    margin: 0,
    padding: 0,
    border: 0,
    width: '100%',
    height: '51px',
    display: 'flex',
    justifyItems: 'left',
    alignItems: 'center',
    // border: 'solid 1px purple',
  },
  label: {
    display: 'flex',
    justifyItems: 'left',
    alignItems: 'center',
    paddingRight: '15.5px',
    cursor: 'pointer',
    color: '#282828',
    // border: 'solid 1px green',
  },
  radio: {
    // position: 'absolute',
    // left: '-9999px',
    // visibility: 'hidden',
    // zIndex: -9999,
    // fontSize: '14px',
    // lineHeight: '21px',
    //
    position: 'absolute',
    filter: 'alpha(opacity=0)',
    MozOpacity: 0, // '-moz-opacity': 0,
    opacity: 0,
    WebkitAppearance: 'none', // '-webkit-appearance': 'none',
    appearance: 'none',
    // border: 'solid 1px red',
  },
  labelText: {
    paddingLeft: '6.5px',
  },
}

export function RadioGroup({
  name,
  value,
  optionValues,
  optionLabels,
  readOnly,
  onChange,
}: RadioGroupProps) {
  const labels = useMemo(() => {
    return optionValues.reduce<{ [name: string]: string }>(
      (labels, optionValue, i) => {
        labels[optionValue] = optionLabels[i]
        return labels
      },
      {}
    )
  }, [optionValues, optionLabels])

  return readOnly ? (
    <InputText name={name} value={value ? labels[value] : ''} readOnly />
  ) : (
    <fieldset style={styles.fieldset}>
      {optionValues.map((optionValue, i) => {
        const optionLabel = optionLabels[i]
        return (
          <label style={styles.label} key={optionValue}>
            <input
              type="radio"
              name={name}
              value={optionValue}
              onChange={onChange}
              checked={optionValue === value}
              style={styles.radio}
            />
            {optionValue === value ? <RadioOnIcon /> : <RadioOffIcon />}
            <span style={styles.labelText}>{optionLabel}</span>
          </label>
        )
      })}
    </fieldset>
  )
}
