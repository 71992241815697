function convertToNumbers(postalCode: string | undefined): string {
  try {
    if (!postalCode) return ''
    if (postalCode.length <= 0) return ''
  } catch (_e: any) {
    return ''
  }

  const numberedPostalCode = postalCode
    .replace(/[０-９]/g, (char) => {
      return String.fromCharCode(char.charCodeAt(0) - 0xfee0)
    })
    .replace(/[^0-9]/g, (char) => {
      return ''
    })

  return numberedPostalCode
}

export function formatPostalCodeWithHyphen(
  postalCode: string | undefined
): string {
  const numberedPostalCode = convertToNumbers(postalCode)

  if (numberedPostalCode.length < 3) {
    return numberedPostalCode
  }

  return numberedPostalCode.slice(0, 3) + '-' + numberedPostalCode.slice(3, 7)
}

export function formatPostalCode(postalCode: string | undefined): string {
  const numberedPostalCode = convertToNumbers(postalCode)

  if (numberedPostalCode.length < 3) {
    return numberedPostalCode
  }

  return numberedPostalCode.slice(0, 3) + numberedPostalCode.slice(3, 7)
}
