import React, { CSSProperties } from 'react'

import { CouponList } from './CouponList'
import { FullWidthButton } from '../atoms/FullWidthButton'
import { Coupon } from '../../models/coupon'

interface SelectableCouponListProps {
  coupons: Coupon[]
  selectedCoupons: { [couponCode: string]: Coupon }
  dateStr: string
  onToggleSelect: (coupon: Coupon) => void
  onShowCouponBarcodes: () => void
}

export function SelectableCouponList({
  coupons,
  selectedCoupons,
  dateStr,
  onToggleSelect,
  onShowCouponBarcodes,
}: SelectableCouponListProps) {
  const couponSelected = Object.keys(selectedCoupons).length > 0
  const styles: { [name: string]: CSSProperties } = {
    button: {
      position: 'fixed',
      bottom: 'calc(env(safe-area-inset-bottom))',
    },
    safearea: {
      position: 'fixed',
      height: 'calc(env(safe-area-inset-bottom))',
      width: '100vw',
      bottom: 0,
      backgroundColor: '#f2f2f2',
    },
    placeholder: {
      paddingBottom: 'calc(env(safe-area-inset-bottom) + 128px)',
    },
  }

  return (
    <>
      <CouponList
        coupons={coupons}
        selectedCoupons={selectedCoupons}
        dateStr={dateStr}
        onToggleSelect={onToggleSelect}
      />
      <div style={styles.placeholder}>
        <div style={styles.safearea} />
        <FullWidthButton
          style={styles.button}
          onClick={onShowCouponBarcodes}
          disabled={!couponSelected}
        >
          選択したクーポンをつかう
        </FullWidthButton>
      </div>
    </>
  )
}
