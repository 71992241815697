import {
  ReservationMeta,
  Reservation,
  ReservationsSearchOptions,
} from '../models/reservation'
import { ResetActionTypes, ResetStoreAction } from './reset'

// Action Types

const FETCH_RESERVATIONS = 'FETCH_RESERVATIONS' as const
const FETCH_RESERVATIONS_REQUEST = 'FETCH_RESERVATIONS_REQUEST' as const
const FETCH_RESERVATIONS_SUCCESS = 'FETCH_RESERVATIONS_SUCCESS' as const
const FETCH_RESERVATIONS_FAILURE = 'FETCH_RESERVATIONS_FAILURE' as const

export const ReservationActionTypes = {
  FETCH_RESERVATIONS,
  // FETCH_RESERVATIONS_REQUEST,
  // FETCH_RESERVATIONS_SUCCESS,
  // FETCH_RESERVATIONS_FAILURE,
}

// Action Creators

export function fetchReservationsAction(options: ReservationsSearchOptions) {
  return {
    type: FETCH_RESERVATIONS,
    payload: { options },
  }
}

function fetchReservationsRequestAction(options: ReservationsSearchOptions) {
  return {
    type: FETCH_RESERVATIONS_REQUEST,
    payload: { options },
  }
}

function fetchReservationsSuccessAction(
  meta: ReservationMeta,
  reservations: Reservation[]
) {
  return {
    type: FETCH_RESERVATIONS_SUCCESS,
    payload: { meta, reservations },
  }
}

function fetchReservationsFailureAction(error: any) {
  return {
    type: FETCH_RESERVATIONS_FAILURE,
    payload: { error },
  }
}

export const ReservationActionCreators = {
  fetchReservationsAction,
  fetchReservationsRequestAction,
  fetchReservationsSuccessAction,
  fetchReservationsFailureAction,
}

// Actions

export type FetchReservationsAction = ReturnType<typeof fetchReservationsAction>

type ReservationAction =
  | FetchReservationsAction
  | ReturnType<typeof fetchReservationsSuccessAction>
  | ReturnType<typeof fetchReservationsSuccessAction>
  | ReturnType<typeof fetchReservationsFailureAction>
  | ResetStoreAction

// State

interface ReservationState {
  meta: ReservationMeta | undefined
  reservations: Reservation[] | undefined
}

const initialState: ReservationState = {
  meta: undefined,
  reservations: undefined,
}

// Selector

export function reservationMetaSelector(rootState: {
  reservation: ReservationState
}) {
  return rootState.reservation.meta
}

export function reservationsSelector(rootState: {
  reservation: ReservationState
}) {
  return rootState.reservation.reservations
}

// Reducer

export function reservationReducer(
  state = initialState,
  action: ReservationAction
): ReservationState {
  switch (action.type) {
    case FETCH_RESERVATIONS_SUCCESS:
      return {
        ...state,
        meta: action.payload.meta,
        reservations: action.payload.reservations,
      }
    case ResetActionTypes.RESET_STORE:
      return initialState
    default:
      return state
  }
}
