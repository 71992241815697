import React, { CSSProperties, useCallback } from 'react'

import { IconButton } from './IconButton'
import { CouponIcon } from '../atoms/icons/CouponIcon'

interface CouponButtonProps {
  badgeCount?: number
}

function Label() {
  const style: CSSProperties = {
    fontSize: '12px',
    fontWeight: 'bold',
    textAlign: 'center',
  }
  return <div style={style}>クーポン</div>
}

export function CouponButton({ badgeCount }: CouponButtonProps) {
  const Icon = useCallback(() => {
    return <CouponIcon badgeCount={badgeCount} />
  }, [badgeCount])
  return <IconButton icon={Icon} label={Label} />
}
