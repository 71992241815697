import React from 'react'

import { BackNavLinkPanel } from '../organisms/BackNavPanel'
import { DefaultTemplate } from '../templates/DefaultTemplate'
import { MembershipAgreementPanel } from '../organisms/MembershipAgreementPanel'
import { Padding } from '../atoms/Padding'

interface MembershipAgreementPagePresenterProps {
  backTo: string
}

export function MembershipAgreementPagePresenter({
  backTo,
}: MembershipAgreementPagePresenterProps) {
  const Main = (
    <>
      <BackNavLinkPanel label="戻る" to={backTo} />
      <Padding bottom="40px">
        <MembershipAgreementPanel />
      </Padding>
    </>
  )

  return (
    <DefaultTemplate title="会員規約・利用規約" main={Main} autoScrollToTop />
  )
}

export function MembershipAgreementPage() {
  return <MembershipAgreementPagePresenter backTo="/" />
}

export function OtherItemMembershipAgreementPage() {
  return <MembershipAgreementPagePresenter backTo="/others" />
}
