import React, { useState, useCallback, useEffect } from 'react'

import { BackNavButtonPanel } from './BackNavPanel'
import { PrivacyPolicyPanel } from './PrivacyPolicyPanel'
import { FullScreen } from '../molecules/FullScreen'
import { useScroll } from '../../hooks/scroll'
import { Padding } from '../atoms/Padding'

export function usePrivacyPolicy() {
  const [privacyPolicyVisibility, setPrivacyPolicyVisibility] =
    useState<boolean>(false)
  const { saveCurrentScrollY, resetScroll, restoreScroll } = useScroll()
  const handlePrivacyPolicyOpen = useCallback(() => {
    saveCurrentScrollY()
    setPrivacyPolicyVisibility(true)
  }, [setPrivacyPolicyVisibility, saveCurrentScrollY])
  const haneldPrivacyPolicyClose = useCallback(() => {
    setPrivacyPolicyVisibility(false)
  }, [setPrivacyPolicyVisibility])

  useEffect(() => {
    if (privacyPolicyVisibility) {
      resetScroll()
    } else {
      restoreScroll()
    }
  }, [privacyPolicyVisibility, resetScroll, restoreScroll])

  return {
    privacyPolicyVisibility,
    handlePrivacyPolicyOpen,
    haneldPrivacyPolicyClose,
  }
}

export interface FullScreenPrivacyPolicyProps {
  backLabel: string
  onClose: () => void
}

export function FullScreenPrivacyPolicy({
  backLabel,
  onClose,
}: FullScreenPrivacyPolicyProps) {
  return (
    <FullScreen>
      <BackNavButtonPanel label={backLabel} onClick={onClose} />
      <Padding bottom="40px">
        <PrivacyPolicyPanel />
      </Padding>
    </FullScreen>
  )
}
