import React, { CSSProperties, PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'

import './NoDecorationLink.css'

export interface NoDecorationLinkProps {
  to: string
  style?: CSSProperties
}

export function NoDecorationLink({
  to,
  style,
  children,
}: PropsWithChildren<NoDecorationLinkProps>) {
  return (
    <Link to={to} style={style} className="no-decoration-link">
      {children}
    </Link>
  )
}

export function NoDecorationExternalLink({
  to,
  style,
  children,
}: PropsWithChildren<NoDecorationLinkProps>) {
  return (
    <a href={to} style={style} className="no-decoration-link">
      {children}
    </a>
  )
}
