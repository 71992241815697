import React, { CSSProperties } from 'react'

import { RoundedListItemHeader, RoundedListItemFooter } from './RoundedList'
import { Panel } from '../atoms/Panel'
import { PointHistoryRecord } from '../../models/point'
import {
  numberWithCommas,
  signedNumberWithCommas,
} from '../../utils/numberWithCommas'
import { formatDatetime } from '../../utils/datetime'

interface PointHistoryListItemProps {
  record: PointHistoryRecord
}

const styles: { [name: string]: CSSProperties } = {
  label: {
    fontSize: '10px',
    lineHeight: '15px',
    fontWeight: 'bold',
  },
  value: {
    paddingLeft: '4px',
    fontSize: '10px',
    lineHeight: '15px',
    fontWeight: 'bold',
  },
  salesInfo: {
    paddingTop: '8px',
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: '8px',
    display: 'grid',
    gap: '8px',
  },
  saleDate: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'start',
  },
  storeName: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'start',
  },
  transactionType: {
    display: 'grid',
    gridTemplateColumns: 'auto',
    justifyContent: 'start',
  },
  transactionTypeLabel: {
    fontSize: '14px',
    lineHeight: '21px',
  },
  pointInfo: {
    paddingTop: '8px',
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: '8px',
    display: 'grid',
    gridTemplateColumns: 'auto auto',
  },
  pointDiff: {
    paddingLeft: '47px',
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    alignItems: 'center',
  },
  pointDiffLabel: {
    fontSize: '10px',
    // lineHeight: '15px',
    color: '#5B5B5B',
    display: 'grid',
    alignItems: 'center',
  },
  pointDiffValue: {
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '22px',
  },
  pointDiffValuePlus: {
    color: '#017DFF',
  },
  pointDiffValueMinus: {
    color: '#CF1414',
  },
  pointBalance: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    alignItems: 'center',
  },
  pointBalanceLabel: {
    fontSize: '10px',
    // lineHeight: '15px',
    color: '#5B5B5B',
    display: 'grid',
    alignItems: 'center',
  },
  pointBalanceValue: {
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '22px',
    textAlign: 'right',
  },
  pointDetail: {
    paddingTop: '9px',
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: '7px',
    display: 'grid',
    gridTemplateColumns: 'auto auto',
  },
  pointDetailLabel: {
    fontSize: '10px',
    lineHeight: '15px',
    color: '#5B5B5B',
    display: 'grid',
    gridTemplateRows: 'auto auto',
  },
  pointDetailValue: {
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 'bold',
    color: '#5B5B5B',
    textAlign: 'right',
    alignSelf: 'center',
  },
}

export function PointHistoryListItem({ record }: PointHistoryListItemProps) {
  const formattedTradeDatetime = formatDatetime(record.tradeDatetime)

  return (
    <Panel style={{ marginTop: '8px' }}>
      <RoundedListItemHeader>
        <div style={styles.salesInfo}>
          <div style={styles.saleDate}>
            <div style={styles.label}>利用日:</div>
            <div style={styles.value}>{formattedTradeDatetime}</div>
          </div>
          <div style={styles.storeName}>
            <div style={styles.label}>利用店舗:</div>
            <div style={styles.value}>{record.shopName || '-'}</div>
          </div>
          <div style={styles.transactionType}>
            <div style={styles.transactionTypeLabel}>{record.title || '-'}</div>
          </div>
        </div>
      </RoundedListItemHeader>
      <RoundedListItemFooter style={{ backgroundColor: '#F8F8F8' }}>
        <div style={styles.pointInfo}>
          <div style={styles.pointDiff}>
            <div style={styles.pointDiffLabel}>
              <span>ポイント</span>
              <span>増減</span>
            </div>
            <div
              style={{
                ...styles.pointDiffValue,
                ...(record.point < 0
                  ? styles.pointDiffValueMinus
                  : styles.pointDiffValuePlus),
              }}
            >
              {signedNumberWithCommas(record.point)}
            </div>
          </div>
          <div style={styles.pointBalance}>
            <div style={styles.pointBalanceLabel}>
              <span>ポイント</span>
              <span>残高</span>
            </div>
            <div style={styles.pointBalanceValue}>
              {numberWithCommas(record.pointAfter)}
            </div>
          </div>
        </div>
      </RoundedListItemFooter>
    </Panel>
  )
}
