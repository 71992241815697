import React, { CSSProperties } from 'react'

import { IconButton } from './IconButton'
import { ReservationIcon } from '../atoms/icons/ReservationIcon'

function Label() {
  const style: CSSProperties = {
    fontSize: '12px',
    fontWeight: 'bold',
    textAlign: 'center',
  }
  return <div style={style}>予約詳細</div>
}

export function ReservationButton() {
  return <IconButton icon={ReservationIcon} label={Label} />
}
