import React, { CSSProperties } from 'react'

import { Panel } from '../atoms/Panel'
import { RoundedPanel } from '../molecules/RoundedPanel'

const styles: { [name: string]: CSSProperties } = {
  list: {
    paddingLeft: '8px',
    listStyleType: "'●'",
    fontSize: '12px',
    lineHeight: '16px',
  },
  secondListItem: { fontWeight: 'bold' },
}

export function AboutReservationPanel() {
  return (
    <Panel paddingTop="12px" paddingBottom="12px">
      <RoundedPanel>
        <ul style={styles.list}>
          <li>
            店舗により販売価格が異なる場合がございますので詳細は店舗までお尋ねください。
          </li>
          <li style={styles.secondListItem}>
            予約後に発売日が変わることがあります。
          </li>
          <li>2021年9月16日以降のご予約を表示しております。</li>
        </ul>
      </RoundedPanel>
    </Panel>
  )
}
