import React, { CSSProperties } from 'react'

import { Panel } from '../atoms/Panel'
import { TradeHistoryRecordGuaranteeDetail } from '../../models/trade'
import { formatDateJa } from '../../utils/date'

function GuaranteeTitle() {
  const style: CSSProperties = {
    padding: '56px 24px 24px',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '24px',
    display: 'grid',
    placeItems: 'center',
    color: '#5B5B5B',
  }

  return <div style={style}>保証書</div>
}

interface GuaranteeHeaderProps {
  registerName: string
  storeName: string
  telNumber: string
  staffCode: string
  transactionNo: string
}

function GuaranteeHeader({
  registerName,
  storeName,
  telNumber,
  staffCode,
  transactionNo,
}: GuaranteeHeaderProps) {
  const styles: { [name: string]: CSSProperties } = {
    container: {
      fontSize: '12px',
      lineHeight: '18px',
      paddingBottom: '16px',
      borderBottom: '1px #5B5B5B dashed',
      color: '#282828',
    },
    row: {
      paddingBottom: '8px',
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      justifyContent: 'space-between',
    },
  }

  return (
    <div style={styles.container}>
      <div style={styles.row}>
        <div>{storeName}</div>
        <div>Tel {telNumber}</div>
      </div>
      <div style={styles.row}>
        <div>
          {registerName} 担{staffCode}
        </div>
        <div>No. {transactionNo}</div>
      </div>
    </div>
  )
}

function GuaranteeBodyItem({
  guaranteeDetail,
}: {
  guaranteeDetail: TradeHistoryRecordGuaranteeDetail
}) {
  const styles: { [name: string]: CSSProperties } = {
    container: {
      fontSize: '12px',
      lineHeight: '18px',
      display: 'grid',
      gridTemplateRows: 'auto',
    },
    target: {
      paddingBottom: '24px',
      borderBottom: '1px #5B5B5B dashed',
    },
    detail: {
      paddingTop: '24px',
    },
    group: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      // gridTemplateColumns: 'repeat(3, 1fr)',
      // gridTemplateAreas: 'a b b',
      // gridTemplateAreas: `a b b
      //                     a b b`,
    },
    label: {
      justifyContent: 'start',
      // gridArea: 'a',
    },
    value: {
      justifyContent: 'start',
      // gridArea: 'b',
    },
    productName: {},
    guaranteeDescription: {
      // textAlign: 'center',
    },
  }

  return (
    <div style={styles.container}>
      <div style={styles.target}>
        <div style={styles.group}>
          <div style={styles.label}>【保証対象商品】</div>
          <div style={styles.value}>
            <div>{guaranteeDetail.targetProductCode}</div>
            <div>{guaranteeDetail.targetProductStandardNo}</div>
          </div>
        </div>
        <div style={styles.productName}>
          {guaranteeDetail.targetProductName}
        </div>
      </div>
      <div style={styles.detail}>
        <div style={styles.group}>
          <div style={styles.label}>【保証内容】</div>
          <div style={styles.value}>{guaranteeDetail.securityGuaranteeJan}</div>
        </div>
        <div style={styles.guaranteeDescription}>
          {guaranteeDetail.targetProductContent}
        </div>
        <div style={styles.group}>
          <div style={styles.label}>【保証ID】</div>
          <div style={styles.value}>
            <div>&nbsp;</div>
            <div>{guaranteeDetail.securityGuaranteeId}</div>
          </div>
        </div>
        <div style={styles.group}>
          <div style={styles.label}>【保証有効期限】</div>
          <div style={styles.value}>
            <div>&nbsp;</div>
            <div>
              {formatDateJa(guaranteeDetail.securityGuaranteeExpireDate)}
              まで有効
            </div>
          </div>
        </div>
        <div>詳しくは、リーフレットをご確認ください</div>
      </div>
    </div>
  )
}

function GuaranteeBody({
  guaranteeDetail,
}: {
  guaranteeDetail: TradeHistoryRecordGuaranteeDetail
}) {
  const styles: { [name: string]: CSSProperties } = {
    container: {
      paddingTop: '24px',
      paddingBottom: '24px',
      borderBottom: '1px #5B5B5B dashed',
      color: '#282828',
    },
  }

  return (
    <div style={styles.container}>
      <GuaranteeBodyItem guaranteeDetail={guaranteeDetail} />
    </div>
  )
}

interface GuaranteeProps {
  guaranteeDetail: TradeHistoryRecordGuaranteeDetail
  transactionNo: string
  registerName: string
  storeCode: string
  storeName: string
  telNumber: string
  systemDate: string
  systemTime: string
  staffCode: string
}

export function Guarantee({
  guaranteeDetail,
  transactionNo,
  registerName,
  storeName,
  telNumber,
  staffCode,
}: GuaranteeProps) {
  const style: CSSProperties = {
    backgroundColor: '#FFFFFF',
  }

  return (
    <Panel style={style}>
      <GuaranteeTitle />
      <GuaranteeHeader
        registerName={registerName}
        storeName={storeName}
        telNumber={telNumber}
        staffCode={staffCode}
        transactionNo={transactionNo}
      />
      <GuaranteeBody guaranteeDetail={guaranteeDetail} />
    </Panel>
  )
}
