import React, { useCallback, useMemo, useEffect, useState } from 'react'

import { BackNavButtonPanel, BackNavLinkPanel } from '../organisms/BackNavPanel'
import { DefaultTemplate } from '../templates/DefaultTemplate'
import { Subtitle } from '../molecules/Subtitle'
import { SubtitleWithPager } from '../molecules/SubtitleWithPager'
import { PagerBar } from '../molecules/PagerBar'
import {
  AccountBarcode,
  AccountBarcodeLabel,
} from '../molecules/AccountBarcode'
import { LoadingPanel } from '../molecules/LoadingPanel'
import {
  ReservationMeta,
  Reservation,
  ReservationsSearchOptions,
} from '../../models/reservation'
import { ReservationList } from '../organisms/ReservationList'
import { AboutReservationPanel } from '../organisms/AboutReservationPanel'
import { useMember, useOnetimeCode } from '../../hooks/member'
import { useReservations } from '../../hooks/reservation'
import { useScroll } from '../../hooks/scroll'
import { getDateStr } from '../../utils/date'
import { ReservationBarcodePanel } from '../organisms/ReservationBarcodePanel'

export function ReservationsLoadingPagePresenter() {
  const Main = (
    <>
      <BackNavLinkPanel label="戻る" to="/" />
      <AboutReservationPanel />
      <Subtitle text="予約詳細" />
      <LoadingPanel />
    </>
  )

  return <DefaultTemplate title="予約詳細" main={Main} autoScrollToTop />
}

interface ReservationsListPagePresenterProps {
  reservationMeta: ReservationMeta
  reservations: Reservation[]
  dateStr: string
  onPageClick: (page: number) => void
  onReservationClick: (reservation: Reservation) => void
}

export function ReservationsListPagePresenter({
  reservationMeta,
  reservations,
  dateStr,
  onPageClick,
  onReservationClick,
}: ReservationsListPagePresenterProps) {
  const Main = useMemo(() => {
    return (
      <>
        <BackNavLinkPanel label="戻る" to="/" />
        <AboutReservationPanel />
        <SubtitleWithPager
          text="予約詳細"
          totalNum={reservationMeta.dataCount}
          perPage={reservationMeta.perPage}
          currentPage={reservationMeta.pageNo}
        />
        <ReservationList
          reservations={reservations}
          dateStr={dateStr}
          onReservationClick={onReservationClick}
        />
        {reservations.length > 0 && (
          <PagerBar
            totalNum={reservationMeta.dataCount}
            perPage={reservationMeta.perPage}
            currentPage={reservationMeta.pageNo}
            onPageClick={onPageClick}
          />
        )}
      </>
    )
  }, [reservations, dateStr, reservationMeta, onPageClick, onReservationClick])

  return <DefaultTemplate title="予約" main={Main} autoScrollToTop />
}

interface ReservationBarcodePagePresenterProps {
  reservation: Reservation
  // account
  onetimeCode: string | undefined
  onetimeCodeExpiresAt: string | undefined
  cardNo: string
  onBackToList: () => void
  // account
  onOnetimeCodeExpire: () => void
}

export function ReservationBarcodePagePresenter({
  reservation,
  onetimeCode,
  onetimeCodeExpiresAt,
  cardNo,
  onBackToList,
  onOnetimeCodeExpire,
}: ReservationBarcodePagePresenterProps) {
  const Main = useMemo(() => {
    return (
      <>
        <BackNavButtonPanel label="戻る" onClick={onBackToList} />
        <AccountBarcodeLabel />
        <AccountBarcode
          onetimeCode={onetimeCode}
          onetimeCodeExpiresAt={onetimeCodeExpiresAt}
          cardNo={cardNo}
          paddingTop="8px"
          paddingBottom="18px"
          onOnetimeCodeExpire={onOnetimeCodeExpire}
        />
        <ReservationBarcodePanel reservation={reservation} />
      </>
    )
  }, [
    reservation,
    onetimeCode,
    onetimeCodeExpiresAt,
    cardNo,
    onBackToList,
    onOnetimeCodeExpire,
  ])

  return <DefaultTemplate title="予約" main={Main} autoScrollToTop />
}

function useReservationsPage() {
  const { member, fetchMember } = useMember()
  const { onetimeCode, onetimeCodeExpiresAt, fetchOnetimeCode } =
    useOnetimeCode()
  const { reservationMeta, reservations, fetchReservations } = useReservations()
  const perPage = 10
  const [selectedReservation, setSelectedReservation] = useState<
    Reservation | undefined
  >(undefined)
  const dateStr = getDateStr()
  const { saveCurrentScrollY, resetScroll, restoreScroll } = useScroll()
  const handlePageClick = useCallback(
    (page: number) => {
      const searchOptions: ReservationsSearchOptions = {
        count: perPage,
        page,
      }
      fetchReservations(searchOptions)
    },
    [perPage, fetchReservations]
  )
  const handleReservationClick = useCallback(
    (reservation: Reservation) => {
      saveCurrentScrollY()
      setSelectedReservation(reservation)
    },
    [saveCurrentScrollY, setSelectedReservation]
  )
  const handleBackToList = useCallback(() => {
    setSelectedReservation(undefined)
  }, [setSelectedReservation])
  const handleOnetimeCodeExpire = useCallback(() => {
    fetchOnetimeCode()
  }, [fetchOnetimeCode])

  useEffect(() => {
    const searchOptions: ReservationsSearchOptions = {
      count: perPage,
      page: 1,
    }
    fetchReservations(searchOptions)
    fetchMember()
    fetchOnetimeCode()
  }, [fetchReservations, fetchMember, fetchOnetimeCode])

  useEffect(() => {
    if (selectedReservation) {
      resetScroll()
    } else {
      restoreScroll()
    }
  }, [selectedReservation, resetScroll, restoreScroll])

  return {
    member,
    onetimeCode,
    onetimeCodeExpiresAt,
    dateStr,
    reservationMeta,
    reservations,
    selectedReservation,
    handlePageClick,
    handleReservationClick,
    handleBackToList,
    handleOnetimeCodeExpire,
  }
}

export function ReservationsPage() {
  const {
    member,
    onetimeCode,
    onetimeCodeExpiresAt,
    dateStr,
    reservationMeta,
    reservations,
    selectedReservation,
    handlePageClick,
    handleReservationClick,
    handleBackToList,
    handleOnetimeCodeExpire,
  } = useReservationsPage()

  if (!member || !reservationMeta || !reservations) {
    return <ReservationsLoadingPagePresenter />
  }

  if (!selectedReservation) {
    return (
      <ReservationsListPagePresenter
        reservationMeta={reservationMeta}
        reservations={reservations}
        dateStr={dateStr}
        onPageClick={handlePageClick}
        onReservationClick={handleReservationClick}
      />
    )
  }

  return (
    <ReservationBarcodePagePresenter
      reservation={selectedReservation}
      onetimeCode={onetimeCode}
      onetimeCodeExpiresAt={onetimeCodeExpiresAt}
      cardNo={member.cardNo}
      onBackToList={handleBackToList}
      onOnetimeCodeExpire={handleOnetimeCodeExpire}
    />
  )
}
