import React from 'react'

export function ReservationIcon() {
  return (
    <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path
          stroke="#5B5B5B"
          d="M6.479 1.333v5.334M22 1.333v5.334M30.667 22.667a8 8 0 11-16 0 8 8 0 0116 0z"
        />
        <path stroke="#5B5B5B" d="M27.333 16V4h-26v22h13.682" />
        <path stroke="#FF9501" d="M26.667 22.667h-4v-4" />
      </g>
    </svg>
  )
}
