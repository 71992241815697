interface AnnouncementsRawResponseRecord {
  title: string
  description: string
  category_name: string
  image_url: string | undefined
  announcement_date: string
  m_announcement_id: number
}

export interface AnnouncementsRawResponse {
  status: 0 | 100 // 正常終了=0, トークン有効期限切れ=200
  data_count: number
  per_page: number
  page_count: number
  not_read_count: number
  announcements: AnnouncementsRawResponseRecord[]
}

export interface AnnouncementMeta {
  dataCount: number
  perPage: number
  pageCount: number
  unreadCount: number
}

export interface Announcement {
  title: string
  description: string
  categoryName: string
  imageUrl: string | undefined
  announcementDate: string
  mAnnouncementId: number
}

export interface AnnouncementsResponse {
  status: 0 | 100 // 正常終了=0, トークン有効期限切れ=200
  meta: AnnouncementMeta
  announcements: Announcement[]
}

export interface AnnouncementsSearchOptions {
  count?: number // ページ当たりの表示件数 未指定の場合全件取得
  page?: number // ページ番号 未指定の場合1
  sort?: number // // ソート(0:日付で降順 1:日付で昇順) 未指定の場合降順
  from?: string // 取得期間開始日(YYYY-MM-DD) 未指定の場合絞らない
  to?: string // 取得期間終了日(YYYY-MM-DD) 未指定の場合絞らない
  id?: number // お知らせID
}

export function parseAnnouncementsSearchOptions(
  options: AnnouncementsSearchOptions
) {
  return {
    count: String(options.count || 100),
    page: String(options.page || 1),
    sort: String(options.sort || 0), // 0:日付で降順 1:日付で昇順
    ...(options.from ? { from: String(options.from) } : undefined),
    ...(options.to ? { to: String(options.to) } : undefined),
    ...(options.id ? { id: String(options.id) } : undefined),
  }
}

export function fromAnnouncementsRawResponse(
  rawResponse: AnnouncementsRawResponse
): { meta: AnnouncementMeta; announcements: Announcement[] } {
  return {
    meta: {
      dataCount: rawResponse.data_count,
      perPage: rawResponse.per_page,
      pageCount: rawResponse.page_count,
      unreadCount: rawResponse.not_read_count,
    },
    announcements: rawResponse.announcements.map((record) => {
      const announcement: Announcement = {
        title: record.title,
        description: record.description,
        categoryName: record.category_name,
        imageUrl: record.image_url,
        announcementDate: record.announcement_date,
        mAnnouncementId: record.m_announcement_id,
      }
      return announcement
    }),
  }
}
