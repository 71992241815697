import { useCallback, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { Announcement } from '../models/announcement'
import {
  AnnouncementActionCreators,
  announcementMetaSelector,
  announcementsSelector,
} from '../modules/announcement'

// const announcements_: Announcement[] = [...Array(10)].map((_, i) => {
//   const n = i + 1
//   return {
//     title: `メンテナンスのお知らせ${11 - n}`,
//     description: `■メンテナンス日時\n2020/5/${20 - n + 1} 23:00 ~ 5/${
//       20 - n + 2
//     } 6:00まで\n\nメンテナンス時間は、急遽変更する場合がございます。\nご不便をおかけしますが、ご理解のほどお願いいたします。\n`,
//     categoryName: 'カテゴリー1',
//     announcementDate: '2020-04-22',
//     mAnnouncementId: 10 - n + 1,
//   }
// })

export function useAnnouncements() {
  const dispatch = useDispatch()
  const fetchAnnouncements = useCallback(() => {
    const options = {}
    return dispatch(
      AnnouncementActionCreators.fetchAnnouncementsAction(options)
    )
  }, [dispatch])
  const announcementMeta = useSelector(announcementMetaSelector, shallowEqual)
  const announcements = useSelector(announcementsSelector, shallowEqual)
  const [selectedAnnouncement, setSelectedAnnouncement] = useState<
    Announcement | undefined
  >(undefined)

  return {
    announcementMeta,
    announcements,
    fetchAnnouncements,
    selectedAnnouncement,
    setSelectedAnnouncement,
  }
}

export function useUpdateAnnouncementAsRead() {
  const dispatch = useDispatch()
  const updateAnnouncementAsRead = useCallback(
    (mAnnouncementId: number) => {
      return dispatch(
        AnnouncementActionCreators.updateAnnouncementAsReadAction(
          mAnnouncementId
        )
      )
    },
    [dispatch]
  )

  return {
    updateAnnouncementAsRead,
  }
}
