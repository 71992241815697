import React, { CSSProperties, useRef, useState } from 'react'

import { Panel } from '../atoms/Panel'
import {
  NoDecorationLink,
  NoDecorationExternalLink,
} from '../atoms/NoDecorationLink'
import { PointHistoryButton } from '../molecules/PointHistoryButton'
import { PointCardButton } from '../molecules/PointCardButton'
import { ReservationButton } from '../molecules/ReservationButton'
import { CouponButton } from '../molecules/CouponButton'
import { BellButton } from '../molecules/BellButton'
import { LineOfficialAccountButton } from '../molecules/LineOfficialAccountButton'
import { OtherItemsButton } from '../molecules/OtherItemsButton'
import { AddHomeShortcutButton } from '../molecules/AddHomeShortcutButton'
import { HomeShortcutModal } from './HomeShortcutModal'
import { ToolTip } from '../atoms/ToolTip'
import { PurchaseHistoryButton } from '../molecules/PurchaseHistoryButton'

const styles: { [name: string]: CSSProperties } = {
  panel: {
    overflowX: 'hidden',
    paddingBottom: '80px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridAutoRows: '80px',
  },
  gridItem: {
    padding: '5px',
    height: '70px',
    // borderStyle: 'solid',
    // borderWidth: '1px',
    // borderColor: 'blue',
    display: 'grid',
    justifySelf: 'strech', // 枠を均等配置
    // justifyContent: 'center', // 中身を真ん中寄せ
    alignItems: 'flex-start', // 中身を上に寄せる
  },
}

// https://developers.line.biz/ja/docs/messaging-api/using-line-url-scheme/#opening-timeline-and-account-page
// https://line.me/R/home/public/main?id={LINE ID without @} 	指定したLINE公式アカウントのタイムラインを開きます。
// https://line.me/R/home/public/profile?id={LINE ID without @} 	指定したLINE公式アカウントのアカウントページを開きます。
// https://line.me/R/home/public/post?id={LINE ID without @}&postId={postId} 指定したLINE公式アカウントの特定のタイムライン投稿を開きます。
//   注：このスキームがサポートされるバージョンは、LINEバージョン9.10.0以降です。
// Bot basic ID / ボットのベーシックID
// https://developers.line.biz/ja/docs/line-login/using-line-url-scheme/
// https://line.me/R/oaMessage/@{LINE ID without @}/  公式アカウントとのトークのページ

interface IconButtonsPanelProps {
  botBasicId: string
  numAnnouncements: number | undefined
  numCoupons: number | undefined
  isShortcutAvailable: boolean
  onHomeShortcutClick: () => void
  onLinkClick: () => void
}

export function IconButtonsPanel({
  botBasicId,
  numAnnouncements,
  numCoupons,
  isShortcutAvailable,
  onHomeShortcutClick,
  onLinkClick,
}: IconButtonsPanelProps) {
  // Link to account's profile
  // const lineAccountLink = `https://line.me/R/home/public/profile?id=${botBasicId}`
  // Link to account's timeline (たぶんメッセージが何もないとプロフィールページが表示される)
  // const lineAccountLink = `https://line.me/R/home/public/main?id=${botBasicId}`
  // Link to account's talk page
  const lineAccountLink = `https://line.me/R/oaMessage/@${botBasicId}/`
  const [showShortcutModal, setShortcutModal] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement | null>(null)
  const clientRect = ref.current?.getBoundingClientRect()
  const shortcutIconPositionX = clientRect != null ? clientRect.x : 0

  return (
    <Panel style={styles.panel} paddingTop={8}>
      <div style={styles.grid}>
        {process.env.REACT_APP_DEBUG_TRADE_HISTORY && (
          <NoDecorationLink to="/trade/history" style={styles.gridItem}>
            <PurchaseHistoryButton />
          </NoDecorationLink>
        )}
        <NoDecorationLink to="/point/history" style={styles.gridItem}>
          <PointHistoryButton />
        </NoDecorationLink>
        <NoDecorationLink to="/cardinfo" style={styles.gridItem}>
          <PointCardButton />
        </NoDecorationLink>
        {process.env.REACT_APP_DEBUG_RESERVATION_DETAILS && (
          <NoDecorationLink to="/reservations" style={styles.gridItem}>
            <ReservationButton />
          </NoDecorationLink>
        )}
        <NoDecorationLink to="/coupons" style={styles.gridItem}>
          <CouponButton badgeCount={numCoupons} />
        </NoDecorationLink>
        <NoDecorationLink to="/announcements" style={styles.gridItem}>
          <BellButton badgeCount={numAnnouncements} />
        </NoDecorationLink>
        <NoDecorationExternalLink to={lineAccountLink} style={styles.gridItem}>
          <LineOfficialAccountButton />
        </NoDecorationExternalLink>
        <NoDecorationLink to="/others" style={styles.gridItem}>
          <OtherItemsButton />
        </NoDecorationLink>
        <div
          ref={ref}
          style={styles.gridItem}
          onClick={() => setShortcutModal(true)}
        >
          <AddHomeShortcutButton />
          <ToolTip
            text={[
              'ホーム画面にショートカットを',
              '追加できるようになりました',
            ]}
            iconPositionX={shortcutIconPositionX}
          />
        </div>
        <HomeShortcutModal
          isShortcutAvailable={isShortcutAvailable}
          showShortcutModal={showShortcutModal}
          closeShortcutModal={() => setShortcutModal(false)}
          onHomeShortcutClick={onHomeShortcutClick}
          onLinkClick={onLinkClick}
        />
      </div>
    </Panel>
  )
}
