import React, { CSSProperties } from 'react'
import { calcPageRange } from '../../utils/pager'

interface SubtitleWithPagerProps {
  text: string
  totalNum: number
  perPage: number
  currentPage: number // 1 ~
}

const styles: { [name: string]: CSSProperties } = {
  wrapper: {
    paddingTop: '6px',
  },
  grid: {
    height: '50px',
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    alignContent: 'center',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  text: {
    justifySelf: 'start',
    color: '#5B5B5B',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'bold',
  },
  pager: {
    justifySelf: 'end',
    color: '#5B5B5B',
    fontSize: '12px',
    lineHeight: '18px',
  },
}

export function SubtitleWithPager({
  text,
  totalNum,
  perPage,
  currentPage,
}: SubtitleWithPagerProps) {
  const { numFrom, numTo } = calcPageRange(totalNum, perPage, currentPage)

  return (
    <div style={styles.wrapper}>
      <div style={styles.grid}>
        <div style={styles.text}>{text}</div>
        {totalNum > 0 && (
          <div style={styles.pager}>
            {numFrom}~{numTo}件 / 全{totalNum}件
          </div>
        )}
      </div>
    </div>
  )
}
