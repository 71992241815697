import React, { CSSProperties, PropsWithChildren } from 'react'

interface PaddingProps {
  top?: string | number
  right?: string | number
  bottom?: string | number
  left?: string | number
}

export function Padding({
  top,
  right,
  bottom,
  left,
  children,
}: PropsWithChildren<PaddingProps>) {
  const style: CSSProperties = {
    margin: 0,
    paddingTop: top || 0,
    paddingRight: right || 0,
    paddingBottom: bottom || 0,
    paddingLeft: left || 0,
  }

  return <div style={style}>{children}</div>
}
