import { useCallback, useReducer } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import {
  CouponActionCreators,
  couponMetaSelector,
  couponsSelector,
} from '../modules/coupon'
import { Coupon, CouponsSearchOptions, sortCoupons } from '../models/coupon'
import { daysToMs, formatSearchOptionDate, getDate } from '../utils/date'

export function useCoupons() {
  const dispatch = useDispatch()
  const fetchCoupons = useCallback(() => {
    const from = formatSearchOptionDate(getDate())
    const to = formatSearchOptionDate(getDate(daysToMs(7)))
    const options: CouponsSearchOptions = {
      // CRM 側で対応された様子
      // from と to で指定した日付の期間で利用可能なクーポンが返ってくるようになっている
      // from <= 有効期限 <= to で、from と to に有効期限が含まれます
      // from: from!,
      // to: to!,
    }
    console.debug('FIXME: specify fetchCoupons: from, to:', from, to)
    return dispatch(CouponActionCreators.fetchCouponsAction(options))
  }, [dispatch])
  const couponMeta = useSelector(couponMetaSelector, shallowEqual)
  const coupons = useSelector(couponsSelector, shallowEqual)
  const orderedCoupons = sortCoupons(coupons)

  return {
    couponMeta,
    coupons: orderedCoupons,
    fetchCoupons,
  }
}

interface SelectedCouponCodesState {
  selectedCoupons: { [couponCode: string]: Coupon }
}

interface SelectedCouponCodesAction {
  type: 'add' | 'remove'
  payload: { coupon: Coupon }
}

const initialSelectedCouponsState: SelectedCouponCodesState = {
  selectedCoupons: {},
}

function selectedCouponsReducer(
  state: SelectedCouponCodesState,
  action: SelectedCouponCodesAction
) {
  const coupon = action.payload.coupon
  const couponCode = coupon.couponCode
  switch (action.type) {
    case 'add':
      return {
        selectedCoupons: {
          ...state.selectedCoupons,
          [couponCode]: coupon,
        },
      }
    case 'remove':
      const { [couponCode]: _, ...reducedSelectedCoupons } =
        state.selectedCoupons
      return { selectedCoupons: reducedSelectedCoupons }
    default:
      throw new Error()
  }
}

export function useSelectedCoupons() {
  const [selectedCoupons, dispatch] = useReducer(
    selectedCouponsReducer,
    initialSelectedCouponsState
  )
  const toggleSelectedCoupon = useCallback(
    (coupon: Coupon) => {
      if (!!selectedCoupons.selectedCoupons[coupon.couponCode]) {
        dispatch({ type: 'remove', payload: { coupon } })
      } else {
        dispatch({ type: 'add', payload: { coupon } })
      }
    },
    [selectedCoupons]
  )

  return {
    selectedCoupons: selectedCoupons.selectedCoupons,
    toggleSelectedCoupon,
  }
}
