import React, { FocusEvent, useCallback } from 'react'

import { InputProps, Input } from '../atoms/Input'

type InputTextKanaProps = InputProps & {
  // formik
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => any
}

function hiraganaToKatakana(text: string) {
  return text.replace(/[ぁ-ゔ]/g, (char) => {
    return String.fromCharCode(char.charCodeAt(0) + 0x60)
  })
}

export function InputTextKana({
  name,
  value,
  readOnly,
  onChange,
  onBlur,
  setFieldValue,
  ...rest
}: InputTextKanaProps) {
  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (value) {
        const correctedValue = hiraganaToKatakana(value)
        if (correctedValue !== value) {
          setFieldValue && setFieldValue(name, correctedValue)
        }
      }
      onBlur && onBlur(event)
    },
    [name, value, onBlur, setFieldValue]
  )

  return (
    <Input
      type="text"
      name={name}
      value={value}
      readOnly={readOnly}
      onChange={onChange}
      onBlur={handleBlur}
      {...rest}
    />
  )
}
