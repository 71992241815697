import React, { CSSProperties, ReactElement } from 'react'
import { Link } from 'react-router-dom'

interface InfoListItemHeaderProps {
  label: string | ReactElement
  rounded?: boolean
}

export function InfoListItemHeader({
  label,
  rounded,
}: InfoListItemHeaderProps) {
  const style: CSSProperties = {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '18px',
    borderRadius: rounded ? '8px 8px 0 0' : '0',
    backgroundColor: '#f8f8f8',
    minHeight: '40px',
    paddingLeft: '16px',
    display: 'grid',
    justifyContent: 'start',
    alignContent: 'center',
  }

  return <div style={style}>{label}</div>
}

interface InfoListItemHeaderWithActionProps {
  label: string | ReactElement
  actions: { label: string; path: string }[]
  rounded?: boolean
}

export function InfoListItemHeaderWithAction({
  label,
  actions,
  rounded,
}: InfoListItemHeaderWithActionProps) {
  const styles: { [name: string]: CSSProperties } = {
    item: {
      fontSize: '12px',
      fontWeight: 'bold',
      lineHeight: '18px',
      borderRadius: rounded ? '8px 8px 0 0' : '0',
      backgroundColor: '#f8f8f8',
      minHeight: '40px',
      paddingLeft: '16px',
      paddingRight: '16px',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      justifyContent: 'start',
      alignContent: 'center',
    },
    label: {
      justifySelf: 'start',
    },
    actions: {
      justifySelf: 'end',
    },
    action: {
      textDecoration: 'none',
      color: '#f06400',
    },
  }

  return (
    <div style={styles.item}>
      <div style={styles.label}>{label}</div>
      <div style={styles.actions}>
        {actions.map((action, i) => {
          return (
            <Link key={i} to={action.path} style={styles.action}>
              {action.label}
            </Link>
          )
        })}
      </div>
    </div>
  )
}

interface InfoListItemProps {
  value: string
  rounded?: boolean
}

const itemStyle: CSSProperties = {
  fontSize: '14px',
  fontWeight: 'normal',
  lineHeight: '16px', // '21px',
}

export function InfoListItem({ value, rounded }: InfoListItemProps) {
  const style: CSSProperties = {
    ...itemStyle,
    borderRadius: rounded ? '0 0 8px 8px' : '0',
    backgroundColor: '#fff',
    minHeight: '44px',
    paddingLeft: '16px',
    display: 'grid',
    justifyContent: 'start',
    alignContent: 'center',
  }

  return <div style={style}>{value}</div>
}

interface InfoListItemWithLabelProps {
  label: string | ReactElement
  value: string
  rounded?: boolean
}

export function InfoListItemWithLabel({
  label,
  value,
  rounded,
}: InfoListItemWithLabelProps) {
  const styles: { [name: string]: CSSProperties } = {
    item: {
      ...itemStyle,
      borderRadius: rounded ? '0 0 8px 8px' : '0',
      backgroundColor: '#fff',
      minHeight: '44px',
      paddingLeft: '16px',
      display: 'grid',
      gridTemplateColumns: '64px auto',
      justifyContent: 'start',
    },
    label: {
      display: 'grid',
      alignContent: 'center',
    },
    value: {
      display: 'grid',
      alignContent: 'center',
    },
  }

  return (
    <div style={styles.item}>
      <div style={styles.label}>{label}</div>
      <div style={styles.value}>{value}</div>
    </div>
  )
}

export function InfoListDivider() {
  const styles: { [name: string]: CSSProperties } = {
    container: {
      backgroundColor: '#fff',
    },
    line: {
      backgroundColor: '#d8d8d8',
      height: '1px',
      margin: '0 16px 0 16px',
    },
  }

  return (
    <div style={styles.container}>
      <div style={styles.line} />
    </div>
  )
}
