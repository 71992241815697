import React, { InputHTMLAttributes, CSSProperties } from 'react'

export interface InputProps {
  name: string
  value: string | undefined
  pattern?: string | undefined
  readOnly?: boolean | undefined
  required?: boolean | undefined
  variant?: 'default' | 'borderless'
  style?: CSSProperties | undefined
  onChange?: InputHTMLAttributes<HTMLInputElement>['onChange']
  onBlur?: InputHTMLAttributes<HTMLInputElement>['onBlur']
}

type BaseInputProps = InputProps & {
  type: InputHTMLAttributes<HTMLInputElement>['type']
}

const styles: { [name: string]: CSSProperties } = {
  input: {
    boxSizing: 'border-box',
    // width: '100%',
    minWidth: '0px', // これがないと input が grid かははみ出す
    height: '51px',
    paddingLeft: '16px',
    paddingRight: '16px',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#000',
    backgroundColor: '#fff',
    borderRadius: '8px',
    // date のフィールドの横幅が縮んでしまう問題対策
    WebkitAppearance: 'none',
    MozAppearance: 'none',
  },
  default: {
    borderStyle: 'solid',
    borderWidth: '3px',
    borderColor: '#d8d8d8',
  },
  borderless: {
    borderStyle: 'solid',
    borderWidth: '3px',
    borderColor: 'rgba(0, 0, 0, 0)',
  },
}

export function Input({
  type,
  name,
  value,
  pattern,
  readOnly,
  required,
  variant = 'default',
  style,
  onChange,
  onBlur,
  ...rest
}: BaseInputProps) {
  const inputStyle: CSSProperties = {
    ...styles.input,
    ...styles[variant],
    ...(readOnly && {
      borderColor: 'transparent',
    }),
    ...style,
  }
  return (
    <input
      type={type}
      name={name}
      value={value}
      pattern={pattern}
      readOnly={readOnly}
      required={required}
      onChange={onChange}
      onBlur={onBlur}
      style={inputStyle}
      {...rest}
    />
  )
}
