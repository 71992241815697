import React, { CSSProperties } from 'react'

import { Panel } from '../atoms/Panel'
import { Title } from '../atoms/Title'
import { Subtitle } from '../molecules/Subtitle'
import {
  RoundedListItemHeader,
  RoundedListItem,
  RoundedListItemFooter,
} from '../molecules/RoundedList'
import { RoundedPanel } from '../molecules/RoundedPanel'
// import { BareParagraph } from '../molecules/BareParagraph'

const descriptionStyle: CSSProperties = {
  fontSize: '12px',
  lineHeight: '16px',
  color: '#282828',
}

// テイツーポイントとは？
export function PointCardQAndA() {
  const styles: { [name: string]: CSSProperties } = {
    description: {
      ...descriptionStyle,
      fontWeight: 'bold',
      paddingTop: '11px',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '16px',
    },
    subdescription: {
      ...descriptionStyle,
      fontWeight: 'normal',
      paddingTop: '11px',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '16px',
    },
    commentList: {
      paddingTop: '12px',
      paddingLeft: '16px',
      listStyleType: "'※'",
      fontSize: '10px',
      lineHeight: '14px',
      fontWeight: 'normal',
    },
  }
  return (
    <Panel paddingBottom="40px">
      <div>
        <RoundedListItemHeader>
          <div style={styles.description}>
            ふるいちポイントは、古本市場、ふるいち、
            ふる1、ブック・スクウェア、トレカパーク、
            ホビーパーク各店にてご購入時・買取時に貯めたり
            お使いいただける共通ポイントです。
          </div>
        </RoundedListItemHeader>
        <RoundedListItem>
          <div style={styles.subdescription}>
            ポイント機能は入会金・年会費無料。無料で発行いたします。発行したその日からポイントを貯めることができます。
          </div>
        </RoundedListItem>
      </div>
      <RoundedListItem>
        <div style={styles.description}>
          お買上・買取に応じてポイント還元、ポイントは1ポイント＝1円として利用可能。
        </div>
      </RoundedListItem>
      <RoundedListItem>
        <div style={styles.subdescription}>
          1ポイントから無駄なく使えます。お会計の際に「ポイントを使います」とお申し出下さい。
        </div>
      </RoundedListItem>
      <RoundedListItem>
        <div style={styles.description}>貯まったポイントは即日ご利用可能。</div>
      </RoundedListItem>
      <RoundedListItemFooter>
        <div style={styles.subdescription}>
          次回以降のお買い物以後いつでも使え、現金との
          併用も可能。また、現金でもクレジットカード、電子
          マネー等でのお支払いでもポイントを進呈。
        </div>
      </RoundedListItemFooter>
      <ul style={styles.commentList}>
        <li>
          ふるいちポイントカード、ふるいちアプリの
          ご提示が無い場合、ポイント付与・利用は出来ません。
        </li>
        <li>
          ポイントでのご購入の場合、そのポイントご購入分に対する
          ポイントの進呈はございません。
        </li>
        <li>
          クレジットカード、電子マネー等の取扱については 店舗により異なります。
        </li>
      </ul>
    </Panel>
  )
}

// テイツーポイントサービスの特徴
export function PointCardAdvantage() {
  const styles: { [name: string]: CSSProperties } = {
    header: {
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 'normal',
    },
    list: {
      paddingTop: '12px',
      paddingLeft: '8px',
      listStyleType: "'●'",
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 'normal',
    },
  }
  return (
    <Panel paddingBottom="40px">
      <RoundedPanel>
        <h3 style={styles.header}>一般会員の場合</h3>
        <ul style={styles.list}>
          <li>税込金額に応じてポイント加算させて頂きます。</li>
          <li>
            ポイント有効期限は最終ご利用日から1年間有効。ポイント使用だけの場合も、最後にご利用頂いた日から1年間有効です。
          </li>
          <li>貯まっているポイントも含めて1年延長されます。</li>
          <li>
            ポイントカード機能をご利用頂いた際に、来店回数を加算しポイント有効期限が変更されます。
          </li>
          <li>
            ポイント残高・履歴についてはふるいちアプリ、専用WEBページ「マイページ」より確認頂けます。
          </li>
        </ul>
      </RoundedPanel>
    </Panel>
  )
}

// どれだけ貯まるの？
export function PointCardSystem() {
  const styles: { [name: string]: CSSProperties } = {
    frame: {
      border: '6px solid #FFC559',
    },
    figure: {
      width: '100%',
    },
    description: {
      ...descriptionStyle,
      paddingTop: '8px',
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingBottom: '8px',
    },
    systemRemark: {
      ...descriptionStyle,
      paddingTop: '8px',
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  }
  return (
    <Panel paddingBottom="40px">
      <RoundedPanel>
        <img
          style={styles.figure}
          src="/images/howtouse01@3x.png"
          alt="直近のお買いもの・買取に応じてポイントが増える！ご利用頂ければ頂くほどポイントもお得！！"
        />
        <div style={styles.description}>
          ふるいちポイント会員ステージは
          「ポイントカード会員」「アプリ会員」・「アプリ登録会員」・
          「アプリVIP会員」があり、ステージによりポイント付与率が異なります。
        </div>
        <img
          style={styles.figure}
          src="/images/howtouse02@3x.png"
          alt="「アプリVIP」になるには?"
        />
      </RoundedPanel>
      <div style={styles.systemRemark}>
        ※2021年2月3日以前に発行されいている「テイツーポイントカード」
        「テイツーカード」会員はポイント付与率、VIP昇格基準が異なります。
      </div>
    </Panel>
  )
}

// VIP会員のポイント率反映について
export function PointCardVipsRate() {
  const styles: { [name: string]: CSSProperties } = {
    strongDescription: {
      ...descriptionStyle,
      fontWeight: 'bold',
      paddingTop: '8px',
      paddingLeft: '8px',
      paddingRight: '8px',
    },
    description: {
      ...descriptionStyle,
      padding: '8px',
    },
    figure: {
      width: '100%',
    },
    attention: {
      paddingTop: '10px',
      border: '4px solid #CF1414',
    },
    attentionMessages: {
      listStyleType: 'none',
      paddingTop: '8px',
      paddingRight: '16px',
      paddingLeft: '16px',
      // paddingBottom: '12px',
    },
    attentionMessage: {
      color: '#CF1414',
      fontSize: '10px',
      fontWeight: 'normal',
      lineHeight: '14px',
      paddingBottom: '14px',
    },
  }
  return (
    <Panel>
      <RoundedPanel>
        <div style={styles.strongDescription}>
          直近の累計付与ポイント数及びお会計回数に応じて、翌月のポイント率がUP！
        </div>
        <div style={styles.description}>
          お買い物・買取ご利用頂くほどおトクなポイント制度。
        </div>
        <img
          style={styles.figure}
          src="/images/howtouse03@3x.png"
          alt="ポイント付与率反映のタイミング"
        />
        <div style={styles.description}>
          お客様のランクは、ふるいちアプリ、ポイントカードマイページにてご確認いただけます。
          ※ポイントカードマイページは「登録アプリ会員」の
          お客様のみご利用いただけます。
        </div>
        <img
          style={styles.figure}
          src="/images/howtouse04@3x.png"
          alt="ポイントランクシステム"
        />
        <div style={styles.attention}>
          <ul style={styles.attentionMessages}>
            <li style={styles.attentionMessage}>
              【注意】　お客様の会員ステージは、直近のご利用金額に応じて翌月変化いたします。
            </li>
            <li style={styles.attentionMessage}>
              例1：VIP会員のお客様がVIP期間終了時点でVIP基準を満たさない場合、翌月はアプリ登録会員となります。
            </li>
          </ul>
        </div>
      </RoundedPanel>
      <div style={styles.description}>
        ※ポイントカード機能をご利用頂いた際に、来店回数を加算しポイント有効期限が変更されます。
      </div>
    </Panel>
  )
}

// ご利用いただける店舗は？
export function SupportedStores() {
  const styles: { [name: string]: CSSProperties } = {
    description: {
      ...descriptionStyle,
      fontWeight: 'bold',
      padding: 0,
    },
  }
  return (
    <Panel paddingBottom="40px">
      <RoundedPanel>
        <div style={styles.description}>
          古本市場、ふるいち、ふる1、ブック・スクウェア、トレカパーク店舗（一部を除く）
        </div>
      </RoundedPanel>
    </Panel>
  )
}

export function AboutPointCardPanel() {
  return (
    <div>
      <Title text={['ふるいちポイント', 'ふるいちポイントシステムについて']} />
      <Subtitle text="ふるいちポイントとは？" />
      <PointCardQAndA />
      <Subtitle text="ふるいちポイントの特徴" />
      <PointCardAdvantage />
      <Subtitle text="どれだけ貯まるの？" />
      <PointCardSystem />
      {/* <Subtitle text="VIP制度について" /> */}
      <Subtitle text="VIP会員のポイント率反映について" />
      <PointCardVipsRate />
      <Subtitle text="ご利用いただける店舗は？" />
      <SupportedStores />
    </div>
  )
}
