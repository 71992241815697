import React, { useCallback, useEffect } from 'react'

import { BackNavLinkPanel, BackNavButtonPanel } from '../organisms/BackNavPanel'
import { DefaultTemplate } from '../templates/DefaultTemplate'
import { AnnouncementListPanel } from '../organisms/AnnouncementListPanel'
import { Announcement } from '../../models/announcement'
import { AnnouncementDetailPanel } from '../organisms/AnnouncementDetailPanel'
import { LoadingPanel } from '../molecules/LoadingPanel'
import {
  useAnnouncements,
  useUpdateAnnouncementAsRead,
} from '../../hooks/announcement'
import { useScroll } from '../../hooks/scroll'
import { Padding } from '../atoms/Padding'

interface AnnouncementListPagePresenterProps {
  announcements: Announcement[] | undefined
  onDetailClick: (annoucement: Announcement) => void
}

export function AnnouncementListPagePresenter({
  announcements,
  onDetailClick,
}: AnnouncementListPagePresenterProps) {
  const Main = (
    <>
      <BackNavLinkPanel label="戻る" to="/pointcard" />
      {announcements ? (
        <Padding bottom="40px">
          <AnnouncementListPanel
            announcements={announcements}
            onDetailClick={onDetailClick}
          />
        </Padding>
      ) : (
        <LoadingPanel />
      )}
    </>
  )

  return <DefaultTemplate title="お知らせ" main={Main} autoScrollToTop />
}

interface AnnouncementDetailPagePresenterProps {
  announcement: Announcement
  onBackClick: () => void
}

export function AnnouncementDetailPagePresenter({
  announcement,
  onBackClick,
}: AnnouncementDetailPagePresenterProps) {
  const Main = (
    <>
      <BackNavButtonPanel label="戻る" onClick={onBackClick} />
      <Padding bottom="40px">
        <AnnouncementDetailPanel announcement={announcement} />
      </Padding>
    </>
  )

  return <DefaultTemplate title="お知らせ情報" main={Main} autoScrollToTop />
}

function useAnnouncementsPage() {
  const {
    announcements,
    fetchAnnouncements,
    selectedAnnouncement,
    setSelectedAnnouncement,
  } = useAnnouncements()
  const { updateAnnouncementAsRead } = useUpdateAnnouncementAsRead()
  const { saveCurrentScrollY, resetScroll, restoreScroll } = useScroll()
  const handleDetailClick = useCallback(
    (announcement: Announcement) => {
      saveCurrentScrollY()
      setSelectedAnnouncement(announcement)
      updateAnnouncementAsRead(announcement.mAnnouncementId)
    },
    [setSelectedAnnouncement, updateAnnouncementAsRead, saveCurrentScrollY]
  )
  const handleBackClick = useCallback(() => {
    setSelectedAnnouncement(undefined)
  }, [setSelectedAnnouncement])

  useEffect(() => {
    fetchAnnouncements()
  }, [fetchAnnouncements])
  useEffect(() => {
    if (selectedAnnouncement) {
      resetScroll()
    } else {
      restoreScroll()
    }
  }, [selectedAnnouncement, resetScroll, restoreScroll])

  return {
    announcements,
    selectedAnnouncement,
    handleDetailClick,
    handleBackClick,
  }
}

export function AnnouncementsPage() {
  const {
    announcements,
    selectedAnnouncement,
    handleDetailClick,
    handleBackClick,
  } = useAnnouncementsPage()

  if (selectedAnnouncement === undefined) {
    return (
      <AnnouncementListPagePresenter
        announcements={announcements}
        onDetailClick={handleDetailClick}
      />
    )
  }

  return (
    <AnnouncementDetailPagePresenter
      announcement={selectedAnnouncement}
      onBackClick={handleBackClick}
    />
  )
}
