import React, { CSSProperties } from 'react'

import { Spinner } from '../atoms/icons/Spinner'

const styles: { [name: string]: CSSProperties } = {
  container: {
    height: '100%',
    display: 'grid',
    placeItems: 'center',
  },
}

export function LoadingPanel() {
  return (
    <div style={styles.container}>
      <Spinner />
    </div>
  )
}
