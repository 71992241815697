import React from 'react'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import { ConnectedRouter } from 'connected-react-router'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import 'ress'

import { useGoogleAnalytics } from './hooks/googleAnalytics'
import { Routes } from './Routes'
import { createFinalStore } from './store'
import { LiffProvider } from './hooks/liff'
import { ApiProvider } from './hooks/api'

const liffId = process.env.REACT_APP_LIFF_ID!
const apiHost = process.env.REACT_APP_API_HOST!
const botBasicId = process.env.REACT_APP_BOT_BASIC_ID!
// const gaMeasurementId = process.env.REACT_APP_GA_MEASUREMENT_ID!
const history = createBrowserHistory()
const store = createFinalStore(history)

const gaMeasurementId = process.env.REACT_APP_GA_MEASUREMENT_ID!

function App() {
  useGoogleAnalytics(history, gaMeasurementId)

  return (
    <HelmetProvider>
      <Helmet>
        <title>ふるいちアプリ</title>
      </Helmet>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <LiffProvider liffId={liffId} botBasicId={botBasicId}>
            <ApiProvider apiHost={apiHost}>
              <Routes />
            </ApiProvider>
          </LiffProvider>
        </ConnectedRouter>
      </Provider>
    </HelmetProvider>
  )
}

export default App
