import React, { CSSProperties } from 'react'

import { Barcode } from '../atoms/Barcode'
import { FullWidthListItem } from '../molecules/FullWidthList'
import { Coupon } from '../../models/coupon'

const styles: { [name: string]: CSSProperties } = {
  container: {
    borderTop: '1px solid #D8D8D8',
    paddingBottom: '16px',
    backgroundColor: '#FFFFFF',
  },
  listItem: {
    border: 0,
  },
  couponName: {
    fontSize: '12px',
    lineHeight: '18px',
    paddingTop: '12px',
    paddingLeft: '16px',
    paddingRight: '16px',
    display: 'grid',
    gridTemplateColumns: 'auto',
    justifyContent: 'start',
  },
  barcode: {
    paddingTop: '16px',
    paddingBottom: '8px',
    color: '#000000',
    display: 'grid',
    justifyItems: 'center',
    alignItems: 'end',
  },
  code: {
    fontSize: '14px',
    fontWeight: 'bold',
    // fontFamily: 'Helvetica Bold',
    color: '#282828',
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'end',
  },
}

interface CouponBarcodeListItemProps {
  coupon: Coupon
}

export function CouponBarcodeListItem({ coupon }: CouponBarcodeListItemProps) {
  return (
    <FullWidthListItem style={styles.listItem}>
      <div style={styles.couponName}>{coupon.couponName}</div>
      <div style={styles.barcode}>
        <Barcode value={coupon.couponCode} />
      </div>
      <div style={styles.code}>
        <span>{coupon.couponCode}</span>
      </div>
    </FullWidthListItem>
  )
}

interface CouponBarcodeListProps {
  selectedCoupons: { [couponCode: string]: Coupon }
}

export function CouponBarcodeList({ selectedCoupons }: CouponBarcodeListProps) {
  return (
    <div style={styles.container}>
      {Object.keys(selectedCoupons).map((couponCode, i) => {
        const coupon = selectedCoupons[couponCode]
        return <CouponBarcodeListItem key={i} coupon={coupon} />
      })}
    </div>
  )
}
