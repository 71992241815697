import React, { InputHTMLAttributes, CSSProperties } from 'react'

import { InputText } from './InputText'

interface SelectProps {
  name: string
  value: string | undefined
  optionValues: string[]
  optionLabels: string[]
  readOnly?: boolean | undefined
  onChange?: InputHTMLAttributes<HTMLSelectElement>['onChange']
}

const styles: { [name: string]: CSSProperties } = {
  input: {
    boxSizing: 'border-box',
    width: '100%',
    height: '51px',
    paddingLeft: '16px',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#000',
    backgroundColor: '#fff',
    borderStyle: 'solid',
    borderWidth: '3px',
    borderColor: '#d8d8d8',
    borderRadius: '8px',
  },
}

export function Select({
  name,
  value,
  optionValues,
  optionLabels,
  readOnly,
  onChange,
}: SelectProps) {
  return readOnly ? (
    <InputText name={name} value={value ? value : ''} readOnly />
  ) : (
    <select name={name} onChange={onChange} value={value} style={styles.input}>
      <option value=""></option>
      {optionValues.map((optionValue, i) => {
        const optionLabel = optionLabels[i]
        return (
          <option key={i} value={optionValue}>
            {optionLabel}
          </option>
        )
      })}
    </select>
  )
}
