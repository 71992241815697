import React, { CSSProperties } from 'react'

import { Panel } from '../atoms/Panel'
import { OpenAgreementButtonLink } from '../molecules/OpenAgreementButton'
import { appVersion } from '../../utils/appInfo'
import { Padding } from '../atoms/Padding'

const styles: { [name: string]: CSSProperties } = {
  wrapper: {
    marginTop: '16px',
  },
  fieldLabel: {
    marginTop: '14.5px',
  },
  version: {
    fontWeight: 'bold',
  },
}

interface OtherItemListProps {
  toMembershipAgreement: string
  toPrivacyPolicy: string
}

export function OtherItemList({
  toMembershipAgreement,
  toPrivacyPolicy,
}: OtherItemListProps) {
  return (
    <>
      <Panel style={styles.wrapper}>
        <Padding top="8px">
          <OpenAgreementButtonLink to={toMembershipAgreement}>
            会員規約・利用規約
          </OpenAgreementButtonLink>
        </Padding>
        <Padding top="22.5px">
          <OpenAgreementButtonLink to={toPrivacyPolicy}>
            個人情報保護方針
          </OpenAgreementButtonLink>
        </Padding>
        <Padding top="22.5px" left="16px">
          <div style={styles.version}>バージョン v{appVersion}</div>
        </Padding>
      </Panel>
    </>
  )
}
