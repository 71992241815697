import React, { CSSProperties, MouseEvent, PropsWithChildren } from 'react'

const styles: { [name: string]: CSSProperties } = {
  header: {
    borderRadius: '8px 8px 0 0',
    marginBottom: '1px',
    backgroundColor: '#fff',
  },
  item: {
    marginBottom: '1px',
    backgroundColor: '#fff',
  },
  footer: {
    borderRadius: '0 0 8px 8px',
    marginTop: '1px',
    backgroundColor: '#fff',
  },
}

interface RoundedListItemProps {
  style?: CSSProperties
  paddingTop?: string
  paddingBottom?: string
  backgroundColor?: string
  // onClick?: (event: MouseEvent<HTMLDivElement, MouseEvent>) => void
  // onClick?: (
  //   event: MouseEvent<HTMLDivElement, MouseEvent<Element, MouseEvent>>
  // ) => void
  // onClick?:
  //   | ((event: MouseEvent<HTMLDivElement, MouseEvent>) => void)
  //   | undefined
  onClick?: ((event: MouseEvent<HTMLDivElement>) => void) | undefined
}

export function RoundedListItemHeader({
  style,
  paddingTop,
  paddingBottom,
  backgroundColor,
  onClick,
  children,
}: PropsWithChildren<RoundedListItemProps>) {
  const _style: CSSProperties = {
    ...styles.header,
    ...style,
    ...(paddingTop ? { paddingTop } : {}),
    ...(paddingBottom ? { paddingBottom } : {}),
    ...(backgroundColor ? { backgroundColor } : {}),
  }
  return (
    <div style={_style} onClick={onClick}>
      {children}
    </div>
  )
}

export function RoundedListItem({
  style,
  paddingTop,
  paddingBottom,
  backgroundColor,
  onClick,
  children,
}: PropsWithChildren<RoundedListItemProps>) {
  const _style: CSSProperties = {
    ...styles.item,
    ...style,
    ...(paddingTop ? { paddingTop } : {}),
    ...(paddingBottom ? { paddingBottom } : {}),
    ...(backgroundColor ? { backgroundColor } : {}),
  }
  return (
    <div style={_style} onClick={onClick}>
      {children}
    </div>
  )
}

export function RoundedListItemFooter({
  style,
  paddingTop,
  paddingBottom,
  backgroundColor,
  onClick,
  children,
}: PropsWithChildren<RoundedListItemProps>) {
  const _style: CSSProperties = {
    ...styles.footer,
    ...style,
    ...(paddingTop ? { paddingTop } : {}),
    ...(paddingBottom ? { paddingBottom } : {}),
    ...(backgroundColor ? { backgroundColor } : {}),
  }
  return (
    <div style={_style} onClick={onClick}>
      {children}
    </div>
  )
}
