import React, { CSSProperties } from 'react'

import { useFormik } from 'formik'

import { Panel } from '../atoms/Panel'
import { Button } from '../atoms/Button'
import { Checkbox } from '../molecules/Checkbox'
import { OpenAgreementButton } from '../molecules/OpenAgreementButton'
import { Padding } from '../atoms/Padding'
import {
  FullScreenMembershipAgreement,
  useMembershipAgreement,
} from './FullScreenMembershipAgreement'
import {
  FullScreenPrivacyPolicy,
  usePrivacyPolicy,
} from './FullScreenPrivacyPolicy'

type AppAgreementsFormValues = {
  membershipAgreementAgreed: boolean
  privacyPolicyAgreed: boolean
}

const styles: { [name: string]: CSSProperties } = {
  wrapper: {
    // height: '187px',
    marginTop: '16px',
  },
  fieldLabel: {
    marginTop: '14.5px',
  },
}

const defaultInitialValues: AppAgreementsFormValues = {
  membershipAgreementAgreed: false,
  privacyPolicyAgreed: false,
}

interface AppAgreementsFormProps {
  onSubmit: () => void
}

export function AppAgreementsForm({ onSubmit }: AppAgreementsFormProps) {
  const formik = useFormik<AppAgreementsFormValues>({
    initialValues: defaultInitialValues,
    onSubmit: (values) => {
      console.debug('values:', values)
      onSubmit()
    },
  })
  const {
    membershipAgreementVisibility,
    handleMembershipAgreementOpen,
    haneldMembershipAgreementClose,
  } = useMembershipAgreement()
  const {
    privacyPolicyVisibility,
    handlePrivacyPolicyOpen,
    haneldPrivacyPolicyClose,
  } = usePrivacyPolicy()

  const agreed =
    formik.values.membershipAgreementAgreed && formik.values.privacyPolicyAgreed

  return (
    <>
      {membershipAgreementVisibility && (
        <FullScreenMembershipAgreement
          backLabel="戻る"
          onClose={haneldMembershipAgreementClose}
        />
      )}
      {privacyPolicyVisibility && (
        <FullScreenPrivacyPolicy
          backLabel="戻る"
          onClose={haneldPrivacyPolicyClose}
        />
      )}
      <Panel style={styles.wrapper}>
        <form onSubmit={formik.handleSubmit}>
          <Padding top="22.5px">
            <OpenAgreementButton onClick={handleMembershipAgreementOpen}>
              会員規約・利用規約を読む
            </OpenAgreementButton>
          </Padding>
          <Padding top={0}>
            <Checkbox
              name="membershipAgreementAgreed"
              value="agreed"
              checked={formik.values.membershipAgreementAgreed}
              onChange={formik.handleChange}
            >
              会員規約・利用規約に同意する
            </Checkbox>
          </Padding>
          <Padding top="22.5px">
            <OpenAgreementButton onClick={handlePrivacyPolicyOpen}>
              個人情報保護方針を読む
            </OpenAgreementButton>
          </Padding>
          <Padding top={0}>
            <Checkbox
              name="privacyPolicyAgreed"
              value="agreed"
              checked={formik.values.privacyPolicyAgreed}
              onChange={formik.handleChange}
            >
              個人情報保護方針に同意する
            </Checkbox>
          </Padding>
          <Button
            type="submit"
            variant="black"
            disabled={!agreed}
            style={{ marginTop: '22.5px' }}
          >
            カードを発行する
          </Button>
        </form>
      </Panel>
    </>
  )
}
