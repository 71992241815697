export function calcPageRange(
  totalNum: number,
  perPage: number,
  currentPage: number
) {
  const numFrom = perPage * (currentPage - 1) + 1
  const numTo = Math.min(totalNum, perPage * currentPage)

  return {
    numFrom,
    numTo,
  }
}

export function calcPagerPageNumbers(
  numPages: number,
  currentPage: number
): number[] {
  switch (numPages) {
    case 1:
      return [1]
    case 2:
      return [1, 2]
    case 3:
      return [1, 2, 3]
    case 4:
      return [1, 2, 3, 4]
    case 5:
      return [1, 2, 3, 4, 5]
  }

  let centerPage = currentPage
  switch (currentPage) {
    case 1:
    case 2:
      centerPage = 3
      break
    case numPages:
    case numPages - 1:
      centerPage = numPages - 2
      break
  }

  return [
    centerPage - 2,
    centerPage - 1,
    centerPage,
    centerPage + 1,
    centerPage + 2,
  ]
}

export function calcPager(
  totalNum: number,
  perPage: number,
  currentPage: number
) {
  const numPages = Math.ceil(totalNum / perPage)
  const firstPage = 1
  const lastPage = numPages
  const prevPage = Math.max(currentPage - 1, firstPage)
  const nextPage = Math.min(currentPage + 1, lastPage)
  const pageNumbers = calcPagerPageNumbers(numPages, currentPage)

  return {
    numPages,
    firstPage,
    lastPage,
    prevPage,
    nextPage,
    pageNumbers,
  }
}
