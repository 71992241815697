import { parse } from 'date-fns/parse'
import { differenceInSeconds } from 'date-fns/differenceInSeconds'
import { format } from 'date-fns/format'

const DATETIME_FORMAT_1 = 'yyyy-MM-dd HH:mm:ss'
const DATETIME_FORMAT_2 = 'yyyy/MM/dd HH:mm:ss'

function parseDate(date: string | Date | null | undefined) {
  if (!date) return null
  if (date instanceof Date) return date
  const d1 = parse(date, DATETIME_FORMAT_1, new Date())
  if (!isNaN(+d1)) return d1
  const d2 = parse(date, DATETIME_FORMAT_2, new Date())
  return d2
}

export function remainingTimeInSeconds(
  expiresAtStr: string | undefined,
  nowStr: string | Date | undefined
) {
  const expiresAt = parseDate(expiresAtStr)
  const now = parseDate(nowStr)
  if (!expiresAt || !now) return null

  const remainingInSeconds = differenceInSeconds(expiresAt, now)

  return remainingInSeconds
}

export function formatSeconds(seconds: number | null) {
  if (seconds === null) {
    return '--:--'
  }
  return format(seconds * 1000, 'mm:ss')
}

export function remainingTimeString(
  expiresAtStr: string | undefined,
  nowStr: string | undefined
) {
  const remainingInSeconds = remainingTimeInSeconds(expiresAtStr, nowStr)
  if (remainingInSeconds === null) return '-'

  const remainingInString = formatSeconds(remainingInSeconds)

  return remainingInString
}

export function getNow(): number {
  return Date.now()
}

export function minutesToMilliSeconds(minutes: number) {
  return minutes * 1000 * 60
}

export function formatDatetime(dateStr: string | Date | null | undefined) {
  const date = parseDate(dateStr)

  if (!date) {
    return '-'
  }

  try {
    return format(date, 'yyyy/MM/dd HH:mm')
  } catch (_e: any) {
    return '-'
  }
}
