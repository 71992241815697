export interface PointHistoryMeta {
  dataCount: number
  perPage: number
  pageNo: number
  pageCount: number
}

export interface TotalPointRawResponse {
  status: 0 | 100 // 正常終了=0, トークン有効期限切れ=100
  total_normal_point: number
  total_expire_point: number
  total_all_point: number
  expire_points: {
    [YYYYMMDD: string]: number
  }
}

export interface ExpirationPointInfo {
  expirationDateString: string
  expirationPoints: number
}

export interface TotalPoint {
  totalNormalPoint: number
  totalExpirePoint: number
  totalAllPoint: number
  // extra
  totalPoinstWithoutTimeLimit: number
  totalPoinstWithTimeLimit: number
  grandTotalPoints: number
  expirationPoints: ExpirationPointInfo[]
}

interface PointHistoryRawResponseRecord {
  // history_type: number // 履歴種別 1:獲得,2:使用,3:配布,4:剥奪,5:失効
  point: number // 差分ポイント数(使用したらマイナス)
  trade_datetime: string // 日時
  point_expire_date: string | null // ポイント有効期限(期間限定ポイントのみ、通常ポイントの場合null)
  point_after: number // ポイント残高
  title: string // 履歴タイトル
  shop_name: string // 店舗
}

export interface PointHistoryRawResponse {
  status: 0 | 100 // 正常終了=0, トークン有効期限切れ=200
  data_count: number
  per_page: number
  page_no: number
  page_count: number
  histories: PointHistoryRawResponseRecord[]
}

export interface PointHistoryRecord {
  // historyType: number
  point: number // 差分ポイント数(使用したらマイナス)
  tradeDatetime: string // 日時
  pointExpireDate: string | null // ポイント有効期限(期間限定ポイントのみ、通常ポイントの場合null)
  pointAfter: number // ポイント残高
  title: string // 履歴タイトル
  shopName: string // 店舗
}

export interface PointHistorySearchOptions {
  count?: number // ページ当たりの表示件数 未指定の場合全件取得
  page?: number // ページ番号 未指定の場合1
  sort?: number // ソート(0:日付で降順 1:日付で昇順) 未指定の場合降順
  from?: string // 取得期間開始日(YYYY-MM-DD) 未指定の場合絞らない
  to?: string // 取得期間終了日(YYYY-MM-DD) 未指定の場合絞らない
}

export function parsePointHistorySearchOptions(
  options: PointHistorySearchOptions
) {
  return {
    count: String(options.count || 100),
    page: String(options.page || 1),
    sort: String(options.sort || 0), // 0:日付で降順 1:日付で昇順
    ...(options.from ? { from: String(options.from) } : undefined),
    ...(options.to ? { to: String(options.to) } : undefined),
  }
}

export function fromTotalPointRawResponse(
  rawResponse: TotalPointRawResponse
): TotalPoint {
  const point: TotalPoint = {
    totalNormalPoint: rawResponse.total_normal_point,
    totalExpirePoint: rawResponse.total_expire_point,
    totalAllPoint: rawResponse.total_all_point,
    // extra
    totalPoinstWithoutTimeLimit: rawResponse.total_normal_point,
    totalPoinstWithTimeLimit: rawResponse.total_expire_point,
    grandTotalPoints: rawResponse.total_all_point,
    expirationPoints: Object.entries(
      rawResponse.expire_points ? rawResponse.expire_points : {}
    ).map((tupple) => ({
      expirationDateString: tupple[0],
      expirationPoints: tupple[1],
    })),
  }

  return point
}

function fromPointHistoryRawResponseRecord(
  rawRecord: PointHistoryRawResponseRecord
): PointHistoryRecord {
  return {
    // historyType: rawRecord.history_type,
    point: rawRecord.point,
    tradeDatetime: rawRecord.trade_datetime,
    pointExpireDate: rawRecord.point_expire_date,
    pointAfter: rawRecord.point_after,
    title: rawRecord.title,
    shopName: rawRecord.shop_name,
  }
}

export function fromPointHistoryRawResponse(
  rawResponse: PointHistoryRawResponse
) {
  const meta: PointHistoryMeta = {
    dataCount: rawResponse.data_count,
    perPage: rawResponse.per_page,
    pageNo: rawResponse.page_no,
    pageCount: rawResponse.page_count,
  }
  const records: PointHistoryRecord[] = rawResponse.histories.map((record) => {
    return fromPointHistoryRawResponseRecord(record)
  })

  return [meta, records]
}
