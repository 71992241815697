import React from 'react'

export function HomeShortcutIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5"
        y="5"
        width="9"
        height="9"
        rx="3"
        stroke="#5B5B5B"
        strokeWidth="2.02"
        strokeLinejoin="round"
      />
      <rect
        x="5"
        y="18"
        width="9"
        height="9"
        rx="3"
        stroke="#5B5B5B"
        strokeWidth="2.02"
        strokeLinejoin="round"
      />
      <rect
        x="18"
        y="5"
        width="9"
        height="9"
        rx="3"
        stroke="#5B5B5B"
        strokeWidth="2.02"
        strokeLinejoin="round"
      />
      <rect
        x="18"
        y="18"
        width="9"
        height="9"
        rx="3"
        stroke="#FF9500"
        strokeWidth="2.02"
        strokeLinejoin="round"
      />
    </svg>
  )
}
