import React from 'react'

import { PublicRoutes } from './PublicRoutes'
import { PrivateRoutes } from './PrivateRoutes'
import { LoadingPage } from './components/pages/LoadingPage'
import { useCrmLogin } from './hooks/crmLogin'

interface RoutesPresenterProps {
  isLoggedIn: boolean
  registrationRequired: boolean
  errorMessages: string[] | undefined
}

function RoutesPresenter({
  isLoggedIn,
  registrationRequired,
  errorMessages,
}: RoutesPresenterProps) {
  if (isLoggedIn) {
    return <PrivateRoutes />
  }

  return registrationRequired ? (
    <PublicRoutes />
  ) : (
    <LoadingPage status="logging-in" errorMessages={errorMessages} />
  )
}

export function Routes() {
  const { isLoggedIn, registrationRequired, errorMessages } = useCrmLogin()

  return (
    <RoutesPresenter
      isLoggedIn={isLoggedIn}
      registrationRequired={registrationRequired}
      errorMessages={errorMessages}
    />
  )
}
