import React, { CSSProperties } from 'react'

import { Panel } from '../atoms/Panel'

interface EmailHelpPanelProps {
  apiErrorMessage?: string
  apiErrorStatus?: number
}

const styles: { [name: string]: CSSProperties } = {
  wrapper1: {
    height: '64px',
    backgroundColor: '#ff9501',
    paddingTop: '8px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  title: {
    color: '#fff',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  wrapper2: {
    // height: '64px',
    // backgroundColor: '#fff',
    borderBottom: '1px #D8D8D8 solid',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  noBorderWrapper2: {
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  message: {
    fontSize: '14px',
    lineHeight: '21px',
    textAlign: 'left',
    whiteSpace: 'pre-wrap',
  },
  message2: {
    fontSize: '10px',
    lineHeight: '21px',
    textAlign: 'left',
  },
}

export function EmailHelpPanel({
  apiErrorMessage,
  apiErrorStatus,
}: EmailHelpPanelProps) {
  return (
    <>
      <Panel fullWidth style={styles.wrapper1}>
        <div style={styles.title}>メールアドレスを入力し</div>
        <div style={styles.title}>【送信】ボタンをおしてください</div>
      </Panel>
      <Panel
        style={apiErrorMessage ? styles.noBorderWrapper2 : styles.wrapper2}
      >
        {apiErrorMessage ? (
          <div style={styles.message}>{apiErrorMessage}</div>
        ) : (
          <>
            <div style={styles.message}>
              入力いただいたメールアドレスへ「認証コード」をお送りします。次の画面で、届いた認証コードを入力してください。
            </div>
            <br />
            <div style={styles.message}>
              認証コードは以下のアドレスより送付されます。
            </div>
            <div style={styles.message}>
              受信出来るよう設定をお願いいたします。
            </div>
            <div style={styles.message}>no-reply@taytwo-point.jp</div>
          </>
        )}
        {apiErrorStatus && (
          <div style={styles.message2}>
            {`（エラーコード：${apiErrorStatus}）`}
          </div>
        )}
      </Panel>
    </>
  )
}
