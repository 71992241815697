import React, { CSSProperties } from 'react'

import { TradeHistoryRecord } from '../../models/trade'
import { Receipt } from './Receipt'
import { Guarantee } from './Guarantee'
import { formatDateJa } from '../../utils/date'

interface FooterProps {
  storeCode: string
  systemDate: string
  systemTime: string
  storeName: string
  telNumber: string
}

function Footer({
  storeCode,
  systemDate,
  systemTime,
  storeName,
  telNumber,
}: FooterProps) {
  const styles: { [name: string]: CSSProperties } = {
    container: {
      backgroundColor: '#FFFFFF',
      padding: '24px',
      fontSize: '12px',
      lineHeight: '18px',
      display: 'grid',
      gridTemplateRows: 'auto',
      paddingBottom: '24px',
      color: '#282828',
      // borderBottom: '1px #5B5B5B dashed',
    },
    row: {
      paddingBottom: '8px',
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      justifyContent: 'space-between',
    },
  }

  return (
    <div style={styles.container}>
      <div style={styles.row}>
        <div>店舗No.{storeCode}</div>
        <div>
          {formatDateJa(systemDate)} {systemTime.substr(0, 5)}
        </div>
      </div>
      <div style={styles.row}>
        <div>{storeName}</div>
        <div>Tel {telNumber}</div>
      </div>
    </div>
  )
}

interface TradeHistoryDetailProps {
  record: TradeHistoryRecord
}

export function TradeHistoryDetail({ record }: TradeHistoryDetailProps) {
  return (
    <>
      <Receipt record={record} />
      {record.guaranteeDetails.map((guaranteeDetail, i) => {
        return (
          <Guarantee
            key={i}
            guaranteeDetail={guaranteeDetail}
            transactionNo={record.transactionNo}
            registerName={record.registerName}
            storeCode={record.storeCode}
            storeName={record.storeName}
            telNumber={record.telNumber}
            systemDate={record.systemDate}
            systemTime={record.systemTime}
            staffCode={record.staffCode}
          />
        )
      })}
      <Footer
        storeCode={record.storeCode}
        systemDate={record.systemDate}
        systemTime={record.systemTime}
        storeName={record.storeName}
        telNumber={record.telNumber}
      />
    </>
  )
}
