import { fork, call, put, select, takeEvery } from 'redux-saga/effects'

import {
  tokenSelector,
  apiHostSelector,
  LoginActionCreators,
} from '../modules/login'
import {
  MemberActionTypes,
  MemberActionCreators,
  UpdateMemberAction,
  CreateMemberAction,
  FetchOnetimeCodeAction,
  FetchMemberAction,
  SendAuthcodeAction,
  VerifyAuthcodeAction,
  FetchMemberRankInfoAction,
  migrateRequiredSelector,
} from '../modules/member'
import {
  fetchMember,
  updateMember,
  fetchOnetimeCode,
  createMember,
  sendAuthcode,
  verifyAuthcode,
  fetchMemberRankInfo,
  login,
} from '../utils/api'
import { ErrorResponse } from '../models/error'

// @ts-ignore
const liff = window.liff

export function* fetchMemberSaga(
  action: FetchMemberAction
): Generator<any, void, any> {
  try {
    const apiHost = yield select(apiHostSelector)
    const token = yield select(tokenSelector)
    yield put(MemberActionCreators.fetchMemberRequestAction())
    const data = yield call(fetchMember, apiHost, token)
    yield put(MemberActionCreators.fetchMemberSuccessAction(data))
  } catch (error) {
    yield put(MemberActionCreators.fetchMemberFailureAction(error))
  }
}

export function* createMemberSaga(
  action: CreateMemberAction
): Generator<any, void, any> {
  try {
    const params = action.payload.params
    const apiHost = yield select(apiHostSelector)
    const token = yield select(tokenSelector)
    yield put(MemberActionCreators.createMemberRequestAction())
    const data = yield call(createMember, apiHost, token, params)
    yield put(MemberActionCreators.createMemberSuccessAction(data))
  } catch (error) {
    yield put(MemberActionCreators.createMemberFailureAction(error))
  }
}

export function* updateMemberSaga(
  action: UpdateMemberAction
): Generator<any, void, any> {
  try {
    const params = action.payload.params
    const apiHost = yield select(apiHostSelector)
    const token = yield select(tokenSelector)
    yield put(MemberActionCreators.updateMemberRequestAction())
    const data = yield call(updateMember, apiHost, token, params)
    yield put(MemberActionCreators.updateMemberSuccessAction(data))
  } catch (error) {
    yield put(MemberActionCreators.updateMemberFailureAction(error))
  }
}

export function* sendAuthcodeSaga(
  action: SendAuthcodeAction
): Generator<any, void, any> {
  try {
    const email = action.payload.email
    const apiHost = yield select(apiHostSelector)
    const token = yield select(tokenSelector)
    const migrateRequired = yield select(migrateRequiredSelector)
    // 初回は場合は、0
    // 1度目の認証コード発行時に要統合となっている場合は、1
    const migrate = migrateRequired ? 1 : 0
    yield put(MemberActionCreators.sendAuthcodeRequestAction(email))
    const data = yield call(sendAuthcode, apiHost, token, email, migrate)
    yield put(
      MemberActionCreators.sendAuthcodeSuccessAction(
        email,
        data.status,
        migrateRequired ? migrateRequired : data.status === 1
      )
    )
  } catch (error) {
    yield put(MemberActionCreators.sendAuthcodeFailureAction(error))
  }
}

export function* verifyAuthcodeSaga(
  action: VerifyAuthcodeAction
): Generator<any, void, any> {
  try {
    const authcode = action.payload.authcode
    const apiHost = yield select(apiHostSelector)
    const token = yield select(tokenSelector)
    const migrateRequired = yield select(migrateRequiredSelector)
    // 認証コード発行時に要統合となっている場合は、1
    const migrate = migrateRequired ? 1 : 0
    yield put(MemberActionCreators.verifyAuthcodeRequestAction(authcode))
    yield call(verifyAuthcode, apiHost, token, authcode, migrate)
    yield put(MemberActionCreators.verifyAuthcodeSuccessAction(authcode))
  } catch (error) {
    yield put(MemberActionCreators.verifyAuthcodeFailureAction(error))
  }
}

export function* refetchMemberSaga(): Generator<any, void, any> {
  try {
    const migrateRequired = yield select(migrateRequiredSelector)
    // 統合の場合は、再度ログインAPIを実施
    if (migrateRequired) {
      const linetoken = liff.getAccessToken() ?? ''
      const apiHost = yield select(apiHostSelector)
      const data = yield call(login, apiHost, linetoken)
      yield put(LoginActionCreators.postLoginSuccess(data))
    }
    yield put(MemberActionCreators.fetchMemberAction())
  } catch (error: unknown) {
    yield put(LoginActionCreators.postLoginFailure(error as ErrorResponse))
  }
}

export function* fetchOnetimeCodeSaga(
  action: FetchOnetimeCodeAction
): Generator<any, void, any> {
  try {
    const apiHost = yield select(apiHostSelector)
    const token = yield select(tokenSelector)
    yield put(MemberActionCreators.fetchOnetimeCodeRequestAction())
    const data = yield call(fetchOnetimeCode, apiHost, token)
    yield put(MemberActionCreators.fetchOnetimeCodeSuccessAction(data))
  } catch (error) {
    yield put(MemberActionCreators.fetchOnetimeCodeFailureAction(error))
  }
}

export function* fetchMemberRankInfoSaga(
  action: FetchMemberRankInfoAction
): Generator<any, void, any> {
  try {
    const apiHost = yield select(apiHostSelector)
    const token = yield select(tokenSelector)
    yield put(MemberActionCreators.fetchMemberRankInfoRequestAction())
    const data = yield call(fetchMemberRankInfo, apiHost, token)
    yield put(MemberActionCreators.fetchMemberRankInfoSuccessAction(data))
  } catch (error) {
    yield put(MemberActionCreators.fetchMemberRankInfoFailureAction(error))
  }
}

function* handleFetchMemberSaga() {
  yield takeEvery(MemberActionTypes.FETCH_MEMBER, fetchMemberSaga)
}

function* handleCreateMemberSaga() {
  yield takeEvery(MemberActionTypes.CREATE_MEMBER, createMemberSaga)
}

function* handleUpdateMemberSaga() {
  yield takeEvery(MemberActionTypes.UPDATE_MEMBER, updateMemberSaga)
}

function* handleSendAuthcodeSaga() {
  yield takeEvery(MemberActionTypes.SEND_AUTHCODE, sendAuthcodeSaga)
}

function* handleVerifyAuthcodeSaga() {
  yield takeEvery(MemberActionTypes.VERIFY_AUTHCODE, verifyAuthcodeSaga)
}

function* handleVerifyAuthcodeSuccessSaga() {
  // AUTH_CODE が受理されたら member を refetch
  yield takeEvery(MemberActionTypes.VERIFY_AUTHCODE_SUCCESS, refetchMemberSaga)
}

function* handleFetchOnetimeCodeSaga() {
  yield takeEvery(MemberActionTypes.FETCH_ONETIME_CODE, fetchOnetimeCodeSaga)
}

function* handleFetchMemberRankInfoSaga() {
  yield takeEvery(
    MemberActionTypes.FETCH_MEMBER_RANK_INFO,
    fetchMemberRankInfoSaga
  )
}

export function* memberSagas() {
  yield fork(handleFetchMemberSaga)
  yield fork(handleCreateMemberSaga)
  yield fork(handleUpdateMemberSaga)
  yield fork(handleSendAuthcodeSaga)
  yield fork(handleVerifyAuthcodeSaga)
  yield fork(handleVerifyAuthcodeSuccessSaga)
  yield fork(handleFetchOnetimeCodeSaga)
  yield fork(handleFetchMemberRankInfoSaga)
}
