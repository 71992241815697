import React, { CSSProperties } from 'react'
import { Link } from 'react-router-dom'

import { Button } from '../atoms/Button'

interface SignUpButtonProps {
  to: string
}

const styles: { [name: string]: CSSProperties } = {
  button: {
    height: '68px',
  },
  primaryCaption: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  secondaryCaption: {
    fontSize: '10px',
    fontWeight: 'bold',
  },
}

export function SignUpButton({ to }: SignUpButtonProps) {
  return (
    <Link to={to}>
      <Button variant="orange" style={styles.button} type="button">
        <div style={styles.primaryCaption}>会員登録</div>
        <div style={styles.secondaryCaption}>
          会員サービスの利用に登録が必要なものがございます
        </div>
      </Button>
    </Link>
  )
}
